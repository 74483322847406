import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import dragdrop from "../../../assets/images/ic_drag_drop.svg";
import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
// import Draggable from "react-draggable";
import add_red from "../../../assets/images/add_red.svg";
import ApiConfig from "../../../api/ApiConfig";
import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from "../../../api/ApiServices";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import swal from "sweetalert";

const AddLocations = () => {
  const { customerData, sidebar, loadId } = useContext(AppContext);
  const [state, setState] = useState([]);
  console.log(state);
  const [address_id, setAddress_Id] = useState("");
  const [address_Priority, setAddress_Priority] = useState("");
  console.log(address_Priority);
  console.log(address_id);
  const [totalCount, setTotalCount] = useState(0);

  let [loading, setLoading] = useState(true);
  let [empty, setEmpty] = useState(false);
  const navigate = useNavigate();
  const handleCart = () => {
    if (totalCount === 0) {
      swal({
        title: "warning",
        text: "Please Add Locations",
        icon: "warning",
        button: true,
      });
    } else {
      changePriority();
    }
  };

  // React state to track order of items

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    // console.log("droppedItem", droppedItem);
    if (!droppedItem.destination) return;
    var updatedList = [...state];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    console.log("reorderedItem", reorderedItem);
    // setAddress_Id(reorderedItem.address_id)
    // setAddress_Priority(reorderedItem.address_priority)
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    // Update State
    // console.log("reorderedItem", updatedList);
    setState(updatedList);
  };

  useEffect(() => {
    getlocationList();

    return () => {};
  }, []);

  let mapping = [
    {
      id: address_id,
      priority: address_Priority,
    },
  ];
  console.log(mapping);

  const getlocationList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_LOCATION_API_CUSTOMERS,
      JSON.stringify({
        ...customerData,
        load_id: loadId.trip_id,
      })
    )
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          const data1 = data.json.location_list;
          console.log(data1);
          setState(data1);
          setTotalCount(data1.length ? data1.length : 0);

          setLoading(false);

          setAddress_Id(data.json.location_list[0].address_id);
          setAddress_Priority(data.json.location_list[0].address_priority);
        }
        if (data.json.result == false) {
          setState([]);
          setEmpty(true);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const changePriority = () => {
    setLoading(true);
    simplePostCall(
      ApiConfig.CHANGE_LOCATION_PRIORITY_API_CUSTOMERS,

      JSON.stringify({
        ...customerData,
        locations: state.map((item, index) => {
          return { id: item.address_id, priority: index + 1 };
        }),
      })
    )
      .then((res) => {
        console.log(res);
        if (res.result) {
          swal({
            title: "Success",

            text: res.message,

            icon: "success",

            button: true,
          }).then(() => {
            navigate("/SendLoadPackageDetails");
          });
        } else {
          swal({
            title: "warning",

            text: "Please Set Your Address priority!",

            icon: "warning",

            button: true,
          }).then(() => {
            // navigate("/SendLoadPackageDetails");
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Add Locations</div>
                    <div className="right">2/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from">
                      <div className="form-inner-top">
                        <div>
                          <h4>PICKUP/DROP LOCATION</h4>
                          <span>
                            You can drag and drop to re-arrange the location
                            based on your load priority
                          </span>
                        </div>
                        <div>
                          <Link to="#"></Link>
                          <Link to="/AddLocations1">
                            <img src={add_red} alt="" className="me-2" />
                            ADD
                          </Link>
                        </div>
                      </div>
                      <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                          {(provided) => (
                            <div
                              className="list-container"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {state &&
                                state.map((address_list, index) => (
                                  <Draggable
                                    key={address_list}
                                    draggableId={"address_list" + index}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className="dragable-card-main"
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        key={"address_list" + index}
                                      >
                                        <div className="left">
                                          <div className="d-flex justify-content-between">
                                            <p className="name">
                                              {address_list.address_name}
                                            </p>
                                            <img src={dragdrop} alt="" />
                                          </div>

                                          <p className="address">
                                            {address_list.address_details}
                                          </p>
                                          <p className="contact address">
                                            {address_list.address_phone_no}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    {/* <Link to="/VehicleInfo">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link> */}
                    <button
                      // onClick={changePriority}
                      type="button"
                      className="cx-text-btn"
                      onClick={handleCart}
                    >
                      {loading ? (
                        <div
                          class="spinner-border"
                          style={{
                            color: "#EE651E",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : (
                        <>
                          <span>Continue </span>

                          <img src={ic_continue_arrow_blue} />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddLocations;
