import React, { useContext, useEffect, useState } from "react";
import add_white from "../../../assets/images/add_white.svg";
import profile_1 from "../../../assets/images/profile_1.svg";
import vertical_dot from "../../../assets/images/vertical_dot.svg";
import profile_2 from "../../../assets/images/profile_2.svg";
import CompanySideBar from "../../../sharedComponent/CompanySideBar";
import SpinnerCmp from "../../../SpinnerCmp";
import { Link, useLocation } from "react-router-dom";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import swal from "sweetalert";

const base_url = "https://logistic.redbytes.in/";
const DriverListing = () => {
  const { customerData, sidebar } = useContext(AppContext);
  let [loading, setLoading] = useState(false);
  const [driverList, setDriverList] = useState([]);
  const { state } = useLocation();
  const getDriverList = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.DRIVER_LIST_API_COMPANY, customerData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          setDriverList(data.json.driver_list);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const onDeleteDriver = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_DELETE_DRIVER_API, {
      ...customerData,
      driver_id: id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then(() => {
            getDriverList();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  const AssignDriver = (driver_id, vehicle_id) => {
    PostCallWithErrorResponse(ApiConfig.COMPANY_ASSSIGN_DRIVER_TO_VEHICLE_API, {
      ...customerData,
      vehicle_id: vehicle_id,
      driver_id: driver_id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          });
        } else {
          swal({
            title: "warning",
            text: data.json.message,
            icon: "warning",
            button: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getDriverList();
  }, []);

  return (
    <React.Fragment>
      <main
        className={sidebar ? "cx-main cx-active p-0" : "cx-main p-0"}
        id="cxMain"
      >
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-12">
                <div className="service_loads d-flex justify-content-between">
                  <h4 className="title">Drivers [{driverList.length}]</h4>
                  <Link to="/AddDriver" className="blue_btn ms-4">
                    <img src={add_white} alt="" /> ADD DRIVERS
                  </Link>
                </div>
              </div>

              {driverList && driverList.length > 0 ? (
                driverList.map((driver, index) => {
                  return (
                    <div className="col-12">
                      <div className="service_load_block">
                        <div className="card card-style">
                          <div
                            className="card-body"
                            // style={{ height: "225px" }}
                          >
                            <div className="drivers_list d-flex justify-content-between border-0 p-0">
                              <div className="itemside">
                                {state ? (
                                  <ul>
                                    <div className="aside">
                                      <img
                                        src={
                                          driver.user_profile_pic
                                            ? base_url + driver.user_profile_pic
                                            : profile_1
                                        }
                                        className="header-profile-pic"
                                      />
                                    </div>

                                    <Link
                                      to={"/DriverDetails/" + driver.user_id}
                                    >
                                      <div className="info align-self-center">
                                        <p className="title">
                                          {driver.user_name}
                                        </p>
                                        <p className="smttitle">
                                          {driver.user_email}
                                        </p>
                                        <p className="smttitle">
                                          {driver.user_address}
                                        </p>
                                        {driver.driver_document_expire ? (
                                          <p className="smttitle">
                                            <span style={{ color: "red" }}>
                                              Driver document expiry-
                                            </span>
                                            {driver.driver_document_expire}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {driver.user_status ? (
                                          <p className="smttitle">
                                            <span style={{ color: "red" }}>
                                              Driver status-
                                            </span>
                                            {driver.user_status}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Link>

                                    <li>
                                      {driver.assigned_vehicle ? (
                                        <ul>
                                          <li>
                                            <span>
                                              {driver.assigned_vehicle
                                                .vehicle_name
                                                ? "assigned_vehicle : " +
                                                  driver.assigned_vehicle
                                                    .vehicle_name
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              {driver.assigned_vehicle.capacity
                                                ? "Vehicle Capacity: " +
                                                  driver.assigned_vehicle
                                                    .capacity
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              {driver.assigned_vehicle
                                                .vehicle_type
                                                ? "Vehicle Type: " +
                                                  driver.assigned_vehicle
                                                    .vehicle_type
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              {driver.assigned_vehicle.length
                                                ? "Vehicle Length: " +
                                                  driver.assigned_vehicle.length
                                                : ""}
                                            </span>
                                          </li>
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>

                                    <li>
                                      {driver.current_load ? (
                                        <ul>
                                          <li>
                                            <span>
                                              {driver.current_load.trip_weight
                                                ? "Trip weight: " +
                                                  driver.current_load
                                                    .trip_weight
                                                : ""}
                                            </span>
                                          </li>
                                          <li>
                                            <span>
                                              {driver.current_load
                                                .delivery_date_time
                                                ? "Delivery Date/time: " +
                                                  driver.current_load
                                                    .delivery_date_time
                                                : ""}
                                            </span>
                                          </li>
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </ul>
                                ) : (
                                  <div className="d-flex">
                                    <div className="aside">
                                      <img
                                        src={
                                          driver.user_profile_pic
                                            ? base_url + driver.user_profile_pic
                                            : profile_1
                                        }
                                        className="header-profile-pic"
                                      />
                                    </div>

                                    <Link
                                      to={"/DriverDetails/" + driver.user_id}
                                    >
                                      <div className="info align-self-center">
                                        <p className="title">
                                          {driver.user_name}
                                        </p>
                                        <p className="smttitle">
                                          {driver.user_email}
                                        </p>
                                        <p className="smttitle">
                                          {driver.user_address}
                                        </p>
                                        {driver.driver_document_expire ? (
                                          <p className="smttitle">
                                            <span>Driver document expiry:</span>
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              {driver.driver_document_expire}
                                            </span>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {driver.user_status ? (
                                          <p className="smttitle">
                                            <span>Driver status-</span>
                                            <span
                                              style={{
                                                color:
                                                  driver.user_status ===
                                                  "pending"
                                                    ? "red"
                                                    : "green",
                                              }}
                                            >
                                              {driver.user_status}
                                            </span>
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </Link>
                                  </div>
                                )}
                              </div>
                              {state ? (
                                <button
                                  style={{
                                    cursor: "pointer",
                                    height: "fit-content",
                                  }}
                                  className="blue_btn  "
                                  onClick={() =>
                                    AssignDriver(driver.user_id, state.id)
                                  }
                                >
                                  Assign
                                </button>
                              ) : (
                                <div className="btn-group drop_dot dropstart">
                                  <a
                                    href="#"
                                    className="btn dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={vertical_dot} alt="" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <li>
                                        <Link
                                          className="dropdown-item"
                                          to={"/VehicleListning"}
                                          state={{
                                            from: "driver",
                                            id: driver.user_id,
                                          }}
                                          /*           onClick={() =>
                                        onDeleteDriver(driver.user_id)
                                      } */
                                        >
                                          Assign vehicle
                                        </Link>
                                      </li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() =>
                                          onDeleteDriver(driver.user_id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="card card-style mb-4">
                  <div className="card-body">
                    <div className="drivers_list d-flex justify-content-between border-0 p-0">
                      <p>Records not found</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
};

export default DriverListing;
