import React, { useEffect, useContext, useState } from "react";

import Sidebar1 from "../Sidebar1";
import "../../assets/css/cx-terms.css";
import { AppContext } from "../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import SpinnerCmp from "../../SpinnerCmp";

export default function TermsConditions(props) {
  const { customerData, loading, setLoading, isCustomer, sidebar } =
    useContext(AppContext);
  const [link, setLink] = useState("");
  const getTermsAndConditionDetails = () => {
    PostCallWithErrorResponse(
      isCustomer
        ? ApiConfig.TERMS_AND_CONDITIONS_API
        : ApiConfig.TERMS_AND_CONDITIONS_API_COMPANY,
      customerData
    )
      .then((data) => {
        setLink(data.json.link);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  useEffect(() => {
    setLoading(true);
    getTermsAndConditionDetails();
  }, []);
  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxpro-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="cxpro-bdetails-content iframeOtrWrapper"
                    style={{ marginTop: 0 }}
                  >
                    <iframe src={link} width="100%" height={"100%"}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
}
