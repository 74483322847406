import React, { useEffect } from "react";
import ic_package_upload from "../assets/images/ic_package_upload.svg";
export default function DragAndDropForVehicles({ setFile }) {
  let id = "DropZone";
  useEffect(() => {
    initDragAndDrop();
  }, []);
  const initDragAndDrop = () => {
    var dropZone = document.getElementById(id);
    // Optional.   Show the copy icon when dragging over.  Seems to only work for chrome.
    dropZone &&
      dropZone.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = "copy";
      });
    dropZone && dropZone.addEventListener("drop", drop);
  };
  function drop(e) {
    e.stopPropagation();
    e.preventDefault();
    let file = "";
    if (e.target && e.target.files) {
      file = e.target.files;
    } else {
      file = e.dataTransfer.files;
    }
    setFile(file);
  }

  return (
    <>
      {/* <div className="drag-drop-main">
        <div className="inner">
          <img src={ic_package_upload} alt="" />
          <br />
          <label htmlFor="">Drag & Drop files to upload</label>
          <br />
          <label htmlFor="">Or</label>
          <br />
          <Link to="#">Browse Files</Link>
        </div>
      </div> */}
      <div className="drag-drop-main" id={id}>
        <div className="inner">
          <img src={ic_package_upload} alt="" />
          <div>
            <label className="m-0 mt-3 drag-drop">
              Drag & Drop files to upload
            </label>
            <br />
            <label className="drag-drop">OR</label>
          </div>
          <a
            href="#"
            style={{ color: "#4D9DE7" }}
            onClick={(e) => {
              e.preventDefault();
              document.getElementById("uploadVehicleImages").click();
            }}
          >
            Browse Files
          </a>
          <input
            multiple
            onChange={drop}
            accept="image/png, image/gif, image/jpeg"
            id={"uploadVehicleImages"}
            type="file"
            name="images"
            style={{ display: "none" }}
          />
        </div>
      </div>
    </>
  );
}
