import React from 'react'
import CompanySideBar from '../../../sharedComponent/CompanySideBar'
import load_image from '../../../assets/images/Details/load_image.png'
import CompanyHeader from '../../../sharedComponent/CompanyHeader'

const PackageDetails = () => {
  return (
    <React.Fragment>
  
        <main className="cx-main p-0" id='cxMain'>
        <section id="cxp-details">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="cxp-bdetails-content" style={{marginTop:"0"}}>
                            <div className="cxp-details-banner"></div>
                            <div className="cxp-details">
                                <div className="cxp-load-image">
                                    <img src={load_image} />
                                    <div className="cxp-load-graphic"></div>
                                    <div className="cxp-load-name">
                                        <label>Package Name</label><span>My Package</span>
                                    </div>
                                </div>
                                <ul className="cxp-details-list">
                                    <li>
                                        <label>Material Type</label>
                                        <p>Household Goods</p>
                                    </li>
                                    <li>
                                        <label>Weight</label>
                                        <p>20 kg</p>
                                    </li>
                                    <li>
                                        <label>Vehicle</label>
                                        <p>Pickup, 3 Ton</p>
                                    </li>
                                    <li>
                                        <label>Distance</label>
                                        <p>10km</p>
                                    </li>
                                    <li>
                                        <label>Delivery Cost</label>
                                        <p>$200</p>
                                    </li>
                                </ul>
                                <div className="cxp-dcondition border-0 pb-0">
                                    <label>Delivery Conditions</label>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.</p>
                                </div>
                                {/* <!-- <div className="cxp-images">
                                    <label>Package Images</label>
                                    <img src="images/Details/package_image_01.png" />
                                    <img src="images/Details/package_image_02.png" />
                                    <img src="images/Details/package_image_03.png" />
                                </div> --> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cxp-details-content" style={{height:"90%"}}>
                            <div className="cxp-content-title">
                                <h4>From</h4>
                            </div>
                            <div className="cxp-content-details">
                                <ul>
                                    <li>
                                        <h4>Leroy Sane</h4>
                                    </li>
                                    <li><label>No.125/2, Kalas Area, Visharantwadi,</label></li>
                                    <li><label>Pune - 411015.</label></li>
                                    <li><a href="#" className="blue_link">8943567421</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cxp-details-content" style={{height:"90%"}}>
                            <div className="cxp-content-title">
                                <h4>To</h4>
                            </div>
                            <div className="cxp-content-details">
                                <ul>
                                    <li>
                                        <h4>Leroy Sane</h4>
                                    </li>
                                    <li><label>No.125/2, Kalas Area, Visharantwadi,</label></li>
                                    <li><label>Pune - 411015.</label></li>
                                    <li><a href="#" className="blue_link">8943567421</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

    </React.Fragment>
  )
}

export default PackageDetails