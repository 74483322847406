import React, { useContext, useState, useEffect } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import upload from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/cross.svg";
import { FileUploader } from "react-drag-drop-files";
import { AppContext } from "../../../context/user/AppContext";
import LoadingBar from "react-top-loading-bar";
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

const UploadDocuments = (props) => {
  let isstatefrom = props.isstatefrom;
  const { state1 } = useLocation();
  const [stateVal, setstateVal] = useState(state1 ? state1.val : "customer");

  const { CompanyUserData, setCompanyUserData } = useContext(AppContext);

  const navigate = useNavigate();

  const [state, setState] = useState({
    tradeLicenseFile: CompanyUserData.license_file
      ? CompanyUserData.license_file
      : "",
    tradeLicenseFileBase64: CompanyUserData.license_file
      ? CompanyUserData.license_file
      : "",
    tradeLicenseNumber: CompanyUserData.license_number
      ? CompanyUserData.license_number
      : "",
    vatCertificateNumber: CompanyUserData.tax_certificate_no
      ? CompanyUserData.tax_certificate_no
      : "",
    vatCertificateFile: CompanyUserData.tax_file
      ? CompanyUserData.tax_file
      : "",
    vatCertificateFileBase64: CompanyUserData.tax_file
      ? CompanyUserData.tax_file
      : "",
    tradeLicenseExpiry: CompanyUserData.license_expiry_date
      ? CompanyUserData.license_expiry_date
      : "",
    emirates_id_file: CompanyUserData.emirates_id_file
      ? CompanyUserData.emirates_id_file
      : "",

    emirates_id_fileBase64: CompanyUserData.emirates_id_file
      ? CompanyUserData.license_file
      : "",

    license_number: CompanyUserData.license_number
      ? CompanyUserData.license_number
      : "",

    license_expiry_date: CompanyUserData.license_expiry_date
      ? CompanyUserData.license_expiry_date
      : "",
    driver_emirates_id: CompanyUserData.driver_emirates_id
      ? CompanyUserData.driver_emirates_id
      : "",
  });
  console.log(state);

  // const [tradeLicenseFile, setTradeLicenseFile] = useState([]);
  // const [taxFile, setTaxFile] = useState([]);

  const handleChange = (file) => {
    getBase64(file, (result) => {
      setState({
        ...state,
        tradeLicenseFile: file,
        tradeLicenseFileBase64: result,
      });
    });
  };
  const handleCertificate = (file) => {
    getBase64(file, (result) => {
      setState({
        ...state,
        vatCertificateFile: file,
        vatCertificateFileBase64: result,
      });
    });
  };
  const handleCertificateemirates = (file) => {
    getBase64(file, (result) => {
      setState({
        ...state,
        emirates_id_file: file,
        emirates_id_fileBase64: result,
      });
    });
  };

  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const removefile = (single) => {
    // setCompanyUserData({ ...CompanyUserData, license_file: null });
    // setTradeLicenseFile(null)
    setState({ ...state, tradeLicenseFile: null });
  };
  const removelicence = (single) => {
    // setCompanyUserData({ ...CompanyUserData, licence: null });
    // setTaxFile(null)
    setState({ ...state, vatCertificateFile: null });
  };
  const handleOnNext = () => {
    props.nextCallback(true, {
      license_number: state.tradeLicenseNumber,
      license_file: state.tradeLicenseFile,
      tax_file: state.vatCertificateFile,
      tax_certificate_no: state.vatCertificateNumber,
      license_expiry_date: state.tradeLicenseExpiry,
      license_number: state.license_number,
      driver_emirates_id: state.driver_emirates_id,
      emirates_id_file: state.emirates_id_file,
    });
  };

  return (
    <React.Fragment>
      <div className="right_block cx-reg-content">
        <div className="d-flex justify-content-between">
          <h2>Upload Documents</h2>
          <p className="step_num">2/2</p>
        </div>
        {isstatefrom == "Individual" ? (
          <div className="register_right mt-4 ">
            <div className="row">
              <div className="col-lg-6 pe-4">
                <label id="cx-rfname">Driving License</label>
                <div className="drop_block mb-3">
                  <div className="vertical_m text-center">
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                      fileOrFiles={state.tradeLicenseFile}
                    />

                    {/*   <img src={upload} alt=""/> */}
                    {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                  </div>
                </div>
                {state.tradeLicenseFile ? (
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>{state.tradeLicenseFile.name}</label>
                    <a onClick={() => removefile()}>
                      <img src={cross} alt="" />
                    </a>
                  </div>
                ) : (
                  ""
                )}

                <div className="drop_input">
                  <input
                    type="text"
                    className="form-control"
                    id="cx-rfname"
                    style={{ height: "44px" }}
                    placeholder="Enter licence Number"
                    value={state.license_number}
                    onChange={(e) => {
                      setState({
                        ...state,
                        license_number: e.target.value,
                      });
                    }}
                  />
                </div>
                <label style={{ marginLeft: "8px", marginTop: "25px" }}>
                  License expiry date
                </label>
                <div className="drop_input ">
                  <input
                    style={{ marginTop: "0px" }}
                    type="date"
                    className="form-control"
                    id="cx-rfname"
                    placeholder="Enter License expiry date"
                    value={state.tradeLicenseExpiry}
                    onChange={(e) => {
                      setState({
                        ...state,
                        tradeLicenseExpiry: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 ps-4">
                <label id="cx-rfname">Emirates Id</label>
                <div className="drop_block mb-3">
                  <div className="vertical_m text-center">
                    <FileUploader
                      handleChange={handleCertificateemirates}
                      name="licence"
                      types={fileTypes}
                      fileOrFiles={state.emirates_id_file}
                    />
                    {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                  </div>
                </div>
                {state.vatCertificateFile ? (
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>{state.emirates_id_file.name}</label>
                    <a onClick={() => removelicence()}>
                      <img src={cross} alt="" />
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div className="drop_input">
                  <input
                    type="text"
                    className="form-control"
                    id="cx-rfname"
                    style={{ height: "44px" }}
                    placeholder="Enter emirates id"
                    value={state.driver_emirates_id}
                    onChange={(e) => {
                      setState({
                        ...state,
                        driver_emirates_id: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="register_right mt-4 ">
            <div className="row">
              <div className="col-lg-6 pe-4">
                <label id="cx-rfname">Trade License</label>
                <div className="drop_block mb-3">
                  <div className="vertical_m text-center">
                    <FileUploader
                      handleChange={handleChange}
                      name="file"
                      types={fileTypes}
                      fileOrFiles={state.tradeLicenseFile}
                    />

                    {/*   <img src={upload} alt=""/> */}
                    {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                  </div>
                </div>
                {state.tradeLicenseFile ? (
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>{state.tradeLicenseFile.name}</label>
                    <a onClick={() => removefile()}>
                      <img src={cross} alt="" />
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <p className="text-center">Or</p>
                <div className="drop_input">
                  <label style={{ marginLeft: "8px", marginTop: "25px" }}>
                    Enter Trade License Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cx-rfname"
                    style={{ height: "44px", border: "1px solid #ccc" }}
                    placeholder="Enter Trade licence Number"
                    value={state.tradeLicenseNumber}
                    onChange={(e) => {
                      setState({
                        ...state,
                        tradeLicenseNumber: e.target.value,
                      });
                    }}
                  />
                </div>
                <label style={{ marginLeft: "8px", marginTop: "25px" }}>
                  expiry date
                </label>
                <div className="drop_input ">
                  <input
                    style={{ marginTop: "0px" }}
                    type="date"
                    className="form-control"
                    id="cx-rfname"
                    placeholder="Enter Emirates Id"
                    value={state.tradeLicenseExpiry}
                    onChange={(e) => {
                      setState({
                        ...state,
                        tradeLicenseExpiry: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 ps-4">
                <label id="cx-rfname">Vat Certificate</label>
                <div className="drop_block mb-3">
                  <div className="vertical_m text-center">
                    <FileUploader
                      handleChange={handleCertificate}
                      name="licence"
                      types={fileTypes}
                      fileOrFiles={state.vatCertificateFile}
                    />
                    {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                  </div>
                </div>
                {state.vatCertificateFile ? (
                  <div className="uploaded_file d-flex justify-content-between">
                    <label>{state.vatCertificateFile.name}</label>
                    <a onClick={() => removelicence()}>
                      <img src={cross} alt="" />
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <p className="text-center">Or</p>

                <div className="drop_input">
                  <label style={{ marginLeft: "8px", marginTop: "25px" }}>
                    Enter Vat Certificate Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cx-rfname"
                    style={{ height: "44px", border: "1px solid #ccc" }}
                    placeholder="Enter Vat Certificate Number"
                    value={state.vatCertificateNumber}
                    onChange={(e) => {
                      setState({
                        ...state,
                        vatCertificateNumber: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="rfbtn_block">
        <div className="d-flex justify-content-between">
          <button onClick={props.prevCallback} className="orange_btn">
            Prev
          </button>
          <button onClick={handleOnNext} className="orange_btn">
            Next
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadDocuments;
