import React from "react";
import load_image from "../../assets/images/Details/load_image.png";
import direction from "../../assets/images/direction.svg";
import package_1 from "../../assets/images/package_1.svg";
import package_2 from "../../assets/images/package_2.svg";
import vehicle from "../../assets/images/vehicle.jpeg";
import edit_blue from "../../assets/images/edit_blue.svg";
import drop from "../../assets/images/drop_off_image.png";
import pan from "../../assets/images/pan_id.svg";
import { Link } from "@mui/material";
import "../../assets/css/loaddetailscompleted.scss";

const LoadDetailsCompleted = () => {
  return (
    <main className="cx-main p-0 load-details-main" id="cxMain">
      <section id="cxp-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cxp-bdetails-content" style={{ marginTop: "0" }}>
                <div className="cxp-details-banner"></div>
                <div className="cxp-details">
                  <div className="cxp-load-image">
                    <img src={load_image} />
                    <div className="cxp-load-graphic"></div>
                    <div className="cxp-load-name">
                      <label>Load Name</label>
                      <span className="package-name">My Package</span>
                    </div>
                  </div>
                  <ul className="cxp-details-list">
                    <li>
                      <label>Material Type</label>
                      <p>Household Goods</p>
                    </li>
                    <li>
                      <label>Weight</label>
                      <p>20 kg</p>
                    </li>
                    <li>
                      <label>Vehicle</label>
                      <p>Pickup, 3 Ton</p>
                    </li>
                    <li>
                      <label>Distance</label>
                      <p>10km</p>
                    </li>
                    <li>
                      <label>Delivery Cost</label>
                      <p>$200</p>
                    </li>
                  </ul>
                  <div className="cxp-dcondition border-0 pb-0">
                    <div className="cxp-content-title">
                      <h4>DELIVERY DETAILS</h4>
                    </div>
                    <div className="load-details-delevery-details d-flex justify-content-between">
                      <div>
                        <div>
                          <label htmlFor="">Current Location</label>
                          <span className="black-value">Queen St</span>
                        </div>
                        <div>
                          <label htmlFor="">Status</label>
                          <span
                            style={{
                              color: "#17c238",
                            }}
                          >
                            Delivered
                          </span>
                        </div>
                      </div>
                      {/* <div className="text-end">
                        <p>
                          <img src={direction} alt="" />
                        </p>

                        <p className="black-value">10 km away</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div
                className="cxp-details-content pickup-location-main"
                style={{ height: "90%" }}
              >
                <div className="cxp-content-title">
                  <h4>PICKUP LOCATIONS</h4>
                </div>
                <div className="pickup-location row" id="pickup-location">
                  <div className="col-lg-6 pickup-location-left">
                    <div
                      className="cxp-details-content"
                      style={{ height: "90%" }}
                    >
                      <div className="cxp-content-details">
                        <ul>
                          <li className="d-flex">
                            <h4>Leroy Sane</h4>{" "}
                            <Link to="#">
                              <img src={edit_blue} alt="" className="ms-2" />
                            </Link>
                          </li>
                          <li>
                            <label>No.125/2, Kalas Area, Visharantwadi,</label>
                          </li>
                          <li>
                            <label>Pune - 411015.</label>
                          </li>
                          <li>
                            <a href="#" className="blue_link">
                              8943567421
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pickup-location-right">
                    <div
                      className="cxp-details-content "
                      style={{ height: "90%" }}
                    >
                      <div className="cxp-content-details">
                        <ul>
                          <li className="d-flex">
                            <h4>Leroy Sane</h4>{" "}
                            <Link to="#">
                              <img src={edit_blue} alt="" className="ms-2" />
                            </Link>
                          </li>
                          <li>
                            <label>No.125/2, Kalas Area, Visharantwadi,</label>
                          </li>
                          <li>
                            <label>Pune - 411015.</label>
                          </li>
                          <li>
                            <a href="#" className="blue_link">
                              8943567421
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cxp-details-content" style={{ height: "90%" }}>
                <div className="cxp-content-title">
                  <h4>DROP LOCATIONS</h4>
                </div>
                <div className="pickup-location row" id="pickup-location">
                  <div className="col-lg-6 pickup-location-left">
                    <div
                      className="cxp-details-content"
                      style={{ height: "90%" }}
                    >
                      <div className="cxp-content-details">
                        <ul>
                          <li className="d-flex">
                            <h4>Leroy Sane</h4>{" "}
                            <Link to="#">
                              <img src={edit_blue} alt="" className="ms-2" />
                            </Link>
                          </li>
                          <li>
                            <label>No.125/2, Kalas Area, Visharantwadi,</label>
                          </li>
                          <li>
                            <label>Pune - 411015.</label>
                          </li>
                          <li>
                            <a href="#" className="blue_link">
                              8943567421
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pickup-location-right">
                    <div
                      className="cxp-details-content "
                      style={{ height: "90%" }}
                    >
                      <div className="cxp-content-details">
                        <ul>
                          <li className="d-flex">
                            <h4>Leroy Sane</h4>{" "}
                            <Link to="#">
                              <img src={edit_blue} alt="" className="ms-2" />
                            </Link>
                          </li>
                          <li>
                            <label>No.125/2, Kalas Area, Visharantwadi,</label>
                          </li>
                          <li>
                            <label>Pune - 411015.</label>
                          </li>
                          <li>
                            <a href="#" className="blue_link">
                              8943567421
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="cxp-details-content">
                <div class="fullwidth desktop-stepper">
                  <div class="container separator">
                    <h6 style={{ color: "#EE651E" }}>Delivery Status</h6>

                    <ul class="progress-tracker progress-tracker--text">
                      <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Package Accepted</h6>
                          <p>25th Jan 2022, 10:30 AM</p>
                        </div>
                      </li>

                      <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Pakcage Picked Up</h6>
                          <p> 25th Jan 2022, 10:30 AM Kothrud, Maharashtra</p>
                        </div>
                      </li>

                      <li class="progress-step is-active" aria-current="step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">
                            Package has arrived at Jofar facility
                          </h6>
                          <p>
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>

                      <li class="progress-step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">
                            Package has left an Jofar facility
                          </h6>
                          <p>
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>

                      <li class="progress-step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Package Delivered</h6>
                          <p>
                            {" "}
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* ---------- */}

                <div class="fullwidth responsive-stepper">
                  <div class="container separator">
                    <h6 style={{ color: "#EE651E" }}>Delivery Status</h6>

                    <ul class="progress-tracker progress-tracker--vertical">
                      <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Package Accepted</h6>
                          <p>25th Jan 2022, 10:30 AM</p>
                        </div>
                      </li>

                      <li class="progress-step is-complete">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Pakcage Picked Up</h6>
                          <p>25th Jan 2022, 10:30 AM Kothrud, Maharashtra</p>
                        </div>
                      </li>

                      <li class="progress-step is-active" aria-current="step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">
                            Package has arrived at Jofar facility
                          </h6>
                          <p>
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>

                      <li class="progress-step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">
                            Package has left an Jofar facility
                          </h6>
                          <p>
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>

                      <li class="progress-step">
                        <div class="progress-marker"></div>
                        <div class="progress-text">
                          <h6 class="progress-title">Package Delivered</h6>
                          <p>
                            {" "}
                            26th Jan 2022, 10:30 AM Viman Nagar, Maharashtra
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="packages mt-4">
                <div class="row gx-5 gy-4">
                  <div class="col-lg-6 col-sm-12">
                    <div class="details-card">
                      <div className="d-flex justify-content-between">
                        <h6
                          className="mb-2"
                          style={{ color: "#EE651E", fontSize: "0.85rem" }}
                        >
                          Drop Off Image
                        </h6>
                        <h6
                          className="mb-2"
                          style={{ color: "#56B3F4", fontSize: "0.85rem" }}
                        >
                          Download PDF
                        </h6>
                      </div>
                      <img className="details-img" src={drop} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div class="details-card">
                      <div className="d-flex justify-content-between">
                        <h6
                          className="mb-2"
                          style={{ color: "#EE651E", fontSize: "0.85rem" }}
                        >
                          Recipient Signature
                        </h6>
                        <h6
                          className="mb-2"
                          style={{ color: "#56B3F4", fontSize: "0.85rem" }}
                        >
                          Download PDF
                        </h6>
                      </div>
                      <img className="details-img" src={drop} alt="" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div class="details-card">
                      <div className="d-flex justify-content-between">
                        <h6
                          className="mb-2"
                          style={{ color: "#EE651E", fontSize: "0.85rem" }}
                        >
                          Recipient ID Proof
                        </h6>
                        <h6
                          className="mb-2"
                          style={{ color: "#56B3F4", fontSize: "0.85rem" }}
                        >
                          Download PDF
                        </h6>
                      </div>
                      <h6>PAN</h6>

                      <img className="details-img" src={pan} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoadDetailsCompleted;
