import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../../context/user/AppContext'
import Map from '../../customer/Sent-Load/Map'
import pack1 from '../../../assets/images/package_1.svg'
import whatsapp from '../../../assets/images/whatsapp.svg'
import star from '../../../assets/images/star_highlighted.svg'
import call from '../../../assets/images/call.svg'
import next_arrow_white from '../../../assets/images/Live/next_arrow_white.svg'
import g_dot from '../../../assets/images/from_loc_red.svg'
import b_dot from '../../../assets/images/from_loc_red (1).svg'
import '../../../assets/css/cx-sLoad.css'
import '../../../assets/css/mapDetailsOngoing.scss'
import ic_loads from '../../../assets/images/ic_location.svg'
import GoogleMapReact from 'google-map-react'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg'
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import locationis from '../../../assets/images/map.png'
import { Link, useLocation, useParams } from 'react-router-dom'

import { height } from '@mui/system'
import ApiConfig from '../../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import Geocode from 'react-geocode'
export default function MapDetailsongoing() {
  const google = window.google

  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey('AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw')

  // set response language. Defaults to english.
  Geocode.setLanguage('en')

  const directionsService = new google.maps.DirectionsService()
  const directionsRenderer = new google.maps.DirectionsRenderer()
  let { id } = useParams()
  const { sidebar, customerData } = useContext(AppContext)

  // const [loading, setLoading] = useState(false)
  const [requestedDetalis, setRequestedDetalis] = useState([])
  console.log(requestedDetalis)
  const [loadDriverDetails, setLoadDriverDetails] = useState([])
  const [loadDetails, setLoadDetails] = useState([])
  const [loadDetailsPackage, setLoadDetailsPackage] = useState([])
  const [loadDetailsMap, setLoadDetailsMap] = useState([])

  const URL = 'https://logistic.redbytes.in/'
  const [locationData, setLocationData] = useState({})
  console.log(locationData)
  let [marker, setMarker] = useState(null)

  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  })
  const coords = { latitude: 19, longitude: 75 }
  const [driverMap, setDriverMap] = useState([])
  console.log(driverMap)
  const [start, setStart] = useState({})
  console.log(start)
  const [driver, setDirver] = useState({ lat: 21.1458, lng: 79.0882 })
  console.log('driver', driver)
  const [end, setEnd] = useState({})
  const [waypointsMap, setwaypoints] = useState({})

  const [loading, setLoading] = useState(true)
  const {} = useContext(AppContext)
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null)
  const AnyReactComponent = ({ text }) => <div>{text}</div>
  useEffect(() => {
    connectiongetprofile()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will be called every 10 mint')
      // connectiongetprofile()
      setDriverMap(driverMap)
    }, 10000000)

    return () => clearInterval(interval)
  }, [])
  // useEffect(() => {
  //   if (driverMap.length > 0) {
  //     setDriverMap({
  //       lat: Number(driverMap.latitude),
  //       lng: Number(driverMap.longitude),
  //     })
  //   }
  // }, [start, driver])

  useEffect(() => {
    console.log('location', locationData)
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData
      // or else call that isApiLoaded function and pass-on these arguments
      apiIsLoaded(map, maps)
    }
  }, [locationData])
  const connectiongetprofile = () => {
    setLoading(true)

    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'customer'
        ? ApiConfig.LOAD_SERVICE_REQUEST_MAP
        : ApiConfig.DRIVER_MAP_API_COMPANY_ONGOING,

      {
        ...customerData,
        load_id: id,
      },
    ).then((res) => {
      console.log(res)
      let locationData = res.json.load_details && res.json.load_details
      setLoading(false)
      setLoadDriverDetails(res.json.driver_details)
      setLoadDetails(res.json.load_details)
      setLoadDetailsPackage(res.json.package_list)
      setLoadDetailsMap(res.json.locations)
      setDriverMap(locationData.current_location)
    })
  }

  useEffect(() => {
    if (loadDetailsMap.length > 0) {
      console.log(loadDetailsMap)

      setStart({
        lat: Number(loadDetailsMap[0].address_latitude),
        lng: Number(loadDetailsMap[0].address_longitude),
      })

      let filteredrWaypoints =
        Array.isArray(loadDetailsMap) &&
        loadDetailsMap.slice(1, -1).map((data) => {
          console.log(data)

          return {
            location: {
              lat: Number(data.address_latitude),
              lng: Number(data.address_longitude),
            },
          }
        })

      console.log(filteredrWaypoints)
      if (filteredrWaypoints.length >= 0) {
        setwaypoints(filteredrWaypoints)
      }
      setEnd({
        lat: Number(loadDetailsMap[loadDetailsMap.length - 1].address_latitude),
        lng: Number(
          loadDetailsMap[loadDetailsMap.length - 1].address_longitude,
        ),
      })
    }
  }, [loadDetailsMap])

  const apiIsLoaded = (map, maps) => {
    console.log(map)

    // let markers = new google.maps.Marker({
    //   // position: { lat: start.lat, lng: start.lng },
    //   position: start,
    //   map:map,

    //   icon: {
    //     url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
    //   },
    // })

    // let markerEnd = new google.maps.Marker({
    //   position: end,
    //   map:map,

    //   icon: {
    //     url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
    //   },
    // })

    setIsGoogleApiLoadedObj(map)
    console.log(start, end)
    directionsRenderer.setDirections({ routes: [] })

    directionsRenderer.setMap(map)

    setMarker(marker)
    // stopover: true,
    const origin = start
    const destination = end
    const waypt = waypointsMap

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        waypoints: waypt,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          console.log(result.geocoded_waypoints[(0, 1)])
          /*  result.geocoded_waypoints = result.geocoded_waypoints[(0, 1)]; */
          console.log('result ::', result)
          directionsRenderer.setDirections(result)
        } else {
          console.error(`error fetching directions ${result}`)
        }
      },
    )
    /// Const Driver //
  }

  useEffect(() => {
    console.log(start, end)

    apiIsLoaded(googleApiObj)
  }, [start, end])

  return (
    <main
      className={sidebar ? 'cx-main cx-active' : 'cx-main'}
      id="cxMain"
      style={{ padding: '0', position: 'relative' }}
    >
      {loading ? (
        ''
      ) : (
        <section id="cxs-locations">
          <div className="PickupLocationMap-main">
            <div style={{ height: 'calc( 100vh - 60px)' }} id="map">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw',
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultCenter={{
                  lat: coords ? coords.latitude : 19,
                  lng: coords ? coords.longitude : 75,
                }}
                defaultZoom={4}
                onGoogleApiLoaded={({ map, maps }) =>
                  setLocationData({ map, maps })
                }
              >
                <div lat={driverMap.latitude} lng={driverMap.longitude}>
                  <img className="img-fluid" src={ic_loads} alt="" />
                </div>
              </GoogleMapReact>
            </div>
            <button
              class="canvas-btn-right"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <img src={next_arrow_white} alt="" />
            </button>
            <div
              class="offcanvas offcanvas-end"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div class="offcanvas-body">
                <button
                  class="canvas-btn"
                  type="button"
                  tabIndex="-1"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <img src={next_arrow_white} alt="" />
                </button>
                <div className="location-card position-relative">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="m-0">
                        Delivery No.
                        <span>{loadDetails.trip_reference_no}</span>
                      </p>
                      <p className="m-0">
                        Current Location
                        <span>Queen St</span>
                      </p>
                    </div>
                    <img src={next_arrow_white} alt="" />
                  </div>
                  <p className="m-0">
                    Status
                    <span style={{ color: '#FF8900' }}>
                      {loadDetails.trip_status}
                    </span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <h5>&#8377;{loadDetails.delivery_cost}</h5>
                    <p className="location-status">
                      <Link
                        // to="/LiveTrackingLoadDetails"
                        state={loadDetails}
                        to={'/OverAllStatus/' + loadDetails.trip_id}
                      >
                        View Status
                      </Link>
                    </p>
                  </div>
                </div>
                {loadDetailsMap &&
                  loadDetailsMap.map((loadDetailsMapItem, index) => {
                    return (
                      <div className="col-lg-12 col-10">
                        <div className="package-details">
                          <span className="package-summary">
                            {loadDetailsMapItem.package_list[0].type} /
                            DELIVERING TO
                          </span>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <img className="me-1" src={g_dot} alt="" />
                              <h6 className="m-0 mt-2">
                                {loadDetailsMapItem.address_name}
                              </h6>
                            </div>
                            <img src={call} alt="" />
                          </div>

                          <p className="m-0">
                            {loadDetailsMapItem.address_details}
                          </p>
                          <p className="m-0" style={{ fontWeight: 'bold' }}>
                            {loadDetailsMapItem.address_phone_no}
                          </p>
                          <p className="ongoing-delivered">Delivered</p>

                          {loadDetailsMapItem.distance[0] ? (
                            <p
                              className="ongoing-delivered"
                              style={{ color: '#5F5F5F' }}
                            >
                              {loadDetailsMapItem.distance}
                              [Arriving in {loadDetailsMapItem.duration}]
                              <span
                                className="ms-2"
                                style={{ color: '#FF8900' }}
                              >
                                In Transit
                              </span>
                            </p>
                          ) : (
                            <p
                              className="ongoing-delivered"
                              style={{ color: '#5F5F5F' }}
                            >
                              {loadDetailsMapItem.distance}
                              [Arriving in {loadDetailsMapItem.duration}]
                              <span
                                className="ms-2"
                                style={{ color: '#FF8900' }}
                              >
                                In Transit
                              </span>
                            </p>
                          )}

                          <hr
                            className="mt-0"
                            style={{
                              color: '#D0D0D0',
                              height: '2px',
                              width: '100%',
                            }}
                          />
                          <div className="ongoing-packages">
                            <p>PACKAGES</p>
                            <div className="d-flex justify-content-between package-item">
                              <div>
                                <p className="m-0">
                                  {
                                    loadDetailsMapItem.package_list[0]
                                      .package_name
                                  }
                                  <span
                                    style={{
                                      color: '#ee651e',
                                    }}
                                  >
                                    [{loadDetailsMapItem.package_list[0].type}]
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className="m-0">
                                  Qty{' '}
                                  <span>
                                    {
                                      loadDetailsMapItem.package_list[0]
                                        .trip_quantity
                                    }
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                <div className="driver-details">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      {URL + loadDriverDetails.user_profile_pic &&
                      URL + loadDriverDetails.user_profile_pic ? (
                        <img
                          className="img-fluid driver-img me-2"
                          src={URL + loadDriverDetails.user_profile_pic}
                          alt=""
                        />
                      ) : (
                        <img src={ic_star_grey} />
                      )}

                      <div>
                        <p className="driver-name m-0 mt-2">
                          {loadDriverDetails.user_name}
                        </p>
                        <div>
                          {/* <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" /> */}
                          {loadDriverDetails.rating > 0 ? (
                            <img src={ic_star_highlighted} />
                          ) : (
                            <img src={ic_star_grey} />
                          )}
                          {loadDriverDetails.rating > 1 ? (
                            <img src={ic_star_highlighted} />
                          ) : (
                            <img src={ic_star_grey} />
                          )}
                          {loadDriverDetails.rating > 2 ? (
                            <img src={ic_star_highlighted} />
                          ) : (
                            <img src={ic_star_grey} />
                          )}
                          {loadDriverDetails.rating > 3 ? (
                            <img src={ic_star_highlighted} />
                          ) : (
                            <img src={ic_star_grey} />
                          )}
                          {loadDriverDetails.rating > 4 ? (
                            <img src={ic_star_highlighted} />
                          ) : (
                            <img src={ic_star_grey} />
                          )}
                          <span className="rating">
                            ({loadDriverDetails.reviewed_count})
                          </span>
                        </div>
                      </div>
                    </div>

                    <img className="call-img mt-2" src={call} alt="" />
                  </div>
                  <div className="d-flex justify-content-between">
                    <Link
                      state={loadDriverDetails}
                      to={'/CustomerDriverDetail/' + loadDriverDetails.user_id}
                      // to="/CustomerDriverDetail"
                    >
                      <p className="m-0 mt-1 driver-view-details">
                        View Details
                      </p>
                    </Link>
                    <img src={whatsapp} alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="location-img">
            <img src={location} alt="" />
          </div>
          <div className="location-img2">
            <img src={location} alt="" />
          </div> */}
          </div>
        </section>
      )}
    </main>
  )
}
