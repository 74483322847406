import React, { useContext, useEffect, useState } from "react";
import arrow_white from "../../assets/images/login/arrow_white.svg";
import driver_profile_default from "../../assets/images/login/driver_profile_default.svg";
import eye_closed from "../../assets/images/login/eye_closed.svg";
import eye_opened from "../../assets/images/login/eye.svg";

import ic_camera from "../../assets/images/login/ic_camera.svg";
import login_bg from "../../assets/images/login/login_bg.svg";
import login_logo from "../../assets/images/jofar_logo_white.svg";
import MaskGroup from "../../assets/images/login/Mask Group 615.svg";
import Rectangle from "../../assets/images/login/Rectangle 5053.svg";
import LoadingBar from "react-top-loading-bar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../urls";
import { AppContext } from "../../context/user/AppContext";
// import { useAlert, positions } from "react-alert";
import swal from "sweetalert";

function Login() {
  const { state } = useLocation();
  const {
    isLoggedin,
    setisLoggedin,
    token,
    isCustomer,
    userDetails,
    setuserDetails,
    setisCustomer,
    setCustomerData,
  } = useContext(AppContext);
  console.log(userDetails);
  const [eye, setEye] = useState(false);
  const [LoginData, setLoginData] = useState({ email: "", password: "" });
  const [progress, setProgress] = useState(0);
  const [role, setRole] = useState(state ? state.val : "customer");
  useEffect(() => {
    if (state) {
      if (state.val === "customer") {
        setisCustomer(true);
      } else {
        setisCustomer(false);
      }
    }
  }, []);
  const navigate = useNavigate();
  // const alert = useAlert();
  useEffect(() => {
    setProgress(100);
  }, []);

  const handleRbChange = async (e) => {
    const { value } = e.target;
    setRole(value);
    setisCustomer(value === "customer");
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setProgress(50);
    let { email, password } = e.target;
    if (role === "customer") {
      //this if block is for customer login
      const response = await fetch(`${BASE_URL}customer/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: email.value,
          password: password.value,
          app_type: "customer",
          app_version: "1",
          device_os: "web",
          device_id: "1",
          device_token: "1",
        }),
      });
      const response_data = await response.json();
      console.log(response_data);
      setProgress(100);
      if (response_data.result === true) {
        setLoginData({ email: "", password: "" });
        localStorage.setItem("api_key", response_data.api_key);
        localStorage.setItem("role", "customer");
        localStorage.setItem("customer_id", response_data.customer_id);
        localStorage.setItem("user_id", response_data.user_id);
        localStorage.setItem("user_name", response_data.user_details.user_name);
        localStorage.setItem(
          "user_pic",
          response_data.user_details.user_profile_pic
        );
        setisCustomer(true);

        setCustomerData({
          api_key: response_data.api_key,
          customer_id: response_data.customer_id,
          user_id: response_data.user_id,
          user_pic: response_data.user_details.user_profile_pic,
          user_name: response_data.user_details.user_name,
          latitude: 18.5204303,
          longitude: -73.9398,
        });

        setisLoggedin(true);
        navigate("/home");
      } else {
        swal({
          title: "warning",
          text: response_data.message,
          icon: "warning",
          button: true,
        });
        setProgress(100);
      }
    } else {
      //this else block is for company login
      let compind = role == "company" ? "company" : "individual";
      const response = await fetch(`${BASE_URL}driver/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: email.value,
          password: password.value,
          app_type: compind,
          app_version: "1",
          device_os: "web",
          device_id: "1",
          device_token: "1",
        }),
      });
      const response_data = await response.json();
      console.log(response_data);
      if (response_data.result === true) {
        setLoginData({ email: "", password: "" });
        localStorage.setItem("api_key", response_data.api_key);
        localStorage.setItem("role", "company");
        localStorage.setItem("role2", compind);
        localStorage.setItem("customer_id", response_data.customer_id);
        localStorage.setItem("user_id", response_data.user_id);
        localStorage.setItem(
          "user_pic",
          response_data.user_details.user_profile_pic
        );
        setCustomerData({
          api_key: response_data.api_key,
          customer_id: response_data.customer_id,
          user_id: response_data.user_id,
          user_pic: response_data.user_details.user_profile_pic,
        });
        setisCustomer(false);
        setisLoggedin(true);
        navigate("/dashboard");
      } else {
        swal({
          title: "warning",
          text: response_data.message,
          icon: "warning",
          button: true,
        });
        setProgress(100);
        //alert.error(response_data.message);
      }
    }
  };

  const handleChange = (e) => {
    // console.log(e.target.value)
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };
  return (
    <section id="cx-login">
      <div className="cx-login-out">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="cx-login-lcontent">
              <div className="text-center">
                <img
                  src={login_logo}
                  alt="jofar-logo"
                  width={200}
                  height={200}
                />
                {/* <p id="login-left-logo-name">JOFAR</p> */}
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <div className="cx-login-content">
              <h2>Welcome back,</h2>
              <p className="cx-p">
                Enter your credentials to login to your account
              </p>
              {/* <div className="mb-2">
              <input
                type="radio"
                className=" form-check-input me-2"
                style={{ marginLeft: "2px !important" }}
                label="Male"
                name="role"
                value="customer"
              />
              <label style={{ fontSize: "14px" }}>Customer</label>
              <input
                type="radio"
                className=" form-check-input me-2"
                style={{ marginLeft: "2px !important" }}
                label="Male"
                name="role"
                value="customer"
              />
              <label style={{ fontSize: "14px" }}>Company</label>
              <input
                type="radio"
                className=" form-check-input me-2"
                style={{ marginLeft: "2px !important" }}
                label="Male"
                name="role"
                value="customer"
              />
              <label style={{ fontSize: "14px" }}>Individual</label>
             
              </div> */}

              <div class="radio-toolbar">
                <input
                  type="radio"
                  id="radio1"
                  name="role"
                  checked={isCustomer}
                  value="customer"
                  onClick={handleRbChange}
                  className="form-check-input "
                />
                <label for="radio1" className="me-2">
                  Customer
                </label>

                <input
                  type="radio"
                  id="radio2"
                  name="role"
                  checked={role == "company" ? true : false}
                  value="company"
                  onClick={handleRbChange}
                  className="form-check-input"
                />
                <label for="radio2">Company</label>

                {/* <input
                  type="radio"
                  id="radio3"
                  className="form-check-input"
                  name="role"
                  checked={role == "individual" ? true : false}
                  value="individual"
                  onClick={handleRbChange}
                />
                <label for="radio3" className="mt-2 mb-2">
                  Individual
                </label> */}
              </div>

              <form onSubmit={(e) => handleLogin(e)}>
                <div className="cx-form-group">
                  <input
                    required
                    onChange={(e) => handleChange(e)}
                    type="email"
                    value={LoginData.email}
                    name="email"
                    className="form-control"
                    id="cx-lemail"
                    placeholder="Email"
                  />
                </div>
                <div className="cx-form-group">
                  <input
                    required
                    name="password"
                    value={LoginData.password}
                    onChange={(e) => handleChange(e)}
                    type={eye ? "text " : "password"}
                    className="form-control"
                    id="cx-lpass"
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    onClick={() => setEye(!eye)}
                    className="cx-text-btn cx-eye-btn"
                  >
                    <img
                      src={eye ? eye_opened : eye_closed}
                      className="cx-eye cx-show"
                      alt="view-password"
                    />
                  </button>
                </div>
                <div className="cx-btn-grp" style={{ textAlign: "right" }}>
                  <Link to="/ForgotPassNew" state={{ role: role }}>
                    <button type="button" className="cx-text-btn">
                      Forgot Password?
                    </button>
                  </Link>
                </div>
                <div className="cx-btn-grp">
                  {/* <Link to="/Home"> */}
                  <button type="submit" className="cx-login-btn">
                    <span>Login</span>
                    <img src={arrow_white} />
                  </button>
                  {/* </Link> */}
                </div>
                <div className="cx-reg">
                  <p>Don’t have an account?</p>
                  <Link to="/Register">
                    <button type="button" className="cx-text-btn">
                      Register
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Login;
