import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../assets/images/ic_current_location.svg'
import add_red from '../../assets/images/add_red.svg'
import status_tick_right from '../../assets/images/status_tick_right.svg'
import '../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../context/user/AppContext'
import '../../assets/css/overAllStatus.scss'
import orange_tick from '../../assets/images/tick_orange.svg'
import orange from '../../assets/images/orange.png'
import drop_off_image from '../../assets/images/drop_off_image.png'
import call from '../../assets/images/call.svg'
import tickk from '../../assets/images/tickk.svg'
import offer_graphics from '../../assets/images/offer_graphics.svg'
import package_image_01 from '../../assets/images/Send Steps/package_image_01.png'
import package_image_02 from '../../assets/images/Send Steps/package_image_02.png'
import package_image_03 from '../../assets/images/Send Steps/package_image_03.png'
import '../../assets/css/overAllStatus.scss'
import Modal from 'react-bootstrap/Modal'
import ApiConfig from '../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import SpinnerCmp from '../../SpinnerCmp'

export default function OverAllStatus() {
  const URL = 'https://logistic.redbytes.in/'

  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadDriverDetails, setLoadDriverDetails] = useState([])
  const [loadDetails, setLoadDetails] = useState([])
  console.log(loadDetails)
  const [LoadAccepted, setLoadAccepted] = useState([])

  const { sidebar, customerData } = useContext(AppContext)
  const [Expand, setExpand] = useState(false)
  const handleExpand = () => setExpand(!Expand)
  const handleExpand1 = () => setExpand(!Expand)

  useEffect(() => {
    connectiongetprofile()
  }, [])

  // useEffect(() => {
  //   let total_price = ""

  //   let cartids = cartchecked.map((cartData) => {
  //     return cartData.cart_id
  //   })
  //   setTotalCount(cartids)
  //   setTotalItem(cartids)
  // }, [cartchecked])

  const connectiongetprofile = () => {
    setLoading(true)

    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'customer'
        ? ApiConfig.LOAD_SERVICE_LOAD_STATUS_COMPLETED
        : ApiConfig.DRIVER_DETAILS_VIEWSTATUS,

      {
        ...customerData,
        load_id: id,
      },
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setLoadDriverDetails(res.json.driver_details)
      setLoadAccepted(res.json)
      setLoadDetails(res.json.locations)
    })
  }

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
          <section id="cxs-locations" className="SendLoadBookingSummary ">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="cxs-content-outer d-flex justify-content-center ">
                    <div
                      className="cxs-content-inner"
                      style={{
                        width: '500px',
                        boxShadow: '0px 0px 16px #0000000D',
                        borderRadius: '15px',
                      }}
                    >
                      <div className="cxs-loc-content" id="OverAllStatus">
                        <div className="cxs-from ">
                          <div className="booking-status-main">
                            <div className=" left-main">
                              <div id="stepper">
                                <div class="d-flex ">
                                  <div class="d-flex flex-column  align-items-center">
                                    <div class="rounded-circle  px-3  ">
                                      {LoadAccepted.load_accepted_date_time ? (
                                        <img src={orange_tick} alt />
                                      ) : (
                                        <img
                                          style={{
                                            height: '23px',
                                            width: '23px',
                                          }}
                                          src={orange}
                                          alt
                                        />
                                      )}
                                    </div>
                                    {LoadAccepted.load_accepted_date_time ? (
                                      <div class="line h-100"></div>
                                    ) : (
                                      <div class="line-1 h-100"></div>
                                    )}
                                  </div>
                                  <div className="stepper-content mb-3">
                                    <div className="load-status-name">
                                      Load Accepted
                                    </div>
                                    <div className="address">
                                      {LoadAccepted.load_accepted_date_time}
                                    </div>
                                  </div>
                                </div>
                                {loadDetails &&
                                  loadDetails.map((loadDetailsItem, index) => {
                                    console.log(
                                      'loadDetailsItem',
                                      loadDetailsItem,
                                    )
                                    return (
                                      <div
                                        class="d-flex"
                                        key={'loadDetailsItem' + index}
                                      >
                                        <div class="d-flex flex-column  align-items-center">
                                          <div class="rounded-circle  px-3  ">
                                            {/* <img src={orange_tick} alt /> */}
                                            {loadDetailsItem.date_time ? (
                                              <img src={orange_tick} alt />
                                            ) : (
                                              <img
                                                style={{
                                                  height: '23px',
                                                  width: '23px',
                                                }}
                                                src={orange}
                                                alt
                                              />
                                            )}
                                          </div>
                                          {loadDetailsItem.date_time ? (
                                            <div class="line h-100"></div>
                                          ) : (
                                            <div class="line-1 h-100"></div>
                                          )}
                                        </div>
                                        <div className="stepper-content">
                                          <div className="load-status-main">
                                            <div className="load-status-name">
                                              {loadDetailsItem.address_name}
                                            </div>
                                            <div className="address">
                                              {loadDetailsItem.date_time}
                                            </div>
                                          </div>
                                          <div className="name-call">
                                            <div className="name">
                                              {loadDetailsItem.address_name}
                                            </div>
                                            <div className="call">
                                              <Link to="#">
                                                <img src={call} alt="" />
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="address">
                                            {loadDetailsItem.address_details}
                                          </div>
                                          <div className="address">
                                            {loadDetailsItem.address_phone_no}
                                          </div>
                                          <div className="right">
                                            {Expand ? (
                                              <Link
                                                to="#"
                                                className="expand"
                                                onClick={handleExpand}
                                              >
                                                Collapse
                                              </Link>
                                            ) : (
                                              <Link
                                                to="#"
                                                className="expand"
                                                onClick={handleExpand}
                                              >
                                                Expand
                                              </Link>
                                            )}
                                          </div>

                                          <div className="package-expand-main">
                                            <div className="left">
                                              PACKAGES [
                                              {loadDetailsItem.package_count}]
                                            </div>
                                          </div>

                                          {Expand &&
                                            loadDetailsItem.package_list.map(
                                              (pickUp, index) => {
                                                return (
                                                  <div key={'pickUp' + index}>
                                                    <div className="col-12 expanded-card">
                                                      <div className="top">
                                                        <div className="left">
                                                          {pickUp.package_name}
                                                        </div>
                                                        <div className="right">
                                                          {pickUp.type}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <span className="key">
                                                          Material Type
                                                        </span>
                                                        <span className="values">
                                                          {
                                                            pickUp.trip_material_type
                                                          }
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <span className="key">
                                                          Weight
                                                        </span>
                                                        <span className="values">
                                                          {pickUp.trip_weight}
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <span className="key">
                                                          Quantity
                                                        </span>
                                                        <span className="values">
                                                          {' '}
                                                          {pickUp.trip_quantity}
                                                        </span>
                                                      </div>
                                                      <div className="d-flex me-2">
                                                        {pickUp.images.map(
                                                          (
                                                            pickupimage,
                                                            index,
                                                          ) => {
                                                            console.log(
                                                              pickupimage,
                                                            )
                                                            return (
                                                              <div
                                                                className="package-pics"
                                                                key={
                                                                  'pickupimage' +
                                                                  index
                                                                }
                                                              >
                                                                <img
                                                                  src={
                                                                    URL +
                                                                    pickupimage.trip_image_url
                                                                  }
                                                                  alt=""
                                                                />
                                                              </div>
                                                            )
                                                          },
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              },
                                            )}
                                        </div>
                                      </div>
                                    )
                                  })}
                                <div class="d-flex ">
                                  <div class="d-flex flex-column  align-items-center">
                                    <div class="rounded-circle  px-3  ">
                                      {LoadAccepted.delivery_date_time ? (
                                        <img src={orange_tick} alt />
                                      ) : (
                                        <img
                                          style={{
                                            height: '23px',
                                            width: '23px',
                                          }}
                                          src={orange}
                                          alt
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="stepper-content">
                                    <div className="load-status-main">
                                      <div className="load-status-name">
                                        Package Delivered
                                      </div>

                                      <div className="address">
                                        {LoadAccepted.delivery_date_time}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  )
}
