import React, { useState, useContext, useEffect } from 'react'
import arrow_white from '../../assets/images/login/arrow_white.svg'
import { Link, useLocation } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { BASE_URL } from '../../urls'

import LoadingBar from 'react-top-loading-bar'
import { AppContext } from '../../context/user/AppContext'

export default function OTP({ route }) {
  const { setForgotData, forgotData } = useContext(AppContext)
  const { state } = useLocation()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setProgress(100)
  }, [])

  const handleChange = (otp) => {
    setForgotData({ ...forgotData, otp: otp })
  }

  return (
    <div>
      <section id="cx-fpass-two">
        <LoadingBar
          color="navy"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="container-fluid">
          <div className="row align-items-center">
            <div
              className="col-12 d-flex justify-content-center align-items-center "
              style={{ height: '100vh' }}
            >
              <div className="cx-fpass-content">
                <h2>Forgot Password</h2>
                <p>Enter your otp to continue</p>
                <form>
                  {/*  <div className="cx-cinput">
                                    <input type="text" className="form-control" />
                                    <input type="text" className="form-control" />
                                    <input type="text" className="form-control" />
                                    <input type="text" className="form-control" />
                                </div> */}
                  <div className="cx-cinput">
                    <OtpInput
                      inputStyle={{ width: '3rem' }}
                      containerStyle={{ justifyContent: 'center' }}
                      value={forgotData.otp}
                      onChange={handleChange}
                      numInputs={4}
                      separator={<span>-</span>}
                    />
                  </div>

                  <div className="cx-btn-grp">
                    <Link to="/UpdatePass">
                      <button
                        type="button"
                        style={{ padding: '23px' }}
                        className="cx-fpass-btn"
                      >
                        <span>Continue</span>
                        <img src={arrow_white} />
                      </button>
                    </Link>
                  </div>
                  <div className="cx-log">
                    <Link to="/Login">
                      {' '}
                      <button type="button" className="cx-text-btn">
                        Go to login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
