import React from 'react';
import {Link} from 'react-router-dom'
import CompanySideBar from '../../../sharedComponent/CompanySideBar';
import ic_tick_blue from '../../../assets/images/Live/ic_tick_blue.svg'
import ic_status_close from '../../../assets/images/Live/ic_status_close.svg'
import booking_package_image from '../../../assets/images/Send Steps/booking_package_image.png'
import profile_img from '../../../assets/images/Live/profile_img.png'
import ic_route_line from '../../../assets/images/Live/ic_route_line.svg'
import ic_call from '../../../assets/images/Live/ic_call.svg'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg'
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import ic_whatsapp from '../../../assets/images/Live/ic_whatsapp.svg'
import profile_2 from '../../../assets/images/profile_2.svg'
import CompanyHeader from '../../../sharedComponent/CompanyHeader';


const LiveTracking = () => {
  return (
      <React.Fragment>
   
    <main className="cx-main p-0" id='cxMain'>
        <section id="cxs-live">
            <div id="cxl-map">
            </div>
            <div className="cxl-status">
                <div className="cxl-status-title">
                    <h4>Status</h4>
                    <button type="button" className="cx-text-btn"><img src={ic_status_close} /></button>
                </div>
                <ul className="cxl-load-status">
                    <li className="cxl-completed cx-status-cleft">
                        <div className="cxl-status-circle"><img src={ic_tick_blue} /></div>
                        <div className="cxl-status-details">
                            <p>Loding Loads</p> 
                            <span>25th Jan 2022, 10:30 AM</span>
                        </div>
                    </li>
                    <li className="cx-status-ccenter">
                        <div className="cxl-status-circle"><img src={ic_tick_blue} /></div>
                        <div className="cxl-status-details">
                            <p>Loding Loads</p>
                            <span>25th Jan 2022, 10:30 AM</span>
                        </div>
                    </li>
                    <li className="cx-status-cright">
                        <div className="cxl-status-circle"><img src={ic_tick_blue} /></div>
                        <div className="cxl-status-details">
                            <p>Loding Loads</p>
                            <span>25th Jan 2022, 10:30 AM</span>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="cxl-details">
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="cxl-load-details">
                            <div className="cxl-ltitle">
                                <h4>Load details</h4>
                                <div className="cxl-lbtns">
                                    <button type="button" className="cx-text-btn cxl-status-toggler">Status</button>
                                    <button type="button" className="cx-text-btn"><Link to="/packagedetails">View Details</Link></button>
                                </div>
                            </div>
                            <div className="cxl-lpdetails">
                                <img src={booking_package_image} />
                                <ul className="cxl-load-ul">
                                    <li>
                                        <h5>My Package</h5>
                                    </li>
                                    <li><label>Current Location</label><span>Queens Street</span></li>
                                    <li><label>Status</label><span style={{color: "#FF8900"}}>Ongoing</span></li>
                                    <li>
                                        <div className="cxl-routes">
                                            <div className="cxl-from-route"><label>From</label><span>Turbo St</span></div>
                                            <div className="cxl-route-info">
                                                <span style={{marginBottom:"15px;"}}>10km away</span>
                                                <span style={{marginTop:"15px;"}}>Arriving in 30 min</span>
                                                <img src={ic_route_line} />
                                            </div>
                                            <div className="cxl-to-route"><label>To</label><span>Queens St</span></div>
                                        </div>
                                    </li>
                                </ul>
                                <span className="cxl-cost">$200</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="cxl-driver-details pt-2">
                            <div className="cxl-ltitle">

                                <ul className="nav nav-pills mb-1" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-driverdetails-tab" data-bs-toggle="pill" data-bs-target="#pills-driverdetails" type="button" role="tab" aria-controls="pills-driverdetails" aria-selected="true">Driver Details</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-customerdetails-tab" data-bs-toggle="pill" data-bs-target="#pills-customerdetails" type="button" role="tab" aria-controls="pills-customerdetails" aria-selected="false">Customer Details</button>
                                    </li>
                                </ul>

                            </div>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-driverdetails" role="tabpanel" aria-labelledby="pills-driverdetails-tab">
                                    <div className="cxl-lpdetails">
                                        <img src={profile_img} />
                                        <ul>
                                            <li>
                                                <h5>Leroy Sane</h5>
                                            </li>
                                            <li className="mb-3">
                                                <div className="cxl-rating">
                                                    <img src={ic_star_highlighted} />
                                                    <img src={ic_star_highlighted} />
                                                    <img src={ic_star_highlighted} />
                                                    <img src={ic_star_highlighted}/>
                                                    <img src={ic_star_grey} />
                                                    <span>(325)</span>
                                                </div>
                                                <a href="#" className="blue_link">View Details</a>
                                            </li>
                                            {/* <li><button type="button" className="cx-text-btn"><img src={ic_call}/></button><button type="button" className="cx-text-btn"><img src={ic_whatsapp} /></button></li> */}

                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-customerdetails" role="tabpanel" aria-labelledby="pills-customerdetails-tab">
                                    <div className="cxl-lpdetails">
                                        <img src={profile_2} />
                                        <ul>
                                            <li>
                                                <h5>Sarah Connors</h5>
                                            </li>
                                            <li>
                                                <h5>sarah@gmail.com</h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

      </React.Fragment>
  );
};

export default LiveTracking;
