import React, { useState } from 'react'
import { AppContext } from './AppContext'

export const AppState = (props) => {
  const [isCustomer, setisCustomer] = useState(true)
  const [loading, setLoading] = useState(false)
  const [start, setStart] = useState({})
  const [end, setEnd] = useState({})
  const [sidebar, setSidebar] = React.useState(false)
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null)
  const [newmap, setNewmap] = useState(null)
  const [isLoggedin, setisLoggedin] = useState(
    localStorage.getItem('api_key') &&
      localStorage.getItem('customer_id') &&
      localStorage.getItem('user_id'),
  )

  /* address context start */
  const [Loactionsmap, setLoactionsmap] = useState('')
  console.log(Loactionsmap)
  const [finalAddress, setfinalAddress] = useState({
    from_address: '',
    from_lat: '',
    from_lon: '',
    from_name: '',
    from_phone_no: '',
    to_address: '',
    to_lat: '',
    to_lon: '',
    to_name: '',
    to_phone_no: '',
  })
  const [fromaddress, setfromAddress] = useState({
    from_address: '',
    from_lat: '',
    from_lon: '',
    from_name: '',
    from_phone_no: '',
  })
  const [toaddress, settoAddress] = useState({
    to_address: '',
    to_lat: '',
    to_lon: '',
    to_name: '',
    to_phone_no: '',
  })

  /* address context end  */
  const [forgotData, setForgotData] = useState({
    username: '',
    role: '',
    otp: '',
  })
  const [location, setlocation] = useState({})
  const [place, setPlace] = useState({
    center: { lat: '', lng: '' },
  })
  const [NotificationData, setNotificationData] = useState('')
  console.log(NotificationData)
  const [addressmap, setAddressmap] = useState('')

  const [CompanyUserData, setCompanyUserData] = useState({
    // name: "",
    // email: "",
    // phone_number: "",
    // whatsapp_number: "",
    // address1: "",
    // address2: "",
    // city: "",
    // po_box: "",
    // password: "",
    // confirm_password: "",
    // emirates: null,
    // licence: null,
    profile_picture: '',
    name: '',
    email: '',
    password: '',
    confirm_password: '',
    phone_number: '',
    whatsapp_number: '',
    address1: '',
    address2: '',
    city: '',
    po_box: '',
    user_role: '',

    //bank details
    bank_account_name: '',
    bank_account_number: '',
    bank_iban: '',
    bank_swift_code: '',
    bank_address: '',
    bank_currency: '',
    //upload doc
    license_number: '',
    license_file: '',
    license_expiry_date: '',
    tax_file: '',
    tax_certificate_no: '',

    reg_card_file: '',
    emirates_id_file: '',
    drivers: [],
    vehicles: [],
    //driver_license_number:"",
    //insurance_file:"",
  })
  //company context data end

  //customer context data start

  const [customerData, setCustomerData] = useState({
    customer_id: localStorage.getItem('customer_id')
      ? localStorage.getItem('customer_id')
      : null,
    user_id: localStorage.getItem('user_id')
      ? localStorage.getItem('user_id')
      : null,
    api_key: localStorage.getItem('api_key')
      ? localStorage.getItem('api_key')
      : null,

    user_pic: localStorage.getItem('user_pic')
      ? localStorage.getItem('user_pic')
      : null,
    user_name: localStorage.getItem('user_name')
      ? localStorage.getItem('user_name')
      : null,
    latitude: localStorage.getItem('latitude')
      ? localStorage.getItem('latitude')
      : 18.5204678,
    longitude: localStorage.getItem('longitude')
      ? localStorage.getItem('longitude')
      : 73.8567117,
  })
  const [loadDetails, setLoadDetails] = useState({})
  //customer context data end

  /* just a trial */

  const [vehicleList, setVehicleList] = useState([])
  const [currentLocation, SetCurrentLocation] = useState('')
  const [homeStatus, SetHomeStatus] = useState('')
  console.log(homeStatus)
  const [vehicleType, setvehicleType] = useState([])
  const [vehicleCapacity, setvehicleCapacity] = useState([])
  const [vehicleLength, setvehicleLength] = useState([])
  const [vehiclenamestate, setvehiclenamestate] = useState([])
  const [vehicletypestate, setvehicletypestate] = useState([])
  const [vehiclecapacitystate, setvehiclecapacitystate] = useState([])
  const [vehiclelengthstate, setvehiclelengthstate] = useState([])
  const [ConfirmMap, setConfirmMap] = useState('')
  const [loadId, setLoadId] = useState(
    {
      trip_id: localStorage.getItem('trip_id')
        ? localStorage.getItem('trip_id')
        : null,
    },
    // '',
  )
  console.log(loadId)

  /* trial end */

  return (
    <AppContext.Provider
      value={{
        setConfirmMap,
        ConfirmMap,
        setLoactionsmap,
        Loactionsmap,
        setNotificationData,
        setPlace,
        NotificationData,
        place,
        loadId,
        SetHomeStatus,
        homeStatus,
        SetCurrentLocation,
        currentLocation,
        setLoadId,
        vehicleList,
        setVehicleList,
        vehicleType,
        setvehicleType,
        vehicleCapacity,
        setvehicleCapacity,
        vehicleLength,
        setvehicleLength,
        vehiclenamestate,
        setvehiclenamestate,
        vehicletypestate,
        setvehicletypestate,
        vehiclecapacitystate,
        setvehiclecapacitystate,
        vehiclelengthstate,
        setvehiclelengthstate,
        isLoggedin,
        setisLoggedin,
        customerData,
        setCustomerData,
        googleApiObj,
        setIsGoogleApiLoadedObj,
        start,
        setStart,
        end,
        setEnd,
        isCustomer,
        setisCustomer,
        newmap,
        setNewmap,
        CompanyUserData,
        setCompanyUserData,
        forgotData,
        setForgotData,
        loading,
        setLoading,
        setfinalAddress,
        finalAddress,
        fromaddress,
        setfromAddress,
        toaddress,
        settoAddress,
        loadDetails,
        setLoadDetails,
        sidebar,
        setSidebar,
        setlocation,
        location,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
