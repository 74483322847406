import React, { useContext, useState } from "react";
import logo_icon from "../assets/images/jofar_logo_white.svg";
import logo_text from "../assets/images/Sidebar/logo_text.svg";
import ic_send_load from "../assets/images/Sidebar/ic_send_load.svg";
import ic_live_tracking from "../assets/images/Sidebar/ic_live_tracking.svg";
import ic_loads from "../assets/images/Sidebar/ic_loads.svg";
import home_icon from "../assets/images/Mask Group 619.svg";

import dashboard_icon from "../assets/images/dashboard_icon.svg";
import { AppContext } from "../context/user/AppContext";
import { Link, NavLink } from "react-router-dom";
export default function Sidebar1() {
  const { isCustomer, sidebar, setSidebar } = useContext(AppContext);

  const [active, setActive] = useState(isCustomer ? "home" : "dashboard");
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  function handleSidebar() {
    if (!sidebar) {
      document.getElementById("cxMain").classList.add("cx-active");
    } else {
      document.getElementById("cxMain").classList.remove("cx-active");
    }
  }

  // function refreshPage() {
  //   setTimeout(() => {
  //     window.location.reload(false)
  //   }, 0)
  //   console.log('page to reload')
  // }
  return (
    <div>
      <aside id="cx-sidebar" className={sidebar ? "cx-active" : null}>
        <div className="cx-sidebar-brand">
          <Link to="#">
            <img
              width={65}
              height={65}
              className="cx-brand-icon"
              src={logo_icon}
            />
            {/* {sidebar && <span className="sidebar-logo-name-top">JOFAR</span>} */}
          </Link>
          <a href="#">
            <img className="cx-brand-icon-text" src={logo_text} />
          </a>
        </div>
        <div
          className={`cx-sidebar-toggler ${sidebar ? "cx-active" : null}`}
          id="cx-sidebar-toggler"
        >
          <button
            onClick={() => {
              showSidebar();
              handleSidebar();
            }}
            type="button"
            className="cx-toggle-btn d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ fill: "rgba(0, 0, 0, 1)" }}
            >
              <path d="m13.061 4.939-2.122 2.122L15.879 12l-4.94 4.939 2.122 2.122L20.121 12z"></path>
              <path d="M6.061 19.061 13.121 12l-7.06-7.061-2.122 2.122L8.879 12l-4.94 4.939z"></path>
            </svg>
          </button>
        </div>
        {isCustomer ? (
          <ul className={`cx-list ${sidebar ? "cx-active" : null} `}>
            <li className={active == "home" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("home")}
                // onClick={() => {
                //   setActive('home')
                //   refreshPage()
                // }}
                activeClassName="active"
                to="/home"
              >
                <img src={home_icon} alt="send-load-icon" />
                <span>Home</span>
              </NavLink>
              <div className="cx-tooltip">Home</div>
            </li>
            <li className={active == "sendload" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("sendload")}
                activeClassName="cx-nav-active"
                to="/VehicleInfo"
              >
                <img src={ic_send_load} alt="send-load-icon" />
                <span>Send Load</span>
              </NavLink>
              <div className="cx-tooltip">Send Load</div>
            </li>
            <li className={active == "Live" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("Live")}
                activeClassName="cx-nav-active"
                to="/AllongoingLoadMap"
              >
                <img src={ic_live_tracking} alt="live-tracking-icon" />
                <span>Live Tracking</span>
              </NavLink>

              <div className="cx-tooltip">Live Tracking</div>
            </li>
            <li className={active == "loads" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("loads")}
                activeClassName="cx-nav-active"
                to="/LoadServiceRequest"
              >
                <img src={ic_loads} alt="load-icon" />
                <span>Loads</span>
              </NavLink>
              <div className="cx-tooltip">Loads</div>
            </li>
          </ul>
        ) : (
          <ul className={`cx-list ${sidebar ? "cx-active" : null} `}>
            <li className={active == "dashboard" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("dashboard")}
                activeClassName="cx-nav-active"
                to="/dashboard"
              >
                <img src={dashboard_icon} alt="send-load-icon" />
                <span>Dashboard</span>
              </NavLink>
              <div className="cx-tooltip">Dashboard</div>
            </li>
            <li className={active == "Live" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("Live")}
                activeClassName="cx-nav-active"
                to="/liveTrackingDrivers"
              >
                <img src={ic_live_tracking} alt="live-tracking-icon" />
                <span>Live Tracking</span>
              </NavLink>
              <div className="cx-tooltip">Live Tracking</div>
            </li>
            <li className={active == "companyloads" ? "cx-nav-active" : ""}>
              <NavLink
                onClick={() => setActive("companyloads")}
                active
                ClassName="cx-nav-active"
                to="/CompanyLoadServiceRequest"
              >
                <img src={ic_loads} alt="load-icon" />
                <span>Loads</span>
              </NavLink>
              <div className="cx-tooltip">Loads</div>
            </li>
          </ul>
        )}
      </aside>
    </div>
  );
}
