import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CompanySideBar from '../../sharedComponent/CompanySideBar'
import vehicles from '../../assets/images/vehicles.svg'
import welcome_img from '../../assets/images/welcome_img.svg'
import live_tracking from '../../assets/images/live_tracking.svg'
import drivers from '../../assets/images/drivers.svg'
import profit from '../../assets/images/profit.svg'
import package_1 from '../../assets/images/package_1.svg'
import package_2 from '../../assets/images/package_2.svg'
import package_3 from '../../assets/images/package_3.svg'
import package_4 from '../../assets/images/package_4.svg'
import direction from '../../assets/images/direction.svg'
import user_1 from '../../assets/images/user_1.svg'
import user_2 from '../../assets/images/user_2.svg'
import user_3 from '../../assets/images/user_3.svg'
import user_4 from '../../assets/images/user_4.svg'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import ApiConfig, { URL } from '../../api/ApiConfig'
import { AppContext } from '../../context/user/AppContext'
import SpinnerCmp from '../../SpinnerCmp'
import Sidebar1 from '../../sharedComponent/Sidebar1'
const Dashboard = () => {
  const [data, setData] = useState({})
  const { customerData, sidebar } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  let comdri = localStorage.getItem('role2')
  // debugger;
  useEffect(() => {
    const getRequestedDataDetails = () => {
      PostCallWithErrorResponse(
        comdri == 'company'
          ? ApiConfig.DASHBOARD_COMPANY_API
          : ApiConfig.DASHBOARD_DRIVER_API,
        customerData,
      )
        .then((data) => {
          console.log(data.json)
          setData(data.json)
          setLoading(false)
        })
        .catch((error) => {
          console.log('api response', error)
          setData([])
        })
    }
    getRequestedDataDetails()
  }, [])

  return (
    <React.Fragment>
      <main id="cxMain" className={sidebar ? 'cx-main cx-active' : 'cx-main'}>
        {loading && data ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-12">
                <div className="cxl-loc-content">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="card card_style_2 mb-3">
                        <div className="card-body">
                          <div className="d-lg-flex d-md-flex justify-content-between">
                            <div>
                              <p className="smtitle">Welcome,</p>
                              <h3 className="mb-3">
                                {comdri == 'company'
                                  ? data.company_home.company_name
                                  : data.driver_home.name}
                              </h3>
                              {/* <Link
                                to="/liveTrackingDrivers"
                                className="blue_btn"
                              >
                                Start Tracking
                              </Link> */}
                            </div>
                            <div className="mt-lg-0 mt-md-0 mt-4">
                              <img src={welcome_img} alt="" />
                            </div>
                          </div>
                          <div className="total_loads mt-4">
                            <div className="load_count mb-lg-0 mb-md-0 mb-3">
                              <p className="title">Total Loads</p>
                              <p className="count">
                                {comdri == 'company'
                                  ? data.company_home.total_loads
                                  : data.driver_home.total_loads}
                              </p>
                            </div>
                            <div className="load_count mb-lg-0 mb-md-0 mb-3">
                              <p className="title">Delivered Loads</p>
                              <p className="count">
                                {comdri == 'company'
                                  ? data.company_home.delivered_loads
                                  : data.driver_home.delivered_loads}
                              </p>
                            </div>
                            <div className="load_count mb-lg-0 mb-md-0 mb-3">
                              <p className="title">Ongoing Loads</p>
                              <p className="count">
                                {comdri == 'company'
                                  ? data.company_home.ongoing_loads
                                  : data.driver_home.ongoing_loads}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="single_count">
                            <div className="card card-style">
                              <div
                                className="card-body d-flex justify-content-between"
                                style={{ height: '130px' }}
                              >
                                <div>
                                  <h4 className="blue">
                                    {data.company_home
                                      ? data.company_home.vehicle_count
                                      : 0}
                                  </h4>
                                  <p>Vehicles</p>
                                </div>
                                <div>
                                  <img src={vehicles} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="single_count mt-lg-0 mt-md-0 mt-4">
                            <div className="card card-style">
                              <div
                                className="card-body d-flex justify-content-between"
                                style={{ height: '130px' }}
                              >
                                <div>
                                  <h4 className="yellow">
                                    {data.company_home
                                      ? data.company_home.live_tracking
                                      : 0}
                                  </h4>
                                  <p>Live Tracking</p>
                                </div>
                                <div>
                                  <img src={live_tracking} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="single_count mt-4">
                            <div className="card card-style">
                              <div
                                className="card-body d-flex justify-content-between"
                                style={{ height: '130px' }}
                              >
                                <div>
                                  <h4 className="green">
                                    {data.company_home
                                      ? data.company_home.driver_count
                                      : 0}
                                  </h4>
                                  <p>Drivers</p>
                                </div>
                                <div>
                                  <img src={drivers} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="single_count mt-4">
                            <div className="card card-style">
                              <div
                                className="card-body d-flex justify-content-between"
                                style={{ height: '130px' }}
                              >
                                <div>
                                  <h4 className="sea">
                                    {data.company_home
                                      ? data.company_home.total_profit
                                      : 0}
                                  </h4>
                                  <p>Total Profit</p>
                                </div>
                                <div>
                                  <img src={profit} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="card card-style mt-5">
                        <div className="card-body">
                          <div className="card_title d-flex justify-content-between">
                            <h4>Vehicles</h4>
                            <Link
                              to="/VehicleListning"
                              state={{ key: 'ongoing' }}
                              className="blue_link"
                            >
                              View All
                            </Link>
                          </div>
                          <div className="user_block pt-4">
                            {data.company_home && data.company_home.vehicle_list
                              ? data.company_home.vehicle_list

                                  .slice(0, 5)
                                  .map((single) => {
                                    console.log(single)
                                    return (
                                      <div className="d-flex justify-content-between mb-2">
                                        <Link
                                          to={
                                            '/VehicleDetails/' +
                                            single.vehicle_id
                                          }
                                          type="button"
                                          class="cx-text-btn cxlo-direction"
                                        >
                                          <p>
                                            <img
                                              src={
                                                single.vehicle_image
                                                  ? URL + single.vehicle_image
                                                  : package_1
                                              }
                                              className="me-2 header-profile-pic"
                                              alt=""
                                            />{' '}
                                            {single.vehicle_name}
                                          </p>
                                          {/* <Link
                                          to={'/VehicleDetails/' + single.trip_id}
                                          type="button"
                                          class="cx-text-btn cxlo-direction"
                                        >
                                          <img src={direction} />
                                        </Link> */}
                                        </Link>
                                      </div>
                                    )
                                  })
                              : null}

                            {/* {

data.driver_home  && 
data.driver_home.ongoing_deliveries
  ? data.driver_home.ongoing_deliveries.slice(0,5).map((single) => {
      return (
        <div className="d-flex justify-content-between mb-2">
          <p>
            <img
              src={single.thumbnail ? (URL  + single.trip_image) :  package_1}
              className="me-2 header-profile-pic"
              alt=""
            />{" "}
            {single.trip_name}
          </p>
          <Link to={"/LiveTracking/" + single.trip_id} type="button" class="cx-text-btn cxlo-direction"><img src={direction} /></Link>

        </div>
      );
    })
  
  : null} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {comdri == 'company' ? (
                      <div className="col-lg-6">
                        <div className="card card-style mt-5">
                          <div className="card-body">
                            <div className="card_title d-flex justify-content-between">
                              <h4>Drivers</h4>
                              <Link to="/DriverListning" className="blue_link">
                                View All
                              </Link>
                            </div>
                            <div className="user_block pt-4">
                              {data.company_home.driver_list
                                ? data.company_home.driver_list
                                    .slice(0, 5)
                                    .map((single) => {
                                      console.log(single)
                                      return (
                                        <div className="d-flex justify-content-between mb-2">
                                          <Link
                                            to={
                                              '/DriverDetails/' +
                                              single.driver_id
                                            }
                                            type="button"
                                            class="cx-text-btn cxlo-direction"
                                          >
                                            <p>
                                              <img
                                                src={
                                                  single.profile_pic
                                                    ? URL + single.profile_pic
                                                    : user_1
                                                }
                                                className="me-2 header-profile-pic"
                                                alt=""
                                              />{' '}
                                              {single.name}
                                            </p>
                                          </Link>
                                        </div>
                                      )
                                    })
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>

      {/* <!-- ACCOUNT STARTS HERE --> */}
      <section id="cx-account">
        <div className="cx-account-out">
          <div className="cx-filter"></div>
          <div className="cx-account-content">
            <div className="cx-account-header">
              <h4>Accounts</h4>
              <button type="button" className="cx-accounts-btn">
                <img src="images/Live/ic_close.svg" alt="close-icon" />
              </button>
            </div>
            <ul className="cx-account-list">
              <li>
                <a href="#">
                  <img src="images/Live/ic_profile.svg" alt="profile-icon" /> My
                  Profile
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/bank_account.svg" alt="payment-icon" /> Bank
                  Account
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/earning.svg" alt="payment-icon" /> Earning
                  Stats
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="images/Live/ic_payment_history.svg"
                    alt="payment-history-icon"
                  />{' '}
                  Payment History
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="images/vehicles_menu.svg"
                    alt="saved-locations-icon"
                  />{' '}
                  Vehicles
                </a>
              </li>
              <li>
                <a href="#">
                  <img
                    src="images/drivers_menu.svg"
                    alt="saved-locations-icon"
                  />{' '}
                  Drivers
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/Live/ic_contact.svg" alt="contact-icon" />{' '}
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/Live/ic_about_us.svg" alt="about-icon" />{' '}
                  About Us
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/Live/ic_terms.svg" alt="terms-icon" /> Terms
                  & Conditions
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/Live/ic_policy.svg" alt="policy-icon" />{' '}
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="images/Live/ic_logout.svg" alt="logout-icon" />{' '}
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Dashboard
