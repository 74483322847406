import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import ic_package_upload from "../../../assets/images/ic_package_upload.svg";
import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
import circle_cross from "../../../assets/images/circle_cross.svg";
import upload_1 from "../../../assets/images/upload_1.svg";
import upload_2 from "../../../assets/images/upload_2.svg";
import upload_3 from "../../../assets/images/upload_3.svg";
import cross from "../../../assets/images/cross.svg";

const ServiceRequestedLoadsEditModifyLoad = () => {
  const { sidebar } = useContext(AppContext);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <section
        id="cxs-locations"
        className="SendLoadAddPackages ServiceRequestedLoadsEditModifyLoad"
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div
                    className="cxs-loc-content"
                    style={{ borderRadius: "15px 15px 0px 0px" }}
                  >
                    <div className="cxs-from ">
                      <div
                        className="row form-section "
                        style={{ border: "none" }}
                      >
                        <div className="left col-lg-6">
                          <div className="col-12">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Upload Image
                                <span className="red-star"> *</span>
                              </label>
                              <div className="drag-drop-main">
                                <div className="inner">
                                  <img src={ic_package_upload} alt="" />
                                  <br />
                                  <label htmlFor="">
                                    Drag & Drop files to upload
                                  </label>
                                  <br />
                                  <label htmlFor="">Or</label>
                                  <br />
                                  <Link to="#">Browse Files</Link>
                                </div>
                              </div>
                              <div className="uploaded_items">
                                <div className="item_list">
                                  <img
                                    className="vehicle_img"
                                    src={upload_1}
                                    alt=""
                                  />
                                  <a href="#" className="close_item">
                                    <img src={circle_cross} alt="" />
                                  </a>
                                </div>
                                <div className="item_list">
                                  <img
                                    className="vehicle_img"
                                    src={upload_2}
                                    alt=""
                                  />
                                  <a href="#" className="close_item">
                                    <img src={circle_cross} alt="" />
                                  </a>
                                </div>
                                <div className="item_list">
                                  <img
                                    className="vehicle_img"
                                    src={upload_3}
                                    alt=""
                                  />
                                  <a href="#" className="close_item">
                                    <img src={circle_cross} alt="" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="right col-lg-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Package Name
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control add-package-input"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Material Type
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control add-package-input"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Weight
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control add-package-input"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Select Vehicle
                                  <span className="red-star"> *</span>
                                </label>
                                <select
                                  class="form-select add-package-input"
                                  aria-label="Default select example"
                                >
                                  <option selected></option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Delivery Conditions
                                  <span className="red-star"> *</span>
                                </label>
                                <textarea
                                  class="form-control add-package-input"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                ></textarea>
                              </div>
                            </div>
                            <div className="edit-pickup-location">
                              <div className="top">
                                <div className="left-edit">
                                  <span>Select pickup location</span>
                                  <div className="delete-selected-loc">
                                    Michael Clarke
                                    <span className="number">2</span>
                                    <Link to="#">
                                      <img src={cross} alt="" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="right-edit">
                                  <Link to="#">View All</Link>
                                </div>
                              </div>
                            </div>
                            <div className="edit-pickup-location">
                              <div className="top">
                                <div className="left-edit">
                                  <span>Select pickup location</span>
                                  <div className="delete-selected-loc">
                                    Michael Clarke
                                    <span className="number">2</span>
                                    <Link to="#">
                                      <img src={cross} alt="" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="right-edit">
                                  <Link to="#">View All</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="#">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    <Link to="/SendLoadBookingSummary">
                      <button type="button" className="cx-text-btn">
                        <span>Save </span>
                        <img src={ic_continue_arrow_blue} />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceRequestedLoadsEditModifyLoad;
