
import React from 'react'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg';
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import ic_call from '../../../assets/images/Live/ic_call.svg'
import profile_2 from '../../../assets/images/profile_2.svg'
import profile_1 from '../../../assets/images/profile_1.svg'

import ic_whatsapp from '../../../assets/images/Live/ic_whatsapp.svg'
import LiveTrackingPackages from './LiveTrackingPackages';
import profile_img from '../../../assets/images/Live/profile_img.png'
import CompanySideBar from '../../../sharedComponent/CompanySideBar';
import CompanyHeader from '../../../sharedComponent/CompanyHeader';


const Drivers = () => {
  return (
    <React.Fragment>
 
        <div class="offcanvas show offcanvas-end ViewDrivers" tabindex="-1" id="ViewDrivers" data-bs-backdrop="false" aria-labelledby="ViewDriversLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="ViewDriversLabel">Drivers</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="cxl-lpdetails">
                <img src={profile_img} />
                <ul>
                    <li>
                        <h5>Leroy Sane</h5>
                    </li>
                    <li class="mb-3">
                        <div class="cxl-rating">
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_grey} />
                            <span>(325)</span>
                        </div>
                        <a href="#" class="blue_link">View Details</a>
                    </li>
                    <li><button type="button" class="cx-text-btn"><img src={ic_call}/></button><button type="button" class="cx-text-btn"><img src={ic_whatsapp} /></button></li>
                </ul>
            </div>
            <div class="cxl-lpdetails">
                <img src={profile_1} />
                <ul>
                    <li>
                        <h5>Robert Lewandowski</h5>
                    </li>
                    <li class="mb-3">
                        <div class="cxl-rating">
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_grey} />
                            <span>(325)</span>
                        </div>
                        <a href="#" class="blue_link">View Details</a>
                    </li>
                    <li><button type="button" class="cx-text-btn"><img src={ic_call} /></button><button type="button" class="cx-text-btn"><img src={ic_whatsapp} /></button></li>
                </ul>
            </div>
            <div class="cxl-lpdetails">
                <img src={profile_2} />
                <ul>
                    <li>
                        <h5>Thomas Muller</h5>
                    </li>
                    <li class="mb-3">
                        <div class="cxl-rating">
                        <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_highlighted} />
                            <img src={ic_star_grey} />
                            <span>(325)</span>
                        </div>
                        <a href="#" class="blue_link">View Details</a>
                    </li>
                    <li><button type="button" class="cx-text-btn"><img src={ic_call} /></button><button type="button" class="cx-text-btn"><img src={ic_whatsapp} /></button></li>
                </ul>
            </div>
        </div>
    </div>

    </React.Fragment>
  );
}

export default Drivers