import React, { useContext, useState, useEffect } from "react";
import direction from "../../../assets/images/Loads/direction.png";
import package_image from "../../../assets/images/Loads/package_image.png";
// import upload_image_default  from "../../assets/images/Loads/upload_image_default.svg"
import "../../../assets/css/cx-loads.css";
import { AppContext } from "../../../context/user/AppContext";
import dots from "../../../assets/images/Loads/dots.svg";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ApiConfig from "../../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  simplePostCall,
} from "../../../api/ApiServices";
import Moment from "react-moment";
import Geocode from "react-geocode";
import arrowright from "../../../assets/images/Loads/arrow_white.svg";
import swal from "sweetalert";
import SpinnerCmp from "../../../SpinnerCmp";

function CompanyLoadServiceRequest() {
  // Geocode.setApiKey('AIzaSyBxqcBX2r8cybljkDigXBgyOByjeQOmqr0')
  // Geocode.setLanguage('en')
  // Geocode.setLocationType('ROOFTOP')

  // Enable or disable logs. Its optional.
  Geocode.setApiKey("AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw");
  // set response language. Defaults to english.
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");

  // Enable or disable logs. Its optional.
  Geocode.enableDebug();
  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("es");

  //
  const { sidebar, customerData, homeStatus, loadId, setLoadId } =
    useContext(AppContext);
  const currentLoadId = useLocation().state;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const DeletehandleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const [requestedDetalis, setRequestedDetalis] = useState([]);
  const [ongoingDetalis, setOngoingDetalis] = useState([]);

  const [completedDetalis, setCompletedDetalis] = useState([]);
  const navigate = useNavigate();
  const [mapDetalis, setMapDetalis] = useState([]);
  const URL = "https://logistic.redbytes.in/";
  const [Addshow, setAddShow] = useState(false);
  const [addressmap, setAddressmap] = useState("");

  const [placeMap, setPlace] = useState({
    lat: "",
    lng: "",
  });

  const AddhandleClose = () => setAddShow(false);
  const AddhandleShow = (id) => {
    setSelectedId(id);
    setAddShow(true);
  };

  // const [place, setPlace] = useState({
  //   center: { lat: 19.8762, lng: 75.3433 },
  // })
  // console.log(place.center.lat);

  const selectedIdMap =
    requestedDetalis &&
    requestedDetalis.map((id) => {
      return id.trip_id;
    });

  useEffect(() => {
    geLoadRequest();
    geLoadONGOING();
    geLoadCompleted();
  }, []);

  const RejectLoad = (id) => {
    PostCallWithErrorResponse(ApiConfig.COMPANY_REJECT_LOAD, {
      ...customerData,
      load_id: id,
    })
      .then((data) => {
        console.log(data.json);

        swal({
          title: "Success",
          text: data.json.message,
          icon: "success",
          button: true,
        });
        geLoadRequest();
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const LoadStatusCancle = () => {
    simplePostCall(
      ApiConfig.CONCEL_LOAD,
      JSON.stringify({ ...customerData, load_id: loadId.trip_id })
    )
      .then((res) => {
        // console.log(res);
        swal(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const geLoadRequest = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig.REQUESTED_LOADDATA_DRIVER,
      customerData
    ).then((res) => {
      // console.log(res);
      setLoading(false);
      setRequestedDetalis(res.json.load_list);
    });
  };

  const EditLoad = (e) => {
    // console.log(id)
    simplePostCall(
      ApiConfig.EDIT_LOAD_REQUEST,
      JSON.stringify({ ...customerData, load_id: selectedId })
    )
      .then((res) => {
        // console.log(res);
        localStorage.setItem("trip_id", res.location_list[0].address_trip_id);
        setLoadId({ trip_id: res.location_list[0].address_trip_id });
        refreshPage();
        navigate("/AddLocations");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const geLoadONGOING = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig.ONGOING_LOADDATA_DRIVER,
      customerData
    ).then((res) => {
      console.log(res);
      const data = res.json.load_list[0] && res.json.load_list[0];
      setOngoingDetalis(res.json.load_list);
      setPlace({
        lat: data.current_location.latitude,
        lng: data.current_location.longitude,
      });
      setMapDetalis(data.locations);
      setLoading(false);
    });
  };
  Geocode.fromLatLng(placeMap.lat, placeMap.lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      setAddressmap(address);
      console.log(address);
    },
    (error) => {
      console.error(error);
    }
  );
  const geLoadCompleted = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig.COMPLETED_LOADDATA_DRIVER,
      customerData
    ).then((res) => {
      // console.log(res);
      setLoading(false);
      setCompletedDetalis(res.json.load_list);
      // setMapDetalis(res.json.load_list[0].locations)
    });
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleCart = () => {
    if (homeStatus === "load_details_added") {
      LoadStatusCancle();
      EditLoad();
    }
    if (homeStatus === "location_added") {
      LoadStatusCancle();
      EditLoad();
    }
    if (homeStatus === "package_added") {
      LoadStatusCancle();
      EditLoad();
    } else {
      navigate("/AddLocations", { state: selectedId });
      EditLoad();
      // refreshPage()
    }
  };

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main
          className={
            sidebar ? "cx-main cx-active" : "cx-main  cx-main-for-responsive"
          }
          id="cxMain"
        >
          <div>
            {/* <!-- MAIN STARTS HERE --> */}
            <header
              className="cx-header"
              style={{ margin: "-20px -20px 0 -20px" }}
            >
              <ul className="nav nav-tabs" id="cx-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Service Requested
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Ongoing
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Completed
                  </button>
                </li>
              </ul>
            </header>
            <main
              className="cx-main"
              id="cxMain"
              style={{ marginLeft: "-27px", paddingTop: "0" }}
            >
              <section id="cxlo-loads">
                <div className="container">
                  <div className="cxlo-loads-content loads-content-for-responsive">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row">
                          {requestedDetalis && requestedDetalis.length > 0 ? (
                            requestedDetalis.map(
                              (requestedDetalisitem, index) => {
                                console.log(requestedDetalisitem.trip_id);
                                return (
                                  <div className="col-md-6 mt-4">
                                    <Link
                                      state={requestedDetalisitem}
                                      to={
                                        "/LoadDetailsDriver/" +
                                        requestedDetalisitem.trip_id
                                      }
                                    >
                                      <div className="service-card-load">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <div>
                                              <img
                                                className="cxlo-thumbnail"
                                                src={
                                                  requestedDetalisitem.load_image
                                                    ? URL +
                                                      requestedDetalisitem.load_image
                                                    : package_image
                                                }
                                              />
                                            </div>
                                            <div className="ms-3">
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Delivery No.
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {
                                                    requestedDetalisitem.trip_reference_no
                                                  }
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Packages
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {
                                                    requestedDetalisitem.package_count
                                                  }
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Price
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {requestedDetalisitem.price}
                                                </span>
                                              </div>
                                              <div>
                                                {/* <label
                                                htmlFor=""
                                                className="waiting-accept"
                                              >
                                                {
                                                  requestedDetalisitem.trip_status
                                                }
                                              </label> */}
                                                <button
                                                  onClick={() =>
                                                    RejectLoad(
                                                      requestedDetalisitem.trip_id
                                                    )
                                                  }
                                                  className="btn btn-sm btn-outline-danger my-3 reject-btn"
                                                  type="button"
                                                >
                                                  Reject
                                                </button>{" "}
                                                <Link
                                                  to="/AssignDriver"
                                                  style={{
                                                    padding: "7px 23px",
                                                  }}
                                                  className="my-3 cx-text-btn cxlo-direction accept-btn"
                                                  state={{
                                                    id: requestedDetalisitem.trip_id,
                                                  }}
                                                  type="button"
                                                >
                                                  Accept
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div>
                                            <div class="btn-group dropstart">
                                              <img
                                                src={dots}
                                                alt=""
                                                class=" dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ cursor: "pointer" }}
                                              />
                                            </div>
                                          </div> */}
                                          <div>
                                            <div class="btn-group dropstart">
                                              <button className="arrow-btn-company">
                                                <img
                                                  src={arrowright}
                                                  alt=""
                                                  style={{ cursor: "pointer" }}
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                    <Modal
                                      show={Addshow}
                                      onHide={handleClose}
                                      size="lg"
                                      aria-labelledby="contained-modal-title-vcenter"
                                      centered
                                      className="home-modal"
                                    >
                                      <Modal.Body>
                                        <p>
                                          There is already Pending Load exist.
                                          Are You sure You Want to delete
                                          Pending Load to edit This
                                        </p>
                                        <div className="btn-yes-no">
                                          <button
                                            className="no"
                                            onClick={AddhandleClose}
                                          >
                                            No
                                          </button>{" "}
                                          <button
                                            state={loadId}
                                            onClick={handleCart}
                                            className="yes"
                                          >
                                            Yes
                                          </button>
                                        </div>
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "auto",
                                marginTop: "60px",
                              }}
                              className=" text-center justify-content-center"
                            >
                              No Requested !!!
                            </p>
                          )}
                        </div>
                      </div>

                      {/* Company card */}

                      <div className="row"></div>
                      {/* <div className="col-md-6 mt-4">
                        <div className="service-card-load-for-company">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <img
                                  className="cxlo-thumbnail"
                                  src={package_image}
                                />
                              </div>
                              <div className="ms-3">
                                <div>
                                  <label htmlFor="" className="delivery-text">
                                    Delivery No.
                                  </label>
                                  <span className="delivery-number ms-2">
                                    45654656567
                                  </span>
                                </div>
                                <div>
                                  <label htmlFor="" className="delivery-text">
                                    Packages
                                  </label>
                                  <span className="delivery-number ms-2">
                                    5
                                  </span>
                                </div>
                                <div>
                                  <label htmlFor="" className="delivery-text">
                                    Delivery Conditions
                                  </label>
                                  <span className="delivery-number ms-2">
                                    Lorem Ipsum Dolar Amit
                                  </span>
                                </div>
                                <div>
                                  <label htmlFor="" className="delivery-text">
                                    Price
                                  </label>
                                  <span className="delivery-number ms-2">
                                    $200
                                  </span>
                                </div>
                                <div className="mt-3">
                                  <button className="reject-btn">Reject</button>
                                  <button className="accept-btn ">
                                    Accept
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="btn-group dropstart">
                                <button className="arrow-btn-company">
                                  <img
                                    src={arrowright}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* end */}
                      <div
                        className="tab-pane fade"
                        id="profile"
                        role="tabpanel"
                        aria-labelledby="profile-tab"
                      >
                        <div className="row">
                          {ongoingDetalis && ongoingDetalis.length > 0 ? (
                            ongoingDetalis.map((ongoingDetalisItem, index) => {
                              return (
                                <div className="col-lg-6 mt-4 ">
                                  <div className="service-card-load">
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex">
                                        <div>
                                          <img
                                            className="cxlo-thumbnail"
                                            src={
                                              ongoingDetalisItem.load_image
                                                ? URL +
                                                  ongoingDetalisItem.load_image
                                                : package_image
                                            }
                                          />
                                        </div>
                                        <Link
                                          state={ongoingDetalisItem}
                                          to={
                                            "/LoadDetailsOnging/" +
                                            ongoingDetalisItem.trip_id
                                          }
                                        >
                                          <div className="ms-3">
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="delivery-text"
                                              >
                                                Delivery No.
                                              </label>
                                              <span className="delivery-number ms-2">
                                                {
                                                  ongoingDetalisItem.trip_reference_no
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="delivery-text"
                                              >
                                                Packages
                                              </label>
                                              <span className="delivery-number ms-2">
                                                {
                                                  ongoingDetalisItem.package_count
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="delivery-text"
                                              >
                                                Pickup Date
                                              </label>
                                              <span className="delivery-number ms-2">
                                                {
                                                  ongoingDetalisItem.pickup_date_time
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="delivery-text"
                                              >
                                                Current Location
                                              </label>
                                              <span className="delivery-number ms-2">
                                                {addressmap}
                                              </span>
                                            </div>
                                            <div>
                                              <label
                                                htmlFor=""
                                                className="delivery-text"
                                              >
                                                Status
                                              </label>
                                              <span className="pick-up-driver ms-2">
                                                {ongoingDetalisItem.trip_status}
                                              </span>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>

                                      <div>
                                        <Link
                                          // to="/MapDetailsongoing"
                                          state={ongoingDetalisItem}
                                          to={
                                            "/OngoingDriver/" +
                                            ongoingDetalisItem.trip_id
                                          }
                                        >
                                          <div
                                            class="btn-group dropstart"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              // onClick={() => {
                                              //   navigate("/MapDetailsongoing", {
                                              //     state: { ...ongoingDetalisItem },
                                              //   });
                                              // }}
                                              src={direction}
                                              alt=""
                                            />
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "auto",
                                marginTop: "60px",
                              }}
                              className=" text-center justify-content-center"
                            >
                              No Ongoing !!!
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="contact"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                      >
                        <div className="row">
                          {completedDetalis && completedDetalis.length > 0 ? (
                            completedDetalis.map(
                              (completedDetalisItem, index) => {
                                return (
                                  <div className="col-md-6 mt-4">
                                    <Link
                                      state={completedDetalisItem}
                                      to={
                                        "/LoadDetailsDriver/" +
                                        completedDetalisItem.trip_id
                                      }
                                    >
                                      <div className="service-card-load">
                                        <div className="d-flex justify-content-between">
                                          <div className="d-flex">
                                            <div>
                                              <img
                                                className="cxlo-thumbnail"
                                                src={
                                                  completedDetalisItem.load_image
                                                    ? URL +
                                                      completedDetalisItem.load_image
                                                    : package_image
                                                }
                                              />
                                            </div>
                                            <div className="ms-3">
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Delivery No.
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {
                                                    completedDetalisItem.trip_reference_no
                                                  }
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Packages
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {
                                                    completedDetalisItem.package_count
                                                  }
                                                </span>
                                              </div>
                                              <div>
                                                <label
                                                  htmlFor=""
                                                  className="delivery-text"
                                                >
                                                  Delivered On
                                                </label>
                                                <span className="delivery-number ms-2">
                                                  {
                                                    completedDetalisItem.delivery_date_time
                                                  }
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <p
                              style={{
                                fontSize: "18px",
                                margin: "auto",
                                marginTop: "60px",
                              }}
                              className=" text-center justify-content-center"
                            >
                              No completed !!!
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </main>
      )}
    </>
  );
}

export default CompanyLoadServiceRequest;
