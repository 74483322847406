import React, { useContext, useEffect, useState } from "react";
import driver_profile from "../../../assets/images/driver_profile.png";
import ic_star_highlighted from "../../../assets/images/Live/ic_star_highlighted.svg";
import ic_star_grey from "../../../assets/images/Live/ic_star_grey.svg";
import pan_id from "../../../assets/images/pan_id.svg";
import right_arrow from "../../../assets/images/right_arrow.svg";
import { Link, useParams } from "react-router-dom";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";

const DriverDetails = () => {
  const { customerData, isCustomer, sidebar } = useContext(AppContext);
  let [loading, setLoading] = useState(false);
  const { id } = useParams();
  const base_url = "http://logistic.redbytes.in/";
  const [driverDetails, setDriverDetails] = useState({});
  const [loadInfo, setLoadInfo] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});
  console.log(vehicleDetails);
  console.log(loadInfo);
  const loadDriverDetails = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_DRIVER_DETAIL, {
      ...customerData,
      driver_id: id,
    })
      .then((data) => {
        setLoading(false);
        if (!data.error && data.json.result) {
          console.log("Driver data ::: ", data);
          setDriverDetails(data.json.driver_details);
          setVehicleDetails(data.json.vehicle_details);
          setLoadInfo(data.json);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  useEffect(() => {
    loadDriverDetails();
  }, []);
  return (
    <React.Fragment>
      <main className="cx-main" id="cxMain">
        <section id="cxl-locations">
          <div className="row">
            <div className="col-lg-12">
              <div className="driver_service_block">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="cxp-bdetails-content"
                      style={{ marginTop: "0" }}
                    >
                      <div className="cxp-details-banner"></div>
                      <div className="cxp-details">
                        <div className="cxp-load-image">
                          <img
                            style={{
                              borderRadius: "50%",
                            }}
                            src={
                              driverDetails.driver_profile_pic
                                ? base_url + driverDetails.driver_profile_pic
                                : driver_profile
                            }
                          />
                          <div className="cxp-load-graphic"></div>
                          <div className="cxp-load-name">
                            <label>
                              {driverDetails.driver_name
                                ? driverDetails.driver_name
                                : "Leroy Sane"}
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="cxl-rating">
                              <ul class="cxd-driver-info">
                                <li>
                                  <div class="cxd-rating">
                                    {driverDetails.rating > 0 ? (
                                      <img src={ic_star_highlighted} />
                                    ) : (
                                      <img src={ic_star_grey} />
                                    )}
                                    {driverDetails.rating > 1 ? (
                                      <img src={ic_star_highlighted} />
                                    ) : (
                                      <img src={ic_star_grey} />
                                    )}
                                    {driverDetails.rating > 2 ? (
                                      <img src={ic_star_highlighted} />
                                    ) : (
                                      <img src={ic_star_grey} />
                                    )}
                                    {driverDetails.rating > 3 ? (
                                      <img src={ic_star_highlighted} />
                                    ) : (
                                      <img src={ic_star_grey} />
                                    )}
                                    {driverDetails.rating > 4 ? (
                                      <img src={ic_star_highlighted} />
                                    ) : (
                                      <img src={ic_star_grey} />
                                    )}

                                    <span>
                                      ({driverDetails.reviewed_count})
                                      {isCustomer ? (
                                        <Link
                                          className="mx-2"
                                          to={"/rating/" + id}
                                        >
                                          Rate and review
                                        </Link>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                </li>
                                <li style={{ fontSize: "14px" }}>
                                  {driverDetails.address
                                    ? driverDetails.address
                                    : "No.125/2, Kalas Area, Visharantwadi, Pune - 411015."}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="service_load_num">
                              <ul>
                                <li>
                                  <h5>Total Loads</h5>
                                  <p>{loadInfo.total_loads}</p>
                                </li>
                                <li>
                                  <h5>Delivered</h5>
                                  <p>{loadInfo.delivered_loads}</p>
                                </li>
                                <li>
                                  <h5>Ongoing</h5>
                                  <p>{loadInfo.ongoing_loads}</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <hr className="lightblack my-4" />

                        <div className="service_social">
                          <h3 className="smorangetitle">Contact Info</h3>
                          <ul className="mt-3">
                            <li>
                              <p className="mb-2">Email</p>
                              <a
                                href="#"
                                className="blue_link text-decoration-underline"
                              >
                                {driverDetails.email
                                  ? driverDetails.email
                                  : "leroysane@gmail.com"}
                              </a>
                            </li>
                            <li>
                              <p className="mb-2">Mobile Number</p>
                              <a
                                href="#"
                                className="blue_link text-decoration-underline"
                              >
                                {driverDetails.mobile_number
                                  ? driverDetails.mobile_number
                                  : 9433443224}
                              </a>
                            </li>
                            <li>
                              <p className="mb-2">WhatsApp Number</p>
                              <a
                                href="#"
                                className="blue_link text-decoration-underline"
                              >
                                {driverDetails.whatsapp_number
                                  ? driverDetails.whatsapp_number
                                  : 9433443224}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="cxd-details-content">
                      <div class="cxd-content-title">
                        <h4>Assigned Vehicle Details</h4>
                      </div>
                      <div class="row">
                        <div class="col-2">
                          <div class="">
                            <label>Vehicle</label>
                            <p>
                              {vehicleDetails.vehicle_name
                                ? vehicleDetails.vehicle_name
                                : "None"}
                            </p>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="">
                            <label>Capacity</label>
                            <p>
                              {vehicleDetails.capacity
                                ? vehicleDetails.capacity
                                : "None"}
                            </p>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="">
                            <label>Type</label>
                            <p>
                              {vehicleDetails.vehicle_type
                                ? vehicleDetails.vehicle_type
                                : "None"}
                            </p>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="">
                            <label>Length</label>
                            <p>
                              {vehicleDetails.length
                                ? vehicleDetails.length
                                : "None"}
                            </p>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="">
                            <label>Color</label>
                            <p>
                              {vehicleDetails.vehicle_color
                                ? vehicleDetails.vehicle_color
                                : "None"}
                            </p>
                          </div>
                        </div>
                        <div class="col-2">
                          <div class="">
                            <label>License Plate Number</label>
                            <p>
                              {vehicleDetails.plate_number
                                ? vehicleDetails.plate_number
                                : "None"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="cxd-details-content">
                      <div class="cxd-content-title">
                        <h4>Documents</h4>
                      </div>
                      <div class="cxd-content-details">
                        <div class="row">
                          <div class="col-md-6 col-lg-4">
                            <div class="cxd-doc-content">
                              <h4>Emirates ID</h4>

                              <img
                                class="cxd-doc"
                                src={
                                  driverDetails.emirates_id_image
                                    ? base_url + driverDetails.emirates_id_image
                                    : pan_id
                                }
                                alt="drop-off-image"
                              />

                              <div class="cxd-doc-no">
                                <label>Emirates ID No.</label>
                                <span>
                                  {driverDetails.emirates_id
                                    ? driverDetails.emirates_id
                                    : 5946443534435}
                                </span>
                              </div>
                              <div class="cxd-doc-no">
                                <label>Emirates Expiry Date:</label>
                                <span>
                                  {driverDetails.driver_emirates_id_expiry_date
                                    ? driverDetails.driver_emirates_id_expiry_date
                                    : ""}
                                </span>
                              </div>
                              <div class="cxd-download">
                                <a
                                  href={
                                    base_url + driverDetails.emirates_id_image
                                  }
                                  class="cx-text-btn"
                                >
                                  Download PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4">
                            <div class="cxd-doc-content">
                              <h4>Driving License</h4>

                              <img
                                class="cxd-doc"
                                src={
                                  driverDetails.license_image
                                    ? base_url + driverDetails.license_image
                                    : pan_id
                                }
                                alt="drop-off-image"
                              />

                              <div class="cxd-doc-no">
                                <label>License No.</label>
                                <span>
                                  {driverDetails.licence_number
                                    ? driverDetails.licence_number
                                    : 5946443534435}
                                </span>
                              </div>
                              <div class="cxd-download">
                                <a
                                  href={base_url + driverDetails.license_image}
                                  class="cx-text-btn"
                                >
                                  Download PDF
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-4">
                            <div class="cxd-doc-content">
                              <h4>Car Registration Card</h4>

                              <img
                                class="cxd-doc"
                                src={
                                  driverDetails.driver_reg_card_image
                                    ? base_url +
                                      driverDetails.driver_reg_card_image
                                    : pan_id
                                }
                                alt="drop-off-image"
                              />

                              <div class="cxd-doc-no">
                                <label>Registration No.</label>
                                <span>
                                  {driverDetails.driver_car_reg_no
                                    ? driverDetails.driver_car_reg_no
                                    : 5946443534435}
                                </span>
                              </div>
                              <div class="cxd-download">
                                <a
                                  href={
                                    base_url +
                                    driverDetails.driver_reg_card_image
                                  }
                                  class="cx-text-btn"
                                >
                                  Download PDF
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default DriverDetails;
