import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import upload from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/cross.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import circle_cross from "../../../assets/images/circle_cross.svg";
import upload_3 from "../../../assets/images/upload_3.svg";
import upload_2 from "../../../assets/images/upload_2.svg";
import upload_1 from "../../../assets/images/upload_1.svg";
import truck from "../../../assets/images/truck.svg";
import vehicle_profile_default from "../../../assets/images/vehicle_profile_default.svg";
import { AppContext } from "../../../context/user/AppContext";
import { FileUploader } from "react-drag-drop-files";
import swal from "sweetalert";
import ApiConfig from "../../../api/ApiConfig";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../../api/ApiServices";
import SpinnerCmp from "../../../SpinnerCmp";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
const base_url = "http://logistic.redbytes.in/";
const AddVehicles = (props) => {
  const { customerData } = useContext(AppContext);
  console.log(customerData);
  const { id } = useParams();
  const action = id ? (id > 0 ? "edit" : "add") : "register";
  // let vehicles = CompanyUserData.vehicles ? CompanyUserData.vehicles : [];
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [vehicleImages, setVehicleImages] = useState([]);
  const [lookUpDataVehicle, setLookUpDataVehicle] = useState([]);
  const [lookUpDataType, setLookUpDataType] = useState([]);
  const [lookUpDataCapacity, setLookUpDataCapacity] = useState([]);
  const [lookUpDataLength, setLookUpDataLength] = useState([]);
  const [lookUpData, setSetLookUpData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [imageWithBase64, setimageWithBase64] = useState({
    vehicle_thumbnail: "",
    vehicle_registration_card: "",
    vehicle_insurance_file: "",
    vehicle_registration_card_Base64: "",
    vehicle_insurance_file_Base64: "",
  });
  const [state, setState] = useState({
    vehicle_plate_no: "",
    vehicle_registration_no: "",
    registration_expiry: "",
    vehicle_insurance_no: "",
    vehicle_type: "",
    vehicle_capacity: "",
    vehicle_capacity_text: "",
    vehicle_color: "",
    vehicle_name: "",
    vehicle_name_text: "",
    vehicle_length: "",
    vehicle_insurance_file: "",
    vehicle_registration_card: "",
    vehicle_thumbnail: "",
    vehicle_images: [],
    images_filelist: "",
    insurance_expiry: "",
  });
  console.log(state);
  const [vehicle_name, setVehiclename] = useState("");
  const [vehicle_length, setVehiclelength] = useState("");
  const [vehicle_capacity, setVehiclecapacity] = useState("");
  const [vehicle_type, setVehicletype] = useState("");
  const [capacityset, setcapacityset] = useState(false);
  const onChangeInputHandle = (e) => {
    const { name, value } = e.target;

    if (name === "vehicle_thumbnail") {
      setimageWithBase64({
        ...imageWithBase64,
        vehicle_thumbnail: e.target.files[0],
      });
      getBase64(e.target.files[0], (result) => {
        setState({ ...state, [name]: result });
      });
    } else if (name === "vehicle_name") {
      const text = name + "_text";
      setVehiclename(value);
      setState({
        ...state,
        vehicle_name: value,
        [text]: e.target.options[e.target.options.selectedIndex].text,
      });

      getType(value);
    } else if (name === "vehicle_type") {
      setVehicletype(value);
      getCapacity(value);
    } else if (name === "vehicle_length") {
      setVehiclelength(value);
    } else {
      if (name === "vehicle_capacity") {
        setVehiclecapacity(value);
        const text = name + "_text";
        setState({
          ...state,
          [name]: value,
          [text]: e.target.options[e.target.options.selectedIndex].text,
        });
        getLength(value);
      } else setState({ ...state, [name]: value });
    }

    /* if(e.target.options[e.target.selectedIndex].text==="Motocycle"){
     
      setDisabled(true)
    }
    else{
      setDisabled(false)
    } */
  };

  const handleChange = (files) => {
    let newvehicleImages = vehicleImages;
    setState({ ...state, images_filelist: files });

    for (let index = 0; index < files.length; index++) {
      if (action === "register") {
        getBase64(files[index], (result) => {
          newvehicleImages.push({ image_url: result });
        });
      } else newvehicleImages.push(files[index]);
    }
    setVehicleImages(newvehicleImages);
  };

  const handleRCImageFileChange = (file) => {
    getBase64(file, (result) => {
      setimageWithBase64({
        ...imageWithBase64,
        vehicle_registration_card: file,
        vehicle_registration_card_Base64: result,
      });
    });
  };
  const handleICImageFileChange = (file) => {
    getBase64(file, (result) => {
      setimageWithBase64({
        ...imageWithBase64,
        vehicle_insurance_file: file,
        vehicle_insurance_file_Base64: result,
      });
    });
  };

  const removefile = (index) => {
    const dt = new DataTransfer();
    const input = state.images_filelist;

    for (let i = 0; i < input.length; i++) {
      const file = input[i];
      if (index !== i) dt.items.add(file);
    }
    let fileinput = dt.files;
    setState({ ...state, images_filelist: fileinput });

    vehicleImages.splice(index, 1);
    setVehicleImages(vehicleImages);
  };
  const removeimageWithBase64 = (e) => {
    alert(e.target.name);
    const { name } = e.target.name;
    const base64file = e.target.name + "_Base64";

    setimageWithBase64({ ...imageWithBase64, [name]: "", [base64file]: "" });
  };

  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // cb(reader.result.replace(/^data:image.+;base64,/, ""));
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const onAddVehicle = () => {
    if (validateFields()) {
      if (id) {
        onSubmit();
      } else {
        state.vehicle_insurance_file =
          imageWithBase64.vehicle_insurance_file_Base64;
        state.vehicle_registration_card =
          imageWithBase64.vehicle_registration_card_Base64;
        state.vehicle_images = vehicleImages;
        if (props.isCallFrom === "registration") props.onAdd(state);
      }
    }
  };
  const onSubmit = () => {
    setLoading(true);
    let vehiclePostData = {
      ...{
        vehicle_id: id,
        plate_no: state.vehicle_plate_no,
        registration_no: state.vehicle_registration_no,
        insurance_no: state.vehicle_insurance_no,
        type: vehicle_type,
        color: state.vehicle_color,
        capacity: vehicle_capacity,
        vehicle_name: vehicle_name,
        length: vehicle_length,
        registration_card: imageWithBase64.vehicle_registration_card,
        insurance_file: imageWithBase64.vehicle_insurance_file,
        images: state.images_filelist ? state.images_filelist : [], //vehicleImages,
        thumbnail: imageWithBase64.vehicle_thumbnail,
        insurance_expiry: state.insurance_expiry,
        registration_expiry: state.registration_expiry,
      },
      ...customerData,
    };

    console.log(vehiclePostData);
    let formData;
    try {
      formData = getFormData(vehiclePostData);
    } catch (error) {
      setLoading(false);
    }
    console.log("form data :: ", formData);
    multipartPostCallWithErrorResponse(
      action === "edit"
        ? ApiConfig.COMPANY_EDIT_VEHICLE_API
        : ApiConfig.COMPANY_ADD_VEHICLE_API,
      formData
    )
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data) {
          if (data.error == false && data.json.result) {
            swal({
              title: "Success",
              text: data.json.message,
              icon: "success",
              button: true,
            }).then(() => {
              navigate("/VehicleListning");
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (key === "images" && object[key].length > 0) {
        for (let index = 0; index < object[key].length; index++) {
          formData.append("images[]", object[key][index]);
        }
      } else formData.append(key, object[key]);
    });
    return formData;
  };

  const validateFields = () => {
    let errorMessage = "Invalid input for ";
    let isValid = true;
    /*  if (!state.vehicle_thumbnail) {
      errorMessage = "Upload thumbnail";
      isValid = false;
    } */ if (
      action !== "edit" &&
      (!vehicleImages || vehicleImages.length === 0)
    ) {
      errorMessage = "Upload vehicle images";
      isValid = false;
    } else if (!vehicle_name) {
      errorMessage += "Select Vehicle";
      isValid = false;
    } else if (!vehicle_capacity) {
      errorMessage += "Capacity";
      isValid = false;
    } else if (!vehicle_type) {
      errorMessage = "Select vehicle type ";
      isValid = false;
    } else if (!vehicle_length) {
      errorMessage += "Length";
      isValid = false;
    } else if (!state.vehicle_plate_no) {
      errorMessage += "License Plate Number";
      isValid = false;
    } else if (!state.vehicle_color) {
      errorMessage += "Color";
      isValid = false;
    } else if (
      action !== "edit" &&
      !imageWithBase64.vehicle_registration_card_Base64
    ) {
      errorMessage = "Upload Car Registration Card";
      isValid = false;
    } else if (
      action !== "edit" &&
      !imageWithBase64.vehicle_insurance_file_Base64
    ) {
      errorMessage = "Upload Insurance Copy";
      isValid = false;
    } else if (!state.vehicle_registration_no) {
      errorMessage += "Registration no.";
      isValid = false;
    } else if (!state.vehicle_insurance_no) {
      errorMessage += "Insurance no.";
      isValid = false;
    } else if (!state.registration_expiry) {
      errorMessage += "Registration expiry date.";
      isValid = false;
    } else if (!state.insurance_expiry) {
      errorMessage += "Insurance expiry date.";
      isValid = false;
    }
    if (!isValid) {
      swal({
        // title: "Warning",
        text: errorMessage,
        icon: "warning",
        button: true,
      });
    }
    return isValid;
  };
  const getCapacity = (vehicle_type_id, vehicle_name_id) => {
    setLookUpDataCapacity([]);
    setVehiclecapacity("NA");
    /*  setLookUpDataCapacity([])
    setState({...state,vehicle_capacity:"NA"}) */
    PostCallWithErrorResponse(
      ApiConfig.DROPDOWN_LIST_COMPANY_VEHICLE_CAPACITIES,
      {
        ...customerData,
        vehicle_name_id: vehicle_name_id ? vehicle_name_id : vehicle_name,
        vehicle_type_id: vehicle_type_id,
      }
    )
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false) {
          setTimeout(() => {
            setLookUpDataCapacity(data.json.vehicle_capacities);
          }, 1000);

          if (action !== "edit") {
            setState({
              ...state,
              vehicle_type: data.json.vehicle_types[0].vehicle_type_id,
              vehicle_capacity:
                data.json.vehicle_capacities[0].vehicle_capacity_id,
              vehicle_name: data.json.vehicle_name[0].vehicle_name_id,
              vehicle_length: data.json.vehicle_length[0].vehicle_length_id,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const getLength = (vehicle_capacity_id, vehicle_type_id, vehicle_name_id) => {
    PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_COMPANY_VEHICLE_LENGTH, {
      ...customerData,
      vehicle_name_id: vehicle_name_id ? vehicle_name_id : vehicle_name,
      vehicle_type_id: vehicle_type_id ? vehicle_type_id : vehicle_type,
      vehicle_capacity_id: vehicle_capacity_id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false) {
          setTimeout(() => {
            setLookUpDataLength(data.json.vehicle_lengths);
          }, 1000);

          if (action !== "edit") {
            setState({
              ...state,
              vehicle_type: data.json.vehicle_types[0].vehicle_type_id,
              vehicle_capacity:
                data.json.vehicle_capacities[0].vehicle_capacity_id,
              vehicle_name: data.json.vehicle_name[0].vehicle_name_id,
              vehicle_length: data.json.vehicle_length[0].vehicle_length_id,
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const getType = (vehicle_name_id, isEdit) => {
    setLookUpDataType([]);
    setVehicletype("NA");

    /* setState({...state,vehicle_type:"NA"}) */
    /* alert(vehicle_name)
      setState({...state,vehicle_type:"NA"})
      alert(vehicle_name) */

    PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_COMPANY_VEHICLE_TYPES, {
      ...customerData,
      vehicle_name_id: vehicle_name_id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false) {
          setLookUpDataType(data.json.vehicle_types);

          setLookUpDataLength([]);
          setLookUpDataCapacity([]);
        } else {
          setSetLookUpData({
            ...lookUpData,
            vehicle_types: [],
            vehicle_capacities: [],
            vehicle_length: [],
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        /*  setSetLookUpData({...lookUpData,vehicle_types:[]}) */
        console.log("api response", error);
      });
  };
  const fillLoockupData = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_COMPANY_API, customerData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false) {
          setLookUpDataVehicle(data.json.vehicle_name);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log("api response", error);
      });
  };

  const loadVehicleDetails = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_VEHICLE_DETAILS_API, {
      ...customerData,
      vehicle_id: id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          var vehicle = data.json.vehicle_details;
          if (action == "edit") getType(vehicle.vehicle_name_id, true);
          getCapacity(vehicle.vehicle_type_id, vehicle.vehicle_name_id);
          getLength(
            vehicle.vehicle_capacity_id,
            vehicle.vehicle_type_id,
            vehicle.vehicle_name_id
          );
          setState({
            vehicle_plate_no: vehicle.plate_number,
            vehicle_registration_no: vehicle.vehicle_reg_no,
            vehicle_insurance_no: vehicle.vehicle_insurance_no,
            vehicle_type: vehicle.vehicle_type_id,
            vehicle_capacity: vehicle.vehicle_capacity_id,
            vehicle_color: vehicle.vehicle_color,
            vehicle_name: vehicle.vehicle_name_id,
            vehicle_length: vehicle.vehicle_length_id,
            vehicle_insurance_file: base_url + vehicle.vehicle_insurance_image,
            vehicle_registration_card: base_url + vehicle.vehicle_reg_card,
            vehicle_thumbnail: base_url + vehicle.vehicle_image,
            vehicle_images: vehicle.images,
            registration_expiry: vehicle.vehicle_registration_expiry,
            insurance_expiry: vehicle.vehicle_insurance_expiry,
          });
          setVehiclename(vehicle.vehicle_name_id);
          setVehiclecapacity(vehicle.vehicle_capacity_id);
          setVehiclelength(vehicle.vehicle_length_id);
          setVehicletype(vehicle.vehicle_type_id);
          // setTimeout(() => {

          // }, 2000);
          /*   getCapacity(vehicle.vehicle_type_id,vehicle.vehicle_name_id)
        getLength(vehicle.vehicle_capacity_id,vehicle.vehicle_type_id,vehicle.vehicle_name_id) */
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  useEffect(() => {
    fillLoockupData();
    if (action === "edit") {
      loadVehicleDetails();
    }
  }, [vehicleImages]);

  const FormRow = () => {
    return (
      <div className="register_right mt-4 ">
        <div className="row">
          {/* <div className="col-lg-12">
            <div className="cx-profile-pic">
              <div className="cx-form-group">
                <label htmlFor="cx-pro-cam">
                  <img
                    className="cx-pdefault"
                    src={
                      state.vehicle_thumbnail
                        ? state.vehicle_thumbnail
                        : vehicle_profile_default
                    }
                  />

                  <img className="cx-camera" src={ic_camera} />
                </label>
                <input
                  type="file"
                  name="vehicle_thumbnail"
                  className="form-control"
                  id="cx-pro-cam"
                  onChange={onChangeInputHandle}
                />
              </div>
            </div>
          </div> */}

          <div className="col-lg-6 pe-4 cx-form-group ">
            <label id="cx-rfname">
              Upload Vehicle Images <sup>*</sup>
            </label>

            <div className="drop_block vehicle_drop mb-3">
              <div className="vertical_m text-center">
                <img src={truck} alt="" className="mb-3" />
                <FileUploader
                  handleChange={handleChange}
                  id="fileupload"
                  name="file"
                  types={fileTypes}
                  multiple={true}
                  classes="cxs-upload-image"
                />
                {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="uploaded_items">
              {action === "edit" &&
              state.vehicle_images &&
              state.vehicle_images.length > 0 &&
              vehicleImages.length === 0
                ? state.vehicle_images.map((images, index) => {
                    return (
                      <div className="item_list">
                        <img
                          className="load_img"
                          src={base_url + images.vehicle_image_url}
                          alt=""
                        />
                      </div>
                    );
                  })
                : state.images_filelist && state.images_filelist.length > 0
                ? [...state.images_filelist].map((images, index) => {
                    console.log(images);
                    return (
                      <div className="item_list">
                        <img
                          className="load_img"
                          src={URL.createObjectURL(images)}
                          alt=""
                        />
                        <a
                          value={index}
                          onClick={() => {
                            removefile(index);
                          }}
                          className="close_item"
                        >
                          <img src={circle_cross} alt="" />
                        </a>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>

          <div className="col-lg-12">
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-rfname">
                      Select Vehicle<sup>*</sup>
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="select vehicle"
                      value={vehicle_name}
                      onChange={(e) => onChangeInputHandle(e)}
                      name="vehicle_name"
                      disabled={lookUpDataVehicle.length == 0}
                    >
                      {lookUpDataVehicle.length == 0 ? (
                        <option value="NA" selected>
                          {" "}
                          Select Vehicle
                        </option>
                      ) : (
                        <option value="NA"> Select Vehicle</option>
                      )}

                      {lookUpDataVehicle.length > 0
                        ? lookUpDataVehicle.map((vehicle, index) => (
                            <option value={vehicle.vehicle_name_id}>
                              {vehicle.vehicle_name_value}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-remail">
                      Capacity<sup>*</sup>
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="select vehicle"
                      value={vehicle_capacity}
                      onChange={onChangeInputHandle}
                      disabled={lookUpDataCapacity.length == 0}
                      name="vehicle_capacity"
                    >
                      <option value="NA">Select Capacity</option>
                      {lookUpDataCapacity.length > 0
                        ? lookUpDataCapacity.map((vehicle, index) => (
                            <option value={vehicle.vehicle_capacity_id}>
                              {vehicle.vehicle_capacity_value}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-rfname">
                      Type<sup>*</sup>
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="select vehicle"
                      value={vehicle_type}
                      /* disabled={disabled} */
                      onChange={onChangeInputHandle}
                      disabled={lookUpDataType && lookUpDataType.length === 0}
                      name="vehicle_type"
                    >
                      <option value="NA">Select Vehicle Type</option>
                      {lookUpDataType && lookUpDataType.length > 0
                        ? lookUpDataType.map((vehicle, index) => (
                            <option value={vehicle.vehicle_type_id}>
                              {vehicle.vehicle_type_code}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-rfname">
                      Length<sup>*</sup>
                    </label>
                    <select
                      className="form-select form-control"
                      aria-label="select vehicle"
                      value={vehicle_length}
                      onChange={onChangeInputHandle}
                      name="vehicle_length"
                      disabled={lookUpDataType && lookUpDataLength.length == 0}
                    >
                      <option value="NA">Vehicle Lengths</option>
                      {lookUpDataType && lookUpDataLength.length > 0
                        ? lookUpDataLength.map((vehicle, index) => (
                            <option value={vehicle.vehicle_length_id}>
                              {vehicle.vehicle_length_value}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-rfname">
                      License Plate Number<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cx-remail"
                      value={state.vehicle_plate_no}
                      onChange={onChangeInputHandle}
                      name="vehicle_plate_no"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="cx-form-group">
                    <label id="cx-remail">
                      Color<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cx-remail"
                      value={state.vehicle_color}
                      onChange={onChangeInputHandle}
                      name="vehicle_color"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-lg-6 pe-4">
            <label id="cx-rfname">Car Registration Card</label>
            <div className="drop_block mb-3">
              <div className="vertical_m text-center">
                <FileUploader
                  handleChange={handleRCImageFileChange}
                  name="file"
                  types={fileTypes}
                  fileOrFiles={imageWithBase64.vehicle_registration_card}
                />
                {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
              </div>
            </div>
            {action === "edit" && !imageWithBase64.vehicle_registration_card ? (
              <img
                className="load_img mb-2"
                style={{
                  width: "-webkit-fill-available",
                  maxWidth: "-webkit-fill-available",
                  height: "-webkit-fill-available",
                  maxHeight: "30%",
                }}
                src={state.vehicle_registration_card}
                alt=""
              />
            ) : null}
            {imageWithBase64.vehicle_registration_card ? (
              <div className="uploaded_file d-flex justify-content-between">
                {imageWithBase64.vehicle_registration_card.name.includes(
                  "pdf"
                ) ? (
                  <label>
                    {imageWithBase64.vehicle_registration_card.name}
                  </label>
                ) : (
                  <img
                    src={imageWithBase64.vehicle_registration_card_Base64}
                    height="130px"
                    width={"170px"}
                  ></img>
                )}

                {/*     <a
                  name="vehicle_registration_card"
                  onClick={(e)=>removeimageWithBase64(e)}
                  type="button"
                >
                  <img height={10} width={10} src={cross} alt="" />
                </a> */}
              </div>
            ) : (
              ""
            )}
            <div className="drop_input">
              <label id="cx-rfname">
                Vehicle Registration No.<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-rfname"
                style={{ border: "1px solid #ccc" }}
                placeholder="Registration No,"
                value={state.vehicle_registration_no}
                onChange={onChangeInputHandle}
                name="vehicle_registration_no"
              />
            </div>
            <label style={{ marginLeft: "8px", marginTop: "25px" }}>
              Registration expiry date:
            </label>
            <div className="drop_input">
              <input
                type="date"
                style={{ marginTop: "0px" }}
                className="form-control"
                id="cx-rfname"
                placeholder="Registration No,"
                value={state.registration_expiry}
                onChange={onChangeInputHandle}
                name="registration_expiry"
              />
            </div>
          </div>
          <div className="col-lg-6 ps-4">
            <label id="cx-rfname" className="new-insurance">
              Insurance Copy
            </label>

            <div className="drop_block mb-3">
              <div className="vertical_m text-center">
                <FileUploader
                  handleChange={handleICImageFileChange}
                  name="file"
                  types={fileTypes}
                  fileOrFiles={imageWithBase64.vehicle_insurance_file}
                />
                {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
              </div>
            </div>
            {action === "edit" && !imageWithBase64.vehicle_insurance_file ? (
              <img
                className="load_img mb-2"
                style={{
                  width: "-webkit-fill-available",
                  maxWidth: "-webkit-fill-available",
                  height: "-webkit-fill-available",
                  maxHeight: "30%",
                }}
                src={state.vehicle_insurance_file}
                alt=""
              />
            ) : null}
            {imageWithBase64.vehicle_insurance_file ? (
              <div className="uploaded_file d-flex justify-content-between">
                {imageWithBase64.vehicle_insurance_file.name.includes("pdf") ? (
                  <label>{imageWithBase64.vehicle_insurance_file.name}</label>
                ) : (
                  <img
                    src={imageWithBase64.vehicle_insurance_file_Base64}
                    height="130px"
                    width={"170px"}
                  ></img>
                )}

                {/*   
                <a
                  name="vehicle_insurance_file"
                  onClick={removeimageWithBase64}
                >
                  <img height={10} width={10} src={cross} alt="" />
                </a> */}
              </div>
            ) : (
              ""
            )}
            <div className="drop_input">
              <label id="cx-rfname">
                Vehicle Insurance No.<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-rfname"
                placeholder="Insurance No."
                style={{ border: "1px solid #ccc" }}
                value={state.vehicle_insurance_no}
                onChange={onChangeInputHandle}
                name="vehicle_insurance_no"
              />
            </div>
            <label style={{ marginLeft: "8px", marginTop: "25px" }}>
              Insurance expiry date:
            </label>
            <div className="drop_input">
              <input
                type="date"
                style={{ marginTop: "0px" }}
                className="form-control my-2"
                id="cx-rfname"
                placeholder="Insurance No."
                value={state.insurance_expiry}
                onChange={onChangeInputHandle}
                name="insurance_expiry"
              />
            </div>
          </div>
          {id ? (
            <div className="col-lg-12">
              <div className="d-flex justify-content-end mt-4">
                <button onClick={onAddVehicle} className="blue_btn">
                  {id > 0 ? "UPDATE" : "ADD"} VEHICLE
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {}, [vehicleImages]);

  return (
    <React.Fragment>
      {loading ? (
        <div className="sweet-loading">
          <SpinnerCmp loading={loading} size={40} />
        </div>
      ) : (
        <>
          {props.isCallFrom && props.isCallFrom === "registration" ? (
            <>
              <div className="right_block cx-reg-content">
                <div className="d-flex justify-content-between">
                  <h2>Add Vehicles</h2>
                  <p className="step_num">4/5</p>
                </div>
                {FormRow()}
              </div>

              <div className="rfbtn_block">
                <div className="d-flex justify-content-between">
                  <button onClick={props.onCancel} className="orange_btn">
                    Cancel
                  </button>
                  <button onClick={onAddVehicle} className="orange_btn">
                    Add
                  </button>
                </div>
              </div>
            </>
          ) : (
            <main className="cx-main" id="cxMain">
              <section id="cxl-locations">
                <div className="row add_vehicle_form cx-reg-content">
                  <div className="col-lg-8 offset-lg-2 col-12">
                    <div className="driver_service_block add_vehicle_service">
                      <div className="row">{FormRow()}</div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default AddVehicles;
