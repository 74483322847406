import React, { cloneElement, useContext, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { AppContext } from '../../../context/user/AppContext'
import vehicle from '../../../assets/images/Live/map_marker.svg'
const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw'
const google = window.google

const AnyReactComponent = ({ text }) => <div>{text}</div>
const Newcomponent = ({ text }) => (
  <div>
    <img
      style={{
        width: '88px',
        height: '93px',
        border: '0px',
        padding: '0px',
        margin: '0px',
        maxWidth: 'none',
        color: 'red',
        transform: 'translate(-50%, -50%)',
      }}
      src={vehicle}
    ></img>
  </div>
)

const directionsService = new google.maps.DirectionsService()
const directionsRenderer = new google.maps.DirectionsRenderer()

function LiveTrackingMap() {
  // console.log('currentlocation', currentlocation)
  const {
    googleApiObj,
    setIsGoogleApiLoadedObj,
    newmap,
    setNewmap,
  } = useContext(AppContext)

  const apiIsLoaded = (map, maps) => {
    setIsGoogleApiLoadedObj(map)

    directionsRenderer.setDirections({ routes: [] })

    directionsRenderer.setMap(map)
    // const origin = start
    // const destination = end

    directionsService.route(
      {
        origin: origin,
        // destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          /* console.log(result.geocoded_waypoints[(0, 1)]); */
          /*  result.geocoded_waypoints = result.geocoded_waypoints[(0, 1)]; */
          console.log('result ::', result)
          directionsRenderer.setDirections(result)
        } else {
          console.error(`error fetching directions ${result}`)
        }
      },
    )
  }

  // useEffect(() => {
  //   apiIsLoaded(googleApiObj, googleApiObj)
  // }, [start, end])

  const onClick = (obj) => {
    console.log(obj)

    console.log(obj.address)
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw',
      }}
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={{ lat: 40.756795, lng: -73.954298 }}
      defaultZoom={18}
      onClick={onClick}
      onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
    >
      {/* <AnyReactComponent
     lat={start.lat}
     lng={start.lng}
     text="My Marker"
   /> */}
      {/* {currentlocation.lat && currentlocation.lng ? (
        <Newcomponent
          style={{ backgroundColor: 'white' }}
          lat={currentlocation.lat}
          lng={currentlocation.lng}
          text="Driver is now at this location"
        />
      ) : (
        ''
      )} */}
    </GoogleMapReact>
  )
}

export default LiveTrackingMap
