import React, { useContext } from "react";
import jofar_logo from "../assets/images/Live/jofar_brand_logo.svg";
import ic_notification from "../assets/images/Live/ic_notification.svg";
import ic_arrow_right from "../assets/images/ic_arrow_right.svg";
import ic_profile from "../assets/images/Live/ic_profile.svg";
import ic_payment from "../assets/images/Live/ic_payment.svg";
import ic_payment_history from "../assets/images/Live/ic_payment_history.svg";
import ic_add_loc from "../assets/images/Live/ic_add_loc.svg";
import rate from "../assets/images/Live/ic_rate.svg";
import tutorial from "../assets/images/Live/ic_tutorial.svg";
import ic_contact from "../assets/images/Live/ic_contact.svg";
import ic_about_us from "../assets/images/Live/ic_about_us.svg";
import ic_terms from "../assets/images/Live/ic_terms.svg";
import ic_policy from "../assets/images/Live/ic_policy.svg";
import ic_logout from "../assets/images/Live/ic_logout.svg";
import ic_close from "../assets/images/Live/ic_close.svg";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../context/user/AppContext";
import bank_account from "../assets/images/bank_account.svg";
import earning from "../assets/images/earning.svg";
import vehicles_menu from "../assets/images/vehicles_menu.svg";
import telr from "../assets/images/ic_telr.png";
import drivers_menu from "../assets/images/drivers_menu.svg";
export default function Header(props) {
  const location = useLocation();
  let currentRoute = location.pathname;
  const [state, setState] = React.useState(false);
  const navigate = useNavigate();
  const base_url = "http://logistic.redbytes.in/";
  const {
    isLoggedin,
    setisLoggedin,
    customerData,
    isCustomer,
    setisCustomer,
    sidebar,
    setSidebar,
    NotificationData,
  } = useContext(AppContext);
  console.log(NotificationData);
  let compdriver = localStorage.getItem("role2");

  const handleLogout = () => {
    localStorage.removeItem("api_key");
    localStorage.removeItem("role");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_pic");
    setisLoggedin(false);
    navigate("/Login");
  };

  return (
    <div>
      <header className={sidebar ? "cx-header cx-active" : "cx-header"}>
        <nav className="navbar navbar-expand-lg cx-navbar">
          <div className="container-fluid">
            <div className="cx-breadcrumb">
              <ul className="cx-breadcrumb-list">
                <li className="cx-inactive cx-inactive-p">
                  {/* <span>{props.title}</span>
                  <img src="images/Send Steps/ic_breadcrumb_arrow.svg" /> */}
                  {currentRoute === "/home" && (
                    <label htmlFor="" className="route-page-name">
                      Home{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />
                    </label>
                  )}
                  {currentRoute === "/VehicleInfo" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Vehicle Info
                    </label>
                  )}
                  {currentRoute === "/AddLocations" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Add Locations
                    </label>
                  )}
                  {currentRoute === "/AddLocations1" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Add Locations
                    </label>
                  )}
                  {currentRoute === "/AddLocations2" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Add Locations
                    </label>
                  )}
                  {currentRoute === "/PickupLocationMap" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Add Locations
                    </label>
                  )}
                  {currentRoute === "/SendLoadPackageDetails" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Package Details
                    </label>
                  )}
                  {currentRoute === "/SendLoadAddPackages" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Add Packages
                    </label>
                  )}
                  {currentRoute === "/SendLoadBookingSummary" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Booking Summary
                    </label>
                  )}
                  {currentRoute === "/SendLoadPaymentMethod" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />
                      Payment Method
                    </label>
                  )}
                  {currentRoute === "/MapDetailsongoing" && (
                    <label htmlFor="" className="route-page-name">
                      Send Load{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />
                      Live Tracking
                    </label>
                  )}
                  {currentRoute === "/LiveTrackingLoadDetails" && (
                    <label htmlFor="" className="route-page-name">
                      Live Tracking{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      45654656567{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Load Details
                    </label>
                  )}
                  {currentRoute === "/OverAllStatus" && (
                    <label htmlFor="" className="route-page-name">
                      Live Tracking{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      45654656567{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Load Details{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />{" "}
                      Status
                    </label>
                  )}
                  {currentRoute === "/LoadServiceRequest" && (
                    <label htmlFor="" className="route-page-name">
                      Loads{" "}
                    </label>
                  )}
                  {currentRoute === "/CustomerProfile" && (
                    <label htmlFor="" className="route-page-name">
                      Accounts{" "}
                      <img src={ic_arrow_right} alt="" className="ms-1 me-1" />
                      My Profile
                    </label>
                  )}
                </li>
              </ul>
            </div>
            {/* VehicleInfo */}
            <ul
              className="navbar-nav ms-auto navbar-new"
              style={{
                flexDirection:
                  window.localStorage.href === "/" ? "column" : "row",
              }}
            >
              <li className="nav-item position-relative">
                <Link
                  to="/Notification"
                  className="nav-link active"
                  aria-current="page"
                >
                  <img src={ic_notification} alt="notification-icon" />
                  <span
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                    style={{ backgroundColor: "#EE651E", color: "white" }}
                  >
                    {NotificationData}
                  </span>

                  <span class="badge bg-primary rounded-pill"></span>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link cx-expand-account show"
                  aria-current="page"
                  onClick={() => {
                    setState(!state);
                  }}
                >
                  <img
                    className="header-profile-pic"
                    src={
                      customerData.user_pic
                        ? base_url + customerData.user_pic
                        : ic_profile
                    }
                    alt="profile-pic"
                  />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <!-- ACCOUNT STARTS HERE --> */}

      <section id="cx-account" className={state ? "cx-open" : null}>
        <div className="cx-account-out">
          <div className="cx-filter"></div>
          <div className="cx-account-content">
            <div className="cx-account-header">
              <h4>Accounts</h4>
              <button
                type="button"
                onClick={() => {
                  setState(!state);
                }}
                className="cx-accounts-btn"
              >
                <img src={ic_close} alt="close-icon" />
              </button>
            </div>
            {isCustomer ? (
              <ul className="cx-account-list">
                <li>
                  <Link to="/CustomerProfile" onClick={() => setState(!state)}>
                    {" "}
                    <img src={ic_profile} alt="profile-icon" /> My Profile
                  </Link>
                </li>
                <li>
                  <Link to="/AddPaymentMethod" onClick={() => setState(!state)}>
                    <img src={ic_payment} alt="payment-icon" /> Payment Method
                  </Link>
                </li>
                <li>
                  <Link to="/PaymentHistory" onClick={() => setState(!state)}>
                    <img src={ic_payment_history} alt="payment-history-icon" />{" "}
                    Payment History
                  </Link>
                </li>
                <li>
                  <Link
                    to="/SavedLocationList"
                    onClick={() => setState(!state)}
                  >
                    <img src={ic_add_loc} alt="saved-locations-icon" /> Saved
                    Locations
                  </Link>
                </li>
                <li>
                  <Link to="/Rating" onClick={() => setState(!state)}>
                    <img
                      src={rate}
                      alt="saved-locations-icon"
                      className=""
                      style={{ marginRight: "14px" }}
                    />
                    Rate & Review
                  </Link>
                </li>
                <li>
                  <Link to="/Tutorial" onClick={() => setState(!state)}>
                    <img
                      src={tutorial}
                      alt="saved-locations-icon"
                      className=""
                      style={{ marginRight: "18px" }}
                    />
                    Tutorial
                  </Link>
                </li>
                <li>
                  <Link to="/ContactUs" onClick={() => setState(!state)}>
                    <img src={ic_contact} alt="contact-icon" /> Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs" onClick={() => setState(!state)}>
                    <img src={ic_about_us} alt="about-icon" /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Terms" onClick={() => setState(!state)}>
                    <img src={ic_terms} alt="terms-icon" /> Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/PrivacyPolicy" onClick={() => setState(!state)}>
                    <img src={ic_policy} alt="policy-icon" /> Privacy Policy
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.telr.com/legal/privacy-policy"
                    onClick={() => setState(!state)}
                  >
                    <img src={telr} alt="policy-icon" /> Telr Privacy Policy
                  </a>
                </li>
                <li>
                  <Link to="" onClick={handleLogout}>
                    <img src={ic_logout} alt="logout-icon" /> Logout
                  </Link>
                </li>
              </ul>
            ) : (
              <ul class="cx-account-list">
                <li>
                  <Link to="/CustomerProfile" onClick={() => setState(!state)}>
                    <img src={ic_profile} alt="profile-icon" /> My Profile
                  </Link>
                </li>

                <li>
                  <Link to="/bankaccount" onClick={() => setState(!state)}>
                    <img src={bank_account} alt="payment-icon" /> Bank Account
                  </Link>
                </li>
                <li>
                  <Link to="/EarningStats" onClick={() => setState(!state)}>
                    <img src={earning} alt="payment-icon" /> Earning Stats
                  </Link>
                </li>
                {compdriver == "company" ? (
                  <li>
                    <Link to="/PaymentHistory" onClick={() => setState(!state)}>
                      <img
                        src={ic_payment_history}
                        alt="payment-history-icon"
                      />{" "}
                      Payment History
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to="/VehicleListning" onClick={() => setState(!state)}>
                    <img src={vehicles_menu} alt="saved-locations-icon" />{" "}
                    Vehicles
                  </Link>
                </li>
                {compdriver == "company" ? (
                  <li>
                    <Link to="/DriverListning" onClick={() => setState(!state)}>
                      <img src={drivers_menu} alt="saved-locations-icon" />{" "}
                      Drivers
                    </Link>
                  </li>
                ) : null}
                <li>
                  <Link to="/ContactUs" onClick={() => setState(!state)}>
                    <img src={ic_contact} alt="contact-icon" /> Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs" onClick={() => setState(!state)}>
                    <img src={ic_about_us} alt="about-icon" /> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Terms" onClick={() => setState(!state)}>
                    <img src={ic_terms} alt="terms-icon" /> Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/PrivacyPolicy" onClick={() => setState(!state)}>
                    <img src={ic_policy} alt="policy-icon" /> Privacy Policy
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.telr.com/legal/privacy-policy"
                    onClick={() => setState(!state)}
                  >
                    <img src={telr} alt="policy-icon" /> Telr Privacy Policy
                  </a>
                </li>
                <li>
                  <Link to="" onClick={handleLogout}>
                    <img src={ic_logout} alt="logout-icon" /> Logout
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
