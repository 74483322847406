import React from 'react'
import {Link} from 'react-router-dom'
import vehicle_profile_default from '../../../assets/images/vehicle_profile_default.svg'
import ic_camera from '../../../assets/images/login/ic_camera.svg'
import truck from '../../../assets/images/truck.svg'
import circle_cross from '../../../assets/images/circle_cross.svg'
import cross from '../../../assets/images/cross.svg'
import upload from '../../../assets/images/upload.svg'
import upload_1 from '../../../assets/images/upload_1.svg'
import upload_2 from '../../../assets/images/upload_2.svg'
import upload_3 from '../../../assets/images/upload_3.svg'
import CompanySideBar from '../../../sharedComponent/CompanySideBar'
import CompanyHeader from '../../../sharedComponent/CompanyHeader'



export const AddVehicle = () => {
  return (
    <React.Fragment>
        
        <main className="cx-main" id='cxMain'>
        <section id="cxl-locations">
            <div className="row add_vehicle_form cx-reg-content">
                <div className="col-lg-8 offset-lg-2 col-12">
                    <div className="driver_service_block add_vehicle_service">
                        <div className="row">
                            <div className="register_right mt-4 ">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="cx-profile-pic mt-0">
                                            <img className="cx-pdefault" src={vehicle_profile_default} />
                                            <img className="cx-camera" src={ic_camera} />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 ps-3 pe-4 cx-form-group">
                                        <label id="cx-rfname">Upload Vehicle Image <sup>*</sup></label>
                                        <div className="drop_block vehicle_drop mb-3">
                                            <div className="vertical_m text-center">
                                                <img src={truck} alt=""/>
                                                <p className="m-0 mt-3">Drag & Drop files to upload</p>
                                                <p>OR</p>
                                                <a href="#">Browse Files</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="uploaded_items">
                                            <div className="item_list">
                                                <img className="vehicle_img" src={upload_1} alt=""/>
                                                <a href="#" className="close_item"><img src={circle_cross} alt=""/></a>
                                            </div>
                                            <div className="item_list">
                                                <img className="vehicle_img" src={upload_2} alt=""/>
                                                <a href="#" className="close_item"><img src={circle_cross} alt=""/></a>
                                            </div>
                                            <div className="item_list">
                                                <img className="vehicle_img" src={upload_3} alt=""/>
                                                <a href="#" className="close_item"><img src={circle_cross} alt=""/></a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-rfname">Select Vehicle<sup>*</sup></label>
                                                        <select className="form-select form-control" aria-label="select vehicle">
                                                            {/* <!-- <option selected>Open this select menu</option> --> */}
                                                            <option value="1">Pickup</option>
                                                            <option value="2">Drop</option>
                                                            <option value="3">Pickup</option>
                                                          </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-remail">Capacity<sup>*</sup></label>
                                                        <input type="text" className="form-control" id="cx-remail" value="1200"/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-rfname">Type<sup>*</sup></label>
                                                        <select className="form-select form-control" aria-label="select vehicle">
                                                            {/* <!-- <option selected>Open this select menu</option> --> */}
                                                            <option value="1">Pickup</option>
                                                            <option value="2">Drop</option>
                                                            <option value="3">Pickup</option>
                                                          </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-rfname">Length<sup>*</sup></label>
                                                        <select className="form-select form-control" aria-label="select vehicle">
                                                            {/* <!-- <option selected>Open this select menu</option> --> */}
                                                            <option value="1">50</option>
                                                            <option value="2">70</option>
                                                            <option value="3">80</option>
                                                          </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-rfname">License Plate Number<sup>*</sup></label>
                                                        <select className="form-select form-control" aria-label="select vehicle">
                                                            {/* <!-- <option selected>Open this select menu</option> --> */}
                                                            <option value="1">MH 12 FX 4529</option>
                                                            <option value="2">MH 14 FX 4529</option>
                                                            <option value="3">MH 18 FX 4529</option>
                                                          </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-4">
                                                        <label id="cx-remail">Color<sup>*</sup></label>
                                                        <input type="text" className="form-control" id="cx-remail"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-lg-6 pe-4">
                                        <label id="cx-rfname">Car Registration Card</label>
                                        <div className="drop_block mb-3">
                                            <div className="vertical_m text-center">
                                                <img src={upload} alt=""/>
                                                <p className="m-0">Drag & Drop or Browse Files</p>
                                            </div>
                                        </div>

                                        <div className="uploaded_file d-flex justify-content-between">
                                            <label>Car Regstration.Jpg</label>
                                            <a href="#"><img src={cross} alt=""/></a>
                                        </div>

                                        <div className="text-center"><label>or</label> </div>

                                        <div className="drop_input">
                                            <input type="text" className="form-control" id="cx-rfname" placeholder="Registration No,"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 ps-4">
                                        <label id="cx-rfname">Insurance Copy</label>
                                        <div className="drop_block mb-3">
                                            <div className="vertical_m text-center">
                                                <img src={upload} alt=""/>
                                                <p className="m-0">Drag & Drop or Browse Files</p>
                                            </div>
                                        </div>

                                        <div className="uploaded_file d-flex justify-content-between">
                                            <label>Insurance Copy.Jpg</label>
                                            <a href="#"><img src={cross} alt=""/></a>
                                        </div>

                                        <div className="text-center"><label>or</label> </div>

                                        <div className="drop_input">
                                            <input type="text" className="form-control" id="cx-rfname" placeholder="Insurance No."/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-5">
                                        <div className="d-flex justify-content-end">
                                            <Link to="/VehicleDetails" className="blue_btn">ADD VEHICLE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </main>

    </React.Fragment>
  )
}
