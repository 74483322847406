import React from "react";
import { Link } from "react-router-dom";
import CompanySideBar from "../../../sharedComponent/CompanySideBar";
import driver_profile_default from "../../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import upload from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/cross.svg";
import CompanyHeader from "../../../sharedComponent/CompanyHeader";

const AddDriver = () => {
  return (
    <React.Fragment>
      <main className="cx-main" id="cxMain">
        <section id="cxl-locations">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="register_right register_serivce cx-reg-content service-reg-content bg-white overflow-auto">
                <div className="row">
                  <div className="col-lg-12">
                    <form>
                      <div className="cx-profile-pic">
                        <img
                          className="cx-pdefault"
                          src={driver_profile_default}
                        />
                        <img className="cx-camera" src={ic_camera} />
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label id="cx-rfname">
                              Full Name<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="cx-rfname"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label id="cx-remail">
                              Email<sup>*</sup>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="cx-remail"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label id="cx-rmno">
                              Mobile Number<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="cx-rmno"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label id="cx-rcpass">
                              Whatsapp Number<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="cx-rcpass"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-6">
                    <label id="cx-rfname">Emirates Id</label>
                    <div className="drop_block mb-3">
                      <div className="vertical_m text-center">
                        <img src={upload} alt="" />
                        {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                      </div>
                    </div>

                    <div className="uploaded_file d-flex justify-content-between">
                      <label>Emiratesid.Jpg</label>
                      <a href="#">
                        <img src={cross} alt="" />
                      </a>
                    </div>
                    <div className="uploaded_file d-flex justify-content-between">
                      <label>Emiratesid.Jpg</label>
                      <a href="#">
                        <img src={cross} alt="" />
                      </a>
                    </div>

                    <div className="text-center">
                      <label>or</label>{" "}
                    </div>

                    <div className="drop_input">
                      <input
                        type="text"
                        style={{ height: "44px" }}
                        className="form-control"
                        id="cx-rfname"
                        placeholder="Enter Emirates Id"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label id="cx-rfname">Driving Lice</label>
                    <div className="drop_block mb-3">
                      <div className="vertical_m text-center">
                        <img src={upload} alt="" />
                        {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                      </div>
                    </div>

                    <div className="uploaded_file d-flex justify-content-between">
                      <label>Drivinglicense.Jpg</label>
                      <a href="#">
                        <img src={cross} alt="" />
                      </a>
                    </div>
                    <div className="uploaded_file d-flex justify-content-between">
                      <label>Drivinglicense.Jpg</label>
                      <a href="#">
                        <img src={cross} alt="" />
                      </a>
                    </div>

                    <div className="text-center">
                      <label>or</label>{" "}
                    </div>

                    <div className="drop_input">
                      <input
                        type="text"
                        style={{ height: "44px" }}
                        className="form-control"
                        id="cx-rfname"
                        placeholder="Enter Driving License Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label id="cx-rfname">
                      Covid Vaccination Certificate [Optional]
                    </label>
                    <div className="drop_block mb-3">
                      <div className="vertical_m text-center">
                        <img src={upload} alt="" />
                        {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
                      </div>
                    </div>

                    <div className="uploaded_file d-flex justify-content-between">
                      <label>Covid Certificate.Jpg</label>
                      <a href="#">
                        <img src={cross} alt="" />
                      </a>
                    </div>

                    <div className="text-center">
                      <label>or</label>{" "}
                    </div>

                    <div className="drop_input">
                      <input
                        type="date"
                        className="form-control"
                        id="cx-rfname"
                      />
                    </div>
                    <div className="drop_input mt-4">
                      <input
                        type="date"
                        className="form-control"
                        id="cx-rfname"
                      />
                    </div>
                    <div className="drop_input mt-4">
                      <input
                        type="date"
                        className="form-control"
                        id="cx-rfname"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-end mt-4">
                      <Link to="/DriverDetails" className="blue_btn">
                        ADD DRIVER
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default AddDriver;
