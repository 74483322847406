import React, { useContext, useEffect, useState } from "react";
import CompanySideBar from "../../sharedComponent/CompanySideBar";
import user_1 from "../../assets/images/user_1.svg";
import user_2 from "../../assets/images/user_2.svg";
import user_3 from "../../assets/images/user_3.svg";
import user_4 from "../../assets/images/user_4.svg";

import Chart from "react-apexcharts";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig, { URL } from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import SpinnerCmp from "../../SpinnerCmp";

const EarningStats = () => {
  const { sidebar } = useContext(AppContext);
  const [active, setActive] = useState("this_week");
  const [state, setState] = useState({
    options: {
      chart: {
        id: "apexchart-example",
        height: 200,
        type: "bar",
        width: 700,
      },
      colors: ["#FFE4C3"],
      plotOptions: {
        bar: {
          horizontal: false,
          // endingShape: 'rounded',
          columnWidth: "10%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        labels: {
          style: {
            colors: "#9aa0ac",
          },
        },
      },
      yaxis: {
        title: {
          text: "Earnings",
        },
        labels: {
          style: {
            color: "#9aa0ac",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$" + val + " thousands";
          },
        },
      },
      title: {
        text: "Earning Dashboard",
        align: "center",
        style: {
          color: "#444",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Collection Amount",
        data: [44, 55, 57, 56, 61, 58, 63],
      },
    ],
  });
  const [statemonthly, setStatemonthly] = useState({
    options: {
      chart: {
        id: "apexchart-example",
        height: 200,
        type: "bar",
        width: 700,
      },
      colors: ["#FFE4C3"],
      plotOptions: {
        bar: {
          horizontal: false,
          // endingShape: 'rounded',
          columnWidth: "10%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            colors: "#9aa0ac",
          },
        },
      },
      yaxis: {
        title: {
          text: "Earnings",
        },
        labels: {
          style: {
            color: "#9aa0ac",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "Earning Dashboard",
        align: "center",
        style: {
          color: "#444",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250,
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Collection Amount",
        data: [44, 55, 57, 56, 61, 58, 63],
      },
    ],
  });

  const [data, setData] = useState({});
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [datetype, setdatetype] = useState(false);
  const [selected, setSelected] = useState("this_week");
  const getPackageDataDetails = (date_type) => {
    setLoading(true);
    setSelected(date_type);
    PostCallWithErrorResponse(
      ApiConfig.EARNING_API_COMPANY,

      { ...customerData, date_type: date_type }
    )
      .then((data) => {
        console.log(data);
        setData(data.json);
        console.log(statemonthly.options.xaxis);
        console.log("datetype", date_type);
        if (date_type == "last_month") {
          setdatetype(true);
          setTimeout(() => {
            let variable = Array.from(data.json.earning_stats, (single) => {
              return single.earnings;
            });
            console.log("stats", variable);
            setStatemonthly({
              ...statemonthly,
              series: [
                {
                  name: "Collection Amount",
                  data: variable,
                },
              ],
            });
          }, 1000);
        } else {
          setdatetype(false);
          let variable = Array.from(data.json.earning_stats, (single) => {
            return single.earnings;
          });
          console.log("stats", variable);
          setState({
            ...state,
            series: [
              {
                name: "Collection Amount",
                data: variable,
              },
            ],
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData(null);
      });
  };

  const handleChange = (e) => {
    setActive(e.target.value);
    getPackageDataDetails(e.target.value);
  };

  useEffect(() => {
    getPackageDataDetails("this_week");
  }, []);

  return (
    <React.Fragment>
      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-8">
                <div className="card card-style mb-4">
                  <div className="card-body">
                    <div className="d-flex justify-content-end">
                      <select
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        className="form-select form-control"
                        aria-label="This Week"
                        style={{
                          width: "132px",
                          height: "38px",
                          fontSize: "14px",
                          lineHeight: "27px",
                        }}
                      >
                        {/* <!-- <option selected>Open this select menu</option> --> */}
                        {active == "this_week" ? (
                          <option selected value="this_week">
                            This Week
                          </option>
                        ) : (
                          <option selected value="this_week">
                            This Week
                          </option>
                        )}
                        {active == "last_week" ? (
                          <option selected value="last_week">
                            Last Week
                          </option>
                        ) : (
                          <option value="last_week">Last Week</option>
                        )}
                        {active == "last_month" ? (
                          <option selected value="last_month">
                            Last Month
                          </option>
                        ) : (
                          <option value="last_month">Last Month</option>
                        )}
                      </select>
                    </div>
                    <div className="recent-report__chart mt-4">
                      {state && datetype == false ? (
                        <Chart
                          options={state.options}
                          series={state.series}
                          type="bar"
                          width="100%"
                          height={320}
                        />
                      ) : (
                        ""
                      )}
                      {statemonthly && datetype == true ? (
                        <Chart
                          options={statemonthly.options}
                          series={statemonthly.series}
                          type="bar"
                          width="100%"
                          height={320}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card card-style total_earn">
                  <div className="card-body">
                    <div className="earn_stats">
                      <p className="small">Total Earnings</p>
                      <p className="title">{data ? data.total_earnings : ""}</p>
                    </div>
                    {/*   <div className="earn_stats">
                                  <p className="small">Total Earnings This Week</p>
                                  <p className="title">40 USD</p>
                              </div> */}
                    {/*   <div className="earn_stats">
                                  <p className="small">Profit</p>
                                  <p className="title">100 USD</p>
                              </div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-style top_drivers mt-4">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4>Top 5 Drivers</h4>
                      {/*                        <a href="#" className="blue_link">View All</a> */}
                    </div>
                    <hr className="lightblack" />
                    <table className="table table-borderless mt-4">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Loads</th>
                          <th scope="col">Earnings</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.drivers
                          ? data.drivers.map((single) => {
                              return (
                                <tr>
                                  <td>
                                    <img
                                      src={
                                        single.driver_profile_pic
                                          ? URL + single.driver_profile_pic
                                          : user_1
                                      }
                                      alt=""
                                      className="me-2 header-profile-pic"
                                    />
                                    {single.driver_name}{" "}
                                  </td>
                                  <td>{single.load_count}</td>
                                  <td>{single.earnings}</td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
};

export default EarningStats;
