import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../../assets/images/ic_current_location.svg'
import Autocomplete from 'react-google-autocomplete'

import '../../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../../context/user/AppContext'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import swal from 'sweetalert'
import Geocode from 'react-geocode'

const YOUR_GOOGLE_MAPS_API_KEY = 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw'
Geocode.setApiKey('AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw')
// set response language. Defaults to english.
Geocode.setLanguage('en')
Geocode.setLocationType('ROOFTOP')

// Enable or disable logs. Its optional.
Geocode.enableDebug()
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('es')
const AddSavedLocation = () => {
  const { sidebar, customerData, loadId } = useContext(AppContext)
  const navigate = useNavigate()
  const [addressList, setAddressList] = useState([])
  const [addressmap, setAddressmap] = useState('')
  const [name, setName] = useState('')
  console.log(addressmap)

  // console.table(addressList);
  let [loading, setLoading] = useState(true)
  let [empty, setEmpty] = useState(false)
  const [placeMap, setPlace] = useState({
    lat: '',
    lng: '',
  })
  console.log(placeMap)

  useEffect(() => {
    getMyLocation()
  }, [])

  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setPlace({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        },
        (error) => {
          // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        },
      )
    }
  }

  Geocode.fromLatLng(placeMap.lat, placeMap.lng).then(
    (response) => {
      const address = response.results[0].formatted_address
      setAddressmap(address)
      console.log(address)
    },
    (error) => {
      console.error(error)
    },
  )

  const getAddressList = () => {
    PostCallWithErrorResponse(ApiConfig.GET_LOCATION_API_CUSTOMER, {
      ...customerData,
    })
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          const data1 = data.json.address_list
          setAddressList(data1)
          setLoading(false)
        }
        if (data.json.result == false) {
          setAddressList([])
          setEmpty(true)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  useEffect(() => {
    getAddressList()
  }, [])
  const [state, setState] = useState({
    name: '',
    landmark: '',
    phone_no: '',
  })
  const [address, setAddress] = useState({
    address: '',
    latitude: '',
    longitude: '',
  })

  const postCall = () => {
    setLoading(true)
    PostCallWithErrorResponse(ApiConfig.ADD_LOCATION_SAVE_LOCATION, {
      ...customerData,
      ...state,
      ...address,
      // trip_id: loadId.trip_id,
    })
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          setState({
            name: '',

            // landmark: "",
            phone_no: '',
          })
          setAddress({
            address: '',
            latitude: '',
            longitude: '',
          })
          // alert.show(" Address added successfully...", {
          //   position: positions.TOP_CENTER,
          // });
          if (data.json.result) {
            swal({
              title: 'Success',

              text: data.json.message,

              icon: 'success',

              button: true,
            }).then(() => {
              navigate('/SavedLocationList')
            })
          } else {
            swal({
              title: 'warning',

              text: data.json.message,

              icon: 'warning',

              button: true,
            })
          }
          setLoading(false)
          getAddressList()
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const handleChange = (e) => {
    // if (e.target.name != "address") {
    // console.log(e.target.value);
    // if (e.target.value.trim()) hideValidationError(e.target.name);
    setState({ ...state, [e.target.name]: e.target.value })
    // }
  }

  const handleSelect = (place) => {
    setAddress({
      ...address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.formatted_address,
    })
  }

  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Add Save Locations</div>
                    {/* <div className="right">2/4</div> */}
                  </div>
                  <div
                    className="cxs-loc-content"
                    style={{
                      height: 'auto',
                      overflow: 'hidden',
                    }}
                  >
                    <div className="cxs-from">
                      <form>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Contact Person Name
                              </label>
                              <input
                                name="name"
                                onChange={(e) => handleChange(e)}
                                type="text"
                                className="form-control"
                                id="cxal-name"
                                placeholder=""
                                value={state.name}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fpno" className="form-label">
                                Contact Person Phone Number
                              </label>
                              <input
                                type="text"
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                                id="cxal-pno"
                                value={state.phone_no}
                                name="phone_no"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                        <label className="form-label">Location</label>
                        <div
                          className="location-add-card-main"
                          style={{ height: 'auto' }}
                        >
                          <div className="col-12 search-wrapper-main">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-search"
                              viewBox="0 0 16 16"
                              style={{ marginTop: '-3px' }}
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                            <Autocomplete
                              className="form-control ps-5"
                              name="address"
                              value={address.address}
                              onChange={(e) =>
                                setAddress({
                                  ...address,
                                  address: e.target.value,
                                })
                              }
                              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                              onPlaceSelected={(place) => handleSelect(place)}
                              placeholder=""
                              options={{
                                types: ['geocode', 'establishment'],
                              }}
                            />
                          </div>
                          {/* <div className="choose-location-address">
                            <Link to="/PickupLocationMap" className="d-flex">
                              <div>
                                <img
                                  src={ic_current_location}
                                  alt=""
                                  className="me-2"
                                />
                              </div>
                              <div className="left">
                                <Link to="/PickupLocationMap">
                                  USE CURRENT LOCATION
                                </Link>{" "}
                                <br />
                                <label htmlFor="">{addressmap}</label>
                              </div>
                            </Link>

                            <div className="right">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                style={{ fill: "#5F5F5F" }}
                              >
                                <path d="m13.061 4.939-2.122 2.122L15.879 12l-4.94 4.939 2.122 2.122L20.121 12z"></path>
                              </svg>
                            </div>
                          </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="/SavedLocationList">
                      <button type="button" className="cx-text-btn">
                        <span id="cancel-location">Cancel</span>
                      </button>
                    </Link>
                    {/* <Link to="/AddLocations2"> */}
                    <button
                      type="button"
                      className="cx-text-btn"
                      onClick={postCall}
                    >
                      {loading ? (
                        <div
                          class="spinner-border"
                          style={{
                            color: '#EE651E',
                            height: '20px',
                            width: '20px',
                          }}
                        />
                      ) : (
                        <>
                          <span>Add Location</span>

                          <img src={ic_continue_arrow_blue} />
                        </>
                      )}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default AddSavedLocation
