import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";

export default function LocationDetails() {
  const navigate = useNavigate();
  const {
    loadDetails,
    setLoadDetails,
    customerData,
    finalAddress,
    setfinalAddress,
    sidebar
  } = useContext(AppContext);
  console.log(loadDetails.locationDetail);
  const [locationDetail, setLocationDetail] = useState(
    
    loadDetails.locationDetail
      ? loadDetails.locationDetail
      : { ...finalAddress, ...customerData }
  );
  const onInputChangeHandle = (e) => {
    const { name, value } = e.target;
    if(value.trim())hideValidationError(name)
    setLocationDetail({ ...locationDetail, [name]: value });
  };
  const onContinue = () => {
    if (validateFields()) {
      setLoadDetails({ ...loadDetails, locationDetail });
      navigate("/LoadDetail");
    }
  };
  const validateFields = () => {
    let errorfields = [];
    let isValid = true;
    if (!locationDetail.from_address) {
      errorfields.push("from_address");
      isValid = false;
    }
    if (!locationDetail.from_name) {
      errorfields.push("from_name");
      isValid = false;
    }
    if (!locationDetail.from_phone_no) {
      errorfields.push("from_phone_no");
      isValid = false;
    }

    if (!locationDetail.to_address) {
      errorfields.push("to_address");
      isValid = false;
    }
    if (!locationDetail.to_name) {
      errorfields.push("to_name");
      isValid = false;
    }

    if (!locationDetail.to_phone_no) {
      errorfields.push("to_phone_no");
      isValid = false;
    }

    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById("error" + field).style.display = "block";
      });
    }
    return isValid;
  };
  const hideValidationError = (field) => {
    document.getElementById("error" + field).style.display = "none";
  };
  useEffect(()=>{
    if(!finalAddress.from_address && !finalAddress.to_address) navigate("/SendLoad");
  },[])
  return (
    <div>
  

      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxs-locations">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="cxs-content-outer d-flex justify-content-center ">
                  <div className="cxs-content-inner">
                    <div className="cxs-loc-content">
                      <div className="cxs-from">
                        <h4>From</h4>
                        <form>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="cx-form-group">
                                <label
                                  for="fname"
                                  className="form-label required"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="from_name"
                                  value={locationDetail.from_name}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="fname"
                                  aria-describedby="emailHelp"
                                />
                                <label
                                  id="errorfrom_name"
                                  className="for-error"
                                >
                                  Enter Name
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="cx-form-group">
                                <label
                                  for="fpno"
                                  className="form-label required"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="from_phone_no"
                                  value={locationDetail.from_phone_no}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="fpno"
                                  aria-describedby="emailHelp"
                                />
                                <label
                                  id="errorfrom_phone_no"
                                  className="for-error"
                                >
                                  Enter phone number
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="cx-form-group">
                                <label for="faddress" className="form-label">
                                  Address
                                </label>
                                <textarea
                                  name="from_address"
                                  value={locationDetail.from_address}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="faddress"
                                  placeholder=""
                                ></textarea>
                                <label
                                  id="errorfrom_address"
                                  className="for-error"
                                >
                                  Enter address
                                </label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="cxs-to">
                        <h4>To</h4>
                        <form>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="cx-form-group">
                                <label
                                  for="fname"
                                  className="form-label required"
                                >
                                  Name
                                </label>
                                <input
                                  type="text"
                                  name="to_name"
                                  value={locationDetail.to_name}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="fname"
                                  aria-describedby="emailHelp"
                                />
                                <label id="errorto_name" className="for-error">
                                  Enter Name
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="cx-form-group">
                                <label
                                  for="fpno"
                                  className="form-label required"
                                >
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="to_phone_no"
                                  value={locationDetail.to_phone_no}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="fpno"
                                  aria-describedby="emailHelp"
                                />
                                <label
                                  id="errorto_phone_no"
                                  className="for-error"
                                >
                                  Enter phone number
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="cx-form-group">
                                <label
                                  for="faddress"
                                  className="form-label required"
                                >
                                  Address
                                </label>
                                <textarea
                                  name="to_address"
                                  value={locationDetail.to_address}
                                  onChange={onInputChangeHandle}
                                  className="form-control"
                                  id="faddress"
                                  placeholder=""
                                ></textarea>
                                <label
                                  id="errorto_address"
                                  className="for-error"
                                >
                                  Enter address
                                </label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="cxs-nav">
                      <Link to="/SendLoad">
                        <button type="button" className="cx-text-btn">
                          <img src={ic_back_arrow_blue} />
                          <span>Back</span>
                        </button>
                      </Link>
                      {/* <Link to="/LoadDetail">  */}
                      <button
                        onClick={onContinue}
                        type="button"
                        className="cx-text-btn"
                      >
                        <span>Continue </span>
                        <img src={ic_continue_arrow_blue} />
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
