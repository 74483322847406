import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import { AppContext } from "../../../context/user/AppContext";
import swal from "sweetalert";
import SpinnerCmp from "../../../SpinnerCmp";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";

const AddBankAccount = (props) => {
  let [loading, setLoading] = useState(false);
  const {
    isCallFrom,
    closeCallback,
    prevCallback,
    onSubmit,
    successCallBack,
    isEdit,
    data,
  } = props;
  const { CompanyUserData, customerData } = useContext(AppContext);
  const navigate = useNavigate();
  const [state, setState] = useState({
    bank_account_name: isEdit
      ? data.bank_account_name
      : CompanyUserData.bank_account_name
      ? CompanyUserData.bank_account_name
      : "",
    bank_account_number: isEdit
      ? data.bank_account_number
      : CompanyUserData.bank_account_number
      ? CompanyUserData.bank_account_number
      : "",
    bank_iban: isEdit
      ? data.bank_account_iban
      : CompanyUserData.bank_iban
      ? CompanyUserData.bank_iban
      : "",
    bank_swift_code: isEdit
      ? data.bank_account_swift_code
      : CompanyUserData.bank_swift_code
      ? CompanyUserData.bank_swift_code
      : "",
    bank_address: isEdit
      ? data.bank_account_address
      : CompanyUserData.bank_address
      ? CompanyUserData.bank_address
      : "",
    bank_currency: isEdit
      ? data.bank_account_currency
      : CompanyUserData.bank_currency
      ? CompanyUserData.bank_currency
      : "",
  });
  const onInputChangeHandle = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const onSubmitData = (e) => {
    if (validateFields()) {
      if (isCallFrom === "registration") onSubmit(state);
      else {
        addEditBankAccount();
      }
    }
  };
  const addEditBankAccount = () => {
    const postData = {
      ...customerData,
      ...{
        account_name: state.bank_account_name,
        account_number: state.bank_account_number,
        iban: state.bank_iban,
        swift_code: state.bank_swift_code,
        address: state.bank_address,
        currency: state.bank_currency,
        bank_account_id: data.bank_account_id ? data.bank_account_id : "",
      },
    };
    const apiUri = isEdit
      ? ApiConfig.COMPANY_EDIT_BANK_ACCOUNTS_API
      : ApiConfig.COMPANY_ADD_BANK_ACCOUNTS_API;

    PostCallWithErrorResponse(apiUri, postData)
      .then((data) => {
        console.log(data);
        if (data.error === false) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then(() => {
            successCallBack();
          });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const validateFields = () => {
    let errorMessage = "Invalid input for ";
    let isValid = true;
    if (!state.bank_account_name) {
      errorMessage += "Account Name";
      isValid = false;
    } else if (!state.bank_account_number) {
      errorMessage += "Account Number";
      isValid = false;
    } else if (!state.bank_iban) {
      errorMessage += "IBAN";
      isValid = false;
    } else if (!state.bank_swift_code) {
      errorMessage += "Swift Code";
      isValid = false;
    } else if (!state.bank_address) {
      errorMessage += "Bank Address";
      isValid = false;
    } else if (!state.bank_currency) {
      errorMessage += "Currency";
      isValid = false;
    }
    if (!isValid) {
      swal({
        // title: "Warning",
        text: errorMessage,
        icon: "warning",
        button: true,
      });
    }
    return isValid;
  };
  const AddCommonContent = () => {
    return (
      <form>
        <div className="row mt-4">
          <div className="col-sm-6">
            <div className="cx-form-group">
              <label id="cx-rfname">
                Account Name<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-rfname"
                placeholder=""
                value={state.bank_account_name}
                name="bank_account_name"
                onChange={onInputChangeHandle}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="cx-form-group">
              <label id="cx-remail">
                Account Number<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-remail"
                placeholder=""
                value={state.bank_account_number}
                name="bank_account_number"
                onChange={onInputChangeHandle}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="cx-form-group">
              <label id="cx-rmno">
                IBAN<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-rmno"
                placeholder=""
                value={state.bank_iban}
                name="bank_iban"
                onChange={onInputChangeHandle}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="cx-form-group">
              <label id="cx-rcpass">
                Swift Code<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                id="cx-rcpass"
                placeholder=""
                value={state.bank_swift_code}
                name="bank_swift_code"
                onChange={onInputChangeHandle}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="cx-form-group">
              <label id="cx-rcpass">
                Bank Address<sup>*</sup>
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                value={state.bank_address}
                name="bank_address"
                onChange={onInputChangeHandle}
              ></textarea>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="cx-form-group">
              <label id="cx-rcpass">
                Currency<sup>*</sup>
              </label>
              <select
                className="form-select form-control"
                aria-label="select vehicle"
                value={state.bank_currency}
                name="bank_currency"
                onChange={onInputChangeHandle}
              >
                <option selected></option>
                <option value="1">Doller</option>
                <option value="2">Euro</option>
                <option value="3">AED</option>
              </select>
            </div>
          </div>
          {isCallFrom !== "registration" ? (
            <div className="col-lg-12">
              <div className="d-flex justify-content-end mt-4">
                <button onClick={closeCallback} className="blue_btn mx-3">
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={onSubmitData}
                  className="blue_btn"
                >
                  {isEdit ? "Update" : "Add"} Account
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    );
  };

  return (
    <React.Fragment>
      {isCallFrom && isCallFrom === "registration" ? (
        <>
          <div className="right_block cx-reg-content">
            <div className="d-flex justify-content-between">
              <h2>Add Bank Account</h2>
              <p className="step_num">5/5</p>
            </div>
            {AddCommonContent()}
          </div>
          <div className="rfbtn_block d-flex justify-content-between">
            <button
              onClick={() => prevCallback(true, state)}
              className="orange_btn"
            >
              Prev
            </button>
            <button onClick={onSubmitData} className="orange_btn">
              Continue
            </button>
          </div>
        </>
      ) : (
        <main className="cx-main" id="cxMain">
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {loading ? (
                  <div className="sweet-loading">
                    <SpinnerCmp loading={loading} size={40} />
                  </div>
                ) : (
                  <div className="register_right register_serivce cx-reg-content service-reg-content bg-white overflow-auto">
                    {AddCommonContent()}
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      )}
    </React.Fragment>
  );
};

export default AddBankAccount;
