import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../../assets/images/ic_current_location.svg'
import { AppContext } from '../../../context/user/AppContext'

import { useAlert, positions } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import '../../../assets/css/cx-sload-steps.css'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import swal from 'sweetalert'

const AddLocations2 = () => {
  const params = useParams()
  const locationData = useLocation().state
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  let id = params.id
  // const validateFields = () => {
  //   let errorfields = []
  //   let isValid = true
  //   if (!state.name) {
  //     errorfields.push('name')
  //     isValid = false
  //   }
  //   if (!state.landmark) {
  //     errorfields.push('landmark')
  //     isValid = false
  //   }
  //   if (!state.phone_no) {
  //     errorfields.push('phone_no')
  //     isValid = false
  //   }
  //   if (!address.address) {
  //     errorfields.push('address')
  //     isValid = false
  //   }
  //   if (!isValid) {
  //     errorfields.forEach((field) => {
  //       document.getElementById('error' + field).style.display = 'block'
  //     })
  //   }
  //   return isValid
  // }

  // const alert = useAlert()

  const {
    sidebar,
    location,
    customerData,
    loadId,
    place,
    setPlace,
    ConfirmMap,
  } = useContext(AppContext)
  console.log('ConfirmMap', ConfirmMap)
  const [state, setState] = useState({
    name: '',
    address: place.address,
    phone_no: '',
    latitude: place.center.lat,
    longitude: place.center.lng,
  })
  const [errMsg, setErrMsg] = useState({
    name: '',
    phone_no: '',
    address: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    const stateCopy = { ...state }
    const errorCopy = { ...errMsg }
    stateCopy[name] = value
    errorCopy[name] = ''
    setState(stateCopy)
    setErrMsg(errorCopy)
  }

  useEffect(() => {
    packageDetailsGet()
  }, [])

  const packageDetailsGet = () => {
    setLoading(true)

    PostCallWithErrorResponse(ApiConfig.GET_LOCATION_API_CUSTOMERS, {
      ...customerData,
      load_id: id,
    }).then((res) => {
      console.log(res)
      setLoading(false)
      // setConnectionProfileList(res.json.user_profile)
    })
  }

  const handleSubmit = (e) => {
    Add()
    e.preventDefault()
  }
  const Add = () => {
    if (state.name.trim().length === 0) {
      setErrMsg({
        ...errMsg,
        name: 'plase enter your name',
      })
      return
    }
    if (state.phone_no.trim().length === 0) {
      setErrMsg({
        ...errMsg,
        phone_no: 'please enter a phone number',
      })
      return
    } else {
      setLoading(true)

      PostCallWithErrorResponse(ApiConfig.ADD_LOCATION_LIST_API_CUSTOMERS, {
        ...customerData,
        ...state,
        trip_id: loadId.trip_id,
      })
        .then((res) => {
          console.log(res)
          swal({
            title: 'Success',

            text: res.json.message,

            icon: 'success',

            button: true,
          })
          setLoading(false)
          navigate('/AddLocations')
        })
        .catch((err) => console.log(err))
      setLoading(false)
    }
  }

  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Add Locations</div>
                    <div className="right">2/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from">
                      <form>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Contact Person Name
                              </label>
                              <input
                                value={state.name}
                                onChange={handleChange}
                                type="text"
                                name="name"
                                className="form-control"
                              />
                              {errMsg.name.length > 0 && (
                                <div className="text-danger text-center">
                                  {errMsg.name}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fpno" className="form-label">
                                Contact Person Phone Number
                              </label>
                              <input
                                value={state.phone_no}
                                onChange={(e) => {
                                  setErrMsg({ ...errMsg, phone_no: '' })

                                  const re = /[^0-9]/g

                                  if (!re.test(e.target.value)) {
                                    setErrMsg({ ...errMsg, phone_no: '' })

                                    setState({
                                      ...state,

                                      phone_no: e.target.value,
                                    })
                                  } else {
                                    setErrMsg({
                                      ...errMsg,

                                      phone_no: 'only number allow',
                                    })
                                  }
                                }}
                                type="text"
                                name="phone_no"
                                maxLength={10}
                                className="form-control"
                              />
                              {errMsg.phone_no.length > 0 && (
                                <div className="text-danger text-center">
                                  {errMsg.phone_no}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <label className="form-label">Location</label>
                        <div
                          className="location-add-card-main"
                          style={{ padding: '15px 20px', height: '100px' }}
                        >
                          <div className="address">{place.address}</div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="/AddLocations1">
                      <button type="button" className="cx-text-btn">
                        <span id="cancel-location">Cancel</span>
                      </button>
                    </Link>
                    <Link to="#">
                      <button
                        onClick={handleSubmit}
                        type="button"
                        className="cx-text-btn"
                      >
                        <span>Add </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default AddLocations2
