import React from "react";
import "../../assets/css/tutorial.scss";

export default function Tutorial() {
  return (
    <main className="cx-main p-0 load-details-main" id="cxMain">
      <section id="cxp-details">
        <div className="container">
          <div className="row">
            <div className="col video-card">
              <iframe
                width="100%"
                height="569px"
                src="https://www.youtube.com/embed/2Gc1zz5bl8I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
