import React, { useContext, useEffect, useState } from "react";
import ic_star_highlighted from "../../../assets/images/Live/ic_star_highlighted.svg";
import ic_star_grey from "../../../assets/images/Live/ic_star_grey.svg";
import ic_call from "../../../assets/images/Live/ic_call.svg";
import profile_2 from "../../../assets/images/profile_2.svg";
import profile_1 from "../../../assets/images/profile_1.svg";
import vehicle from "../../../assets/images/Live/map_marker.svg";

import ic_whatsapp from "../../../assets/images/Live/ic_whatsapp.svg";
import LiveTrackingPackages from "./LiveTrackingPackages";
import profile_img from "../../../assets/images/Live/profile_img.png";

import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import whatsapp from "../../../assets/images/whatsapp.svg";
import star from "../../../assets/images/star_highlighted.svg";
import call from "../../../assets/images/call.svg";

import g_dot from "../../../assets/images/from_loc_red.svg";
import close_icon from "../../../assets/images/close_icon.svg";
import delivery_box_inactive from "../../../assets/images/delivery_box_inactive.svg";
import driver_list_active from "../../../assets/images/driver_list_active.svg";
import delivery_box_active from "../../../assets/images/delivery_box_active.svg";
import driver_list_inactive from "../../../assets/images/driver_list_inactive.svg";
import Drivers from "./Drivers";
import {
  PostCallWithErrorResponse,
  simplePostCall,
} from "../../../api/ApiServices";
import ApiConfig, { URL } from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import DriverMap from "./DriverMap";
import SpinnerCmp from "../../../SpinnerCmp";
import Geocode from "react-geocode";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import direction from "../../../assets/images/Loads/direction.png";

Geocode.setApiKey("AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw");
// set response language. Defaults to english.
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");
const LiveTrackingDrivers = () => {
  const [placeNew, setPlaceNew] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });
  const [addressmap, setAddressmap] = useState("");
  const [placeMap, setPlace] = useState({
    lat: "",
    lng: "",
  });
  console.log(placeMap);
  const [loadDetails, setLoadDetails] = useState([]);
  const [loadDriverDetails, setLoadDriverDetails] = useState([]);
  console.log(loadDriverDetails);

  const [loadDetailsPackage, setLoadDetailsPackage] = useState([]);
  const [loadDetailsMap, setLoadDetailsMap] = useState([]);
  const { customerData, sidebar } = useContext(AppContext);
  const [active, setActive] = useState("driver");
  const [show, setShow] = useState(true);

  const [loadLocation, setloadLocation] = useState(null);
  const [load, setload] = useState([]);
  console.log(load);
  const [packagestatus, setPackageStatus] = useState(false);
  const [data, setData] = useState([]);
  const [loadmap, setloadmap] = useState(false);
  console.log(loadmap);
  const [loading, setLoading] = useState(true);
  const getRequestedDataDetails = () => {
    PostCallWithErrorResponse(
      localStorage.getItem("role") == "company"
        ? ApiConfig.DRIVER_LIST_API_COMPANY
        : "",
      customerData
    )
      .then((data) => {
        console.log(data.json);
        setData(data.json.driver_list);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData([]);
      });
  };
  const getLoadDataDetails = (id) => {
    PostCallWithErrorResponse(
      localStorage.getItem("role") == "company"
        ? ApiConfig.DRIVER_MAP_API_COMPANY
        : "",
      { ...customerData, driver_id: id }
    )
      .then((data) => {
        console.log(data.json);
        console.log(data.json.current_location.latitude);
        setPlace({
          lat: data.json.current_location.latitude,
          lng: data.json.current_location.longitude,
        });
        setloadLocation(data.json);
        setPackageStatus(false);
        setloadmap(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData([]);
      });
  };

  const LoadStatus = (id, e) => {
    // setLoading(true);

    simplePostCall(
      ApiConfig.DETAILS_LOADDATA_DRIVER,
      JSON.stringify({ ...customerData, load_id: id })
    )
      .then((res) => {
        console.log(res);
        setLoadDriverDetails(res.driver_details);
        setLoadDetails(res.load_details);
        setLoadDetailsPackage(res.package_list);
        setLoadDetailsMap(res.locations);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPackageDataDetails = () => {
    PostCallWithErrorResponse(
      localStorage.getItem("role") == "company"
        ? ApiConfig.ALL_LOAD_STATUS_API_COMPANY
        : "",
      { ...customerData }
    )
      .then((data) => {
        console.log(data.json);
        setPackageStatus(true);
        setloadLocation(data.json);
        setShow(false);
        setloadmap(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setPackageStatus(false);
        /*    setData([]); */
      });
  };

  const getPackageDataDetailsNEw = () => {
    PostCallWithErrorResponse(
      ApiConfig.ALL_LOAD_STATUS_API_COMPANY_NEW,

      { ...customerData }
    )
      .then((data) => {
        console.log(data.json);
        // setPackageStatus(true)
        setload(data.json.load_list);
        // setShow(false)
        setloadmap(true);
        // setLoading(false)
      })
      .catch((error) => {
        console.log("api response", error);
        // setPackageStatus(false)
        /*    setData([]); */
      });
  };

  const handlePackage = () => {
    getPackageDataDetailsNEw();
  };
  useEffect(() => {
    getPackageDataDetails();
    getRequestedDataDetails();
  }, []);

  const handleClick = (id) => {
    setloadmap(true);
    getLoadDataDetails(id);
    setShow(!show);
  };

  Geocode.fromLatLng(placeMap.lat, placeMap.lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      setAddressmap(address);
      console.log(address);
    },
    (error) => {
      console.error(error);
    }
  );
  return (
    <React.Fragment>
      <main
        className={sidebar ? "cx-main cx-active p-0" : "cx-main p-0"}
        id="cxMain"
      >
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxs-live">
            <div id="cxl-map">
              {loadLocation && loadmap == false ? (
                // <DriverMap
                //   start={start}
                //   end={end}
                //   loadLocation={loadLocation.load_list}
                //   currentlocation={currentlocation}
                //   page={packagestatus ? 'package' : ''}
                // />
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
                  }}
                  center={placeNew.center}
                  defaultZoom={7}
                >
                  {loadLocation.load_list &&
                    loadLocation.load_list.length &&
                    loadLocation.load_list.map((mapDetalisAll, index) => {
                      console.log(mapDetalisAll);
                      return (
                        mapDetalisAll.locations &&
                        mapDetalisAll.locations.map((location, index) => {
                          console.log(location);
                          return (
                            <div
                              key={("location", index)}
                              lat={Number(location.latitude)}
                              lng={Number(location.longitude)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={(e) => {
                                  LoadStatus(mapDetalisAll.load_id, e);
                                }}
                                src={vehicle}
                                alt=""
                              />
                            </div>
                          );
                        })
                      );
                    })}
                </GoogleMapReact>
              ) : (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
                  }}
                  center={placeNew.center}
                  defaultZoom={7}
                >
                  {load &&
                    load.length &&
                    load.map((mapDetalisAll, index) => {
                      console.log(mapDetalisAll);
                      return (
                        mapDetalisAll.locations &&
                        mapDetalisAll.locations.map((location, index) => {
                          console.log(location);
                          return (
                            <div
                              lat={location.address_latitude}
                              lng={location.address_longitude}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={(e) => {
                                  LoadStatus(location.address_trip_id, e);
                                }}
                                src={vehicle}
                                alt=""
                              />
                            </div>
                          );
                        })
                      );
                    })}
                </GoogleMapReact>
                // <div className="sweet-loading">
                //   <SpinnerCmp loading={loadmap} size={40} />
                // </div>
              )}
            </div>
            <div
              class={
                !show
                  ? "offcanvas  offcanvas-end ViewDrivers show"
                  : "offcanvas  offcanvas-end ViewDrivers"
              }
              style={
                !show ? { visibility: "visible " } : { visibility: "hidden" }
              }
              tabindex="-1"
              id="ViewDrivers"
              data-bs-backdrop="false"
              aria-labelledby="ViewDriversLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="ViewDriversLabel">
                  Drivers
                </h5>
                <button
                  type="button"
                  // onClick={() => setShow(!show)}
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                {data
                  ? data.map((single) => {
                      return (
                        <div class="cxl-lpdetails" id={single.user_id}>
                          <img
                            src={
                              single.user_profile_pic
                                ? URL + single.user_profile_pic
                                : profile_img
                            }
                          />
                          <ul>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => handleClick(single.user_id)}
                              >
                                {" "}
                                <h5>{single.user_name}</h5>
                              </a>
                            </li>
                            <li class="mb-3">
                              <div className="cxl-rating">
                                {single.rating > 0 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 1 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 2 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 3 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 4 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}
                                <span>({single.reviewed_count})</span>
                              </div>
                              {/* <a href="#" onClick={(e) => handleClick(single.user_id)} class="blue_link">
                                View Details
                              </a> */}
                            </li>
                            <li>
                              <button type="button" class="cx-text-btn">
                                <img src={ic_call} />
                              </button>
                              <button type="button" class="cx-text-btn">
                                <img src={ic_whatsapp} />
                              </button>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            {loadLocation && packagestatus == false && loadmap == false ? (
              <div className="cx-live-track-driver">
                <div className="card card-style">
                  <div className="card-body">
                    <div className="drivers_list d-flex justify-content-between border-0 p-0">
                      <div className="itemside">
                        <div className="aside">
                          <img
                            className="header-profile-pic"
                            src={
                              loadLocation.driver_details.user_profile_pic
                                ? URL +
                                  loadLocation.driver_details.user_profile_pic
                                : profile_1
                            }
                          />
                        </div>
                        <div className="info align-self-center">
                          <p className="title">
                            {loadLocation.driver_details.user_name}
                          </p>
                          <p className="smttitle">
                            Current Location{" "}
                            <span className="catvehcile">{addressmap}</span>
                          </p>
                          <p className="smttitle mb-3">
                            Delivering{" "}
                            <span className="catvehcile">
                              {loadLocation.load_count} loads
                            </span>
                          </p>
                          <Link
                            to={
                              "/DriverDetails/" +
                              loadLocation.driver_details.user_id
                            }
                            className="blue_link"
                          >
                            View Driver Details
                          </Link>
                        </div>
                      </div>
                      {/*   <div className="btn-group drop_dot dropstart">
                      <a href="#">
                        <img src={close_icon} alt="" />
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                show ? "drivers_toggle active" : "drivers_toggle active "
              }
            >
              <div style={{ marginBottom: "51px" }}>
                <Link
                  to="#"
                  onClick={() => {
                    handlePackage();
                    setShow(!show);
                    setActive("loads");
                  }}
                  className={
                    active == "loads" ? "driver_panel active" : "driver_panel"
                  }
                >
                  {active == "loads" ? (
                    <img src={delivery_box_active} alt="" />
                  ) : (
                    <img src={delivery_box_inactive} alt="" />
                  )}
                </Link>
              </div>
              <div>
                <a
                  onClick={() => {
                    setShow(!show);
                    setActive("driver");
                  }}
                  className={
                    active == "driver" ? "driver_panel active" : "driver_panel"
                  }
                  role="button"
                  aria-controls="ViewDriversLabel"
                >
                  {active == "driver" ? (
                    <img src={driver_list_active} alt="" />
                  ) : (
                    <img src={driver_list_inactive} alt="" />
                  )}
                </a>
              </div>
              <div
                class="offcanvas offcanvas-end"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-body">
                  <button
                    class="canvas-btn"
                    type="button"
                    tabIndex="-1"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <img src={next_arrow_white} alt="" />
                  </button>

                  <div className="location-card position-relative">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="m-0">
                          Delivery No.
                          <span>
                            {loadDetails && loadDetails.trip_reference_no}
                          </span>
                        </p>
                        <p className="m-0">
                          Current Location
                          <span></span>
                        </p>
                      </div>
                      <img src={next_arrow_white} alt="" />
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        Status
                        <span style={{ color: "#FF8900" }}>
                          {loadDetails && loadDetails.trip_status}
                        </span>
                      </p>
                      <Link
                        // to="/LiveTrackingLoadDetails"
                        state={loadDetails}
                        to={"/OngoingDriver/" + loadDetails.trip_id}
                      >
                        <img src={direction} alt="" />
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <h5>{loadDetails.delivery_cost}</h5>
                      <p className="location-status">
                        <Link
                          // to="/LiveTrackingLoadDetails"
                          state={loadDetails}
                          to={"/LoadDetailsDriver/" + loadDetails.trip_id}
                        >
                          View Status
                        </Link>
                      </p>
                    </div>
                  </div>

                  {loadDetailsMap &&
                    loadDetailsMap.map((loadDetailsMapItem, index) => {
                      return (
                        <div className="col-lg-12 col-10 mt-2">
                          {loading ? (
                            <div
                              class="spinner-border"
                              style={{
                                color: "#EE651E",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          ) : (
                            <div className="package-details">
                              <span className="package-summary">
                                {loadDetailsMapItem.package_list[0].type} /
                                DELIVERING TO
                              </span>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <img className="me-1" src={g_dot} alt="" />
                                  <h6 className="m-0 mt-2">
                                    {loadDetailsMapItem.address_name}
                                  </h6>
                                </div>
                                <img src={call} alt="" />
                              </div>

                              <p className="m-0">
                                {loadDetailsMapItem.address_details}
                              </p>
                              <p className="m-0" style={{ fontWeight: "bold" }}>
                                {loadDetailsMapItem.address_phone_no}
                              </p>

                              {loadDetailsMapItem.status === "Delivered" ? (
                                // <p
                                //   className="ongoing-delivered"
                                //   style={{ color: "#5F5F5F" }}
                                // >
                                //   {loadDetailsMapItem.distance}
                                //   [Arriving in {loadDetailsMapItem.duration}]
                                //   <span
                                //     className="ms-2"
                                //     style={{ color: "#FF8900" }}
                                //   >
                                //     In Transit
                                //   </span>
                                // </p>
                                <p className="ongoing-delivered">Delivered</p>
                              ) : (
                                <p
                                  className="ongoing-delivered"
                                  style={{ color: "#5F5F5F" }}
                                >
                                  {loadDetailsMapItem.distance}
                                  [Arriving in {loadDetailsMapItem.duration}]
                                  <span
                                    className="ms-2"
                                    style={{ color: "#FF8900" }}
                                  >
                                    In Transit
                                  </span>
                                </p>
                              )}

                              <hr
                                className="mt-0"
                                style={{
                                  color: "#D0D0D0",
                                  height: "2px",
                                  width: "100%",
                                }}
                              />
                              <div className="ongoing-packages">
                                <p>PACKAGES</p>
                                <div className="d-flex justify-content-between package-item">
                                  <div>
                                    <p className="m-0">
                                      {
                                        loadDetailsMapItem.package_list[0]
                                          .package_name
                                      }
                                      <span
                                        style={{
                                          color: "#ee651e",
                                        }}
                                      >
                                        [
                                        {
                                          loadDetailsMapItem.package_list[0]
                                            .type
                                        }
                                        ]
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="m-0">
                                      Qty{" "}
                                      <span>
                                        {
                                          loadDetailsMapItem.package_list[0]
                                            .trip_quantity
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  <div className="driver-details">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img
                          className="img-fluid driver-img me-2"
                          src={URL + loadDriverDetails.user_profile_pic}
                          alt=""
                        />

                        {/* {URL + loadDriverDetails &&
                    loadDriverDetails.user_profile_pic ? (
                      <img
                        src={
                          URL + loadDriverDetails &&
                          loadDriverDetails.user_profile_pic
                        }
                      />
                    ) : (
                      <img src={whatsapp} />
                    )} */}
                        <div>
                          <p className="driver-name m-0 mt-2">
                            {loadDriverDetails.user_name}
                          </p>
                          <div>
                            {loadDriverDetails.rating > 0 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 1 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 2 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 3 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 4 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            <span className="rating">
                              ({loadDriverDetails.reviewed_count})
                            </span>
                          </div>
                        </div>
                      </div>

                      <img className="call-img mt-2" src={call} alt="" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Link
                        state={loadDriverDetails}
                        to={
                          "/CustomerDriverDetail/" + loadDriverDetails.user_id
                        }
                        // to="/CustomerDriverDetail"
                      >
                        <p className="m-0 mt-1 driver-view-details">
                          View Details
                        </p>
                      </Link>
                      <img src={whatsapp} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
};

export default LiveTrackingDrivers;
