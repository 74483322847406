import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import driver_profile_default from "../../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import LoadingBar from "react-top-loading-bar";
import { BASE_URL } from "../../../urls";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import swal from "sweetalert";

const Register = (props) => {
  const navigate = useNavigate();
  const { CompanyUserData, setCompanyUserData } = useContext(AppContext);
  const [state, setState] = useState({
    profile_picture: CompanyUserData.profile_picture
      ? CompanyUserData.profile_picture
      : "",
    name: CompanyUserData.name ? CompanyUserData.name : "",
    email: CompanyUserData.email ? CompanyUserData.email : "",
    password: CompanyUserData.password ? CompanyUserData.password : "",
    confirm_password: CompanyUserData.confirm_password
      ? CompanyUserData.confirm_password
      : "",
    phone_number: CompanyUserData.phone_number
      ? CompanyUserData.phone_number
      : "",
    whatsapp_number: CompanyUserData.whatsapp_number
      ? CompanyUserData.whatsapp_number
      : "",
    address1: CompanyUserData.address1 ? CompanyUserData.address1 : "",
    address2: CompanyUserData.address2 ? CompanyUserData.address2 : "",
    city: CompanyUserData.city ? CompanyUserData.city : "",
    po_box: CompanyUserData.po_box ? CompanyUserData.po_box : "",
    trn_number: CompanyUserData.trn_number ? CompanyUserData.trn_number : "",
    user_role: "company",
  });
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(100);
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "profile_picture") {
      setState({ ...state, [e.target.name]: e.target.files[0] });
    } else setState({ ...state, [e.target.name]: e.target.value });
  };
  const onsubmit = () => {
    if (validateFields()) {
      if (props.isCallFrom === "registration") props.nextCallback(true, state);
    }
  };
  const validateFields = () => {
    let errorMessage = "Invalid input for ";
    let isValid = true;

    if (!state.profile_picture) {
      errorMessage = "Select profile picture";
      isValid = false;
    } else if (!state.name) {
      errorMessage += "Full Name";
      isValid = false;
    } else if (!state.email) {
      errorMessage += "Email";
      isValid = false;
    } else if (!state.phone_number) {
      errorMessage += "Telephone Number";
      isValid = false;
    } else if (!state.whatsapp_number) {
      errorMessage += "Whatsapp Number";
      isValid = false;
    } else if (!state.address1) {
      errorMessage += "Address Line 1";
      isValid = false;
    } else if (!state.city) {
      errorMessage += "City";
      isValid = false;
    } else if (!state.po_box) {
      errorMessage += "P.O Box";
      isValid = false;
    } else if (!state.trn_number) {
      errorMessage += "TRN Number";
      isValid = false;
    } else if (!state.password) {
      errorMessage += "password";
      isValid = false;
    } else if (!state.confirm_password) {
      errorMessage += "Confirm Password";
      isValid = false;
    } else if (state.password !== state.confirm_password) {
      errorMessage = "Password doesn't match";
      isValid = false;
    }
    if (!isValid) {
      swal({
        // title: "Warning",
        text: errorMessage,
        icon: "warning",
        button: true,
      });
    }
    return isValid;
  };

  return (
    <>
      <div className="right_block cx-reg-content">
        <div className="d-flex justify-content-between">
          <h2>Profile Info</h2>
          <p className="step_num">1/2</p>
        </div>

        <form>
          <div className="cx-profile-pic">
            <div className="cx-form-group">
              <label htmlFor="cx-pro-cam">
                <img
                  className="cx-pdefault"
                  src={
                    state.profile_picture
                      ? URL.createObjectURL(state.profile_picture)
                      : driver_profile_default
                  }
                />

                <img className="cx-camera" src={ic_camera} />
              </label>
              <input
                type="file"
                name="profile_picture"
                className="form-control"
                id="cx-pro-cam"
                onChange={handleChange}
              />
            </div>
          </div>

          {/* <div className="cx-profile-pic">
            <img className="cx-pdefault" src={driver_profile_default} />
            <img className="cx-camera" src={ic_camera} />
          </div> */}
          <div className="row">
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-rfname">
                  Full Name<sup>*</sup>
                </label>
                <input
                  name="name"
                  value={state.name}
                  onChange={(e) => handleChange(e)}
                  type="text"
                  className="form-control"
                  id="cx-rfname"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-remail">
                  Email<sup>*</sup>
                </label>
                <input
                  value={state.email}
                  name="email"
                  onChange={(e) => handleChange(e)}
                  type="email"
                  className="form-control"
                  id="cx-remail"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-rmno">
                  Telephone Number<sup>*</sup>
                </label>
                <input
                  name="phone_number"
                  value={state.phone_number}
                  onChange={(e) => handleChange(e)}
                  type="number"
                  className="form-control"
                  id="cx-rmno"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-rcpass">
                  Whatsapp Number<sup>*</sup>
                </label>
                <input
                  value={state.whatsapp_number}
                  name="whatsapp_number"
                  type="number"
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  id="cx-rcpass"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-rcpass">
                  Address Line 1<sup>*</sup>
                </label>
                <textarea
                  value={state.address1}
                  name="address1"
                  onChange={(e) => handleChange(e)}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group">
                <label id="cx-rcpass">Address Line 2 (Optional)</label>
                <textarea
                  onChange={(e) => handleChange(e)}
                  name="address2"
                  value={state.address2}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group mb-lg-0 mb-md-0">
                <label id="cx-rpass">
                  City<sup>*</sup>
                </label>
                <input
                  onChange={(e) => handleChange(e)}
                  name="city"
                  value={state.city}
                  type="text"
                  className="form-control"
                  id="cx-rpass"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group mb-lg-0 mb-md-0">
                <label id="cx-rpass">
                  P.O Box<sup>*</sup>
                </label>
                <input
                  name="po_box"
                  value={state.po_box}
                  onChange={(e) => handleChange(e)}
                  state
                  type="text"
                  className="form-control"
                  id="cx-rpass"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group mb-lg-0 mb-md-0">
                <label id="cx-rpass">
                  TRN Number<sup>*</sup>
                </label>
                <input
                  name="trn_number"
                  value={state.trn_number}
                  onChange={(e) => handleChange(e)}
                  state
                  type="text"
                  className="form-control"
                  id="cx-rpass"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group mb-lg-0 mb-md-0">
                <label id="cx-rpass">
                  Password<sup>*</sup>
                </label>
                <input
                  value={state.password}
                  onChange={(e) => handleChange(e)}
                  name="password"
                  type="password"
                  className="form-control"
                  id="cx-rpass"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="cx-form-group mb-lg-0 mb-md-0">
                <label id="cx-rpass">
                  Confirm Password<sup>*</sup>
                </label>
                <input
                  value={state.confirm_password}
                  onChange={(e) => handleChange(e)}
                  name="confirm_password"
                  type="password"
                  className="form-control"
                  id="cx-rpass"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="rfbtn_block d-flex justify-content-between">
        <Link to="/" className="orange_btn">
          Prev
        </Link>
        <button onClick={onsubmit} className="orange_btn">
          Next
        </button>
      </div>
    </>
  );
};

export default Register;
