import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import driver_profile_default from "../../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import upload from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/cross.svg";
import { AppContext } from "../../../context/user/AppContext";
import { FileUploader } from "react-drag-drop-files";
import swal from "sweetalert";
import SpinnerCmp from "../../../SpinnerCmp";
import { multipartPostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
const RegisterAddDrivers = (props) => {
  const { customerData } = useContext(AppContext);
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  // let drivers = CompanyUserData.drivers ? CompanyUserData.drivers : [];
  const [state, setState] = useState(
    props.isEdit
      ? props.data
      : {
          driver_name: "",
          driver_email: "",
          driver_whatsapp_number: "",
          driver_phone_number: "",
          driver_emirated_id: "",
          driver_password: "",
          driver_confirm_password: "",
          driver_address: "",
          driver_license_number: "",
          driver_profile_picture: "",
          driver_license_file: "",
          driver_emirates_id_file: "",
          driver_vaccination_certificate: "",
          first_dose_date: "",
          second_dose_date: "",
          third_dose_date: "",
          emirates_id_expiry_date: "",
          license_expiry_date: "",
        }
  );
  const [files, setfiles] = useState({
    driver_profile_picture: "",
    driver_profile_picture_Base64: "",
    driver_license_file: "",
    driver_license_fileBase64: "",
    driver_emirates_id_file: "",
    driver_emirates_id_fileBase64: "",
    driver_vaccination_certificate: "",
    driver_vaccination_certificateBase64: "",
  });

  const onAddDriver = () => {
    if (validateFields()) {
      if (props.isCallFrom === "registration") {
        state.driver_vaccination_certificate =
          files.driver_vaccination_certificateBase64;
        state.driver_license_file = files.driver_license_fileBase64;
        state.driver_emirates_id_file = files.driver_emirates_id_fileBase64;
        state.driver_profile_picture = files.driver_profile_picture_Base64;

        props.onAdd(state);
      } else onSubmit();
    }
  };
  const removefile = (e) => {
    const { name } = e.target;
    const base64file = name + "Base64";
    setfiles({ ...files, [name]: "", [base64file]: "" });
  };
  const emiratesIdFileChange = (file) => {
    getBase64(file, (result) => {
      setfiles({
        ...files,
        driver_emirates_id_file: file,
        driver_emirates_id_fileBase64: result,
      });
    });
  };
  const dlFileChange = (file) => {
    getBase64(file, (result) => {
      setfiles({
        ...files,
        driver_license_file: file,
        driver_license_fileBase64: result,
      });
    });
  };
  const vaccinFileChange = (file) => {
    getBase64(file, (result) => {
      setfiles({
        ...files,
        driver_vaccination_certificate: file,
        driver_vaccination_certificateBase64: result,
      });
    });
  };
  const onInputChangeHandle = (e) => {
    const { name, value } = e.target;
    if (name === "driver_profile_picture") {
      getBase64(e.target.files[0], (result) => {
        setfiles({
          ...files,
          [name]: e.target.files[0],
          [name + "_Base64"]: result,
        });
      });
    } else if (name == "driver_phone_number") {
      setState({
        ...state,
        driver_whatsapp_number: value,
        driver_phone_number: value,
      });
    } else setState({ ...state, [name]: value });
  };
  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // cb(reader.result.replace(/^data:image.+;base64,/, ""));
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const validateFields = () => {
    let errorMessage = "Invalid input for ";
    let isValid = true;
    if (!files.driver_profile_picture_Base64) {
      errorMessage = "Upload profile picture";
      isValid = false;
    } else if (!state.driver_name) {
      errorMessage += "Full Name";
      isValid = false;
    } else if (!state.driver_email) {
      errorMessage += "Email";
      isValid = false;
    } else if (!state.driver_phone_number) {
      errorMessage += "Mobile Number";
      isValid = false;
    } else if (!state.driver_whatsapp_number) {
      errorMessage += "Whatsapp Number";
      isValid = false;
    } else if (!state.driver_password) {
      errorMessage += "Password.";
      isValid = false;
    } else if (!state.driver_confirm_password) {
      errorMessage += "Confirm password.";
      isValid = false;
    } else if (state.driver_password !== state.driver_confirm_password) {
      errorMessage = "Password does not match...!";
      isValid = false;
    } else if (!files.driver_license_fileBase64) {
      errorMessage = "Upload driving license file";
      isValid = false;
    } else if (!state.driver_license_number) {
      errorMessage += "Driving license no.";
      isValid = false;
    } else if (!files.driver_emirates_id_fileBase64) {
      errorMessage = "Upload Emirates Id file";
      isValid = false;
    } else if (!state.driver_emirated_id) {
      errorMessage += "Emirated_id.";
      isValid = false;
    } else if (!state.license_expiry_date) {
      errorMessage += "Licence expiry date.";
      isValid = false;
    } else if (!state.emirates_id_expiry_date) {
      errorMessage += "Emirated_id expiry date.";
      isValid = false;
    }
    if (!isValid) {
      swal({
        // title: "Warning",
        text: errorMessage,
        icon: "warning",
        button: true,
      });
    }
    return isValid;
  };
  const onSubmit = () => {
    setLoading(true);
    let driver = {
      ...{
        name: state.driver_name,
        email: state.driver_email,
        phone_number: state.driver_phone_number,
        whatsapp_number: state.driver_whatsapp_number,
        license_number: state.driver_license_number,
        first_dose_date: state.first_dose_date,
        second_dose_date: state.second_dose_date,
        third_dose_date: state.third_dose_date,
        license_file: files.driver_license_file,
        emirates_id_file: files.driver_emirates_id_file,
        vaccination_certificate: files.driver_vaccination_certificate,
        address: state.driver_address,
        profile_picture: files.driver_profile_picture,
        emirated_id: state.driver_emirated_id,
        password: state.driver_confirm_password,
        confirm_password: state.driver_password,
        emirates_id_expiry_date: state.emirates_id_expiry_date,
        license_expiry_date: state.license_expiry_date,
      },
      ...customerData,
    };

    // const finalObject = { ...customerData, ...state };
    console.log(driver);
    let formData;
    try {
      formData = getFormData(driver);
    } catch (error) {
      setLoading(false);
    }
    console.log("form data :: ", formData);
    multipartPostCallWithErrorResponse(
      ApiConfig.COMPANY_ADD_DRIVER_API,
      formData
    )
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data) {
          if (data.error == false && data.json.result) {
            swal({
              title: "Success",
              text: "Driver Added Successfuly",
              icon: "success",
              button: true,
            }).then(() => {
              navigate("/DriverListning");
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => {
      formData.append(key, object[key]);
    });
    return formData;
  };
  const AddDriverFormRow = () => {
    return (
      <div className="row">
        <div className="col-lg-12">
          <form>
            <div className="cx-profile-pic">
              <div className="cx-form-group">
                <label htmlFor="cx-pro-cam">
                  <img
                    className="cx-pdefault"
                    src={
                      files.driver_profile_picture_Base64
                        ? files.driver_profile_picture_Base64
                        : driver_profile_default
                    }
                  />

                  <img className="cx-camera" src={ic_camera} />
                </label>
                <input
                  type="file"
                  name="driver_profile_picture"
                  className="form-control"
                  id="cx-pro-cam"
                  onChange={onInputChangeHandle}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-rfname">
                    Full Name<sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cx-rfname"
                    placeholder=""
                    value={state.driver_name}
                    name="driver_name"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-remail">
                    Email<sup>*</sup>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="cx-remail"
                    placeholder=""
                    value={state.driver_email}
                    name="driver_email"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-rmno">
                    Mobile Number<sup>*</sup>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="cx-rmno"
                    placeholder=""
                    value={state.driver_phone_number}
                    name="driver_phone_number"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-rcpass">
                    Whatsapp Number<sup>*</sup>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="cx-rcpass"
                    placeholder=""
                    value={state.driver_whatsapp_number}
                    name="driver_whatsapp_number"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-rmno">
                    Password<sup>*</sup>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="cx-rmno"
                    placeholder=""
                    value={state.driver_password}
                    name="driver_password"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cx-form-group">
                  <label id="cx-rmno">
                    Confirm Password<sup>*</sup>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="cx-rmno"
                    placeholder=""
                    value={state.driver_confirm_password}
                    name="driver_confirm_password"
                    onChange={onInputChangeHandle}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-lg-6">
          <label id="cx-rfname" className="required">
            Driving License
          </label>
          <div className="drop_block mb-3">
            <div className="vertical_m text-center">
              <FileUploader
                handleChange={dlFileChange}
                name="file"
                types={fileTypes}
                fileOrFiles={files.driver_license_file}
              />
              {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
            </div>
          </div>
          {files.driver_license_file ? (
            <div className="uploaded_file d-flex justify-content-between">
              {files.driver_license_file.name.includes("pdf") ? (
                <label>{files.driver_license_file.name}</label>
              ) : (
                <img
                  src={files.driver_license_fileBase64}
                  height="130px"
                  width={"170px"}
                ></img>
              )}

              {/*  <label>{files.driver_license_file.name}</label>
              <a name="driver_license_file" onClick={removefile}>
                <img src={cross} alt="" />
              </a> */}
            </div>
          ) : (
            ""
          )}
          <div className="drop_input">
            <label id="cx-rfname">
              License Number<sup>*</sup>
            </label>
            <input
              type="number"
              className="form-control"
              id="cx-rfname"
              placeholder="Enter Driving License Number"
              style={{ border: "1px solid #ccc" }}
              value={state.driver_license_number}
              name="driver_license_number"
              onChange={onInputChangeHandle}
            />
          </div>
          <label style={{ marginLeft: "8px", marginTop: "25px" }}>
            Licence expiry date:
          </label>
          <div className="drop_input">
            <input
              style={{ marginTop: "0px" }}
              type="date"
              className="form-control"
              id="cx-rfname"
              placeholder="Enter Driving License Number"
              value={state.license_expiry_date}
              name="license_expiry_date"
              onChange={onInputChangeHandle}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <label id="cx-rfname" className="required">
            Emirates Id
          </label>
          <div className="drop_block mb-3">
            <div className="vertical_m text-center">
              <FileUploader
                handleChange={emiratesIdFileChange}
                name="file"
                types={fileTypes}
                fileOrFiles={files.driver_emirates_id_file}
              />
              {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
            </div>
          </div>
          {files.driver_emirates_id_file ? (
            <div className="uploaded_file d-flex justify-content-between">
              {files.driver_emirates_id_file.name.includes("pdf") ? (
                <label>{files.driver_emirates_id_file.name}</label>
              ) : (
                <img
                  src={files.driver_emirates_id_fileBase64}
                  height="130px"
                  width={"170px"}
                ></img>
              )}

              {/*  <label>{files.driver_emirates_id_file.name}</label>
              <a name="driver_emirates_id_file" onClick={removefile}>
                <img src={cross} alt="" />
              </a> */}
            </div>
          ) : (
            ""
          )}
          <div className="drop_input">
            <label id="cx-rfname">
              Emirates ID Number<sup>*</sup>
            </label>
            <input
              type="number"
              className="form-control"
              id="cx-rfname"
              placeholder="Enter Emirates Id"
              style={{ border: "1px solid #ccc" }}
              value={state.driver_emirated_id}
              name="driver_emirated_id"
              onChange={onInputChangeHandle}
            />
          </div>
          <label style={{ marginLeft: "8px", marginTop: "25px" }}>
            EID expiry date:
          </label>
          <div className="drop_input">
            <input
              style={{ marginTop: "0px" }}
              type="date"
              className="form-control"
              id="cx-rfname"
              placeholder="Enter Emirates Id"
              value={state.emirates_id_expiry_date}
              name="emirates_id_expiry_date"
              onChange={onInputChangeHandle}
            />
          </div>
        </div>

        <div className="col-lg-6 mt-4">
          <label id="cx-rfname">Covid Vaccination Certificate [Optional]</label>
          <div className="drop_block mb-3">
            <div className="vertical_m text-center">
              <FileUploader
                handleChange={vaccinFileChange}
                name="file"
                types={fileTypes}
                fileOrFiles={files.driver_vaccination_certificate}
              />
              {/* <p className="m-0">Drag & Drop or Browse Files</p> */}
            </div>
          </div>
          {files.driver_vaccination_certificate ? (
            <div className="uploaded_file d-flex justify-content-between">
              {files.driver_vaccination_certificate.name.includes("pdf") ? (
                <label>{files.driver_vaccination_certificate.name}</label>
              ) : (
                <img
                  src={files.driver_vaccination_certificateBase64}
                  height="130px"
                  width={"170px"}
                ></img>
              )}
              {/*   <label>{files.driver_vaccination_certificate.name}</label>
              <a name="driver_vaccination_certificate" onClick={removefile}>
                <img src={cross} alt="" />
              </a> */}
            </div>
          ) : (
            ""
          )}
          <div className="drop_input">
            <label id="cx-rfname">Dose1 :</label>
            <input
              type="date"
              className="form-control"
              id="cx-rfname"
              value={state.first_dose_date}
              name="first_dose_date"
              onChange={onInputChangeHandle}
            />
          </div>
          <div className="drop_input mt-4">
            <label id="cx-rfname">Dose2 :</label>
            <input
              type="date"
              className="form-control"
              id="cx-rfname"
              value={state.second_dose_date}
              name="second_dose_date"
              onChange={onInputChangeHandle}
            />
          </div>
          <div className="drop_input mt-4">
            <label id="cx-rfname">Dose3 :</label>
            <input
              type="date"
              className="form-control"
              id="cx-rfname"
              value={state.third_dose_date}
              name="third_dose_date"
              onChange={onInputChangeHandle}
            />
          </div>
        </div>
        {props.isCallFrom !== "registration" ? (
          <div className="col-lg-12">
            <div className="d-flex justify-content-end mt-4">
              <button onClick={onAddDriver} className="blue_btn">
                ADD DRIVER
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      {props.isCallFrom && props.isCallFrom === "registration" ? (
        <>
          <div className="right_block cx-reg-content">
            <div className="d-flex justify-content-between">
              <h2>Add Drivers</h2>
              <p className="step_num">3/5</p>
            </div>

            <div className="register_right mt-4 ">{AddDriverFormRow()}</div>
          </div>
          <div className="rfbtn_block">
            <div className="d-flex justify-content-between">
              <button onClick={props.onCancel} className="orange_btn">
                Cancel
              </button>
              <button onClick={onAddDriver} className="orange_btn">
                Add
              </button>
            </div>
          </div>
        </>
      ) : (
        <main className="cx-main" id="cxMain">
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                {loading ? (
                  <div className="sweet-loading">
                    <SpinnerCmp loading={loading} size={40} />
                  </div>
                ) : (
                  <div className="register_right register_serivce cx-reg-content service-reg-content bg-white overflow-auto">
                    {AddDriverFormRow()}
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      )}
    </React.Fragment>
  );
};

export default RegisterAddDrivers;
