import React, { useState, useEffect, useContext, useRef } from 'react'
import ic_menu_options from '../../../assets/images/Notifications/ic_menu_options.svg'
import ic_add_blue from '../../../assets/images/Send Steps/ic_add_blue.svg'
import '../../../assets/css/cx-paymethod.css'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'
import SpinnerCmp from '../../../SpinnerCmp'
import ApiConfig from '../../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import { BASE_URL } from '../../../urls'
import { AppContext } from '../../../context/user/AppContext'
import swal from 'sweetalert'

export default function AssignDriver(props) {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [show, setShow] = React.useState({ id: '' })
  const { customerData, sidebar } = useContext(AppContext)
  const [data, setData] = useState([])
  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState('#ffffff')
  let [successdelete, setSuccessDelete] = useState(false)
  let [empty, setEmpty] = useState(false)
  /* spinner  */
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `
  useEffect(() => {
    let compid = localStorage.getItem('role2')
    if (compid == 'individual') {
      AssignDriver(customerData.user_id, state.id)
    }

    if (state) {
      console.log('first')
    } else {
      navigate('/Loads')
    }
  })

  const getDrivers = () => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'company'
        ? ApiConfig.DRIVER_LIST_API_COMPANY
        : navigate('/Login'),
      customerData,
    )
      .then((data) => {
        console.log(data.json)
        setData(data.json.driver_list)
        setLoading(false)
      })
      .catch((error) => {
        console.log('api response', error)
        setData([])
      })
  }
  const AssignDriver = (driver_id, load_id) => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'company'
        ? ApiConfig.ASSIGN_DRIVER_TO_LOAD
        : navigate('/Login'),

      { ...customerData, load_id: load_id, driver_id: driver_id },
    )
      .then((data) => {
        if (data.json.result == false) {
          swal({
            title: 'warning',
            text: data.json.message,
            icon: 'warning',
            button: true,
          })
        } else {
          swal({
            title: 'success',
            text: data.json.message,
            icon: 'success',
            button: true,
          }).then(() => {
            navigate('/CompanyLoadServiceRequest')
          })
        }

        console.log(data.json)
        /*   setData(data.json) */
        setLoading(false)
      })
      .catch((error) => {
        console.log('api response', error)
        setData([])
      })
  }

  /* spinner */
  useEffect(() => {
    getDrivers()
  }, [])

  const setClick = (driver_id) => {
    AssignDriver(driver_id, state.id)
  }

  const handleDelete = (id) => {
    PostCallWithErrorResponse(ApiConfig.DELETE_CREDIT_CARD_CUSTOMER, {
      ...customerData,
      credit_card_id: id,
    })
      .then((data) => {
        console.log(data)
        if (data.error == false) {
          setSuccessDelete(!successdelete)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  return (
    <div>
      <header className={sidebar ? 'cx-header cx-active' : 'cx-header'}>
        <div className="cx-action-bar">
          <div className="cx-no">
            <label>Saved Drivers</label>
            <span>[{data ? data.length : 0}]</span>
          </div>

          <div className="cx-button-grp">
            <Link to="/AddDriver">
              {' '}
              <button type="button" className="cx-text-btn">
                <img src={ic_add_blue} />
                ADD NEW DRIVER
              </button>
            </Link>
          </div>
        </div>
      </header>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        <div className="sweet-loading">
          <SpinnerCmp loading={loading} size={40} />
        </div>
        {empty ? (
          <div id="" className="container col-md-12">
            <div className="cxlo-content">No Driver found</div>
          </div>
        ) : (
          ''
        )}
        <section id="cxpm-notifications">
          <div className="container">
            <div className="cxpm-notification-content">
              <div className="row">
                {data.map((data, index) => (
                  <div className="col-lg-6" key={index}>
                    <div
                      className="cxpm-ncontent"
                      style={{ height: '350px' }}
                      key={index}
                    >
                      <ul>
                        <li>
                          <h4>{data.user_name.replace(/\d(?=\d{4})/g, '*')}</h4>
                        </li>
                        <li>
                          <span>{data.user_email}</span>
                        </li>
                        <li>
                          <span>{data.user_address}</span>
                        </li>
                        <li>
                          {data.assigned_vehicle ? (
                            <ul>
                              <li>
                                <span>
                                  {data.assigned_vehicle.vehicle_name
                                    ? 'assigned_vehicle : ' +
                                      data.assigned_vehicle.vehicle_name
                                    : ''}
                                </span>
                              </li>
                              <li>
                                <span>
                                  {data.assigned_vehicle.capacity
                                    ? 'Vehicle Capacity: ' +
                                      data.assigned_vehicle.capacity
                                    : ''}
                                </span>
                              </li>
                              <li>
                                <span>
                                  {data.assigned_vehicle.vehicle_type
                                    ? 'Vehicle Type: ' +
                                      data.assigned_vehicle.vehicle_type
                                    : ''}
                                </span>
                              </li>
                              <li>
                                <span>
                                  {data.assigned_vehicle.length
                                    ? 'Vehicle Length: ' +
                                      data.assigned_vehicle.length
                                    : ''}
                                </span>
                              </li>
                            </ul>
                          ) : (
                            ''
                          )}
                        </li>
                        <hr />
                        <li>
                          {data.current_load ? (
                            <ul>
                              <li>
                                <span>
                                  {data.current_load.trip_weight
                                    ? 'Trip weight: ' +
                                      data.current_load.trip_weight
                                    : ''}
                                </span>
                              </li>
                              <li>
                                <span>
                                  {data.current_load.delivery_date_time
                                    ? 'Delivery Date/time: ' +
                                      data.current_load.delivery_date_time
                                    : ''}
                                </span>
                              </li>
                            </ul>
                          ) : (
                            ''
                          )}
                        </li>
                      </ul>
                      <div className="cx-btn-grp1" key={index}>
                        <button
                          type="button"
                          onClick={() => setClick(data.user_id)}
                          className="cx-text-btn cxpm-options"
                        >
                          Assign
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
