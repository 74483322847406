import React, { createContext, useContext, useEffect, useState } from 'react'
import arrow_white from '../assets/images/login/arrow_white.svg'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import '../assets/css/cx-fpassword.css'
import { BASE_URL } from '../urls'

import LoadingBar from 'react-top-loading-bar'
import { AppContext } from '../context/user/AppContext'
import ApiConfig from '../api/ApiConfig'
import swal from 'sweetalert'

export default function ForgotPassNew() {
  const { state } = useLocation()
  const { setForgotData, forgotData } = useContext(AppContext)
  const [progress, setProgress] = useState(0)
  const [role, setRole] = useState(state.role)
  const navigate = useNavigate()
  useEffect(() => {
    setProgress(100)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let { username } = e.target

    if (!regEmail.test(username.value)) {
      swal({
        title: 'Warning',
        text: 'Please Enter Valid Email Address',
        icon: 'warning',
        button: true,
      })
    } else {
      setProgress(50)

      setForgotData({
        ...forgotData,
        role: role === 'customer' ? 'customer' : 'company',
      })
      //this if block is for customer login
      const response = await fetch(
        role === 'customer'
          ? BASE_URL + 'customer/forgotpassword'
          : ApiConfig.COMPANY_FORGOT_PASSWORD_API,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: JSON.stringify({
            username: username.value,
          }),
        },
      )
      const response_data = await response.json()
      console.log(response_data)
      if (response_data.result === true) {
        navigate('/Otp')
      } else {
        setProgress(100)
        swal({
          title: 'Warning',
          text: response_data.message,
          icon: 'warning',
          button: true,
        })
      }
    }
  }



  const handleChange = (e) => {
    setForgotData({ ...forgotData, [e.target.name]: e.target.value })
  }

  return (
    <>
      <section id="cx-fpass-one">
        <LoadingBar
          color="navy"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex justify-content-center">
              <div className="cx-fpass-content">
                <h2>Forgot Password</h2>
                <p>
                  Enter the code sent your registered mobile number/email id to
                  continue
                </p>
                Customer
                <input
                  type="radio"
                  className="mx-2 my-3"
                  label="Male"
                  name="same"
                  defaultChecked={role === 'customer'}
                  value="customer"
                  onClick={() => setRole('customer')}
                />
                Company
                <input
                  type="radio"
                  className="mx-2 my-3"
                  label="Female"
                  name="same"
                  defaultChecked={role === 'company'}
                  value="company"
                  onClick={() => setRole('company')}
                />
                <form
                  onSubmit={(e) => {
                    handleSubmit(e)
                  }}
                >
                  <div className="cx-form-group">
                    <input
                      type="email"
                      value={forgotData.username}
                      onChange={(e) => handleChange(e)}
                      required
                      name="username"
                      className="form-control"
                      id="cx-fmobile"
                      placeholder="Email"
                    />
                  </div>
                  <div className="cx-btn-grp1">
                    <button type="submit" className="cx-fpass-btn">
                      <span>Continue</span>
                      <img src={arrow_white} />
                    </button>
                  </div>
                  <div className="cx-log">
                    <Link to="/Login">
                      {' '}
                      <button type="button" className="cx-text-btn">
                        Go to login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
