import React, { useState, useContext } from "react";
import Sidebar1 from "../../sharedComponent/Sidebar1";
import arrow_white from "../../assets/images/login/arrow_white.svg";
import "../../assets/css/cx-addlocations.css";
import Autocomplete from "react-google-autocomplete";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { useAlert, positions } from "react-alert";
import { useNavigate } from "react-router-dom";

const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw";

export default function AddLocation() {
  const { customerData ,sidebar, loadId} = useContext(AppContext);
  const navigate = useNavigate();
  const alert = useAlert();
  const [state, setState] = useState({
    name: "",
    landmark: "",   
    phone_no: "",
  });
  const [address, setAddress] = useState({
    address: "",
    latitude: "",
    longitude: "",
  });

  const postCall = () => {
    if (!validateFields()) return false;
    PostCallWithErrorResponse(ApiConfig.ADD_LOCATION_API_CUSTOMER, {
      ...customerData,
      ...state,
      ...address,
    })
      .then((data) => {
        console.log(data);
        if (data.error == false) {
          setState({
            name: "",

            landmark: "",
            phone_no: "",
          });
          setAddress({
            address: "",
            latitude: "",
            longitude: "",
          });
          alert.show(" Address added successfully...", {
            position: positions.TOP_CENTER,
          });
          navigate("/SaveLocation");
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const handleChange = (e) => {
    if (e.target.name != "address") {
      console.log(e.target.value);
      if (e.target.value.trim()) hideValidationError(e.target.name);
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleSelect = (place) => {
    setAddress({
      ...address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.formatted_address,
    });
  };
  const validateFields = () => {
    let errorfields = [];
    let isValid = true;
    if (!state.name) {
      errorfields.push("name");
      isValid = false;
    }
    if (!state.landmark) {
      errorfields.push("landmark");
      isValid = false;
    }
    if (!state.phone_no) {
      errorfields.push("phone_no");
      isValid = false;
    }
    if (!address.address) {
      errorfields.push("address");
      isValid = false;
    }
    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById("error" + field).style.display = "block";
      });
    }
    return isValid;
  };
  const hideValidationError = (field) => {
    document.getElementById("error" + field).style.display = "none";
  };

  return (
    <div>


      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxal-rating">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="cxal-contact-outer">
                  <form>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="cx-form-group">
                          <label id="cxal-name" className="required">
                            Name
                          </label>
                          <input
                            name="name"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            className="form-control"
                            id="cxal-name"
                            placeholder=""
                            value={state.name}
                          />
                          <label id="errorname" className="for-error">
                            Enter name
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="cx-form-group">
                          <div>
                            <label id="cxal-name" className="required">
                              Address
                            </label>
                            <Autocomplete
                              className="form-control"
                              name="address"
                              
                              value={address.address}
                              onChange={(e) =>
                                setAddress({
                                  ...address,
                                  address: e.target.value,
                                })
                              }
                              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                              onPlaceSelected={(place) => handleSelect(place)}
                              placeholder=""
                              options={{
                                types: ["geocode", "establishment"],
                              }}
                            />
                            <label id="erroraddress" className="for-error">
                              Enter address
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="cx-form-group">
                          <label id="cxal-land" className="required">
                            Landmark
                          </label>
                          <input
                            type="text"
                            name="landmark"
                            value={state.landmark}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            id="cxal-land"
                            placeholder=""
                          />
                          <label id="errorlandmark" className="for-error">
                            Enter landmark
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="cx-form-group">
                          <label id="cxal-pno" className="required">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            id="cxal-pno"
                            value={state.phone_no}
                            name="phone_no"
                            placeholder=""
                          />
                          <label id="errorphone_no" className="for-error">
                            Enter phone number
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="cx-btn-grp1">
                      <button
                        type="button"
                        onClick={postCall}
                        className="cxal-rate-btn"
                      >
                        <span>Add</span>
                        <img src={arrow_white} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
