import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import driver_profile_default from "../../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import LoadingBar from "react-top-loading-bar";
import { BASE_URL } from "../../../urls";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../../api/ApiServices";
import Register from "./Register";
import UploadDocuments from "./UploadDocuments";
import RegisterVehicles from "./RegisterVehicles";
import RegisterDrivers from "./RegisterDrivers";
import AddBankAccount from "./AddBankAccount";
import SpinnerCmp from "../../../SpinnerCmp";
import swal from "sweetalert";

const RegistrationWizard = () => {
  const { state } = useLocation();
  const wizardList = [
    "Profile Info",
    "Upload Documents",
    // "Add Drivers",
    // "Add Vehicles",
    // "Add Bank Account",
  ];
  const [progress, setProgress] = useState(0);
  const [wizardIndex, setWizardIndex] = useState(0);
  const navigate = useNavigate();
  const { CompanyUserData, setCompanyUserData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [stateVal, setstateVal] = useState(state ? state.val : "customer");

  /*  let [loading, setLoading] = useState(false); */
  useEffect(() => {
    setProgress(100);
  }, []);

  const handleChange = (e) => {
    setCompanyUserData({ ...CompanyUserData, [e.target.name]: e.target.value });
  };
  const handleNexCallback = (isValue, data) => {
    if (isValue) setCompanyUserData({ ...CompanyUserData, ...data });
    // debugger
    if (wizardIndex === 1) {
      setLoading(true);
      CompanyUserData.license_file = data.license_file;
      // if (stateVal == "Individual") {
      //   CompanyUserData.user_role = "Individual";
      // }

      let formData;
      try {
        formData = getFormData(CompanyUserData);
      } catch (error) {
        setLoading(false);
      }

      multipartPostCallWithErrorResponse(
        ApiConfig.COMPANY_REGISTER_API,
        formData
      )
        .then((data) => {
          console.log(data);
          console.log(data);
          if (data.error === false) {
            if (data.json.result === false) {
              swal({
                title: "warning",
                text: data.json.message,
                icon: "warning",
                button: true,
              });
              setLoading(false);
            } else {
              setLoading(false);
              swal({
                title: "Success",
                text: data.json.message,
                icon: "success",
                button: true,
              }).then(() => {
                window.location.href = "/login";
              });
            }
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    } else {
      setWizardIndex(wizardIndex + 1);
    }
  };

  /// Not Use ////
  const handlePrevCallback = (isValue, data) => {
    if (isValue) setCompanyUserData({ ...CompanyUserData, ...data });
    setWizardIndex(wizardIndex - 1);
  };
  /// End ///

  const onSubmitData = (data) => {
    setLoading(true);
    const finalObject = { ...CompanyUserData, ...data };
    setCompanyUserData(finalObject);
    console.log(finalObject);
    let formData;
    try {
      formData = getFormData(finalObject);
    } catch (error) {
      setLoading(false);
    }

    console.log("form data :: ", formData);
    multipartPostCallWithErrorResponse(ApiConfig.REGISTER_COMPANY_API, formData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data) {
          if (data.error == false && data.json.result) {
            swal({
              title: "Success",
              text: "User registered successfuly",
              icon: "success",
              button: true,
            }).then(() => {
              //setCompanyUserData(null);
              window.location.href = "/Login";
            });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const getFormData = (object) => {
    const formData = new FormData();

    Object.keys(object).forEach((key) => {
      if (key === "drivers" || key === "vehicles") {
        let jsonstring = JSON.stringify(object[key]);
        formData.append(key, jsonstring);
      } else formData.append(key, object[key]);
    });
    return formData;
  };

  return (
    <section id="cx-register">
      {/* <LoadingBar
        color="navy"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      /> */}
      <div className="cx-reg-out">
        <div className="row">
          <div className="col-lg-4 col-md-12 p-0">
            <div className="left_block">
              <div className="lf_img">
                <img src={logisticsLogo} alt="" />
              </div>
              <h2>Register</h2>

              <div className="trackingstep pt-5">
                <div
                  className={
                    "step " +
                    (wizardIndex > 0
                      ? "step-complete"
                      : wizardIndex === 0
                      ? "step-active"
                      : "")
                  }
                >
                  <div>
                    <div className="circle"></div>
                  </div>
                  <div>
                    <div className="title">Profile Info</div>
                  </div>
                </div>
                <div
                  className={
                    "step " +
                    (wizardIndex > 1
                      ? "step-complete"
                      : wizardIndex === 1
                      ? "step-active"
                      : "")
                  }
                >
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <div>
                    <div className="title">Upload Documents</div>
                  </div>
                </div>
                {/* <>
                  <div
                    className={
                      "step " +
                      (wizardIndex > 2
                        ? "step-complete"
                        : wizardIndex === 2
                        ? "step-active"
                        : "")
                    }
                  >
                    <div>
                      <div className="circle">3</div>
                    </div>
                    <div>
                      <div className="title">Add Drivers</div>
                    </div>
                  </div>
                  <div
                    className={
                      "step " +
                      (wizardIndex > 3
                        ? "step-complete"
                        : wizardIndex === 3
                        ? "step-active"
                        : "")
                    }
                  >
                    <div>
                      <div className="circle">4</div>
                    </div>
                    <div>
                      <div className="title">Add Vehicles</div>
                    </div>
                  </div>
                  <div
                    className={
                      "step " +
                      (wizardIndex > 4
                        ? "step-complete"
                        : wizardIndex === 4
                        ? "step-active"
                        : "")
                    }
                  >
                    <div>
                      <div className="circle">5</div>
                    </div>
                    <div>
                      <div className="title">Add Bank Account</div>
                    </div>
                  </div>{" "}
                </> */}
              </div>
            </div>
          </div>

          <div className="col-lg-8 col-md-12 p-0">
            <div className="wizard_mainWrapper">
              {loading ? (
                <div className="sweet-loading">
                  <SpinnerCmp loading={loading} size={40} />
                </div>
              ) : (
                <>
                  {wizardIndex === 0 ? (
                    <Register
                      isCallFrom="registration"
                      prevCallback={handlePrevCallback}
                      nextCallback={handleNexCallback}
                    />
                  ) : null}
                  {wizardIndex === 1 ? (
                    <UploadDocuments
                      isCallFrom="registration"
                      isstatefrom={state && state.val}
                      prevCallback={handlePrevCallback}
                      nextCallback={handleNexCallback}
                    />
                  ) : null}
                  {wizardIndex === 2 ? (
                    <RegisterDrivers
                      isCallFrom="registration"
                      prevCallback={handlePrevCallback}
                      nextCallback={handleNexCallback}
                    />
                  ) : null}
                  {wizardIndex === 3 ? (
                    <RegisterVehicles
                      isCallFrom="registration"
                      prevCallback={handlePrevCallback}
                      nextCallback={handleNexCallback}
                    />
                  ) : null}
                  {wizardIndex === 4 ? (
                    <AddBankAccount
                      isCallFrom="registration"
                      prevCallback={handlePrevCallback}
                      onSubmit={onSubmitData}
                    />
                  ) : null}

                  {/* <div className="d-flex justify-content-between">
                <h2>{wizardList[wizardIndex]}</h2>
                <p className="step_num">
                  {(wizardIndex+1) + "/" + wizardList.length}
                </p>
              </div>
              <button
                onClick={() => {
                  let temp = (wizardIndex >0?wizardIndex:5);
                  setWizardIndex(temp-1);
                }}
              >
                back
              </button>
              <button
                onClick={() => {
                  let temp = (wizardIndex<4?wizardIndex:-1);
                  setWizardIndex(temp+1);
                }}
              >
                Next
              </button> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistrationWizard;
