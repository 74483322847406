import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../sharedComponent/Sidebar1";
import "font-awesome/css/font-awesome.min.css";
import jofar_logo from "../../assets/images/Live/jofar_logo.svg";
import ic_notification from "../../assets/images/Live/ic_notification.svg";
import profile_img from "../../assets/images/Live/profile_img.png";
import next_arrow_white from "../../assets/images/Live/next_arrow_white.svg";
import ic_close_sload from "../../assets/images/Live/ic_close_sload.svg";
import arrow from "../../assets/images/Group 30880.svg";
import gps from "../../assets/images/Page-1.svg";
import pickup_location from "../../assets/images/Live/pickup_location.svg";
import route from "../../assets/images/Live/route.svg";
import drop_location from "../../assets/images/Live/drop_location.svg";
import ic_location_grey from "../../assets/images/Live/ic_location_grey.svg";
import ic_arrow_next from "../../assets/images/Live/ic_arrow_next.svg";
import ic_btn_arrow from "../../assets/images/Live/ic_btn_arrow.svg";
import ic_tick_white from "../../assets/images/Live/ic_tick_white.svg";
import "../../assets/css/cx-sLoad.css";
import "../../assets/css/mapDetailsOngoing.scss";
import close from "../../assets/images/ic_close_sload.svg";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Map from "../customer/Sent-Load/Map";
import { AppContext } from "../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useNavigate } from "react-router-dom";
import locationDrop from "../../assets/images/ic_location_grey.svg";
import pack1 from "../../assets/images/package_1.svg";
import g_dot from "../../assets/images/from_loc_red.svg";
import b_dot from "../../assets/images/from_loc_red (1).svg";
import r_arrow from "../../assets/images/dropdown_icon.svg";
import star from "../../assets/images/star_highlighted.svg";
import call from "../../assets/images/call.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import { useAlert, positions } from "react-alert";
import swal from "sweetalert";
import { fontWeight } from "@mui/system";

const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw";

const google = window.google;

export default function SendLoad() {
  const {
    start,
    setStart,
    setEnd,
    customerData,
    setfinalAddress,
    finalAddress,
    fromaddress,
    setfromAddress,
    toaddress,
    settoAddress,
    sidebar,
    setLoadDetails,
    setVehicleList,
    setvehicleType,
    vehiclenamestate,
    setvehiclenamestate,
    vehicletypestate,
    setvehicletypestate,
    vehicleCapacity,
    setvehicleCapacity,
    vehicleLength,
    setvehicleLength,
  } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showdestiny, setShowDestiny] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [DestinyIndex, setDestinationIndex] = useState(null);
  const [SourceIndex, setSourceIndex] = useState(null);
  const [pinend, setpinend] = useState(false);
  const [pinstart, setpinstart] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();

  const pinendChange = () => {
    if (pinend) {
      setpinend(false);
    } else {
      setpinend(true);
      setpinstart(false);
    }
  };

  const pinstartChange = () => {
    if (pinstart) {
      setpinstart(false);
    } else {
      setpinstart(true);
      setpinend(false);
    }
  };

  /* combined address logic */
  const CombineAddress = (e) => {
    if (!(fromaddress.from_address && toaddress.to_address)) {
      swal({
        title: "Warning",
        text: "Please select pickup and drop location!",
        icon: "warning",
        button: true,
      });
      return;
    }
    setvehicleLength([]);
    setvehicleCapacity([]);
    setvehicleType([]);
    setvehicletypestate([]);
    setvehiclenamestate([]);
    setLoadDetails({});
    setfinalAddress({
      ...fromaddress,
      ...toaddress,
      from_name: "",
      from_phone_no: "",
      to_name: "",
      to_phone_no: "",
    });
    setfromAddress({
      from_name: "",
      from_phone_no: "",
    });
    settoAddress({
      to_name: "",
      to_phone_no: "",
    });

    navigate("/LocationDetails");
  };

  /* combined address logic end  */
  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => console.log(place),
  });

  /* get saved location effect */
  const postCall = () => {
    PostCallWithErrorResponse(ApiConfig.GET_LOCATION_API_CUSTOMER, {
      ...customerData,
    })
      .then((data) => {
        console.log(data);
        if (data.error == false) {
          setFetchedData(data.json.address_list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    postCall();
    settoAddress({
      ...toaddress,
      to_address: "",
    });
    setfromAddress({
      ...fromaddress,
      from_address: "",
    });
  }, []);

  /* end saved location effect */
  const [state, setState] = React.useState(false);

  const onClick = (obj) => {
    console.log(obj);

    console.log(obj.address);
  };

  return (
    <div>
      {/*      <Header /> */}

      {/* <!-- MAIN STARTS HERE --> */}

      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        <section id="cx-map-container">
          <div id="cx-map">
            <div style={{ height: "100vh", width: "100%" }} id="map">
              <Map pinend={pinend} pinstart={pinstart} />
            </div>
          </div>

          <div className={`cx-slocation ${state ? "cx-open" : null}`}>
            <select class="form-select" aria-label="Default select example">
              <option selected>My Package</option>
              <option value="3">Deliver</option>
            </select>

            <div className="location-card mt-4">
              <div className="d-flex justify-content-between">
                <p className="package-summary m-0">SUMMARY</p>
                <img src={next_arrow_white} alt="" />
              </div>
              <div className="mt-3">
                <p className="m-0">
                  Delivery No.
                  <span>45654656567</span>
                </p>
                <p className="m-0">
                  Current Location
                  <span>Queen St</span>
                </p>
                <div className="d-flex justify-content-between ">
                  <p className="m-0">
                    Status
                    <span style={{ color: "#FF8900" }}>Ongoing</span>
                  </p>

                  <p className="location-status">View Status</p>
                </div>
              </div>

              <div className="col-lg-12 col-10">
                <div className="ongoing-driver-details">
                  <p className="package-summary m-0">DRIVER DETAIL</p>

                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img
                        className="img-fluid driver-img me-2"
                        src={pack1}
                        alt=""
                      />
                      <div>
                        <p className="driver-name m-0 mt-2">My Package</p>
                        <div>
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <span className="rating">(320)</span>
                        </div>
                      </div>
                    </div>

                    <img className="call-img mt-2" src={call} alt="" />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 mt-1 driver-view-details">View Details</p>
                    <img src={whatsapp} alt="" />
                  </div>
                </div>
                <hr
                  style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                />
                <div>
                  <p className="package-summary m-0">DELIVERY CONDITIONS</p>
                  <p className="m-0 delivery-condition">
                    Lorem ipsum dolor sit amet, consetetur <br /> sadipscing
                    elitr, sed diam nonumy eirmod tempor invidunt.
                  </p>
                </div>
                <hr
                  style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                />
                <div>
                  <p className="package-summary m-0">PACKAGES</p>
                  <div className="ongoing-package mt-3">
                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        My Package
                        <span
                          className="ms-1"
                          style={{
                            color: "#EE651E",
                          }}
                        >
                          [2]
                        </span>
                      </p>
                      <img src={r_arrow} alt="" />
                    </div>
                    <hr
                      style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                    />

                    <div className="d-flex justify-content-between">
                      <p className="m-0">Deliver</p>
                      <img src={r_arrow} alt="" />
                    </div>
                    <hr
                      style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="cx-sendload-toggler">
            <button
              onClick={() => {
                setState(!state);
              }}
              type="button"
            >
              Send Load
              <img src={ic_btn_arrow} />
            </button>
          </div>
        </section>

        <section id="cx-saved-loc" className={show ? "cx-open" : ""}>
          <div className="cx-sloc-out">
            <div className="cx-filter"></div>
            <div className="cx-sloc-content">
              <div className="cx-sloc-header">
                <h4>Saved Source</h4>
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="cx-sloc-btn"
                >
                  <img src={ic_close_sload} alt="close-icon" />
                </button>
              </div>
              <ul className="cx-sloc-list" data-simplebar>
                {fetchedData
                  ? fetchedData.map((single) => {
                      return (
                        <li id={single.address_id}>
                          <label
                            className="form-check-label mx-3"
                            for="flexRadio3"
                          >
                            {single.address_name}{" "}
                            <div className="cx-radio">
                              <img src={ic_tick_white} />
                            </div>
                          </label>

                          <input
                            className="form-check-input"
                            onClick={() =>
                              setSourceIndex({
                                lat: parseFloat(single.address_latitude),
                                lng: parseFloat(single.address_longitude),
                                from_phone_no: single.address_phone_no,
                                from_name: single.address_name,
                                from_address: single.address_details,
                              })
                            }
                            type="radio"
                            name="flexRadio"
                            id={single.address_id + "source"}
                          />
                        </li>
                      );
                    })
                  : "No Address Found"}
              </ul>
              <div className="cx-btn-grp">
                <button
                  type="button"
                  onClick={() => {
                    setStart({ lat: SourceIndex.lat, lng: SourceIndex.lng });
                    setShow(false);
                    setfromAddress({
                      ...fromaddress,
                      from_phone_no: SourceIndex.from_phone_no,
                      from_name: SourceIndex.from_name,
                      from_address: SourceIndex.from_address,
                    });
                  }}
                  className="cx-con-btn"
                >
                  <span>Continue</span>
                  <img src={next_arrow_white} />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="cx-saved-loc" className={showdestiny ? "cx-open" : ""}>
          <div className="cx-sloc-out">
            <div className="cx-filter"></div>
            <div className="cx-sloc-content">
              <div className="cx-sloc-header">
                <h4>Saved Destiny</h4>
                <button
                  type="button"
                  onClick={() => setShowDestiny(!showdestiny)}
                  className="cx-sloc-btn"
                >
                  <img src={ic_close_sload} alt="close-icon" />
                </button>
              </div>
              <ul className="cx-sloc-list" data-simplebar>
                {fetchedData
                  ? fetchedData.map((single) => {
                      return (
                        <li id={single.address_id}>
                          <label
                            className="form-check-label mx-3"
                            for="flexRadio3"
                          >
                            {single.address_name}{" "}
                          </label>
                          <input
                            className="form-check-input"
                            type="radio"
                            onClick={() =>
                              setDestinationIndex({
                                lat: parseFloat(single.address_latitude),
                                lng: parseFloat(single.address_longitude),
                                to_phone_no: single.address_phone_no,
                                to_name: single.address_name,
                                to_address: single.address_details,
                              })
                            }
                            name="end"
                            id={single.address_id + "destination"}
                          />
                          <div className="cx-radio">
                            <img src={ic_tick_white} />
                          </div>
                        </li>
                      );
                    })
                  : "No Address Found"}
              </ul>
              <div className="cx-btn-grp">
                <button
                  type="button"
                  onClick={() => {
                    setEnd({ lat: DestinyIndex.lat, lng: DestinyIndex.lng });
                    setShowDestiny(false);
                    settoAddress({
                      ...toaddress,
                      to_phone_no: DestinyIndex.to_phone_no,
                      to_name: DestinyIndex.to_name,
                      to_address: DestinyIndex.to_address,
                    });
                  }}
                  className="cx-con-btn"
                >
                  <span>Continue</span>
                  <img src={next_arrow_white} />
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
