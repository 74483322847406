import React, { useContext, useEffect, useState } from "react";
import package_image from "../../assets/images/Loads/package_image.png";
import ic_from from "../../assets/images/Phistory/ic_from.svg";
import ic_route_line_his from "../../assets/images/Phistory/ic_route_line_his.svg";
import ic_to from "../../assets/images/Phistory/ic_to.svg";

import Sidebar1 from "../Sidebar1";
import "../../assets/css/cx-phistory.css";
import { AppContext } from "../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import SpinnerCmp from "../../SpinnerCmp";
import ApiConfig, { URL } from "../../api/ApiConfig";

export default function PaymentHistory(props) {
  const { customerData, loading, setLoading,sidebar } = useContext(AppContext);
  const [data, setData] = useState([]);
  const getRequestedDataDetails = () => {
    PostCallWithErrorResponse(localStorage.getItem('role')=='customer'? ApiConfig.PAYMENTHISTORY_API_CUSTOMER:ApiConfig.PAYMENTHISTORY_API_COMPANY, {
      ...customerData,
      date: "",
    })
      .then((data) => {
        console.log(data.json);
        setData(data.json.load_list);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData([]);
      });
  };
  useEffect(() => {
      setLoading(true)
    getRequestedDataDetails();
  }, []);

  return (
    <div>
  
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"} id="cxMain">
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxph-loads">
            <div className="container">
              <div className="cxph-loads-content">
                <div className="cxph-outer">
                  <div className="row">

                      {
                          data?data.map((data)=>{
                              return (
                                <div className="col-md-6">
                                <div className="cxph-content">
                                  <img className="cxph-thumbnail" src={data.thumbnail ? (URL + data.thumbnail) : package_image} />
                                  <ul>
                                    <li>
                                      <h4>{data.trip_name}</h4>
                                    </li>
                                    <li>
                                      <label>{data.date_time}</label>
                                    </li>
                                    <li>
                                      <label className="cxph-cos">{data.amount}</label>
                                    </li>
                                    <li className="cxph-routes">
                                      <div className="cxph-from">
                                        <img src={ic_from} />
                                        <div className="cxph-fdet">
                                          <label>From</label>
                                          <span>{data.trip_start_address}</span>
                                        </div>
                                      </div>
                                      <div className="cxph-to">
                                        <img src={ic_to} />
                                        <div className="cxph-fdet">
                                          <label>To</label>
                                          <span>{data.trip_end_address}</span>
                                        </div>
                                      </div>
                                      <img
                                        className="cxph-route-line"
                                        src={ic_route_line_his}
                                      />
                                    </li>
                                  </ul>
                                  <div className="cxph-cost">{data.amount}</div>
                                </div>
                              </div>
                              )
                          }):<div id="" className="col-md-12">
                          <div className="cxlo-content">
                             No Payment History  Found
                          </div>
                      </div>
                      }
              
                  
                  
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
}
