import React from "react";
import load_image from "../../assets/images/Details/load_image.png";
import "../../assets/css/loaddetailscompleted.scss";
import profile_edit from "../../assets/images/ic_profile_edit.svg";

const LoadDetailsCompleted = () => {
  return (
    <main className="cx-main p-0 load-details-main" id="cxMain">
      <section id="cxp-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cxp-bdetails-content" style={{ marginTop: "0" }}>
                <div className="cxp-details-banner">
                  <img
                    style={{ float: "right", cursor: "pointer" }}
                    src={profile_edit}
                    alt=""
                  />
                </div>
                <div className="cxp-details">
                  <div className="cxp-load-image">
                    <img src={load_image} />
                    <div className="cxp-load-graphic"></div>
                  </div>
                  <ul className="cxp-details-list" style={{ border: "none" }}>
                    <li>
                      <label>Full Name</label>
                      <p>Robert Lewandowski</p>
                    </li>
                    <li>
                      <label>Email</label>
                      <p>robertlewa@gmail.com</p>
                    </li>
                    <li>
                      <label>Mobile Number</label>
                      <p>+91 9453453234</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoadDetailsCompleted;
