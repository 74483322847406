import React, { useContext } from "react";
import { AppContext } from "../../../context/user/AppContext";
import forward from "../../../assets/images/ic_continue_arrow_blue.svg";
import back from "../../../assets/images/ic_back_arrow_blue.svg";
import "../../../assets/css/loadlocationdetails.scss";
import add from "../../../assets/images/add_red.svg";
import edit from "../../../assets/images/edit_blue.svg";

import { Link } from "react-router-dom";
export default function  () {
  const { sidebar } = useContext(AppContext);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="col-12">
        <div className="load-details-card col-lg-6 col-sm-12 mx-auto mt-3">
          <div className="d-flex justify-content-between">
            <Link to="/DropLocationForm">
              <div className="detail-btn">
                <img className="me-1" src={add} alt="" />
                <span>ADD PACKAGES</span>
              </div>
            </Link>
            <div>
              <label>2 added</label>
            </div>
          </div>
          <div className="package-details col-12 d-flex justify-content-between">
            <p className="mb-1">My Package</p>
            <img src={edit} alt="" />
          </div>
          <hr style={{ color: "#D0D0D0", height: "1px", width: "100%" }} />
          <div className="package-details col-12 d-flex justify-content-between">
            <p className="mb-1">Deliver</p>
            <img src={edit} alt="" />
          </div>
        </div>
        <div className="load-details-btn col-lg-6 col-sm-12 mx-auto mt-1">
          <div className="d-flex justify-content-between">
            <Link to="/LoadLocationDetails">
              <div className="detail-btn">
                <img className="me-1" src={back} alt="" />
                <span>Back</span>
              </div>
            </Link>
            <div className="detail-btn">
              <span className="me-1">continue</span>
              <img src={forward} alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
