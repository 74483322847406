import React, { useContext, useState, useEffect } from "react";

import Map from "../customer/Sent-Load/Map";
import banner from "../../assets/images/banner_image.svg";
import { AppContext } from "../../context/user/AppContext";
import arrow from "../../assets/images/banner_arrow.svg";
import direction from "../../assets/images/direction.svg";
import ic_loads from "../../assets/images/ic_location.svg";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/home.scss";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import GoogleMapReact from "google-map-react";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import Moment from "react-moment";
import SpinnerCmp from "../../SpinnerCmp";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const {
    sidebar,
    customerData,
    loadId,
    setLoadId,
    SetHomeStatus,
    setNotificationData,
    CompanyUserData,
  } = useContext(AppContext);
  console.log(loadId);

  let [marker, setMarker] = useState(null);
  const [show, setShow] = useState(false);
  const [requestedDetalis, setRequestedDetalis] = useState({});
  const [AddloadStatus, setLoadStatus] = useState({});
  const navigate = useNavigate();

  const [ongoingDetalis, setOngoingDetalis] = useState({});
  // console.log(ongoingDetalis)
  const [completedDetalis, setCompletedDetalis] = useState({});
  const [mapDetalis, setMapDetalis] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });

  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    SetHomeStatus(AddloadStatus);
  }, [AddloadStatus]);

  useEffect(() => {
    geDetailsHome();
    if (
      AddloadStatus === "location_added" ||
      AddloadStatus === "load_details_added" ||
      AddloadStatus === "package_added"
    ) {
      handleShow(!show);
    } else {
      setShow(show);
    }
  }, [AddloadStatus]);

  useEffect(() => {
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps);
    }
  }, [locationData, place]);

  const LoadStatus = () => {
    simplePostCall(
      ApiConfig.CONCEL_LOAD,
      JSON.stringify({ ...customerData, load_id: loadId.trip_id })
    )
      .then((res) => {
        // localStorage.removeItem("trip_id");
        // console.log(res);
        if (res.result) {
          swal({
            title: "Success",

            text: res.message,

            icon: "success",

            button: true,
          });
        } else {
          swal({
            title: "warning",

            text: res.message,

            icon: "warning",

            button: true,
          });
        }
        setShow(handleClose);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCart = () => {
    if (AddloadStatus === "load_details_added") {
      navigate("/AddLocations");
    }
    if (AddloadStatus === "location_added") {
      navigate("/SendLoadPackageDetails");
    }
    if (AddloadStatus === "package_added") {
      navigate("/SendLoadBookingSummary");
    } else {
      // setShow(handleClosea)""
    }
  };

  const geDetailsHome = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig.DASHBOARD_COMPANY_HOME,
      customerData,
      mapDetalis
    ).then((res) => {
      console.log(res);
      localStorage.setItem("trip_id", res.json.load_id);
      setLoadId({ trip_id: res.json.load_id });
      setNotificationData(res.json.notification_count);
      setRequestedDetalis(res.json.requested_list);
      setLoadStatus(res.json.current_load_status);
      setOngoingDetalis(res.json.ongoing_list);
      setCompletedDetalis(res.json.completed_list);
      setMapDetalis(res.json.nearest_drivers);
      setLoading(false);
    });
  };

  const renderMarkers = (map, maps) => {
    marker && marker.setMap(null);

    marker = new maps.Marker({
      // draggable: true,
      position: { ...place.center },
      map,
    });
    maps.event.addListener(marker, "dragend", async function (event) {});
    setMarker(marker);

    return { marker };
  };

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
          <div className="col-12 home-card-1 d-flex justify-content-between">
            <div>
              <div className="card-1-typo">
                <p>Welcome back,</p>

                <h1>{customerData.user_name}</h1>
              </div>
              <button className="banner-btn position-relative sm-p-0">
                <Link to="/VehicleInfo">
                  {" "}
                  <p className="btn-typo m-0">Start Sending Loads</p>
                </Link>

                <img className="img-fluid banner-arrow" src={arrow} alt="" />
              </button>
            </div>
            <div>
              <img className="img-fluid banner-img" src={banner} alt="" />
            </div>
          </div>
          <div className="col-12">
            <div className="section-2 d-flex justify-content-between">
              <h5>Loads</h5>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "87%" }} />
              <Link to="/LoadServiceRequest" className="m-0 mt-2">
                View All
              </Link>
            </div>
          </div>
          <div className="col-12">
            <div className="row mt-2">
              <div className="col-sm">
                <div className="service-card mt-2">
                  <h6>Service Requested</h6>
                  {/* {requestedDetalis && requestedDetalis.length > 0 ? (
                requestedDetalis.map((requestedDetalisitem, index) => {
                  return ( */}
                  <>
                    <p>
                      Delivery ID
                      {/* <span>{requestedDetalis.trip_reference_no}</span> */}
                      {requestedDetalis &&
                      requestedDetalis.trip_reference_no ? (
                        <span>
                          {requestedDetalis &&
                            requestedDetalis.trip_reference_no}
                        </span>
                      ) : (
                        <span>N/N</span>
                      )}
                    </p>
                    <p>
                      Packages{" "}
                      <span>
                        {requestedDetalis && requestedDetalis.package_count}
                      </span>
                    </p>
                    <p>
                      Load added on{" "}
                      <span>
                        <Moment format="MMMM Do YYYY, h:mm:ss a">
                          {requestedDetalis &&
                            requestedDetalis.trip_created_date}
                        </Moment>
                      </span>
                    </p>
                    <p style={{ color: "#EE651E" }}>
                      {requestedDetalis && requestedDetalis.trip_status}
                    </p>
                  </>
                  {/* )
                })
              ) : (
                <p
                  style={{
                    fontSize: '18px',
                    margin: 'auto',
                    marginTop: '60px',
                  }}
                  className=" text-center justify-content-center"
                >
                  No Requested !!!
                </p>
              )} */}
                </div>
              </div>
              <div className="col-sm">
                <div className="service-card mt-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Ongoing Deliveries</h6>
                    </div>
                    {ongoingDetalis && ongoingDetalis.trip_id && (
                      <div>
                        <Link
                          state={ongoingDetalis && ongoingDetalis}
                          to={"/MapDetailsongoing/" + ongoingDetalis.trip_id}
                        >
                          <img src={direction} alt="" />
                        </Link>
                      </div>
                    )}
                  </div>

                  <p>
                    Delivery ID
                    {/* <span>{ongoingDetalis.trip_reference_no}</span> */}
                    {ongoingDetalis && ongoingDetalis.trip_reference_no ? (
                      <span>{ongoingDetalis.trip_reference_no}</span>
                    ) : (
                      <span>N/N</span>
                    )}
                  </p>
                  <p>
                    Packages{" "}
                    <span>
                      {ongoingDetalis && ongoingDetalis.package_count}
                    </span>
                  </p>
                  <p>
                    Pickup Date{" "}
                    <span>
                      <Moment format="MMMM Do YYYY, h:mm:ss a">
                        {ongoingDetalis && ongoingDetalis.pickup_date}
                      </Moment>
                    </span>
                  </p>
                  <p>
                    Current Location
                    <span> N/N </span>
                    {/* {ongoingDetalis.current_location ? (
                  <span>{ongoingDetalis.current_location}</span>
                ) : (
                  <span>N/N</span>
                )} */}
                  </p>

                  <p>
                    Status{" "}
                    <span style={{ color: "#56B3F4" }}>
                      {ongoingDetalis && ongoingDetalis.trip_status}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-sm">
                <div className="service-card mt-2">
                  <h6>Completed</h6>

                  {completedDetalis && completedDetalis.trip_reference_no ? (
                    <p>
                      Delivery ID
                      <span>{completedDetalis.trip_reference_no}</span>
                    </p>
                  ) : (
                    <p>
                      Delivery ID
                      <span>N/N</span>
                    </p>
                  )}

                  <p>
                    Packages{" "}
                    <span>
                      {completedDetalis && completedDetalis.package_count}
                    </span>
                  </p>
                  <p>
                    Delivered On{" "}
                    <span>
                      <Moment format="MMMM Do YYYY, h:mm:ss ">
                        {completedDetalis &&
                          completedDetalis.delivery_date_time}
                      </Moment>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="section-2 d-flex justify-content-between">
              <h5>Around You</h5>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "87%" }} />
            </div>
          </div>
          <div className="col-12 mt-2">
            <div style={{ height: "50vh" }} id="map">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
                }}
                center={place.center}
                defaultZoom={7}
              >
                {mapDetalis &&
                  mapDetalis.length &&
                  mapDetalis.map((mapDetalisAll, index) => {
                    return (
                      <div
                        lat={mapDetalisAll.latitude}
                        lng={mapDetalisAll.longitude}
                      >
                        <img src={ic_loads} alt="" />
                      </div>
                    );
                  })}
              </GoogleMapReact>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="home-modal"
          >
            <Modal.Body>
              <p>
                We have retrieved your last unsaved load details. Start were you
                left off or send new?
              </p>
              <div className="btn-yes-no">
                <button className="no" onClick={LoadStatus}>
                  No
                </button>{" "}
                <button onClick={handleCart} className="yes">
                  Yes
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </main>
      )}
    </>
  );
}
