import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import "../../../assets/css/liveloaddetails.scss";
import direction from "../../../assets/images/direction.svg";
import pack1 from "../../../assets/images/package_1.svg";
import whatsapp from "../../../assets/images/whatsapp.svg";
import star from "../../../assets/images/star_highlighted.svg";
import call from "../../../assets/images/call.svg";
import driver_profile from "../../../assets/images/Details/driver_profile.png";

import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import SpinnerCmp from "../../../SpinnerCmp";
import LiveTrackingMap from "../../customer/Tracking/LiveTrackingMap";
import ic_star_highlighted from "../../../assets/images/Live/ic_star_highlighted.svg";
import ic_star_grey from "../../../assets/images/Live/ic_star_grey.svg";

export default function LoadDetailsDriver() {
  // const params = useParams()
  const URL = "https://logistic.redbytes.in/";

  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { customerData, sidebar } = useContext(AppContext);
  const [loadstatus, setLoadstatus] = useState([]);
  const [loadDriverDetails, setLoadDriverDetails] = useState([]);
  const [loadDetails, setLoadDetails] = useState([]);
  console.log("loadDetails", loadDriverDetails);
  const [loadDetailsPackage, setLoadDetailsPackage] = useState([]);
  const [loadDetailsPackageImage, setLoadDetailsImage] = useState([]);
  console.log(loadDetailsPackageImage);
  const handleClose = () => setShow(false);
  const [showModal, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    connectiongetprofile();
  }, []);
  const connectiongetprofile = () => {
    setLoading(true);

    PostCallWithErrorResponse(ApiConfig.DETAILS_LOADDATA_CUSTOMER, {
      ...customerData,
      load_id: id,
    }).then((res) => {
      console.log(res);
      setLoading(false);
      setLoadDriverDetails(res.json.customer_details);
      setLoadDetails(res.json.load_details);
      setLoadDetailsPackage(res.json.package_list);
      setLoadDetailsImage(res.json.package_list[0].images);
    });
  };

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main className={sidebar ? "cx-main cx-active" : "cx-main"}>
          {" "}
          <div show={showModal}>
            <LiveTrackingMap

            // start={start}
            // end={end}
            // currentlocation={currentlocation}
            />
          </div>
          <section id="cxp-details">
            <div className="container">
              <div className="live-details-card">
                <div class="container overflow-hidden">
                  <div class="row gx-5 gy-4">
                    <div class="col-lg-6 col-sm-12">
                      <div class="details-card">
                        <div className="d-flex justify-content-between">
                          <h6 className="mb-2">SUMMARY</h6>{" "}
                          {loadDetails.trip_status === "assigned" && (
                            <Link
                              // to="/LiveTrackingLoadDetails"
                              state={loadDetails}
                              to={
                                "/OngoingLiveTrackingMap/" + loadDetails.trip_id
                              }
                            >
                              <img src={direction} alt="" />
                            </Link>
                          )}
                        </div>
                        <p className="mt-1">
                          Delivery ID{" "}
                          <span style={{ fontWeight: "600", color: "#1C0909" }}>
                            {loadDetails.trip_reference_no}
                          </span>
                        </p>

                        <div className="d-flex justify-content-between mt-1">
                          <p>
                            Status
                            <span
                              style={{
                                fontWeight: "600",
                                color: "#FF8900",
                                padding: "5px",
                              }}
                            >
                              {loadDetails.trip_status}
                            </span>
                          </p>
                          <h6 style={{ color: "#56B3F4" }}>
                            <Link
                              state={loadDetails}
                              to={"/OverAllStatus/" + loadDetails.trip_id}
                            >
                              View Status
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <div class="details-card">
                        <h6 className="mb-2">Customer DETAIL</h6>

                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <img
                              className="img-fluid driver-img me-2"
                              src={
                                loadDriverDetails.user_profile_pic
                                  ? URL + loadDriverDetails.user_profile_pic
                                  : driver_profile
                              }
                              alt=""
                            />
                            <div>
                              <p
                                className="driver-name m-0 mt-1"
                                style={{ fontWeight: "bold" }}
                              >
                                {loadDriverDetails.user_name}
                              </p>
                            </div>
                          </div>

                          <img className="call-img mt-2" src={call} alt="" />
                        </div>
                        {/* <div className="d-flex justify-content-between">
                          <p
                            className="m-0 mt-1 driver-view-details"
                            style={{ fontWeight: "600", color: "#FF8900" }}
                          >
                            <Link
                              state={loadDriverDetails}
                              to={"/DriverDetails/" + loadDriverDetails.user_id}
                            >
                              {" "}
                              View Details
                            </Link>
                          </p>
                          <img src={whatsapp} alt="" />
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="packages">
                    <h6 className="">PACKAGES</h6>
                    {loadDetailsPackage && loadDetailsPackage.length > 0 ? (
                      loadDetailsPackage.map(
                        (loadDetailsPackageItem, index) => {
                          console.log(loadDetailsPackageItem);
                          return (
                            <div class="row gx-5 gy-4">
                              <div class="col-lg-6 col-sm-12">
                                <div class="details-card">
                                  <h6
                                    className="mb-2"
                                    style={{
                                      color: "#1C0909",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    My Package
                                  </h6>
                                  <p className="mt-1">
                                    Material Type{" "}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#1C0909",
                                      }}
                                    >
                                      {
                                        loadDetailsPackageItem.trip_material_type
                                      }
                                    </span>
                                  </p>
                                  <p className="mt-1">
                                    Weight{" "}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#1C0909",
                                      }}
                                    >
                                      {loadDetailsPackageItem.trip_weight} Kg
                                    </span>
                                  </p>
                                  <p className="mt-1">
                                    Quantity{" "}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#1C0909",
                                      }}
                                    >
                                      {loadDetailsPackageItem.trip_quantity}
                                    </span>
                                  </p>
                                  {loadDetailsPackageItem.pickup_locations.map(
                                    (pickup) => {
                                      return (
                                        <p className="mt-1">
                                          Pickup Locations{" "}
                                          <span
                                            style={{
                                              fontWeight: "600",
                                              color: "#1C0909",
                                            }}
                                          >
                                            {pickup.address_name}
                                          </span>
                                        </p>
                                      );
                                    }
                                  )}

                                  {loadDetailsPackageItem.drop_locations.map(
                                    (drop) => {
                                      return (
                                        <p className="mt-1">
                                          Drop Locations{" "}
                                          <span
                                            style={{
                                              fontWeight: "600",
                                              color: "#1C0909",
                                            }}
                                          >
                                            {drop.address_name}
                                          </span>
                                        </p>
                                      );
                                    }
                                  )}
                                  <div className="d-flex">
                                    {loadDetailsPackageImage &&
                                      loadDetailsPackageImage.map(
                                        (
                                          loadDetailsPackageImageItem,
                                          index
                                        ) => {
                                          console.log(
                                            loadDetailsPackageImageItem
                                          );

                                          return (
                                            <div
                                              className="package-img mt-3 me-3"
                                              key={
                                                "loadDetailsPackageImageItem" +
                                                index
                                              }
                                            >
                                              <img
                                                src={
                                                  URL +
                                                  loadDetailsPackageImageItem.trip_image_url
                                                }
                                                alt=""
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <p
                        style={{
                          fontSize: "18px",
                          margin: "auto",
                          marginTop: "60px",
                        }}
                        className=" text-center justify-content-center"
                      >
                        No Requested !!!
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
