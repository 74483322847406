import React, { useContext } from "react";
import { AppContext } from "../../../context/user/AppContext";
import "../../../assets/css/loadlocationdetails.scss";
import add from "../../../assets/images/add_red.svg";
import forward from "../../../assets/images/ic_continue_arrow_blue.svg";
import back from "../../../assets/images/ic_back_arrow_blue.svg";
import { Link } from "react-router-dom";
export default function LoadLocationDetails() {
  const { sidebar } = useContext(AppContext);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="col-12">
        <div className="load-details-card col-lg-6 col-sm-12  mx-auto mt-3">
          <div className="d-flex justify-content-between">
            <div>
              <h6>PICKUP LOCATION</h6>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <p className="m-0">Michael Clarke</p>
              <div className="acco-check d-flex ">
                <label className=" position-relative">
                  <label className="alert-check">
                    <input
                      class="check "
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                    />
                    <span class="label-text"></span>
                  </label>
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <p className="m-0 address">
                No.125/2, Kalas Area, Visharantwadi, <br />
                Pune - 411015.
              </p>
              <p className="m-0 address">8943567421</p>
            </div>
            <div className="col-lg-2 col-3">
              <select class="form-select" aria-label="Default select example">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <p className="m-0">Michael Clarke</p>
              <div className="acco-check d-flex ">
                <label className=" position-relative">
                  <label className="alert-check">
                    <input
                      class="check "
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                    />
                    <span class="label-text"></span>
                  </label>
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <p className="m-0 address">
                No.125/2, Kalas Area, Visharantwadi, <br />
                Pune - 411015.
              </p>
              <p className="m-0 address">8943567421</p>
            </div>
            <div className="col-lg-2 col-3">
              <select class="form-select" aria-label="Default select example">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
          <div className="d-flex justify-content-between">
            <div>
              <h6>DROP LOCATION</h6>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <p className="m-0">Michael Clarke</p>
              <div className="acco-check d-flex">
                <label className=" position-relative">
                  <label className="alert-check">
                    <input
                      class="check "
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                    />
                    <span class="label-text"></span>
                  </label>
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <p className="m-0 address">
                No.125/2, Kalas Area, Visharantwadi, <br />
                Pune - 411015.
              </p>
              <p className="m-0 address">8943567421</p>
            </div>
            <div className="col-lg-2 col-3">
              <select class="form-select" aria-label="Default select example">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <p className="m-0">Michael Clarke</p>
              <div className="acco-check d-flex ">
                <label className=" position-relative">
                  <label className="alert-check">
                    <input
                      class="check "
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                    />
                    <span class="label-text"></span>
                  </label>
                </label>
              </div>
            </div>
            <div className="col-lg-5 col-sm-12">
              <p className="m-0 address">
                No.125/2, Kalas Area, Visharantwadi, <br />
                Pune - 411015.
              </p>
              <p className="m-0 address">8943567421</p>
            </div>
            <div className="col-lg-2 col-3">
              <select class="form-select" aria-label="Default select example">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
        </div>
        <div className="load-details-btn col-lg-6 col-sm-12 mx-auto mt-1">
          <div className="d-flex justify-content-between">
            <Link to="/LoadLocationDetails">
              <div className="detail-btn">
                <img className="me-1" src={back} alt="" />
                <span>Back</span>
              </div>
            </Link>
            <div className="detail-btn">
              <span className="me-1">continue</span>
              <img src={forward} alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
