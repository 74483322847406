import React, { Component } from "react";
import { SyncLoader } from "react-spinners";

class SpinnerCmp extends Component {
  render() {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: this.props.marginTop ? this.props.marginTop : "30vh",
            display: this.props.loading === false ? "none" : "block",
          }}
        >
          <SyncLoader color={"#4190B7"} loading={this.props.loading} />
        </div>
      </>
    );
  }
}

export default SpinnerCmp;