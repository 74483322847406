import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import arrow_white from "../../../assets/images/login/arrow_white.svg";
import "../../../assets/css/cx-addpayment.css";
import { BASE_URL } from "../../../urls";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../../context/user/AppContext";
import { useAlert, positions } from "react-alert";
import MaskInput from 'react-maskinput';
export default function AddPayment() {
  const navigate = useNavigate();
  const state = useLocation().state;
  const { customerData ,sidebar} = useContext(AppContext);
  const alert = useAlert();

  const [paymentData, setPaymentData] = useState({
    account_holder_name: "",
    card_number: "",
    expiry_date: "",
    cvv: "",
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(100);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return false;

    let { account_holder_name, card_number, expiry_date, cvv } = e.target;

    setProgress(50);

    const response = await fetch(`${BASE_URL}customer/creditcard/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        account_holder_name: account_holder_name.value,
        card_number: card_number.value,
        expiry_date: expiry_date.value,
        cvv: cvv.value,
        customer_id: customerData.customer_id,
        user_id: customerData.user_id,
        api_key: customerData.api_key,
      }),
    });
    const response_data = await response.json();

    if (response_data.result === true) {
      alert.show("Credit card added successfully...", {
        position: positions.TOP_CENTER,
      });
      setPaymentData({
        account_holder_name: "",
        card_number: "",
        expiry_date: "",
        cvv: "",
      });
      navigate(state && state.cbUri ? state.cbUri : "/AddPaymentMethod");
    }
    else{
      alert.show("Enter valid information", {
        position: positions.TOP_CENTER,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.value.trim()) hideValidationError(e.target.name);
    setPaymentData({ ...paymentData, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    let errorfields = [];
    let isValid = true;
    if (!paymentData.account_holder_name) {
      errorfields.push("account_holder_name");
      isValid = false;
    }
    if (!paymentData.card_number) {
      errorfields.push("card_number");
      isValid = false;
    }
    if (!paymentData.cvv) {
      errorfields.push("cvv");
      isValid = false;
    }
    if (!paymentData.expiry_date || paymentData.expiry_date.includes('M') || paymentData.expiry_date.includes('Y')) {
      errorfields.push("expiry_date");
      
      isValid = false;
    }
    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById("error" + field).style.display = "block";
      });
    }
    return isValid;
  };
  const hideValidationError = (field) => {
    document.getElementById("error" + field).style.display = "none";
  };

  return (
    <div>



      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxap-rating">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="cxap-contact-outer">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="row">
                      <div className="col-sm-12">

                        <div className="cx-form-group">
                          <label id="cxap-ncard" className="required">
                            Card Number
                          </label>
                          <input
                            type="number"
                            onChange={(e) => handleChange(e)}
                            value={paymentData.card_number}
                            className="form-control"
                            name="card_number"
                            id="cxap-ncard"
                            placeholder=""
                          />
                           <label id="errorcard_number" className="for-error">
                            Enter card number
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="cx-form-group">
                          <label id="cxap-edate" className="required">
                            Expiry Date
                          </label>
                          <MaskInput
                            value={paymentData.expiry_date}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            name="expiry_date"
                            className="form-control"
                            id="cxap-edate"
                            placeholder=""
                            alwaysShowMask maskString="YYYY-MM" mask="0000-00" size={6}
                          />
                           <label id="errorexpiry_date" className="for-error">
                            Enter expiry date
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="cx-form-group">
                          <label id="cxap-cvv" className="required">
                            CVV
                          </label>
                          <input
                            type="number"
                            name="cvv"
                            value={paymentData.cvv}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            id="cxap-cvv"
                            placeholder=""
                          />
                          <label id="errorcvv" className="for-error">
                            Enter CVV
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="cx-form-group">
                          <label id="cxap-aname" className="required">
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            value={paymentData.account_holder_name}
                            name="account_holder_name"
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            id="cxap-aname"
                            placeholder=""
                          />
                          <label id="erroraccount_holder_name" className="for-error">
                            Enter account holder name
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="cx-btn-grp1">
                      <button type="submit" className="cxap-rate-btn">
                        <span>Add</span>
                        <img src={arrow_white} />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
