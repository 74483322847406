import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import ic_call from '../../assets/images/Contact Us/ic_call.svg'
import ic_mail from '../../assets/images/Contact Us/ic_mail.svg'
import contact_logo from '../../assets/images/Contact Us/jofar_logo1.svg'
import jofar_contact_logo from '../../assets/images/Contact Us/jofar_contact_logo.svg'
import Sidebar1 from '../Sidebar1'
import '../../assets/css/cx-contact.css'
import ApiConfig from '../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import { AppContext } from '../../context/user/AppContext'
// import { useAlert, positions } from "react-alert";
import swal from 'sweetalert'

export default function ContactUs(props) {
  // const alert = useAlert();
  const navigate = useNavigate()

  const { customerData, isCustomer, sidebar } = useContext(AppContext)
  const [state, setState] = useState({
    text: '',
  })

  const postCall = () => {
    if (!state.text) {
      // swal("please enter your feedback");
      swal({
        title: 'warning',

        text: 'Please enter your feedback',

        icon: 'warning',

        button: true,
      })

      return false
    }
    PostCallWithErrorResponse(
      isCustomer ? ApiConfig.CONTACT_US_API : ApiConfig.CONTACT_US_API_COMPANY,
      { ...customerData, ...state },
    )
      .then((data) => {
        console.log(data)
        if (data.json.result) {
          swal({
            title: 'Success',
            text: data.json.message,
            icon: 'success',
            button: true,
          }).then(() => {
            navigate('/Home')
          })
        } else {
          swal({
            title: 'warning',
            text: data.json.message,
            icon: 'warning',
            button: true,
          })
        }
        // if (data.error === false) {
        //   setState({ text: "" });
        //   alert.show("sent successfully...", {
        //     position: positions.TOP_CENTER,
        //   });
        // }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        <section id="cxcu-contact">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="cxcu-contact-outer">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cxcu-contact-in">
                        <img
                          src={contact_logo}
                          width={255}
                          height={118}
                          className="cxcu-logo"
                        />
                        <ul>
                          <li>
                            <img src={ic_call} />
                            <span>logistics@gmail.com</span>
                          </li>
                          <li>
                            <img src={ic_mail} />
                            <span>+91 8934563432</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cxcu-contact-in">
                        <h4>Get In Touch With Us</h4>
                        <div className="cx-form-group">
                          <label for="cxcu-message"></label>
                          <textarea
                            className="form-control"
                            id="cxcu-message"
                            placeholder=""
                            name="text"
                            value={state.text}
                            onChange={(e) => {
                              setState({ ...state, text: e.target.value })
                            }}
                          ></textarea>
                        </div>
                        <div className="cx-btn-grp">
                          <button
                            type="button"
                            className="cx-btn"
                            onClick={postCall}
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
