import React, { useContext, useState,useEffect } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import { Link, useNavigate } from "react-router-dom";
import ic_package_upload from "../../../assets/images/Send Steps/ic_package_upload.svg";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import ic_edit_blue from "../../../assets/images/Send Steps/ic_edit_blue.svg";
import booking_package_image from "../../../assets/images/Send Steps/booking_package_image.png";
import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import moment from "moment";
import TimePicker from 'react-time-picker';
export default function BookingSummary() {
  const base_url = "https://logistic.redbytes.in/";

  const { loadDetails, setLoadDetails, customerData,sidebar } = useContext(AppContext);
  const { trip_details } = loadDetails;
  const [value, onChange] = useState( loadDetails.time ? loadDetails.time :'10:00');
  const [bookingSummary, setBookingSummary] = useState(
    loadDetails.bookingSummary
      ? loadDetails.bookingSummary
      : {
          load_id: trip_details.trip_id ? trip_details.trip_id : 0,
          pickup_date: "",
          pickup_time: value,
          pickup_date_time: "",
          credit_card_id: "",
        }
  );
  
  const inputChangeHandle = (e) => {
    const { name, value } = e.target;
    var puDate = "";
    if (value.trim()) hideValidationError(name);
    var newBookingSummary = { ...bookingSummary, [name]: value };
    setBookingSummary(newBookingSummary);
  };
  const onContinue = () => {
    if (validateFields()) {
      setDataToContextState();
      console.log(loadDetails);
      navigate("/PaymentMethod");
    }
  };
  const setDataToContextState = () => {
    bookingSummary.pickup_date_time = moment(
      bookingSummary.pickup_date + " " + value
    ).format("DD-MMM-YYYY h:mm A");
    setLoadDetails({ ...loadDetails, bookingSummary ,time:value});
  };
  const onBack = () => {
    setDataToContextState();
    navigate("/LoadDetail");
  };
  const validateFields = () => {
    let errorfields = [];
    let isValid = true;
    if (!bookingSummary.pickup_date) {
      errorfields.push("pickup_date");
      isValid = false;
    }
    if (!bookingSummary.pickup_time) {
      errorfields.push("pickup_time");
      isValid = false;
    }
    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById("error" + field).style.display = "block";
      });
    }
    return isValid;
  };
  const hideValidationError = (field) => {
    document.getElementById("error" + field).style.display = "none";
  };
  const navigate = useNavigate();
  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxs-summary">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="cxs-content-outer d-flex justify-content-center ">
                  <div className="cxs-content-inner cxs-content-inner-fullwidth">
                    <div className="cxs-summary-content">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="cxs-summary-content-01">
                            <div className="cxs-scontent">
                              <div className="cxs-scontent-title">
                                <h4>Package Details</h4>
                                <Link
                                  to={"/LoadDetail"}
                                  className="cx-text-btn"
                                >
                                  <img src={ic_edit_blue} />
                                </Link>
                              </div>
                              <div className="cxs-scontent-details">
                                <img
                                  src={base_url + trip_details.trip_image}
                                  alt=""
                                />
                                <ul>
                                  <li>
                                    <h4>{loadDetails.name}</h4>
                                  </li>
                                  <li>
                                    <label>Material Type</label>
                                    <span>
                                      {trip_details.trip_material_type}
                                    </span>
                                  </li>
                                  <li>
                                    <label>Weight</label>
                                    <span>{loadDetails.weight}</span>
                                  </li>
                                  <li>
                                    <label>Vehicle</label>
                                    <span>
                                      {trip_details.vehicle_name}, 3 Ton
                                    </span>
                                  </li>
                                  <li>
                                    <label>Distance</label>
                                    <span>{trip_details.trip_distance}km</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="cxs-scontent">
                              <div className="cxs-scontent-title">
                                <h4>From</h4>
                                <Link
                                  to="/LocationDetails"
                                  className="cx-text-btn"
                                >
                                  <img src={ic_edit_blue} />
                                </Link>
                              </div>
                              <div className="cxs-scontent-details">
                                <ul>
                                  <li>
                                    <h4>{loadDetails.from_name}</h4>
                                  </li>
                                  <li>
                                    <label>{loadDetails.from_address}</label>
                                  </li>
                                  <li>
                                    <label>{loadDetails.from_phone_no}</label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="cxs-scontent">
                              <div className="cxs-scontent-title">
                                <h4>To</h4>
                                {/* <button type="button" className="cx-text-btn">
                                  <img src={ic_edit_blue} />
                                </button> */}
                              </div>
                              <div className="cxs-scontent-details">
                                <ul>
                                  <li>
                                    <h4>{loadDetails.to_name}</h4>
                                  </li>
                                  <li>
                                    <label>{loadDetails.to_address}</label>
                                  </li>
                                  <li>
                                    <label> {loadDetails.to_phone_no}</label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="cxs-summary-content-02">
                            <div className="cxs-srcontent">
                              <h4>Delivery Estimates</h4>
                              <ul>
                                <li>
                                  <label>Cost</label>
                                  <p>{trip_details.cost}</p>
                                </li>
                                <li>
                                  <label>Estimated Delivery Time</label>
                                  <p>{trip_details.estimated_time}</p>
                                </li>
                              </ul>
                            </div>
                            <div className="cxs-srcontent">
                              <h4>Schedule Pickup</h4>
                              <form>
                                <div className="cx-form-group">
                                  <label
                                    for="edate"
                                    className="form-label required"
                                  >
                                    Select Pickup Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="edate"
                                    value={bookingSummary.pickup_date}
                                    min={new Date().toISOString().split("T")[0]}
                                    name="pickup_date"
                                    onChange={inputChangeHandle}
                                  />
                                  <label
                                    id="errorpickup_date"
                                    className="for-error"
                                  >
                                    select pickup date
                                  </label>
                                </div>
                               {/*  <div className="cx-form-group">
                                  <label
                                    for="etime"
                                    className="form-label required"
                                  >
                                    Select Pickup Time
                                  </label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    id="etime"
                                    value={bookingSummary.pickup_time}
                                    name="pickup_time"
                                    onChange={inputChangeHandle}
                                  />
                                  <label
                                    id="errorpickup_time"
                                    className="for-error"
                                  >
                                    select pickup time
                                  </label>
                                </div> */}
                                 <div className="cx-form-group">
                                  <label
                                    for="etime"
                                    className="form-label required"
                                  >
                                    Select Pickup Time
                                  </label>
                                  <TimePicker   id="etime"  className="form-control"  name="pickup_time" onChange={onChange} value={value} />
                                  <label
                                    id="errorpickup_time"
                                    className="for-error"
                                  >
                                    select pickup time
                                  </label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cxs-nav">
                      {/* <Link to="/LoadDetail"> */}
                      <button
                        onClick={onBack}
                        type="button"
                        className="cx-text-btn"
                      >
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                      {/* </Link> */}
                      {/* <Link to="/PaymentMethod">
                        {" "} */}
                      <button
                        onClick={onContinue}
                        type="button"
                        className="cx-text-btn"
                      >
                        <span>Continue </span>
                        <img src={ic_continue_arrow_blue} />
                      </button>{" "}
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
