import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../../assets/images/ic_current_location.svg'
import { AppContext } from '../../../context/user/AppContext'

import { useAlert, positions } from 'react-alert'
import { useNavigate } from 'react-router-dom'
import '../../../assets/css/cx-sload-steps.css'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import swal from 'sweetalert'

const AddSaveLocations = () => {
  const params = useParams()
  const locationData = useLocation().state
  console.log(locationData)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  let id = params.id
  // const validateFields = () => {
  //   let errorfields = []
  //   let isValid = true
  //   if (!state.name) {
  //     errorfields.push('name')
  //     isValid = false
  //   }
  //   if (!state.landmark) {
  //     errorfields.push('landmark')
  //     isValid = false
  //   }
  //   if (!state.phone_no) {
  //     errorfields.push('phone_no')
  //     isValid = false
  //   }
  //   if (!address.address) {
  //     errorfields.push('address')
  //     isValid = false
  //   }
  //   if (!isValid) {
  //     errorfields.forEach((field) => {
  //       document.getElementById('error' + field).style.display = 'block'
  //     })
  //   }
  //   return isValid
  // }

  // const alert = useAlert()

  const {
    sidebar,
    location,
    customerData,
    loadId,
    place,
    setPlace,
  } = useContext(AppContext)
  const [state, setState] = useState({
    name: locationData && locationData.address_name,
    address: locationData && locationData.address_details,
    phone_no: locationData && locationData.address_phone_no,
    latitude: locationData && locationData.address_latitude,
    longitude: locationData && locationData.address_longitude,
  })
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false)
    }, 0)

    console.log('page to reload')
  }
  const postCall = (e) => {
    setLoading(true)

    PostCallWithErrorResponse(ApiConfig.ADD_LOCATION_LIST_API_CUSTOMERS, {
      ...customerData,
      ...state,
      trip_id: loadId.trip_id,
      location_id: locationData && locationData.address_id,
    })
      .then((res) => {
        console.log(res)
        if (res) {
          swal({
            title: 'Success',

            text: res.json.message,

            icon: 'success',

            button: true,
          }).then(() => {
            navigate('/AddLocations')
            // refreshPage()
          })
        } else {
          swal({
            title: 'warning',

            text: res.json.message,

            icon: 'warning',

            button: true,
          })
        }
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }
  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Add Locations</div>
                    <div className="right">2/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from">
                      <form>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Contact Person Name
                              </label>
                              <input
                                value={state.name}
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    name: e.target.value,
                                  })
                                }}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fpno" className="form-label">
                                Contact Person Phone Number
                              </label>
                              <input
                                value={
                                  locationData && locationData.address_phone_no
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    phone_no: e.target.value,
                                  })
                                }}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <label className="form-label">Location</label>
                        <div
                          className="location-add-card-main"
                          style={{ padding: '15px 20px', height: '100px' }}
                        >
                          <div className="address">
                            {locationData && locationData.address_details}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="/AddLocations1">
                      <button type="button" className="cx-text-btn">
                        <span id="cancel-location">Cancel</span>
                      </button>
                    </Link>
                    <Link to="#">
                      <button
                        onClick={postCall}
                        type="button"
                        className="cx-text-btn"
                      >
                        <span>Add </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default AddSaveLocations
