import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import CompanySideBar from "../../../sharedComponent/CompanySideBar";
import add_white from "../../../assets/images/add_white.svg";
import vehicle_1 from "../../../assets/images/vehicle_1.svg";
import vertical_dot from "../../../assets/images/vertical_dot.svg";
import SpinnerCmp from "../../../SpinnerCmp";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import swal from "sweetalert";

const VehicleListning = () => {
  const base_url = "http://logistic.redbytes.in/";
  const { customerData, sidebar } = useContext(AppContext);
  let [loading, setLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const { state } = useLocation();

  const getVehicleList = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_VEHICLE_LIST_API, customerData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          setVehicleList(data.json.vehicle_list);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const onDeleteVehicle = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_DELETE_VEHICLE_API, {
      ...customerData,
      vehicle_id: id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then((res) => {
            getVehicleList();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  const AssignVehicle = (driver_id, vehicle_id) => {
    PostCallWithErrorResponse(ApiConfig.COMPANY_ASSSIGN_VEHICLE_TO_DRIVER_API, {
      ...customerData,
      vehicle_id: vehicle_id,
      driver_id: driver_id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          });
        } else {
          swal({
            title: "warning",
            text: data.json.message,
            icon: "warning",
            button: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getVehicleList();
  }, []);
  return (
    <React.Fragment>
      <main
        className={sidebar ? "cx-main cx-active p-0" : "cx-main p-0"}
        id="cxMain"
      >
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-12">
                <div className="service_loads d-flex justify-content-between">
                  <h4 className="title">Vehicles [{vehicleList.length}]</h4>
                  <Link to="/addvehicles/0" className="blue_btn ms-4">
                    <img src={add_white} alt="" /> ADD VEHICLE
                  </Link>
                </div>
              </div>
              {vehicleList && vehicleList.length > 0 ? (
                vehicleList.map((vehicle, index) => {
                  return (
                    <div key={index} className="col-lg-12">
                      <div className="service_load_block">
                        <div className="card card-style mb-4">
                          <div className="card-body">
                            <div className="drivers_list d-flex justify-content-between border-0 p-0">
                              <div className="itemside">
                                <div className="aside">
                                  <img
                                    src={
                                      vehicle.vehicle_image
                                        ? base_url + vehicle.vehicle_image
                                        : vehicle_1
                                    }
                                    className="header-profile-pic"
                                  />
                                </div>
                                <Link
                                  to={"/VehicleDetails/" + vehicle.vehicle_id}
                                >
                                  <div className="info align-self-center">
                                    <p className="title">
                                      {vehicle.vehicle_name}
                                    </p>
                                    <p className="smttitle">
                                      Capacity{" "}
                                      <span className="catvehcile">
                                        {vehicle.capacity}
                                      </span>
                                    </p>
                                    <p className="smttitle">
                                      License Plate No.{" "}
                                      <span className="catvehcile">
                                        {vehicle.plate_number}
                                      </span>
                                    </p>
                                    {vehicle.vehicle_document_expire ? (
                                      <p className="smttitle">
                                        <span> vehicle documents expired</span>{" "}
                                        :{" "}
                                        <span
                                          style={{ color: "red" }}
                                          className="catvehcile"
                                        >
                                          {vehicle.vehicle_document_expire}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {vehicle.vehicle_status ? (
                                      <p className="smttitle">
                                        Vehicle Status :{" "}
                                        <span
                                          style={{
                                            color:
                                              vehicle.vehicle_status ===
                                              "pending"
                                                ? "red"
                                                : "green",
                                          }}
                                          className="catvehcile"
                                        >
                                          {vehicle.vehicle_status}
                                        </span>
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Link>
                              </div>
                              {state ? (
                                <button
                                  style={{
                                    cursor: "pointer",
                                    height: "fit-content",
                                  }}
                                  className="blue_btn "
                                  onClick={() =>
                                    AssignVehicle(state.id, vehicle.vehicle_id)
                                  }
                                >
                                  Assign
                                </button>
                              ) : (
                                <div className="btn-group drop_dot dropstart">
                                  <a
                                    href="#"
                                    className="btn dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={vertical_dot} alt="" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <Link
                                        to={
                                          "/addvehicles/" + vehicle.vehicle_id
                                        }
                                        className="dropdown-item"
                                        //   type="button"
                                        //   onClick={() =>
                                        //     navigate("/addvehicles", {
                                        //       state: {
                                        //         id: vehicle.vehicle_id,
                                        //         isCallFrom: "listing",
                                        //       },
                                        //     })
                                        //   }
                                      >
                                        Edit
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to="/DriverListning"
                                        state={{
                                          from: "vehicle",
                                          id: vehicle.vehicle_id,
                                        }}
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        Assign Driver
                                      </Link>
                                    </li>
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() =>
                                          onDeleteVehicle(vehicle.vehicle_id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="card card-style mb-4">
                  <div className="card-body">
                    <div className="drivers_list d-flex justify-content-between border-0 p-0">
                      <p>Records not found</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
};

export default VehicleListning;
