import React, { cloneElement, useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import "font-awesome/css/font-awesome.min.css";
import { AppContext } from "../../../context/user/AppContext";
import useGeolocation from "react-navigator-geolocation";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw";
const google = window.google;

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw");

// set response language. Defaults to english.
Geocode.setLanguage("en");
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const Newcomponent = ({ text }) => (
  <div
    style={{
      fontSize: "50px",
      color: "red",
      transform: "translate(-50%, -50%)",
    }}
  >
    {" "}
    <i class="fa fa-map-marker" aria-hidden="true"></i>
  </div>
);

const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();

function Map({ pinend, pinstart }) {
  //const [Center,setCenter]=useState({lat:"",lng:""}
  //const { isEnabled, coords } =    useGeolocation()
  const coords = { latitude: 19, longitude: 75 };
  const [loading, setLoading] = useState(true);
  const {
    start,
    setStart,
    googleApiObj,
    setIsGoogleApiLoadedObj,
    end,
    setEnd,
    newmap,
    setNewmap,
    fromaddress,
    setfromAddress,
    toaddress,
    settoAddress,
  } = useContext(AppContext);

  const apiIsLoaded = (map, maps) => {
    setIsGoogleApiLoadedObj(map);

    directionsRenderer.setDirections({ routes: [] });

    directionsRenderer.setMap(map);
    const origin = start;
    const destination = end;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          console.log(result.geocoded_waypoints[(0, 1)]);
          /*  result.geocoded_waypoints = result.geocoded_waypoints[(0, 1)]; */
          console.log("result ::", result);
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  /*  useEffect(()=>{

 if(navigator.geolocation){
  navigator.geolocation.getCurrentPosition(function(position) {
   
    setCenter({lat:position.coords.latitude,lng:position.coords.longitude})
  
  });
  setTimeout(() => {
    setLoading(false) 
  }, 2000);
  
 }
 else{

  setCenter({lat:19,lng:75})
  
 }


 },[]) */
  setTimeout(() => {
    setLoading(false);
  }, 2000);
  useEffect(() => {
    apiIsLoaded(googleApiObj, googleApiObj);
  }, [start, end]);
  useEffect(() => {}, [start, end, fromaddress, toaddress]);

  const onClick = (obj) => {
    console.log(obj);
    if (pinend) {
      setEnd({ ...end, lat: obj.lat, lng: obj.lng });

      Geocode.fromLatLng(obj.lat, obj.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);

          settoAddress({
            ...toaddress,
            to_address: address,
            to_lat: obj.lat,
            to_lon: obj.lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
    if (pinstart) {
      setStart({ ...start, lat: obj.lat, lng: obj.lng });

      console.log("start lat", start.lat);
      console.log("start lng ", start.lng);
      Geocode.fromLatLng(obj.lat, obj.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);

          setfromAddress({
            ...fromaddress,
            from_address: address,
            from_lat: obj.lat,
            from_lon: obj.lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  return (
    <>
      {loading ? (
        ""
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
          }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={{
            lat: coords ? coords.latitude : 19,
            lng: coords ? coords.longitude : 75,
          }}
          defaultZoom={4}
          onClick={onClick}
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
        >
          {pinend ? (
            <Newcomponent lat={end.lat} lng={end.lng} text="My city" />
          ) : (
            ""
          )}
          {pinstart ? (
            <Newcomponent lat={start.lat} lng={start.lng} text="My city" />
          ) : (
            ""
          )}
        </GoogleMapReact>
      )}
    </>
  );
}

export default Map;
