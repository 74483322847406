import React, { createContext, useContext, useEffect, useState } from "react";
import arrow_white from "../../assets/images/login/arrow_white.svg";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../urls";

import LoadingBar from "react-top-loading-bar";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

export default function ForgotPassword() {
 

  //This function will be called when the user will submit form
  

  return (
    <>
      <section id="cx-fpass-one">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex justify-content-center align-items-center " style={{height:"100vh"}}>
              <div className="cx-fpass-content">
                <h2>Forgot Password</h2>
                <p>
                  Enter the code sent your registered mobile number/email id to
                  continue
                </p>
                Customer
                <input
                  type="radio"
                  className="mx-2 my-3"
                  label="Male"
                  name="same"
                  value="customer"
                />
                Company
                <input
                  type="radio"
                  className="mx-2 my-3"
                  label="Female"
                  name="same"
                  value="company"
                />
                <form
                  
                >
                  <div className="cx-form-group">
                    <input
                      type="email"
                      required
                      name="username"
                      className="form-control"
                      id="cx-fmobile"
                      placeholder="Email"
                    />
                  </div>
                  <div className="cx-btn-grp1">
                    <button type="submit" className="cx-fpass-btn">
                      <span>Continue</span>
                      <img src={arrow_white} />
                    </button>
                  </div>
                  <div className="cx-log">
                    <Link to="/Login">
                   
                      <button type="button" className="cx-text-btn">
                        Go to login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
