import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../assets/images/ic_current_location.svg'
import add_red from '../../assets/images/add_red.svg'
import status_tick_right from '../../assets/images/status_tick_right.svg'
import '../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../context/user/AppContext'
import '../../assets/css/overAllStatus.scss'
import orange_tick from '../../assets/images/tick_orange.svg'
import drop_off_image from '../../assets/images/drop_off_image.png'
import call from '../../assets/images/call.svg'
import tickk from '../../assets/images/tickk.svg'
import offer_graphics from '../../assets/images/offer_graphics.svg'
import package_image_01 from '../../assets/images/Send Steps/package_image_01.png'
import package_image_02 from '../../assets/images/Send Steps/package_image_02.png'
import package_image_03 from '../../assets/images/Send Steps/package_image_03.png'
import '../../assets/css/overAllStatus.scss'
import Modal from 'react-bootstrap/Modal'
import ApiConfig from '../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../api/ApiServices'

export default function MapOverAllStatus() {
  const URL = 'https://logistic.redbytes.in/'

  let { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadDriverDetails, setLoadDriverDetails] = useState([])
  const [loadDetails, setLoadDetails] = useState([])
  const [LoadAccepted, setLoadAccepted] = useState([])
  const [loadPickupPackage, setLoadPickupPackage] = useState([])
  const { sidebar, customerData } = useContext(AppContext)
  const [Expand, setExpand] = useState(false)
  const handleExpand = () => setExpand(!Expand)

  useEffect(() => {
    connectiongetprofile()
  }, [])
  const connectiongetprofile = () => {
    setLoading(true)

    PostCallWithErrorResponse(ApiConfig.LOAD_SERVICE_LOAD_STATUS_COMPLETED, {
      ...customerData,
      load_id: id,
    }).then((res) => {
      console.log(res)
      setLoading(false)
      setLoadDriverDetails(res.json.driver_details)
      setLoadAccepted(res.json)
      setLoadDetails(res.json.locations)
      setLoadPickupPackage(res.json.locations.package_list)
    })
  }

  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations" className="SendLoadBookingSummary ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div
                  className="cxs-content-inner"
                  style={{
                    width: '500px',
                    boxShadow: '0px 0px 16px #0000000D',
                    borderRadius: '15px',
                  }}
                >
                  <div className="cxs-loc-content" id="OverAllStatus">
                    <div className="cxs-from ">
                      <div className="booking-status-main">
                        <div className=" left-main">
                          <div id="stepper">
                            <div class="d-flex ">
                              <div class="d-flex flex-column  align-items-center">
                                <div class="rounded-circle  px-3  ">
                                  <img src={orange_tick} alt />
                                </div>
                                <div class="line h-100"></div>
                              </div>
                              <div className="stepper-content mb-3">
                                <div className="load-status-name">
                                  Load Accepted
                                </div>
                                <div className="address">
                                  {LoadAccepted.load_accepted_date_time}
                                </div>
                              </div>
                            </div>
                            {loadDetails &&
                              loadDetails.map((loadDetailsItem, index) => {
                                console.log(
                                  'loadDetailsItem',
                                  loadDetailsItem.package_list.type,
                                )
                                return (
                                  <div class="d-flex ">
                                    <div class="d-flex flex-column  align-items-center">
                                      <div class="rounded-circle  px-3  ">
                                        <img src={orange_tick} alt />
                                      </div>
                                      <div class="line h-100"></div>
                                    </div>
                                    <div className="stepper-content">
                                      <div className="load-status-main">
                                        <div className="load-status-name">
                                          {loadDetailsItem.address_name}
                                        </div>
                                        <div className="address">
                                          {loadDetailsItem.date_time}
                                        </div>
                                      </div>
                                      <div className="name-call">
                                        <div className="name">
                                          {loadDetailsItem.address_name}
                                        </div>
                                        <div className="call">
                                          <Link to="#">
                                            <img src={call} alt="" />
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="address">
                                        {loadDetailsItem.address_details}
                                      </div>
                                      <div className="address">
                                        {loadDetailsItem.address_phone_no}
                                      </div>
                                      {/* <div className="address">8943567421</div> */}
                                      <div className="package-expand-main">
                                        <div className="left">PACKAGES [1]</div>
                                        <div className="right">
                                          {Expand ? (
                                            <Link
                                              to="#"
                                              className="expand"
                                              onClick={handleExpand}
                                            >
                                              Collapse
                                            </Link>
                                          ) : (
                                            <Link
                                              to="#"
                                              className="expand"
                                              onClick={handleExpand}
                                            >
                                              Expand
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                      {Expand && (
                                        <div>
                                          <div className="expanded-card">
                                            <div className="top">
                                              <div className="left">
                                                {
                                                  loadDetailsItem
                                                    .package_list[0]
                                                    .package_name
                                                }
                                              </div>
                                              <div className="right">
                                                {
                                                  loadDetailsItem
                                                    .package_list[0].type
                                                }
                                              </div>
                                            </div>
                                            <div>
                                              <span className="key">
                                                Material Type
                                              </span>
                                              <span className="values">
                                                {
                                                  loadDetailsItem
                                                    .package_list[0]
                                                    .trip_material_type
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span className="key">
                                                Weight
                                              </span>
                                              <span className="values">
                                                {
                                                  loadDetailsItem
                                                    .package_list[0].trip_weight
                                                }
                                              </span>
                                            </div>
                                            <div>
                                              <span className="key">
                                                Quantity
                                              </span>
                                              <span className="values">
                                                {' '}
                                                {
                                                  loadDetailsItem
                                                    .package_list[0]
                                                    .trip_quantity
                                                }
                                              </span>
                                            </div>
                                            <div className="package-pics">
                                              <img
                                                src={URL + loadDetailsItem.verification_documents.drop_images[0].trip_drop_image_url}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            <div class="d-flex ">
                              <div class="d-flex flex-column  align-items-center">
                                <div class="rounded-circle  px-3  ">
                                  <img src={orange_tick} alt />
                                </div>
                              </div>
                              <div className="stepper-content">
                                <div className="load-status-main">
                                  <div className="load-status-name">
                                   Package Delivered
                                  </div>
                                  <div className="address">
                                    {LoadAccepted.delivery_date_time}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
