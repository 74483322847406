import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
import Draggable from "react-draggable";
import add_red from "../../../assets/images/add_red.svg";
const SendLoadPaymentMethod = () => {
  const { sidebar } = useContext(AppContext);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <section id="cxs-locations" className="SendLoadPaymentMethod">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="cxs-loc-content" style={{borderRadius: "15px 15px 0px 0px"}}>
                    <div className="cxs-from">
                      <div className="form-inner-top">
                        <div>
                          <h4>Saved Cards</h4>
                        </div>
                        <div>
                          <Link to="#"></Link>
                          <Link to="#">
                            <img src={add_red} alt="" className="me-2" />
                            ADD A CREDIT/DEBIT CARD
                          </Link>
                        </div>
                      </div>
                      <label htmlFor="choosCard1" className="d-block">
                        <div className="credit-card-main">
                          <div className="left">
                            <div className="card-number">
                              ICICI Bank Credit Card *****3245
                            </div>
                            <div className="name">Robert lewandowski</div>
                            <div className="expiry">Expires 5/2025</div>
                          </div>
                          <div className="right">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="choosCard1"
                              />
                            </div>
                          </div>
                        </div>
                      </label>
                      <label htmlFor="choosCard1" className="d-block">
                        <div className="credit-card-main">
                          <div className="left">
                            <div className="card-number">
                              ICICI Bank Credit Card *****3245
                            </div>
                            <div className="name">Robert lewandowski</div>
                            <div className="expiry">Expires 5/2025</div>
                          </div>
                          <div className="right">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="choosCard1"
                              />
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="/AddLocations">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    <Link to="/AddLocations1">
                      <button type="button" className="cx-text-btn">
                        <span>Continue </span>
                        <img src={ic_continue_arrow_blue} />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SendLoadPaymentMethod;
