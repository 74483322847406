import React, { useContext, useEffect, useState } from 'react'
import CompanySideBar from '../../../sharedComponent/CompanySideBar';
import delivery_box_active from '../../../assets/images/delivery_box_active.svg'
import driver_list_inactive from '../../../assets/images/driver_list_inactive.svg'
import { Link } from 'react-router-dom';
import CompanyHeader from '../../../sharedComponent/CompanyHeader';
import { PostCallWithErrorResponse } from '../../../api/ApiServices';
import ApiConfig from '../../../api/ApiConfig';
import { AppContext } from '../../../context/user/AppContext';
import DriverMap from './DriverMap';


const LiveTrackingPackages = () => {
    const { customerData,sidebar } = useContext(AppContext);
    const [loadLocation, setloadLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState({ lat: 40.756795, lng: -73.954298 });
    const [end, setEnd] = useState({ lat: 40.756795, lng: -73.954298 });
    const [currentlocation, setCurrentLocation] = useState(null);
  const [data, setData] = useState([]);

    const getLoadDataDetails = () => {

        PostCallWithErrorResponse(
          localStorage.getItem("role") == "company"
            ? ApiConfig.ALL_LOAD_STATUS_API_COMPANY
            : "",
          { ...customerData}
        )
          .then((data) => {
            console.log(data.json);
       setloadLocation(data.json)
            setLoading(false);
          })
          .catch((error) => {
            console.log("api response", error);
            setData([]);
          });
      };

      useEffect(() => {
        getLoadDataDetails();
      }, []);
  return (
    <React.Fragment>
    
        <main className={sidebar ?  "cx-main cx-active p-0" :"cx-main p-0"}  id='cxMain'>
        <section id="cxs-live">
            <div id="cxl-map">
            {
                 loadLocation ?   <DriverMap
                 start={start}
                 end={end}
                 loadLocation={loadLocation.load_list}
                 currentlocation={currentlocation}
                 page="package"
               />:""
             }


            </div>
            <div className="drivers_toggle">
                <div style={{marginBottom: "51px"}}>
                    <Link to="/liveTrackingPackages" className="driver_panel active"><img src={delivery_box_active} alt=""/></Link>
                </div>
                <div>
                    <Link to="/liveTrackingDrivers"  className="driver_panel" data-bs-toggle="offcanvas"  role="button" aria-controls="ViewDriversLabel"><img src={driver_list_inactive} alt=""/></Link>
                </div>
            </div>
        </section>
    </main>
    </React.Fragment>
  );
}

export default LiveTrackingPackages