import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/user/AppContext";
import Map from "../../customer/Sent-Load/Map";
import pack1 from "../../../assets/images/package_1.svg";
import direction from "../../../assets/images/Loads/direction.png";

import whatsapp from "../../../assets/images/whatsapp.svg";
import star from "../../../assets/images/star_highlighted.svg";
import call from "../../../assets/images/call.svg";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import g_dot from "../../../assets/images/from_loc_red.svg";
import b_dot from "../../../assets/images/from_loc_red (1).svg";
import "../../../assets/css/cx-sLoad.css";
import "../../../assets/css/mapDetailsOngoing.scss";
import ic_loads from "../../../assets/images/2.svg";
import GoogleMapReact from "google-map-react";
import ic_star_highlighted from "../../../assets/images/Live/ic_star_highlighted.svg";
import ic_star_grey from "../../../assets/images/Live/ic_star_grey.svg";
import locationis from "../../../assets/images/map.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { height } from "@mui/system";
import ApiConfig from "../../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import Geocode from "react-geocode";
import SpinnerCmp from "../../../SpinnerCmp";
export default function MapDetailsongoing() {
  const google = window.google;

  // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  Geocode.setApiKey("AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw");

  // set response language. Defaults to english.
  Geocode.setLanguage("en");

  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();
  let { id } = useParams();
  const { sidebar, customerData } = useContext(AppContext);
  const [pinend, setpinend] = useState(false);
  const [pinstart, setpinstart] = useState(false);
  const [state, setState] = React.useState(false);
  // const [loading, setLoading] = useState(false)
  const [requestedDetalis, setRequestedDetalis] = useState([]);
  console.log(requestedDetalis);
  const [loadDriverDetails, setLoadDriverDetails] = useState([]);
  const [loadDetails, setLoadDetails] = useState([]);
  const [loadDetailsPackage, setLoadDetailsPackage] = useState([]);
  const [loadDetailsMap, setLoadDetailsMap] = useState([]);
  console.log(loadDetailsMap);
  const URL = "https://logistic.redbytes.in/";
  const [locationData, setLocationData] = useState({});
  console.log(locationData);

  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });
  const coords = { latitude: 19, longitude: 75 };
  const [start, setStart] = useState();
  console.log(start);
  const [end, setEnd] = useState();

  const [loading, setLoading] = useState(true);
  const {} = useContext(AppContext);
  const [googleApiObj, setIsGoogleApiLoadedObj] = useState(null);

  useEffect(() => {
    connectiongetprofile();
  }, []);

  useEffect(() => {
    console.log("location", locationData);
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData;
      // or else call that isApiLoaded function and pass-on these arguments
      apiIsLoaded(map, maps);
    }
  }, [locationData]);
  const connectiongetprofile = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'customer'
        ? ApiConfig.LOAD_SERVICE_REQUEST_MAP
        : ApiConfig.DRIVER_MAP_API_COMPANY,

      {
        ...customerData,
        load_id: id,
      },
    ).then((res) => {
      console.log(res)
      setLoading(false)
      setLoadDriverDetails(res.json.driver_details)
      setLoadDetails(res.json.load_details)
      setLoadDetailsPackage(res.json.package_list)
      setLoadDetailsMap(res.json.locations)
    })
  }

  useEffect(() => {
    if (loadDetailsMap.length > 0) {
      setStart({
        lat: Number(loadDetailsMap[0].address_latitude),
        lng: Number(loadDetailsMap[0].address_longitude),
      });
      setEnd({
        lat: Number(loadDetailsMap[1].address_latitude),
        lng: Number(loadDetailsMap[1].address_longitude),
      });
    }
  }, [loadDetailsMap]);

  const apiIsLoaded = (map, maps) => {
    console.log(map);
    setIsGoogleApiLoadedObj(map);
    console.log(start, end);
    directionsRenderer.setDirections({ routes: [] });

    directionsRenderer.setMap(map);
    const origin = start;
    const destination = end;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          console.log(result.geocoded_waypoints[(0, 1)]);
          /*  result.geocoded_waypoints = result.geocoded_waypoints[(0, 1)]; */
          console.log("result ::", result);
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  useEffect(() => {
    console.log(start, end);

    apiIsLoaded(googleApiObj);
  }, [start, end]);

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main
          className={sidebar ? "cx-main cx-active" : "cx-main"}
          id="cxMain"
          style={{ padding: "0", position: "relative" }}
        >
          <section id="cxs-locations">
            <div className="PickupLocationMap-main">
              <div style={{ height: "calc( 100vh - 60px)" }} id="map">
                {/* <Map pinend={pinend} pinstart={pinstart}></Map> */}
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
                  }}
                  center={place.center}
                  defaultZoom={6}
                >
                  {loadDetailsMap &&
                    loadDetailsMap.length &&
                    loadDetailsMap.map((previousPageDataAll, index) => {
                      console.log(previousPageDataAll);
                      return (
                        <div
                          lat={previousPageDataAll.address_latitude}
                          lng={previousPageDataAll.address_longitude}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            className="img-fluid"
                            src={locationis}
                            alt=""
                          />
                        </div>
                      );
                    })}
                </GoogleMapReact>

                {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={{
                lat: coords ? coords.latitude : 19,
                lng: coords ? coords.longitude : 75,
              }}
              defaultZoom={4}
              onGoogleApiLoaded={({ map, maps }) =>
                setLocationData({ map, maps })
              }
            ></GoogleMapReact> */}
              </div>
              {/* <button
                class="canvas-btn-right"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img src={next_arrow_white} alt="" />
              </button> */}
              <div
                class="offcanvas offcanvas-end"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-body">
                  <button
                    class="canvas-btn"
                    type="button"
                    tabIndex="-1"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <img src={next_arrow_white} alt="" />
                  </button>
                  <div className="location-card position-relative">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="m-0">
                          Delivery No.
                          <span>{loadDetails.trip_reference_no}</span>
                        </p>
                        <p className="m-0">
                          Current Location
                          <span></span>
                        </p>
                      </div>
                      <img src={next_arrow_white} alt="" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        Status
                        <span style={{ color: "#FF8900" }}>
                          {loadDetails.trip_status}
                        </span>
                      </p>
                      <Link
                        // to="/LiveTrackingLoadDetails"
                        state={loadDetails}
                        to={"/OngoingLiveTrackingMap/" + loadDetails.trip_id}
                      >
                        <img src={direction} alt="" />
                      </Link>
                    </div>

                    <div className="d-flex justify-content-between mt-3">
                      <h5>&#8377;{loadDetails.delivery_cost}</h5>
                      <p className="location-status">
                        <Link
                          // to="/LiveTrackingLoadDetails"
                          state={loadDetails}
                          to={"/LiveTrackingLoadDetails/" + loadDetails.trip_id}
                        >
                          View Status
                        </Link>
                      </p>
                    </div>
                  </div>
                  {loadDetailsMap &&
                    loadDetailsMap.map((loadDetailsMapItem, index) => {
                      return (
                        <div className="col-lg-12 col-10">
                          <div className="package-details">
                            <span className="package-summary">
                              {loadDetailsMapItem.package_list[0].type} /
                              DELIVERING TO
                            </span>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <img className="me-1" src={g_dot} alt="" />
                                <h6 className="m-0 mt-2">
                                  {loadDetailsMapItem.address_name}
                                </h6>
                              </div>
                              <img src={call} alt="" />
                            </div>

                            <p className="m-0">
                              {loadDetailsMapItem.address_details}
                            </p>
                            <p className="m-0" style={{ fontWeight: "bold" }}>
                              {loadDetailsMapItem.address_phone_no}
                            </p>
                            <p className="ongoing-delivered">Delivered</p>

                            {loadDetailsMapItem.distance[0] ? (
                              <p
                                className="ongoing-delivered"
                                style={{ color: "#5F5F5F" }}
                              >
                                {loadDetailsMapItem.distance}
                                [Arriving in {loadDetailsMapItem.duration}]
                                <span
                                  className="ms-2"
                                  style={{ color: "#FF8900" }}
                                >
                                  In Transit
                                </span>
                              </p>
                            ) : (
                              <p
                                className="ongoing-delivered"
                                style={{ color: "#5F5F5F" }}
                              >
                                {loadDetailsMapItem.distance}
                                [Arriving in {loadDetailsMapItem.duration}]
                                <span
                                  className="ms-2"
                                  style={{ color: "#FF8900" }}
                                >
                                  In Transit
                                </span>
                              </p>
                            )}

                            <hr
                              className="mt-0"
                              style={{
                                color: "#D0D0D0",
                                height: "2px",
                                width: "100%",
                              }}
                            />
                            <div className="ongoing-packages">
                              <p>PACKAGES</p>
                              <div className="d-flex justify-content-between package-item">
                                <div>
                                  <p className="m-0">
                                    {
                                      loadDetailsMapItem.package_list[0]
                                        .package_name
                                    }
                                    <span
                                      style={{
                                        color: "#ee651e",
                                      }}
                                    >
                                      [{loadDetailsMapItem.package_list[0].type}
                                      ]
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className="m-0">
                                    Qty{" "}
                                    <span>
                                      {
                                        loadDetailsMapItem.package_list[0]
                                          .trip_quantity
                                      }
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="driver-details">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img
                          className="img-fluid driver-img me-2"
                          src={URL + loadDriverDetails.user_profile_pic}
                          alt=""
                        />
                        <div>
                          <p className="driver-name m-0 mt-2">
                            {loadDriverDetails.user_name}
                          </p>
                          <div>
                            {/* <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" />
                        <img className="star" src={star} alt="" /> */}
                            {loadDriverDetails.rating > 0 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 1 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 2 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 3 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 4 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            <span className="rating">
                              ({loadDriverDetails.reviewed_count})
                            </span>
                          </div>
                        </div>
                      </div>

                      <img className="call-img mt-2" src={call} alt="" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Link
                        state={loadDriverDetails}
                        to={
                          "/CustomerDriverDetail/" + loadDriverDetails.user_id
                        }
                        // to="/CustomerDriverDetail"
                      >
                        <p className="m-0 mt-1 driver-view-details">
                          View Details
                        </p>
                      </Link>
                      <img src={whatsapp} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="location-img">
            <img src={location} alt="" />
          </div>
          <div className="location-img2">
            <img src={location} alt="" />
          </div> */}
            </div>
          </section>
        </main>
      )}
    </>
  );
}
