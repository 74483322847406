import React, { useState } from "react";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import ic_close_sload from "../../../assets/images/Live/ic_close_sload.svg";
import pickup_location from "../../../assets/images/Live/pickup_location.svg";
import route from "../../../assets/images/Live/route.svg";
import drop_location from "../../../assets/images/Live/drop_location.svg";
import ic_location_grey from "../../../assets/images/Live/ic_location_grey.svg";
import ic_btn_arrow from "../../../assets/images/Live/ic_btn_arrow.svg";
import c_cross from "../../../assets/images/Group 30951.svg";
import arrow_red from "../../../assets/images/arrow_red.svg";
import add_red from "../../../assets/images/add_red (1).svg";
import "../../../assets/css/cx-sLoad.css";
import Map from "../../customer/Sent-Load/Map";

export default function SendLoad() {
  const [state, setState] = useState(false);
  const [pinend, setpinend] = useState(false);
  const [pinstart, setpinstart] = useState(false);
  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className="cx-main" id="cxMain" style={{ padding: "0px" }}>
        <section id="cx-map-container">
          <div id="cx-map">
            <div style={{ height: "100vh" }} id="map">
              <Map pinend={pinend} pinstart={pinstart}></Map>
            </div>
          </div>
          <div
            className={`cx-slocation ${state ? "cx-open" : null}`}
            style={{ background: "#fff" }}
          >
            <div className="cx-slocation-content">
              <div className="cx-slocaton-title">
                <h4>Send Load</h4>
                <div className="cx-btns-title">
                  <button type="button" className="cx-slocation-btn">
                    <img src={next_arrow_white} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setState(!state);
                    }}
                    className="cx-sclose-btn"
                  >
                    <img src={ic_close_sload} />
                  </button>
                </div>
              </div>
              <form>
                <div
                  className="cx-form-group1"
                  style={{ marginBottom: "30px" }}
                >
                  <img
                    src={pickup_location}
                    className="cx-pickup"
                    alt="pickup-location"
                  />
                  <img src={route} className="cx-route" alt="pickup-location" />
                  <label for="cx-pickup" className="form-label">
                    Enter pickup location
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="cx-pickup"
                    placeholder=""
                  />
                </div>
                <div
                  className="cx-form-group1"
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,.1)",
                    paddingBottom: "30px",
                    marginBottom: 0,
                  }}
                >
                  <img
                    src={drop_location}
                    className="cx-drop"
                    alt="drop-location"
                  />
                  <label for="cx-pickup" className="form-label">
                    Enter pickup location
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="cx-pickup"
                    placeholder=""
                  />
                </div>
              </form>
            </div>
            <div className="cx-slocatoin-loc">
              <ul>
                <li>
                  <img src={ic_location_grey} alt="location-icon" />
                  <h5>Adelaide St</h5>
                  <p>Street name and address</p>
                </li>
                <li>
                  <img src={ic_location_grey} alt="location-icon" />
                  <h5>Adelaide St</h5>
                  <p>Street name and address</p>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
            style={{ width: "320px" }}
          >
            <div class="offcanvas-header">
              <h5 id="offcanvasRightLabel">Saved locations</h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body position-relative">
              <div className="d-flex justify-content-between">
                <label className="mt-2">Robert Lewandowski</label>
                <img src={c_cross} alt="" />
              </div>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
              <div className="d-flex justify-content-between">
                <label className="mt-2">Robert Lewandowski</label>
                <img src={c_cross} alt="" />
              </div>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
              <div className="offcanvas-btn">
                <div className="d-flex justify-content-between">
                  <button>
                    <img src={add_red} alt="" />
                    <span className="ms-2">Add New Location</span>
                  </button>
                  <img src={arrow_red} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="cx-sendload-toggler">
            <button
              onClick={() => {
                setState(!state);
              }}
              type="button"
            >
              Send Load
              <img src={ic_btn_arrow} />
            </button>
          </div>
          <div className="cx-sendload-toggler2 ms-lg-4">
            <button
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Select From Saved Locations
              <img className="ms-4" src={ic_btn_arrow} />
            </button>
          </div>
        </section>
      </main>
    </div>
  );
}
