import React, { useEffect, useContext, useState } from "react";
import ic_profile_edit from "../../assets/images/Details/ic_profile_edit.svg";
import driver_profile_default from "../../assets/images/login/driver_profile_default.svg";

import Sidebar1 from "../Sidebar1";
import "../../assets/css/cx-profile.css";
import { BASE_URL } from "../../urls";
import { AppContext } from "../../context/user/AppContext";
import SpinnerCmp from "../../SpinnerCmp";
import { useNavigate } from "react-router-dom";

export default function CustomerProfile(props) {
  const { customerData, isCustomer, setCustomerData, sidebar } =
    useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const base_url = "http://logistic.redbytes.in/";
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const response = await fetch(
        localStorage.getItem("role") == "customer"
          ? BASE_URL + "customer/profile"
          : BASE_URL + "driver/profile/company",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: JSON.stringify({
            api_key: customerData.api_key,
            customer_id: customerData.customer_id,
            user_id: customerData.user_id,
          }),
        }
      );
      const response_data = await response.json();
      if (response_data.result === true) {
        if (localStorage.getItem("role") == "customer") {
          console.log(response_data);
          setProfile(response_data.customer_profile);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          localStorage.setItem(
            "user_pic",
            response_data.company_profile.company_profile_pic
          );
          setCustomerData({
            ...customerData,
            user_pic: response_data.company_profile.company_profile_pic,
          });
          console.log(response_data);
          setProfile(response_data.company_profile);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        <section id="cxpro-details">
          <div className="container">
            <div className="row">
              {loading ? (
                <div className="sweet-loading">
                  <SpinnerCmp loading={loading} size={40} />
                </div>
              ) : (
                <div className="col-lg-12">
                  <div
                    className="cxpro-bdetails-content"
                    style={{ marginTop: 0 }}
                  >
                    <div className="cxpro-details-banner">
                      {
                        <button
                          onClick={() => {
                            navigate("/EditCustomerProfile");
                          }}
                          type="button"
                          className="cx-text-btn"
                        >
                          <img src={ic_profile_edit} />{" "}
                        </button>
                      }
                    </div>

                    <div className="cxpro-details">
                      <div className="cxpro-load-image">
                        <img
                          className="cx-pdefault"
                          src={
                            base_url + profile.user_profile_pic &&
                            profile.user_profile_pic
                              ? profile.user_profile_pic
                              : driver_profile_default
                          }
                        />
                        <div className="cxpro-load-graphic"></div>
                      </div>
                      {/* <div style={{width:"100%"}}>
                                <div>
                               <span>Full Name</span> :    
                               <span>{profile.user_name ? profile.user_name : profile.company_name}</span>     
                                </div>
                                
                             </div>  */}

                      {/* <table>
                                <thead>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Mobile Number</th>
                                    <th>Adderess</th>
                                    <th>{profile.license_image?"Trade Licence":""}</th>
                                    <th>{profile.licence_number ? "Licence No.":""}</th>
                                    <th>{profile.license_expiry_date ? "Licence Expiry Date.":""}</th>
                                    <th>{profile.po_number ?"Po Number":""}</th>
                                </thead>
                                <tbody>
                                <tr>
                                        <td>{profile.user_name ? profile.user_name : profile.company_name}</td>
                                        <td>{profile.user_email ? profile.user_email : profile.company_email}</td>
                                        <td>{profile.user_mobile ? profile.user_mobile : profile.company_ph_no}</td>
                                        <td>{profile.address ? profile.address : profile.address}</td>
                                        <td><a href={profile.license_image ? profile.license_image:""}>{profile.license_image ?"Download":""}</a></td>
                                        <td>{profile.licence_number ? profile.licence_number:""}</td>
                                        <td>{profile.license_expiry_date ? profile.license_expiry_date:""}</td>
                                        <td>{profile.po_number ? profile.po_number:""}</td>
                                </tr>
                                </tbody>
                             </table> */}
                      <ul className="">
                        <li>
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <label style={{ fontWeight: "bold" }}>
                              Full Name :{" "}
                            </label>
                            <p>
                              {profile.user_name
                                ? profile.user_name
                                : profile.company_name}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <label style={{ fontWeight: "bold" }}>
                              Email :{" "}
                            </label>
                            <p>
                              {profile.user_email
                                ? profile.user_email
                                : profile.company_email}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div style={{ display: "flex", marginTop: "15px" }}>
                            <label style={{ fontWeight: "bold" }}>
                              Mobile Number :{" "}
                            </label>
                            <p>
                              {profile.user_mobile
                                ? profile.user_mobile
                                : profile.company_ph_no}
                            </p>
                          </div>
                        </li>
                        <li>
                          {profile.address ? (
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <label style={{ fontWeight: "bold" }}>
                                {profile.address ? "Address : " : ""}
                              </label>
                              <p>
                                {profile.address
                                  ? profile.address
                                  : profile.address}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                        <li>
                          {profile.license_image ? (
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <label style={{ fontWeight: "bold" }}>
                                {profile.license_image
                                  ? "Trade Licence : "
                                  : ""}
                              </label>
                              <br />
                              <a
                                href={
                                  profile.license_image
                                    ? profile.license_image
                                    : ""
                                }
                              >
                                {profile.license_image ? "Download" : ""}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                        <li>
                          {profile.licence_number ? (
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <label style={{ fontWeight: "bold" }}>
                                {profile.licence_number ? "Licence No. : " : ""}
                              </label>
                              <br />
                              <p>
                                {profile.licence_number
                                  ? profile.licence_number
                                  : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                        <li>
                          {profile.license_expiry_date ? (
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <label style={{ fontWeight: "bold" }}>
                                {profile.license_expiry_date
                                  ? "Licence Expiry Date. : "
                                  : ""}
                              </label>
                              <br />
                              <p>
                                {profile.license_expiry_date
                                  ? profile.license_expiry_date
                                  : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                        <li>
                          {profile.po_number ? (
                            <div style={{ display: "flex", marginTop: "15px" }}>
                              <label style={{ fontWeight: "bold" }}>
                                {profile.po_number ? "Po Number : " : ""}{" "}
                              </label>
                              <br />
                              <p>
                                {profile.po_number ? profile.po_number : ""}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
