import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/user/AppContext";
import forward from "../../../assets/images/ic_continue_arrow_blue.svg";
import back from "../../../assets/images/ic_back_arrow_blue.svg";
import pack1 from "../../../assets/images/package_1.svg";
import package_upload from "../../../assets/images/ic_package_upload.svg";
import pack2 from "../../../assets/images/package_2.svg";
import pack3 from "../../../assets/images/package_3.svg";
import removeImg from "../../../assets/images/Group 30951.svg";
import "../../../assets/css/loadlocationdetails.scss";
import Form from "react-bootstrap/Form";
import Select from "react-select";  
import makeAnimated from "react-select/animated";
import DragAndDropForVehicles from "../../../sharedComponent/DragAndDropImages";

import { Link } from "react-router-dom";
export default function PickupForm() {
  const animatedComponents = makeAnimated();
  const [state, setState] = useState({});
  const { sidebar } = useContext(AppContext);
  const options = [{ value: "Michael Clarke", label: "Michael Clarke" }];
  const removeImage = (index) => {
    let images = state.images;
    console.log("images before ", images);
    images = images.filter((img) => images.indexOf(img) != index);
    console.log("images after ", images);
    setState({ ...state, images: images });
  };

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="col-12">
        <div className="load-details-card col-lg-6 col-sm-12 mx-auto mt-3">
          <div class="row">
            <div class="col">
              <div className="upload-subtitle">
                <label>
                  Upload Image<span>*</span>
                </label>
                <div className="cxs-upload-image">
                  <form
                    action="/upload"
                    method="post"
                    className="cxs-iupload-form"
                  >
                    <img src={package_upload} alt="package-upload" />
                    <span className="cxs-inner">Drag & Drop image here</span>
                    <span className="cxs-inner">or</span>
                    <span className="cxs-select">Browse</span>
                    <input
                      type="file"
                      name="file"
                      className="cxs-file"
                      multiple
                    />
                  </form>
                </div>
              </div>
              <div className="d-flex item_list mt-3">
                <div className="position-relative p-1">
                  <img
                    width={72}
                    height="72"
                    className="vehicle_img"
                    src={pack1}
                    alt=""
                  />
                  <a href="#" className="close_item">
                    <img src={removeImg} alt="" onClick={() => removeImage()} />
                  </a>
                </div>
                <div className="position-relative p-1">
                  <img
                    width={72}
                    height="72"
                    className="vehicle_img"
                    src={pack2}
                    alt=""
                  />
                  <a href="#" className="close_item">
                    <img src={removeImg} alt="" onClick={() => removeImage()} />
                  </a>
                </div>
                <div className="position-relative p-1">
                  <img
                    width={72}
                    height="72"
                    className="vehicle_img"
                    src={pack3}
                    alt=""
                  />
                  <a href="#" className="close_item">
                    <img src={removeImg} alt="" onClick={() => removeImage()} />
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <div className="upload-subtitle">
                <label>
                  Package Name<span>*</span>
                </label>
                <input type="email" class="form-control"></input>
              </div>
              <div className="upload-subtitle">
                <label>
                  Weight<span>*</span>
                </label>
                <input type="email" class="form-control"></input>
              </div>
              <div className="upload-subtitle">
                <label>
                  Select Vehicle<span>*</span>
                </label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="upload-subtitle">
                <label>
                  Material Type<span>*</span>
                </label>
                <input type="email" class="form-control"></input>
              </div>
              <div className="upload-subtitle">
                <label>
                  Delivery Conditions<span>*</span>
                </label>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "60px" }}
                />
              </div>
              <div className="upload-subtitle d-flex justify-content-between">
                <label>Select pickup location</label>
                <span style={{ color: "#56B3F4" }}>View All</span>
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[options[4], options[5]]}
                isMulti
                options={options}
              />
              <div className="upload-subtitle d-flex justify-content-between">
                <label>Select drop location</label>
                <span style={{ color: "#56B3F4" }}>View All</span>
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={[options[4], options[5]]}
                isMulti
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="load-details-btn col-lg-6 col-sm-12 mx-auto mt-1">
          <div className="d-flex justify-content-between">
            <Link to="/LoadLocationDetails">
              <div className="detail-btn">
                <img className="me-1" src={back} alt="" />
                <span>Back</span>
              </div>
            </Link>
            <div className="detail-btn">
              <span className="me-1">continue</span>
              <img src={forward} alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
