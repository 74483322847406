import React, { useEffect, useState } from "react";
import arrow_white from "../../assets/images/login/arrow_white.svg";
import driver_profile_default from "../../assets/images/login/driver_profile_default.svg";
import eye_closed from "../../assets/images/login/eye_closed.svg";
import eye from "../../assets/images/login/eye.svg";
import ic_camera from "../../assets/images/login/ic_camera.svg";
import login_bg from "../../assets/images/login/login_bg.svg";
import login_logo from "../../assets/images/jofar_logo_white.svg";
import MaskGroup from "../../assets/images/login/Mask Group 615.svg";
import Rectangle from "../../assets/images/login/Rectangle 5053.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/cx-register.css";
import { BASE_URL } from "../../urls";
import LoadingBar from "react-top-loading-bar";
import swal from "sweetalert";

export default function Register() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone_number: "",
    password: "",
    confirm_password: "",
    profile_picture: "",
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(100);
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();

    let {
      name,
      email,
      phone_number,
      password,
      confirm_password,
      profile_picture,
    } = e.target;

    setProgress(50);
    if (password.value != confirm_password.value) {
      alert("password did not match ");
      setProgress(100);
      return;
    }

    const data = new FormData();
    data.append("name", name.value);
    data.append("email", email.value);
    data.append("phone_number", phone_number.value);
    data.append("password", password.value);
    data.append("confirm_password", confirm_password.value);
    data.append("profile_picture", profile_picture.files[0]);

    console.log("data", data);
    const response = await fetch(`${BASE_URL}customer/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: data,
    });
    const response_data = await response.json();
    console.log(response_data);
    if (response_data.result == true) {
      setProfileData({
        name: "",
        email: "",
        phone_number: "",
        password: "",
        confirm_password: "",
        profile_picture: "",
      });
      swal({
        title: "success",
        text: "Customer Registered Successfully",
        icon: "success",
        button: true,
      });
      navigate("/Login");
    } else if (response_data.message === "User already registered") {
      swal({
        title: "warning",
        text: response_data.message,
        icon: "warning",
        button: true,
      });
      setProgress(100);
      return;
    } else {
      swal({
        title: "warning",
        text: "something went wrong ",
        icon: "warning",
        button: true,
      });

      setProgress(100);
      return;
    }
  };

  const handleChange = (e) => {
    console.log(e.target.value);

    if (e.target.name === "profile_picture") {
      setProfileData({ ...profileData, [e.target.name]: e.target.files[0] });
    } else setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <LoadingBar
        color="navy"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <section id="cx-reg">
        <div className="cx-reg-out">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="cx-reg-lcontent">
                <div className="text-center">
                  <img
                    src={login_logo}
                    alt="jofar-logo"
                    width={200}
                    height={200}
                  />
                  {/* <p id="login-left-logo-name">JOFAR</p> */}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <div className="cx-reg-content">
                <h2>Register</h2>
                <p className="cx-rp">Fill Details Below To Register</p>
                <form onSubmit={(e) => handleRegister(e)}>
                  <div className="cx-profile-pic">
                    <div className="cx-form-group">
                      <label htmlFor="cx-pro-cam">
                        <img
                          className="cx-pdefault"
                          src={
                            profileData.profile_picture
                              ? URL.createObjectURL(profileData.profile_picture)
                              : driver_profile_default
                          }
                        />

                        <img className="cx-camera" src={ic_camera} />
                      </label>
                      <input
                        type="file"
                        name="profile_picture"
                        className="form-control"
                        id="cx-pro-cam"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="cx-form-group">
                        <label id="cx-rfname">
                          Full Name<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          required
                          name="name"
                          value={profileData.name}
                          onChange={(e) => handleChange(e)}
                          className="form-control"
                          id="cx-rfname"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="cx-form-group">
                        <label id="cx-remail">
                          Email<sup>*</sup>
                        </label>
                        <input
                          type="email"
                          required
                          name="email"
                          value={profileData.email}
                          onChange={(e) => handleChange(e)}
                          className="form-control"
                          id="cx-remail"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="cx-form-group">
                        <label id="cx-rmno">
                          Mobile Number<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          required
                          name="phone_number"
                          value={profileData.phone_number}
                          onChange={(e) => handleChange(e)}
                          className="form-control"
                          id="cx-rmno"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="cx-form-group">
                        <label id="cx-rcpass">
                          password<sup>*</sup>
                        </label>
                        <input
                          type="password"
                          required
                          name="password"
                          value={profileData.password}
                          onChange={(e) => handleChange(e)}
                          className="form-control"
                          id="cx-rcpass"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="cx-form-group">
                        <label id="cx-rpass">
                          Confirm Password<sup>*</sup>
                        </label>
                        <input
                          type="password"
                          value={profileData.confirm_password}
                          name="confirm_password"
                          onChange={(e) => handleChange(e)}
                          className="form-control"
                          id="cx-rpass"
                          placeholder=" "
                        />
                      </div>
                    </div>
                  </div>
                  <div className="cx-btn-grp">
                    {/* <Link to="/Home"> */}
                    <button type="submit" className="cx-reg-btn">
                      <span>Register</span>
                      <img src={arrow_white} />
                    </button>
                    {/* </Link> */}
                  </div>
                  <div className="cx-reg">
                    <p>Already have an account?</p>
                    <Link to="/Login">
                      <button type="button" className="cx-text-btn">
                        Login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
