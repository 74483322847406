import React, { useEffect, useContext, useState } from 'react';
import Sidebar1 from '../Sidebar1'
import { AppContext } from '../../context/user/AppContext';
import { PostCallWithErrorResponse } from '../../api/ApiServices';
import ApiConfig from '../../api/ApiConfig';
import SpinnerCmp from '../../SpinnerCmp';

export default function (props) {
  const { customerData,loading,setLoading,isCustomer } = useContext(AppContext);
  const [aboutUsLink, setAboutUsLink] = useState('')
  const getAboutUsDetails = () => {
    PostCallWithErrorResponse(isCustomer? ApiConfig.ABOUT_US_API :ApiConfig.ABOUT_US_API_COMPANY, customerData)
      .then((data) => {
        setLoading(false)
        setAboutUsLink(data.json.link)
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  useEffect(() => {
    setLoading(true)
    getAboutUsDetails();
  }, [])

  return (
    <div>

      <main className="cx-main" id="cxMain">
     {
       loading ?
       <div className="sweet-loading">
      
      <SpinnerCmp   loading={loading}    
        size={40} />
    </div>:
 <section id="cxpro-details">
        
 <div className="container">
   <div className="row">
     <div className="col-lg-12">
       <div className="cxpro-bdetails-content iframeOtrWrapper" style={{ marginTop: 0 }}>
         <iframe src={aboutUsLink} width="100%" height={"100%"}></iframe>
       </div>
     </div>
   </div>
 </div>
</section>
     }
       
      </main>
    </div>
  )
} 
