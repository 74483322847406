import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";

import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
import DragAndDropImages from "../../../sharedComponent/DragAndDropImages";

import circle_cross from "../../../assets/images/circle_cross.svg";
import {
  multipartPostCall,
  postWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";
import { useEffect } from "react";
const SendLoadAddPackages = () => {
  const { sidebar, customerData, loadId } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [PickUpDropLists, setPickUpDropLists] = useState([]);
  const [empty, setEmpty] = useState(false);

  const [pickIds, setPickIds] = useState([]);
  console.log(pickIds);
  const [dropIds, setDropIds] = useState([]);

  const [pickChecked, setchecked] = useState([]);
  const [dropchecked, setDropchecked] = useState([]);

  const URL = "https://logistic.redbytes.in/";

  const navigate = useNavigate();
  const [state, setState] = useState({
    name: "",
    material_type: "",
    weight: "",
    special_instruction: "",
    quantity: "",

    images: [],
  });
  const [errMsg, setErrMsg] = useState({
    name: "",
    material_type: "",
    weight: "",
    special_instruction: "",
    pickup_ids: "",
    drop_ids: "",
    quantity: "",
    images: "",
  });

  const removeImage = (index) => {
    let images = state.images;
    // console.log("images before ", images);
    images = images.filter((img) => images.indexOf(img) != index);
    // console.log("images after ", images);
    setState({ ...state, images: images });
  };

  useEffect(() => {}, [pickIds]);

  const continueNext = () => {
    if (
      !state.images.length ||
      state.name === "" ||
      state.material_type === "" ||
      state.weight === "" ||
      state.special_instruction === "" ||
      state.pickup_ids === "" ||
      state.drop_ids === "" ||
      state.quantity === ""
    ) {
      if (!state.images.length) {
        setErrMsg({ ...errMsg, images: "Images is required" });
        window.scrollTo(0, 0);

        window.scrollTo(0, 0);
        return;
      }

      if (state.name === "") {
        setErrMsg({ ...errMsg, name: "Name is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.material_type === "") {
        setErrMsg({ ...errMsg, material_type: "Material Type is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.weight === "") {
        setErrMsg({ ...errMsg, weight: "Weight is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.special_instruction === "") {
        setErrMsg({
          ...errMsg,
          special_instruction: "Special Instruction is required",
        });

        window.scrollTo(0, 0);

        return;
      }
      if (state.pickup_ids === "") {
        setErrMsg({ ...errMsg, pickup_ids: "Pickup Id is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.drop_ids === "") {
        setErrMsg({ ...errMsg, drop_ids: "Drop Id is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.quantity === "") {
        setErrMsg({ ...errMsg, quantity: "Quantity is required" });

        window.scrollTo(0, 0);

        return;
      }
    } else {
      let formData = new FormData();

      formData.append("api_key", customerData.api_key);
      formData.append("user_id", customerData.user_id);
      formData.append("customer_id", customerData.customer_id);
      formData.append("trip_id", loadId.trip_id);
      formData.append("name", state.name);
      formData.append("material_type", state.material_type);
      formData.append("weight", state.weight);
      formData.append("special_instruction", state.special_instruction);
      formData.append("quantity", state.quantity);
      formData.append("pickup_ids", JSON.stringify(pickIds));
      formData.append("drop_ids", JSON.stringify(dropIds));
      state.images.map((img) => {
        formData.append("images[]", img);
      });

      setLoading(true);
      multipartPostCall(ApiConfig.ADD_PACKAGE_API_CUSTOMERS, formData)
        .then((res) => {
          // console.log(res);
          if (res.result) {
            swal({
              title: "Success",

              text: res.message,

              icon: "success",

              button: true,
            }).then(() => {
              navigate("/SendLoadPackageDetails");
            });
          } else {
            swal({
              title: "warning",

              text: res.message,

              icon: "warning",

              button: true,
            });
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const getlocationList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.GET_LOCATION_API_CUSTOMERS,
      JSON.stringify({
        ...customerData,
        load_id: loadId.trip_id,
      })
    )
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          const data1 = data.json.location_list;
          setPickUpDropLists(data1);
          setLoading(false);
        }
        if (data.json.result == false) {
          setPickUpDropLists([]);
          setEmpty(true);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getlocationList();
  }, []);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <section id="cxs-locations" className="SendLoadAddPackages">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Package Details</div>
                    <div className="right">3/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from ">
                      <div className="row form-section">
                        <div className="left col-lg-6">
                          <div className="col-12">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Upload Image
                                <span className="red-star"> *</span>
                              </label>

                              {/* <DragAndDropImages
                                setFile={(files) => {
                                  setErrMsg({ ...errMsg, images: "" });
                                  setState({
                                    ...state,
                                    images: [...state.images, ...files],
                                  });
                                }}
                              /> */}

                              <DragAndDropImages
                                setFile={(files) => {
                                  setState({
                                    ...state,
                                    images: [...state.images, ...files],
                                  });
                                }}
                              />

                              <div className="uploaded_items d-flex mt-4">
                                {state.images.map((img, index) => {
                                  return (
                                    <div
                                      className="item_list"
                                      key={"vehicleImages" + index}
                                    >
                                      <img
                                        width={72}
                                        height="72"
                                        className="vehicle_img"
                                        src={
                                          img.name
                                            ? window.URL.createObjectURL(img)
                                            : URL + img.trip_image_url
                                        }
                                        alt=""
                                      />
                                      <a href="#" className="close_item">
                                        <img
                                          src={circle_cross}
                                          alt=""
                                          onClick={() => removeImage(index)}
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                              {errMsg.images.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.images}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="right col-lg-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Package Name
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="text"
                                  value={state.name}
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  onChange={(e) => {
                                    setErrMsg({ ...errMsg, name: "" });
                                    setState({
                                      ...state,
                                      name: e.target.value,
                                    });
                                  }}
                                  className="form-control add-package-input"
                                />
                                {errMsg.name.length > 0 && (
                                  <div className="text-center text-danger">
                                    <span> {errMsg.name}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Material Type
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="text"
                                  value={state.material_type}
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  onChange={(e) => {
                                    setErrMsg({ ...errMsg, material_type: "" });
                                    setState({
                                      ...state,
                                      material_type: e.target.value,
                                    });
                                  }}
                                  className="form-control add-package-input"
                                />
                                {errMsg.material_type.length > 0 && (
                                  <div className="text-center text-danger">
                                    <span> {errMsg.material_type}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Weight
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="number"
                                  value={state.weight}
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  onChange={(e) => {
                                    setErrMsg({ ...errMsg, weight: "" });
                                    setState({
                                      ...state,
                                      weight: e.target.value,
                                    });
                                  }}
                                  className="form-control add-package-input"
                                />
                                {errMsg.weight.length > 0 && (
                                  <div className="text-center text-danger">
                                    <span> {errMsg.weight}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Quantity
                                  <span className="red-star"> *</span>
                                </label>
                                <input
                                  type="number"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  onChange={(e) => {
                                    setErrMsg({ ...errMsg, quantity: "" });
                                    setState({
                                      ...state,
                                      quantity: e.target.value,
                                    });
                                  }}
                                  className="form-control add-package-input"
                                />
                              </div>
                              {errMsg.quantity.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.quantity}</span>
                                </div>
                              )}
                            </div>

                            <div className="col-12">
                              <div className="cx-form-group">
                                <label for="fname" className="form-label">
                                  Special Instructions
                                  <span className="red-star"> *</span>
                                </label>
                                <textarea
                                  value={state.special_instruction}
                                  onChange={(e) => {
                                    setErrMsg({
                                      ...errMsg,
                                      special_instruction: "",
                                    });
                                    setState({
                                      ...state,
                                      special_instruction: e.target.value,
                                    });
                                  }}
                                  class="form-control add-package-input"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                />
                                {errMsg.special_instruction.length > 0 && (
                                  <div className="text-center text-danger">
                                    <span> {errMsg.special_instruction}</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="select-location row">
                        <div className="col-lg-6 left">
                          <label htmlFor="" className="heading">
                            Select Pickup Locations{" "}
                            <span style={{ fontSize: "9px", color: "#EE651E" }}>
                              (please select the same quantity as above)
                            </span>
                          </label>
                          {PickUpDropLists &&
                            PickUpDropLists.length &&
                            PickUpDropLists.map((list, index) => {
                              // console.log(list);
                              return (
                                <div
                                  className="card-select-locaton"
                                  key={"list" + index}
                                >
                                  <div className="card-select-locatio-inner">
                                    <div className="left-section">
                                      <div className="left-inner">
                                        <div className="acco-check d-flex ">
                                          <label className=" position-relative">
                                            {/* <label className="alert-check">
                                            <input
                                              class="check "
                                              type="checkbox"
                                              style={{ cursor: "pointer" }}
                                            />
                                            <span class="label-text"></span>
                                          </label> */}
                                            <label
                                              key={"list" + index}
                                              value={list.address_trip_id}
                                            >
                                              <input
                                                type="checkbox"
                                                required
                                                class="radio-custom"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setPickIds([
                                                      ...pickIds,
                                                      {
                                                        id: list.address_id,
                                                      },
                                                    ]);
                                                  } else {
                                                    setPickIds(
                                                      pickIds.filter(
                                                        (data) =>
                                                          data.id !=
                                                          list.address_id
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span class="radio-custom-label"></span>
                                            </label>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="right-inner">
                                        <div className="name">
                                          {list.address_name}
                                        </div>
                                        <div className="address">
                                          {list.address_details}
                                        </div>
                                        <div className="contact address">
                                          {list.address_phone_no}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right-section">
                                      <div className="cx-form-group">
                                        <input
                                          type="number"
                                          className="form-control add-package-input"
                                          id="exampleInputEmail1"
                                          disabled={
                                            pickIds.length > 0 ? false : true
                                          }
                                          aria-describedby="emailHelp"
                                          onChange={(e) => {
                                            setErrMsg({
                                              ...errMsg,
                                              quantity: "",
                                            });
                                            setPickIds(
                                              pickIds.map((pickup) => {
                                                if (
                                                  pickup.id === list.address_id
                                                )
                                                  return {
                                                    ...pickup,
                                                    quantity: e.target.value,
                                                  };
                                                else return pickup;
                                              })
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="seprat-line"></div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        {errMsg.pickup_ids.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.pickup_ids}</span>
                          </div>
                        )}
                        <div className="col-lg-6 right">
                          <label htmlFor="" className="heading">
                            Select Drop Locations{" "}
                            <span style={{ fontSize: "9px", color: "#EE651E" }}>
                              (please select the same quantity as above)
                            </span>
                          </label>
                          {PickUpDropLists &&
                            PickUpDropLists.length &&
                            PickUpDropLists.map((list, index) => {
                              // console.log(PickUpDropLists);
                              return (
                                <div
                                  className="card-select-locaton"
                                  key={"list" + index}
                                >
                                  <div className="card-select-locatio-inner">
                                    <div className="left-section">
                                      <div className="left-inner">
                                        <div className="acco-check d-flex ">
                                          <label className=" position-relative">
                                            {/* <label className="alert-check">
                                            <input
                                              class="check "
                                              type="checkbox"
                                              style={{ cursor: 'pointer' }}
                                            />
                                            <span class="label-text"></span>
                                          </label> */}

                                            <label
                                              key={"list" + index}
                                              value={list.address_trip_id}
                                            >
                                              <input
                                                type="checkbox"
                                                required
                                                class="radio-custom"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    setDropIds([
                                                      ...dropIds,
                                                      {
                                                        id: list.address_id,
                                                      },
                                                    ]);
                                                  } else {
                                                    setDropIds(
                                                      dropIds.filter(
                                                        (data) =>
                                                          data.id !=
                                                          list.address_id
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span class="radio-custom-label"></span>
                                            </label>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="right-inner">
                                        <div className="name">
                                          {list.address_name}
                                        </div>
                                        <div className="address">
                                          {list.address_details}
                                        </div>
                                        <div className="contact address">
                                          {list.address_phone_no}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right-section">
                                      <div className="cx-form-group">
                                        <input
                                          type="number"
                                          className="form-control add-package-input"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          disabled={
                                            dropIds.length > 0 ? false : true
                                          }
                                          onChange={(e) => {
                                            setErrMsg({
                                              ...errMsg,
                                              quantity: "",
                                            });
                                            setDropIds(
                                              dropIds.map((drop) => {
                                                if (drop.id === list.address_id)
                                                  return {
                                                    ...drop,
                                                    quantity: e.target.value,
                                                  };
                                                else return drop;
                                              })
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="seprat-line"></div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {errMsg.drop_ids.length > 0 && (
                          <div className="text-center text-danger">
                            <span> {errMsg.drop_ids}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="#">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    {/* <Link to="/SendLoadPackageDetails"> */}
                    <button
                      onClick={() => {
                        continueNext();
                      }}
                      type="button"
                      className="cx-text-btn"
                    >
                      {loading ? (
                        <div
                          class="spinner-border"
                          style={{
                            color: "#EE651E",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      ) : (
                        <>
                          <span>Continue </span>

                          <img src={ic_continue_arrow_blue} />
                        </>
                      )}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SendLoadAddPackages;
