import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CompanyHeader from "../../../sharedComponent/CompanyHeader";
import CompanySideBar from "../../../sharedComponent/CompanySideBar";
import edit_circle from "../../../assets/images/edit_circle.svg";
import vehicle_profile from "../../../assets/images/vehicle_profile.svg";
import upload_1 from "../../../assets/images/upload_1.svg";
import upload_2 from "../../../assets/images/upload_2.svg";
import upload_3 from "../../../assets/images/upload_3.svg";
import pan_id from "../../../assets/images/pan_id.svg";
import dot_circle from "../../../assets/images/dot_circle.svg";
import ApiConfig, { URL } from "../../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import { AppContext } from "../../../context/user/AppContext";
import SpinnerCmp from "../../../SpinnerCmp";

const VehicleDetails = () => {
  const { customerData, sidebar } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [size, setsize] = useState(window.innerWidth);

  const requestVehicleData = () => {
    PostCallWithErrorResponse(ApiConfig.COMPANY_VEHICLE_DETAILS_API, {
      ...customerData,
      vehicle_id: id,
    })
      .then((data) => {
        if (data.error == false) {
          setData(data.json);
          console.log(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  useEffect(() => {
    requestVehicleData();
  }, []);

  return (
    <React.Fragment>
      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxl-locations">
            <div className="row">
              <div className="col-lg-12">
                <div className="driver_service_block">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        className="cxp-bdetails-content"
                        style={{ marginTop: "0" }}
                      >
                        <div className="cxp-details-banner">
                          <div className="driver_service_action d-flex justify-content-end pt-3 pe-3">
                            <Link
                              to={
                                "/addvehicles/" +
                                data.vehicle_details.vehicle_id
                              }
                            >
                              <img src={edit_circle} alt="" />
                            </Link>
                            <div className="btn-group threedot_drop">
                              <a
                                href="#"
                                className="btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={dot_circle} alt="" />
                              </a>

                              <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    <Link
                                      to="/DriverListning"
                                      state={{
                                        from: "vehicle",
                                        id: data.vehicle_details.vehicle_id,
                                      }}
                                    >
                                      Assign Driver
                                    </Link>
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="cxd-details">
                          <div className="cxd-load-image">
                            <img
                              src={
                                data.vehicle_details.vehicle_image
                                  ? URL + data.vehicle_details.vehicle_image
                                  : vehicle_profile
                              }
                            />
                            <div className="cxp-load-graphic"></div>
                            <div className="cxp-load-name">
                              <label
                                style={{ fontWeight: "600", color: "#1C0909" }}
                              >
                                Pickup
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="service_social assigned_vehicle_block">
                                <ul className="mt-3">
                                  <li>
                                    <p className="mb-2">Capacity</p>
                                    <p className="regular_black">
                                      {
                                        data.vehicle_details
                                          .vehicle_capacity_value
                                      }
                                    </p>
                                  </li>
                                  <li>
                                    <p className="mb-2">Type</p>
                                    <p className="regular_black">
                                      {data.vehicle_details.vehicle_type_code}
                                    </p>
                                  </li>
                                  <li>
                                    <p className="mb-2">Length</p>
                                    <p className="regular_black">
                                      {
                                        data.vehicle_details
                                          .vehicle_length_value
                                      }
                                    </p>
                                  </li>
                                  <li>
                                    <p className="mb-2">Color</p>
                                    <p className="regular_black">
                                      {data.vehicle_details.vehicle_color}
                                    </p>
                                  </li>
                                  <li>
                                    <p className="mb-2">License Plate Number</p>
                                    <p className="regular_black">
                                      {data.vehicle_details.vehicle_reg_no}
                                    </p>
                                  </li>
                                  {data.vehicle_details
                                    .vehicle_document_expire ? (
                                    <li>
                                      <p className="mb-2">
                                        Vehicle Document Expiry
                                      </p>
                                      <p className="regular_black">
                                        {
                                          data.vehicle_details
                                            .vehicle_document_expire
                                        }
                                      </p>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="card card-style mt-4">
                        <div className="card-body uploaded_items">
                          <div className="service_social assigned_vehicle item_list">
                            <h3 className="smorangetitle mb-3">
                              Vehicle Images
                            </h3>
                            {data.vehicle_details.images
                              ? data.vehicle_details.images.map((single) => {
                                  return (
                                    <img
                                      src={URL + single.vehicle_image_url}
                                      alt=""
                                      className="me-3 load_img"
                                    />
                                  );
                                })
                              : "No images found"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="card card-style mt-4">
                        <div className="card-body">
                          <div className="cxp-details-content p-0 m-0">
                            <div className="cxp-content-title d-flex justify-content-between">
                              <h4>Documents</h4>
                            </div>

                            <div
                              className={
                                size > 576
                                  ? "registercard_list d-flex mt-3"
                                  : "registercard_list mt-3"
                              }
                            >
                              <div className="card document_card">
                                <div className="card-body">
                                  <div className="cxp-drop-img text-center cxd-doc-content">
                                    <p className="text-start regular_black mb-3">
                                      Indurance Id
                                    </p>

                                    {data.vehicle_details
                                      .vehicle_insurance_image &&
                                    data.vehicle_details.vehicle_insurance_image.includes(
                                      ".pdf"
                                    ) ? (
                                      <a
                                        href={
                                          URL +
                                          data.vehicle_details
                                            .vehicle_insurance_image
                                        }
                                        class="cx-text-btn"
                                      >
                                        Download PDF
                                      </a>
                                    ) : (
                                      <img
                                        src={
                                          data.vehicle_details
                                            .vehicle_insurance_image
                                            ? URL +
                                              data.vehicle_details
                                                .vehicle_insurance_image
                                            : ""
                                        }
                                        className="img-fluid cxd-doc"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-between pt-4">
                                    <p className="m-0">Indurance Id.</p>
                                    <p
                                      className="m-0"
                                      style={{ color: "#1C0909" }}
                                    >
                                      {
                                        data.vehicle_details
                                          .vehicle_insurance_no
                                      }
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between pt-4">
                                    <p className="m-0">Indurance Expiry.</p>
                                    <p
                                      className="m-0"
                                      style={{ color: "#1C0909" }}
                                    >
                                      {
                                        data.vehicle_details
                                          .vehicle_insurance_expiry
                                      }
                                    </p>
                                  </div>
                                  <hr className="lightblack my-3" />
                                  {/*  <div className="d-flex justify-content-end">
                                                              <a href="#" className="blue_link">Download PDF</a>
                                                          </div> */}
                                </div>
                              </div>

                              <div className="card document_card">
                                <div className="card-body">
                                  <div className="cxp-drop-img text-center cxd-doc-content">
                                    <p className="text-start regular_black mb-3">
                                      Registration Card
                                    </p>
                                    {data.vehicle_details.vehicle_reg_card &&
                                    data.vehicle_details.vehicle_reg_card.includes(
                                      ".pdf"
                                    ) ? (
                                      <a
                                        href={
                                          URL +
                                          data.vehicle_details.vehicle_reg_card
                                        }
                                        class="cx-text-btn"
                                      >
                                        Download PDF
                                      </a>
                                    ) : (
                                      <img
                                        src={
                                          data.vehicle_details.vehicle_reg_card
                                            ? URL +
                                              data.vehicle_details
                                                .vehicle_reg_card
                                            : ""
                                        }
                                        className="img-fluid cxd-doc"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="d-flex justify-content-between pt-4">
                                    <p className="m-0">Registration Card No</p>
                                    <p
                                      className="m-0"
                                      style={{ color: "#1C0909" }}
                                    >
                                      {data.vehicle_details.vehicle_reg_no}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between pt-4">
                                    <p className="m-0">Registration Expiry</p>
                                    <p
                                      className="m-0"
                                      style={{ color: "#1C0909" }}
                                    >
                                      {
                                        data.vehicle_details
                                          .vehicle_registration_expiry
                                      }
                                    </p>
                                  </div>
                                  <hr className="lightblack my-3" />
                                  {/*  <div className="d-flex justify-content-end">
                                                              <a href="#" className="blue_link">Download PDF</a>
                                                          </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  );
};

export default VehicleDetails;
