import React, { useContext, useEffect, useState } from 'react'
import Sidebar1 from '../../../sharedComponent/Sidebar1'
import details_bg from '../../../assets/images/Details/details_bg.png'
import driver_profile from '../../../assets/images/Details/driver_profile.png'
import drop_off_image from '../../../assets/images/Details/drop_off_image.png'
import ic_profile_edit from '../../../assets/images/Details/ic_profile_edit.png'
import load_image from '../../../assets/images/Details/load_image.png'
import package_image_01 from '../../../assets/images/Details/package_image_01.png'
import package_image_02 from '../../../assets/images/Details/package_image_02.png'
import package_image_03 from '../../../assets/images/Details/package_image_03.png'
import pan_id from '../../../assets/images/Details/pan_id.png'
import signature from '../../../assets/images/Details/signature.png'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg'
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import '../../../assets/css/cx-driver-details.css'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import { AppContext } from '../../../context/user/AppContext'
import SpinnerCmp from '../../../SpinnerCmp'
import { Link, useParams } from 'react-router-dom'

export default function CustomerDriverDetail() {
  const { customerData, isCustomer, sidebar } = useContext(AppContext)
  const [Activebutton, setActivebutton] = useState(false)
  const { id } = useParams()
  const base_url = 'http://logistic.redbytes.in/'
  let [loading, setLoading] = useState(false)
  const [driverDetails, setDriverDetails] = useState({})

  // const loadDriverDetails = () => {
  //   PostCallWithErrorResponse(
  //     ApiConfig.DRIVER_DETAILS_CUSTOMER,
  //     JSON.stringify({
  //       ...customerData,
  //       driver_id: id,
  //     }),
  //   )
  //     .then((data) => {
  //       setLoading(false)
  //       if (!data.error && data.json.result) {
  //         console.log('Driver data ::: ', data)
  //         setDriverDetails(data.json.driver_details)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('api response', error)
  //     })
  // }

  const loadDriverDetails = () => {
    setLoading(true)
    PostCallWithErrorResponse(
      // localStorage.getItem("role") == "customer"
      ApiConfig.DRIVER_DETAILS_CUSTOMER,
      // : ApiConfig.COMPANY_DRIVER_DETAIL,
      {
        ...customerData,
        ...{
          driver_id: id,
        },
      },
    )
      .then((data) => {
        setLoading(false)
        if (!data.error && data.json.result) {
          console.log('Driver data ::: ', data)
          setDriverDetails(data.json.driver_details)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }
  useEffect(() => {
    loadDriverDetails()
  }, [])

  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        <section id="cxd-details">
          <div class="container">
            {loading ? (
              <div className="sweet-loading">
                <SpinnerCmp loading={loading} size={40} />
              </div>
            ) : (
              <div class="row">
                <div class="col-lg-12">
                  <div class="cxd-bdetails-content" style={{ marginTop: 0 }}>
                    <div class="cxd-details-banner"></div>
                    <div class="cxd-details">
                      <div class="cxd-load-image">
                        <img
                          src={
                            driverDetails.driver_profile_pic
                              ? base_url + driverDetails.driver_profile_pic
                              : driver_profile
                          }
                        />
                        <div class="cxd-load-graphic"></div>
                        <div class="cxd-load-name">
                          <span>
                            {driverDetails.driver_name
                              ? driverDetails.driver_name
                              : 'Leroy Sane'}
                          </span>
                        </div>
                      </div>
                      <div class="cxd-info">
                        <ul class="cxd-driver-info">
                          <li>
                            <div class="cxd-rating">
                              {driverDetails.rating > 0 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}
                              {driverDetails.rating > 1 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}
                              {driverDetails.rating > 2 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}
                              {driverDetails.rating > 3 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}
                              {driverDetails.rating > 4 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}

                              <span>
                                ({driverDetails.reviewed_count})
                                {isCustomer ? (
                                  <Link className="mx-2" to={'/rating/' + id}>
                                    Rate and review
                                  </Link>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                          </li>
                          <li style={{ fontSize: '14px' }}>
                            {driverDetails.address
                              ? driverDetails.address
                              : 'No.125/2, Kalas Area, Visharantwadi, Pune - 411015.'}
                          </li>
                        </ul>
                      </div>
                      <div class="cxd-contact-info">
                        <div class="cxd-contact-title">
                          <h4>Contact Info</h4>
                        </div>
                        <ul class="cxd-details-list">
                          <li>
                            <label>Email</label>
                            <p>
                              <a href="#">
                                {driverDetails.email
                                  ? driverDetails.email
                                  : 'leroysane@gmail.com'}
                              </a>
                            </p>
                          </li>
                          <li>
                            <label>Mobile Number</label>
                            <p>
                              <a href="#">
                                {' '}
                                {driverDetails.mobile_number
                                  ? driverDetails.mobile_number
                                  : 9433443224}
                              </a>
                            </p>
                          </li>
                          <li>
                            <label>WhatsApp Number</label>
                            <p>
                              <a href="#">
                                {driverDetails.whatsapp_number
                                  ? driverDetails.whatsapp_number
                                  : 9433443224}
                              </a>
                            </p>
                          </li>
                          {driverDetails.driver_document_expire ? (
                            <li>
                              <label>Document Expiry:</label>
                              <p>
                                <a href="#">
                                  {driverDetails.driver_document_expire}
                                </a>
                              </p>
                            </li>
                          ) : (
                            ''
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="cxd-details-content">
                    <div class="cxd-content-title">
                      <h4>Documents</h4>
                    </div>
                    <div class="cxd-content-details">
                      <div class="row">
                        <div class="col-md-6 col-lg-4">
                          <div class="cxd-doc-content">
                            <h4>Emirates ID</h4>
                            {driverDetails.emirates_id_image &&
                            driverDetails.emirates_id_image.includes('.pdf') ? (
                              <div class="cxd-download">
                                <a
                                  href={
                                    base_url + driverDetails.emirates_id_image
                                  }
                                  class="cx-text-btn"
                                >
                                  Download PDF
                                </a>
                              </div>
                            ) : (
                              <img
                                class="cxd-doc"
                                src={
                                  driverDetails.emirates_id_image
                                    ? base_url + driverDetails.emirates_id_image
                                    : pan_id
                                }
                                alt="drop-off-image"
                              />
                            )}

                            <div class="cxd-doc-no">
                              <label>Emirates ID Number</label>
                              <span>
                                {driverDetails.emirates_id
                                  ? driverDetails.emirates_id
                                  : 5946443534435}
                              </span>
                            </div>
                            <div class="cxd-doc-no">
                              <label>Emirates Expiry Date:</label>
                              <span>
                                {driverDetails.driver_emirates_id_expiry_date
                                  ? driverDetails.driver_emirates_id_expiry_date
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                          <div class="cxd-doc-content">
                            <h4>Driving License</h4>

                            {driverDetails.license_image &&
                            driverDetails.license_image.includes('.pdf') ? (
                              <div class="cxd-download">
                                <a
                                  href={base_url + driverDetails.license_image}
                                  class="cx-text-btn"
                                >
                                  Download PDF
                                </a>
                              </div>
                            ) : (
                              <img
                                class="cxd-doc"
                                src={
                                  driverDetails.license_image
                                    ? base_url + driverDetails.license_image
                                    : pan_id
                                }
                                alt="drop-off-image"
                              />
                            )}

                            <div class="cxd-doc-no">
                              <label>License Number</label>
                              <span>
                                {driverDetails.licence_number
                                  ? driverDetails.licence_number
                                  : ''}
                              </span>
                            </div>
                            <div class="cxd-doc-no">
                              <label>License Expiry Date:</label>
                              <span>
                                {driverDetails.driver_license_expiry_date
                                  ? driverDetails.driver_license_expiry_date
                                  : ''}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-md-6 col-lg-4">
                          <div class="cxd-doc-content">
                            <h4>Car Registration Card</h4>
                            <img
                              class="cxd-doc"
                              src={
                                driverDetails.driver_reg_card_image
                                  ? base_url + driverDetails.driver_reg_card_image
                                  : pan_id
                              }
                              alt="drop-off-image"
                            />
                            <div class="cxd-doc-no">
                              <label>Registration No</label>
                              <span>  {driverDetails.driver_car_reg_no
                                  ?  driverDetails.driver_car_reg_no
                                  : 5946443534435}</span>
                            </div>
                            <div class="cxd-download">
                              <button type="button" class="cx-text-btn">
                                Download PDF
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  )
}
