import React, { useEffect, useContext, useState } from 'react'
import Sidebar1 from '../Sidebar1'
import { AppContext } from '../../context/user/AppContext'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import SpinnerCmp from '../../SpinnerCmp'
export default function PrivacyPolicy(props) {
  const { customerData, loading, setLoading, isCustomer, sidebar } = useContext(
    AppContext,
  )
  const [link, setLink] = useState('https://www.telr.com/legal/privacy-policy')
  const iframeUrl = 'https://www.telr.com/legal/privacy-policy'
  const iframeid = 'iframe'
  return (
    <div>
      <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxpro-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="cxpro-bdetails-content iframeOtrWrapper"
                    style={{ marginTop: 0 }}
                  >
                    {/* <iframe src={link} width="100%" height={"100%"} /> */}
                    <iframe
                      src={iframeUrl}
                      idth="100%"
                      height={'100%'}
                      id={iframeid}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  )
}
