import React, { useContext } from "react";
import { AppContext } from "../../../context/user/AppContext";
import forward from "../../../assets/images/ic_continue_arrow_blue.svg";
import back from "../../../assets/images/ic_back_arrow_blue.svg";
import r_arrow from "../../../assets/images/dropdown_icon.svg";
import { Link } from "react-router-dom";

export default function LoadBookingSummary() {
  const { sidebar } = useContext(AppContext);
  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="row gy-1 gx-1 custom-width">
        <div className="col-lg-8 col-sm-12">
          <div className="load-details-card-1">
            <div className="bookingDetails">
              <div>
                <h6>Package</h6>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0">
                  My Package
                  <span className="ms-1">[2]</span>
                </p>
                <img src={r_arrow} alt="" />
              </div>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />

              <div className="d-flex justify-content-between">
                <p className="m-0">Deliver</p>
                <img src={r_arrow} alt="" />
              </div>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
              <div>
                <h6>OFFERS & BENEFITS</h6>
              </div>
              <div className="d-flex justify-content-between">
                <p className="offer-typo m-0">FIRSTORDER coupon applied!</p>
                <h5>Remove</h5>
              </div>
              <p className="offer-typo-2 m-0">
                You have availed 10% off on this delivery.
              </p>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
              <div>
                <h6>DELIVERY PRIORITIES</h6>
              </div>
              <div className="row gx-3 gy-2">
                <div className="col">
                  <div className="delivery-card">
                    <h6>Leroy Sane</h6>
                    <div className="col-7">
                      <p className="m-0 deliver-address">
                        No.125/2,Kalas Area,Visharantwadi,
                        <br />
                        Pune - 411015.
                      </p>
                      <p className="delivery-number">8943567421</p>
                    </div>
                    <div className="col-12">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="1">High</option>
                        <option value="2">Medium</option>
                        <option value="3">Low</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="delivery-card">
                    <h6>Leroy Sane</h6>
                    <div className="col-7">
                      <p className="m-0 deliver-address">
                        No.125/2,Kalas Area,Visharantwadi,
                        <br />
                        Pune - 411015.
                      </p>
                      <p className="delivery-number">8943567421</p>
                    </div>
                    <div className="col-12">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="1">High</option>
                        <option selected value="2">
                          Medium
                        </option>
                        <option value="3">Low</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div
            className="load-details-card-1  "
            style={{
              borderRadius: "0px 15px 0px 0px",
            }}
          >
            <div className="bookingDetails">
              <h6>Delivery Estimates</h6>
              <div>
                <p className="cost_typo m-0">Delivery Cost</p>
                <p className="delivery_price m-0">$300</p>
              </div>
              <div>
                <p className="cost_typo m-0">Estimated Delivery Time</p>
                <p className="delivery_price m-0">3 hrs</p>
              </div>
              <div>
                <p className="cost_typo m-0">Coupon Applied</p>
                <p className="delivery_price m-0">-10%</p>
              </div>
              <p className="delivery_price m-0 mt-2">To pay</p>
              <p
                className="delivery_price m-0 mt-2"
                style={{ color: "#EE651E" }}
              >
                &#8377; 270
              </p>
              <hr style={{ color: "#D0D0D0", height: "2px", width: "100%" }} />
              <h6>Schedule Pickup</h6>
              <div>
                <label>Select Pickup Date</label>
                <input type="date" className="form-control" id="edate" />
              </div>
              <div className="mt-2">
                <label>Select Pickup Time</label>
                <input type="time" className="form-control" id="edate" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="load-details-btn">
            <div className="d-flex justify-content-between">
              <Link to="/LoadLocationDetails">
                <div className="detail-btn">
                  <img className="me-1" src={back} alt="" />
                  <span>Back</span>
                </div>
              </Link>
              <div className="detail-btn">
                <span className="me-1">continue</span>
                <img src={forward} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
