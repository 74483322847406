import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/user/AppContext";
import Map from "../../customer/Sent-Load/Map";
import pack1 from "../../../assets/images/package_1.svg";
import whatsapp from "../../../assets/images/whatsapp.svg";
import star from "../../../assets/images/star_highlighted.svg";
import call from "../../../assets/images/call.svg";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import g_dot from "../../../assets/images/from_loc_red.svg";
import b_dot from "../../../assets/images/from_loc_red (1).svg";
import "../../../assets/css/cx-sLoad.css";
import "../../../assets/css/mapDetailsOngoing.scss";
import locationmap from "../../../assets/images/location (2).svg";
import { Link, useLocation } from "react-router-dom";
import { height } from "@mui/system";
import ApiConfig from "../../../api/ApiConfig";
import GoogleMapReact from "google-map-react";
import ic_star_highlighted from "../../../assets/images/Live/ic_star_highlighted.svg";
import ic_star_grey from "../../../assets/images/Live/ic_star_grey.svg";
import direction from "../../../assets/images/Loads/direction.png";

import {
  PostCallWithErrorResponse,
  simplePostCall,
} from "../../../api/ApiServices";
import SpinnerCmp from "../../../SpinnerCmp";
export default function AllongoingLoadMap() {
  const URL = "https://logistic.redbytes.in/";
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const { sidebar, customerData } = useContext(AppContext);
  const [pinend, setpinend] = useState({});
  console.log(pinend);
  const [loadDriverDetails, setLoadDriverDetails] = useState([]);
  console.log(loadDriverDetails);
  const [loadDetails, setLoadDetails] = useState([]);
  const [loadDetailsPackage, setLoadDetailsPackage] = useState([]);
  const [loadDetailsMap, setLoadDetailsMap] = useState([]);
  console.log("loadDetailsMap", loadDetailsMap);
  const [loading, setLoading] = useState(false);
  const [ongoingloadlist, setOngoingloadList] = useState([]);
  console.log("ongoingloadlist", ongoingloadlist);
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  });

  const LoadStatus = (id, e) => {
    // setLoading(true);

    simplePostCall(
      ApiConfig.LOAD_SERVICE_REQUEST_MAP,
      JSON.stringify({ ...customerData, load_id: id })
    )
      .then((res) => {
        console.log(res);
        setLoadDriverDetails(res.driver_details);
        setLoadDetails(res.load_details);
        setLoadDetailsPackage(res.package_list);
        setLoadDetailsMap(res.locations);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    geOngoingMap();
  }, []);

  const geOngoingMap = () => {
    setLoading(true);

    PostCallWithErrorResponse(
      ApiConfig.LOAD_SERVICE_ONGOING,
      customerData
    ).then((res) => {
      console.log(res);
      setOngoingloadList(res.json.load_list);
      setLoading(false);
    });
  };

  return (
    <>
      {loading ? (
        <SpinnerCmp />
      ) : (
        <main
          className={sidebar ? "cx-main cx-active" : "cx-main"}
          id="cxMain"
          style={{ padding: "0", position: "relative" }}
        >
          <section id="cxs-locations">
            <div className="PickupLocationMap-main">
              <div style={{ height: "calc( 100vh - 60px)" }} id="map">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
                  }}
                  center={place.center}
                  defaultZoom={5}
                >
                  {/* // map 1 // */}
                  {ongoingloadlist &&
                    ongoingloadlist.length &&
                    ongoingloadlist.map((ongoingload, index) => {
                      console.log(ongoingload.locations);

                      /// map 2 //
                      return (
                        ongoingload.locations &&
                        ongoingload.locations.map((location, index) => {
                          console.log(Number(location.address_latitude));
                          return (
                            <div
                              key={("location", index)}
                              lat={Number(location.address_latitude)}
                              lng={Number(location.address_longitude)}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                                onClick={(e) => {
                                  LoadStatus(ongoingload.trip_id, e);
                                }}
                                src={locationmap}
                                alt=""
                              />
                            </div>
                          );
                        })
                      );
                    })}
                </GoogleMapReact>
              </div>
              {/* <button
                  class="canvas-btn-right"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  <img src={next_arrow_white} alt="" />
                </button> */}
              <div
                class="offcanvas offcanvas-end"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div class="offcanvas-body">
                  <button
                    class="canvas-btn"
                    type="button"
                    tabIndex="-1"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                  >
                    <img src={next_arrow_white} alt="" />
                  </button>

                  <div className="location-card position-relative">
                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="m-0">
                          Delivery No.
                          <span>
                            {loadDetails && loadDetails.trip_reference_no}
                          </span>
                        </p>
                        <p className="m-0">
                          Current Location
                          <span></span>
                        </p>
                      </div>
                      <img src={next_arrow_white} alt="" />
                    </div>

                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        Status
                        <span style={{ color: "#FF8900" }}>
                          {loadDetails && loadDetails.trip_status}
                        </span>
                      </p>
                      <Link
                        // to="/LiveTrackingLoadDetails"
                        state={loadDetails}
                        to={"/OngoingLiveTrackingMap/" + loadDetails.trip_id}
                      >
                        <img src={direction} alt="" />
                      </Link>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <h5>&#8377;{loadDetails.delivery_cost}</h5>
                      <p className="location-status">
                        <Link
                          // to="/LiveTrackingLoadDetails"
                          state={loadDetails}
                          to={"/LiveTrackingLoadDetails/" + loadDetails.trip_id}
                        >
                          View Status
                        </Link>
                      </p>
                    </div>
                  </div>

                  {loadDetailsMap &&
                    loadDetailsMap.map((loadDetailsMapItem, index) => {
                      return (
                        <div className="col-lg-12 col-10 mt-2">
                          {loading ? (
                            <div
                              class="spinner-border"
                              style={{
                                color: "#EE651E",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          ) : (
                            <div className="package-details">
                              <span className="package-summary">
                                {loadDetailsMapItem.package_list[0].type} /
                                DELIVERING TO
                              </span>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                  <img className="me-1" src={g_dot} alt="" />
                                  <h6 className="m-0 mt-2">
                                    {loadDetailsMapItem.address_name}
                                  </h6>
                                </div>
                                <img src={call} alt="" />
                              </div>

                              <p className="m-0">
                                {loadDetailsMapItem.address_details}
                              </p>
                              <p className="m-0" style={{ fontWeight: "bold" }}>
                                {loadDetailsMapItem.address_phone_no}
                              </p>
                              <p className="ongoing-delivered">Delivered</p>

                              {loadDetailsMapItem.distance[0] ? (
                                <p
                                  className="ongoing-delivered"
                                  style={{ color: "#5F5F5F" }}
                                >
                                  {loadDetailsMapItem.distance}
                                  [Arriving in {loadDetailsMapItem.duration}]
                                  <span
                                    className="ms-2"
                                    style={{ color: "#FF8900" }}
                                  >
                                    In Transit
                                  </span>
                                </p>
                              ) : (
                                <p
                                  className="ongoing-delivered"
                                  style={{ color: "#5F5F5F" }}
                                >
                                  {loadDetailsMapItem.distance}
                                  [Arriving in {loadDetailsMapItem.duration}]
                                  <span
                                    className="ms-2"
                                    style={{ color: "#FF8900" }}
                                  >
                                    In Transit
                                  </span>
                                </p>
                              )}

                              <hr
                                className="mt-0"
                                style={{
                                  color: "#D0D0D0",
                                  height: "2px",
                                  width: "100%",
                                }}
                              />
                              <div className="ongoing-packages">
                                <p>PACKAGES</p>
                                <div className="d-flex justify-content-between package-item">
                                  <div>
                                    <p className="m-0">
                                      {
                                        loadDetailsMapItem.package_list[0]
                                          .package_name
                                      }
                                      <span
                                        style={{
                                          color: "#ee651e",
                                        }}
                                      >
                                        [
                                        {
                                          loadDetailsMapItem.package_list[0]
                                            .type
                                        }
                                        ]
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="m-0">
                                      Qty{" "}
                                      <span>
                                        {
                                          loadDetailsMapItem.package_list[0]
                                            .trip_quantity
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  <div className="driver-details">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <img
                          className="img-fluid driver-img me-2"
                          src={URL + loadDriverDetails.user_profile_pic}
                          alt=""
                        />

                        {/* {URL + loadDriverDetails &&
                    loadDriverDetails.user_profile_pic ? (
                      <img
                        src={
                          URL + loadDriverDetails &&
                          loadDriverDetails.user_profile_pic
                        }
                      />
                    ) : (
                      <img src={whatsapp} />
                    )} */}
                        <div>
                          <p className="driver-name m-0 mt-2">
                            {loadDriverDetails.user_name}
                          </p>
                          <div>
                            {loadDriverDetails.rating > 0 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 1 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 2 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 3 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            {loadDriverDetails.rating > 4 ? (
                              <img src={ic_star_highlighted} />
                            ) : (
                              <img src={ic_star_grey} />
                            )}
                            <span className="rating">
                              ({loadDriverDetails.reviewed_count})
                            </span>
                          </div>
                        </div>
                      </div>

                      <img className="call-img mt-2" src={call} alt="" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Link
                        state={loadDriverDetails}
                        to={
                          "/CustomerDriverDetail/" + loadDriverDetails.user_id
                        }
                        // to="/CustomerDriverDetail"
                      >
                        <p className="m-0 mt-1 driver-view-details">
                          View Details
                        </p>
                      </Link>
                      <img src={whatsapp} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
