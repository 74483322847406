import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../sharedComponent/Sidebar1";
import "../../assets/css/cx-addpayment.css";
import { BASE_URL } from "../../urls";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import driver_profile_default from "../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../assets/images/login/ic_camera.svg";
import "../../assets/css/cx-register.css";
import { FileUploader } from "react-drag-drop-files";
import swal from "sweetalert";

function EditCustomerProfile() {
  const navigate = useNavigate();
  const { customerData, setCustomerData, isCustomer, sidebar } =
    useContext(AppContext);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone_number: "",
    profile_picture: "",
    isImageChange: false,
    company_name: "",
    address: "",
  });

  useEffect(() => {
    console.log(customerData);
    const fetchData = async () => {
      const response = await fetch(
        isCustomer
          ? `${BASE_URL}customer/profile`
          : `${BASE_URL}driver/profile/company`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: JSON.stringify({
            api_key: customerData.api_key,
            customer_id: customerData.customer_id,
            user_id: customerData.user_id,
          }),
        }
      );
      const response_data = await response.json();
      if (response_data.result === true) {
        console.log(response_data);
        isCustomer
          ? setProfileData({
              ...profileData,
              name: response_data.customer_profile.user_name,
              email: response_data.customer_profile.user_email,
              phone_number: response_data.customer_profile.user_mobile,
              profile_picture:
                "http://logistic.redbytes.in/" +
                response_data.customer_profile.user_profile_pic,
            })
          : setProfileData({
              ...profileData,
              company_name: response_data.company_profile.company_name,
              address: response_data.company_profile.address,
              profile_picture:
                "http://logistic.redbytes.in/" +
                response_data.company_profile.company_profile_pic,
            });
      }
    };
    fetchData();
  }, []);
  const handleUpdate = async (e) => {
    e.preventDefault();

    let { name, email, phone_number, profile_picture, company_name, address } =
      e.target;

    const data = new FormData();
    if (isCustomer) {
      data.append("name", name.value);
      data.append("email", email.value);
      data.append("phone_number", phone_number.value);
      // data.append("profile_picture", profile_picture.files[0]);
      data.append("profile_picture", profile_picture.files[0]);
      data.append("customer_id", customerData.customer_id);
      data.append("user_id", customerData.user_id);
      data.append("api_key", customerData.api_key);
    } else {
      data.append("name", company_name.value);
      data.append("address", address.value);
      data.append("customer_id", customerData.customer_id);
      data.append("user_id", customerData.user_id);
      data.append("api_key", customerData.api_key);
      data.append("profile_picture", profile_picture.files[0]);
    }

    const response = await fetch(
      isCustomer
        ? `${BASE_URL}customer/profile/edit`
        : `${BASE_URL}driver/profile/editcompany`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: data,
      }
    );
    const response_data = await response.json();
    console.log(response_data);
    if (response_data.result == true) {
      if (isCustomer) {
        localStorage.setItem(
          "user_pic",
          response_data.user_details.user_profile_pic
        );
        setCustomerData({
          ...customerData,
          user_pic: response_data.user_details.user_profile_pic,
        });
        navigate("/CustomerProfile");
      } else {
        swal({
          title: "success",
          text: "Profile updated successfully",
          icon: "success",
          button: true,
        }).then(() => {
          navigate("/CustomerProfile");
        });
      }
    } else {
      swal({
        title: "warning",
        text: "Something went wrong",
        icon: "warning",
        button: true,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "profile_picture") {
      setProfileData({
        ...profileData,
        [e.target.name]: e.target.files[0],
        isImageChange: true,
      });
      console.log(e.target.files[0]);
    } else setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };
  console.log(profileData.profile_picture, "profile");
  return (
    <div>
      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        <section id="cxap-rating">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="cxap-contact-outer">
                  {isCustomer ? (
                    <form
                      onSubmit={(e) => {
                        handleUpdate(e);
                      }}
                    >
                      <div className="cx-profile-pic">
                        <div className="cx-form-group">
                          <label htmlFor="cx-pro-cam">
                            <img
                              className="cx-pdefault"
                              src={
                                profileData.isImageChange
                                  ? URL.createObjectURL(
                                      profileData.profile_picture
                                    )
                                  : driver_profile_default
                              }
                            />
                            <img className="cx-camera" src={ic_camera} />
                          </label>
                          <input
                            type="file"
                            name="profile_picture"
                            className="form-control"
                            onChange={handleChange}
                            id="cx-pro-cam"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label id="cx-rfname">
                              Full Name<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              required
                              name="name"
                              value={profileData.name}
                              onChange={(e) => handleChange(e)}
                              className="form-control"
                              id="cx-rfname"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label id="cx-remail">
                              Email<sup>*</sup>
                            </label>
                            <input
                              type="email"
                              required
                              name="email"
                              value={profileData.email}
                              onChange={(e) => handleChange(e)}
                              className="form-control"
                              id="cx-remail"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label id="cx-rmno">
                              Mobile Number<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              required
                              name="phone_number"
                              value={profileData.phone_number}
                              onChange={(e) => handleChange(e)}
                              className="form-control"
                              id="cx-rmno"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cx-btn-grp">
                        <button
                          style={{ backgroundColor: "#ee651e", color: "white" }}
                          type="submit"
                          className="cx-reg-btn"
                        >
                          <span>Update</span>
                        </button>
                      </div>
                    </form>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        handleUpdate(e);
                      }}
                    >
                      <div className="cx-profile-pic">
                        <div className="cx-form-group">
                          <label htmlFor="cx-pro-cam">
                            <img
                              className="cx-pdefault"
                              src={
                                profileData.isImageChange
                                  ? URL.createObjectURL(
                                      profileData.profile_picture
                                    )
                                  : profileData.profile_picture
                              }
                            />
                            <img className="cx-camera" src={ic_camera} />
                          </label>
                          <input
                            type="file"
                            name="profile_picture"
                            className="form-control"
                            onChange={handleChange}
                            id="cx-pro-cam"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label id="cx-rfname">
                              Company Name<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              required
                              name="company_name"
                              value={profileData.company_name}
                              onChange={(e) => handleChange(e)}
                              className="form-control"
                              id="cx-rfname"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label id="cx-remail">
                              Company Address<sup>*</sup>
                            </label>
                            <input
                              type="text"
                              required
                              name="address"
                              value={profileData.address}
                              onChange={(e) => handleChange(e)}
                              className="form-control"
                              id="cx-remail"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="cx-btn-grp">
                        <button
                          style={{ backgroundColor: "#ee651e", color: "white" }}
                          type="submit"
                          className="cx-reg-btn"
                        >
                          <span>Update</span>
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default EditCustomerProfile;
