import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrow_white from "../assets/images/login/arrow_white.svg";
import eye_closed from "../assets/images/login/eye_closed.svg";
import eye_opened from "../assets/images/login/eye.svg";
import "../assets/css/cx-fpassword.css";
import { BASE_URL } from "../urls";
import { useAlert } from "react-alert";
import { positions } from "react-alert";
import LoadingBar from "react-top-loading-bar";
import { AppContext } from "../context/user/AppContext";
import ApiConfig from "../api/ApiConfig";

export default function UpdatePassword() {
 

  return (
    <div>
      <section id="cx-fpass-three">
        <LoadingBar
          color="navy"
        />
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex justify-content-center align-items-center " style={{height:"100vh"}}>
              <div className="cx-fpass-content">
                <h2>Forgot Password</h2>
                <form >
                  <div className="cx-form-group">
                    <label className="cx-fnpass">Enter New Password</label>
                    <input
                      name="new_password"
                      className="form-control"
                      id="cx-fnpass"
                      placeholder="Password"
                    />
                    <button type="button"   className="cx-text-btn cx-eye-btn">
                      <img
                        src={eye_closed }
                        className="cx-eye cx-show"
                        alt="view-password"
                      />
                    </button>
                  </div>
                  <div className="cx-form-group">
                    <label className="cx-fcpass">Confirm New Password</label>
                    <input
                     
                      name="confirm_password"
                      className="form-control"
                      id="cx-fcpass"
                      placeholder="Password"
                    />
                    <button type="button" className="cx-text-btn cx-eye-btn">
                      <img
                        src={eye_opened  }
                        className="cx-eye cx-show"
                        alt="view-password"
                      />
                    </button>
                  </div>
                  <div className="cx-btn-grp1">
                    <button type="submit" className="cx-fpass-btn">
                      <span>Continue</span>
                      <img src={arrow_white} />
                    </button>
                  </div>
                  <div className="cx-log">
                    <Link to="/Login">
                      <button type="button" className="cx-text-btn">
                        Go to login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
