import React, { useEffect, useState, useContext } from "react";
import Sidebar1 from "../Sidebar1";
import ic_general_notification from "../../assets/images/Notifications/ic_general_notification.svg";
import ic_menu_options from "../../assets/images/Notifications/ic_menu_options.svg";
import "../../assets/css/cx-notifications.css";
import SpinnerCmp from "../../SpinnerCmp";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import swal from "sweetalert";

export default function Notification(props) {
  let [loading, setLoading] = useState(false);
  let [state, setState] = useState({
    pageIndex: 1,
  });
  const [show, setShow] = React.useState({ id: "" });
  const { customerData ,sidebar} = useContext(AppContext);
  let [notifications, setNotifications] = useState(false);
  const loadNotifications = () => {
    setLoading(true);
    PostCallWithErrorResponse(localStorage.getItem('role')=="customer" ?  ApiConfig.CUSTOMER_NOTIFICATION_API : ApiConfig.COMPANY_NOTIFICATIONS, {
      ...customerData,
      ...{
        page: state.pageIndex,
      },
    })
      .then((data) => {
        setLoading(false);
        if (!data.error && data.json.result) {
          setNotifications(data.json.notifications);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const onDeleteNotification = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(localStorage.getItem('role')=="customer" ?  ApiConfig.NOTIFICATION_DELETE_API :ApiConfig.COMPANY_DELETE_NOTIFICATION, {
      ...customerData,
      ...{
        notification_ids: [id],
      },
    })
      .then((data) => {
        setLoading(false);
        if (!data.error && data.json.result) {
          swal({
            title: "Success",
            text: "notification deleted successfuly!",
            icon: "success",
            button: true,
          }).then(
            ()=>{
              loadNotifications()
            }
          )
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const setClick = (id) => {
    if (show.id === id) {
      setShow({ id: "" });
    } else {
      setShow({ id: id });
    }
  };
  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <div>
     
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"} id="cxMain">
        <section id="cxn-notifications">
          <div className="container">
            {loading ? (
              <div className="sweet-loading">
                <SpinnerCmp loading={loading} size={40} />
              </div>
            ) : (
              <div className="cxn-notification-content">
                {notifications && notifications.length > 0 ? (
                  notifications.map((notification, index) => {
                    return (
                      <div className="row mt-2">
                        <div className="col-lg-12">
                          <div className="cx-ncontent">
                            <img
                              src={ic_general_notification}
                              className="cxn-not-image"
                            />
                            <div className="cxn-not-details">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: notification.notification_text,
                                }}
                              />
                              <span>
                                {notification.notification_date +
                                  " " +
                                  notification.notification_time}
                              </span>
                            </div>

                            <div className="cx-btn-grp1">
                              <button
                                onClick={() =>
                                  setClick(notification.notification_id)
                                }
                                type="button"
                                className="cx-text-btn cxsl-options"
                              >
                                <img src={ic_menu_options} />

                                {show.id === notification.notification_id ? (
                                  <ul
                                    id={
                                      "notification_" +
                                      notification.notification_id
                                    }
                                    className={"cxsl-dropdown cx-active"}
                                  >
                                    <li>
                                      <a onClick={()=>{onDeleteNotification(notification.notification_id)}}>Delete</a>
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="row mt-1">
                    <div className="col-lg-12">
                      <div className="cx-ncontent">
                        There are no notifications
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
}
