import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import "font-awesome/css/font-awesome.min.css";
import jofar_logo from "../../../assets/images/Live/jofar_logo.svg";
import ic_notification from "../../../assets/images/Live/ic_notification.svg";
import profile_img from "../../../assets/images/Live/profile_img.png";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import ic_close_sload from "../../../assets/images/Live/ic_close_sload.svg";
import pickup_location from "../../../assets/images/Live/pickup_location.svg";
import route from "../../../assets/images/Live/route.svg";
import drop_location from "../../../assets/images/Live/drop_location.svg";
import ic_location_grey from "../../../assets/images/Live/ic_location_grey.svg";
import ic_arrow_next from "../../../assets/images/Live/ic_arrow_next.svg";
import ic_btn_arrow from "../../../assets/images/Live/ic_btn_arrow.svg";
import ic_tick_white from "../../../assets/images/Live/ic_tick_white.svg";
import "../../../assets/css/cx-sLoad.css";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import Map from "./Map";
import { AppContext } from "../../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { useNavigate } from "react-router-dom";

import { useAlert, positions } from "react-alert";
import swal from "sweetalert";

const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw";

const google = window.google;

export default function SendLoad() {
  const {
    start,
    setStart,
    setEnd,
    customerData,
    setfinalAddress,
    finalAddress,
    fromaddress,
    setfromAddress,
    toaddress,
    settoAddress,
    sidebar,
    setLoadDetails,
    setVehicleList,
    setvehicleType,
    vehiclenamestate,
    setvehiclenamestate,
    vehicletypestate,
    setvehicletypestate,
    vehicleCapacity,
    setvehicleCapacity,
    vehicleLength,
    setvehicleLength,
  } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showdestiny, setShowDestiny] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [DestinyIndex, setDestinationIndex] = useState(null);
  const [SourceIndex, setSourceIndex] = useState(null);
  const [pinend, setpinend] = useState(false);
  const [pinstart, setpinstart] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();

  const pinendChange = () => {
    if (pinend) {
      setpinend(false);
    } else {
      setpinend(true);
      setpinstart(false);
    }
  };

  const pinstartChange = () => {
    if (pinstart) {
      setpinstart(false);
    } else {
      setpinstart(true);
      setpinend(false);
    }
  };

  /* combined address logic */
  const CombineAddress = (e) => {
    if (!(fromaddress.from_address && toaddress.to_address)) {
      swal({
        title: "Warning",
        text: "Please select pickup and drop location!",
        icon: "warning",
        button: true,
      });
      return;
    }
    setvehicleLength([]);
    setvehicleCapacity([]);
    setvehicleType([]);
    setvehicletypestate([]);
    setvehiclenamestate([]);
    setLoadDetails({});
    setfinalAddress({
      ...fromaddress,
      ...toaddress,
      from_name: "",
      from_phone_no: "",
      to_name: "",
      to_phone_no: "",
    });
    setfromAddress({
      from_name: "",
      from_phone_no: "",
    });
    settoAddress({
      to_name: "",
      to_phone_no: "",
    });

    navigate("/LocationDetails");
  };

  /* combined address logic end  */
  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => console.log(place),
  });

  /* get saved location effect */
  const postCall = () => {
    PostCallWithErrorResponse(ApiConfig.GET_LOCATION_API_CUSTOMER, {
      ...customerData,
    })
      .then((data) => {
        console.log(data);
        if (data.error == false) {
          setFetchedData(data.json.address_list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    postCall();
    settoAddress({
      ...toaddress,
      to_address: "",
    });
    setfromAddress({
      ...fromaddress,
      from_address: "",
    });
  }, []);

  /* end saved location effect */
  const [state, setState] = React.useState(false);

  const onClick = (obj) => {
    console.log(obj);

    console.log(obj.address);
  };

  return (
    <div>
      {/*      <Header /> */}

      {/* <!-- MAIN STARTS HERE --> */}

      <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
        <section id="cx-map-container">
          <div id="cx-map">
            <div style={{ height: "100vh", width: "100%" }} id="map">
              <Map pinend={pinend} pinstart={pinstart} />
            </div>
          </div>

          <div className={`cx-slocation ${state ? "cx-open" : null}`}>
            <div className="cx-slocation-content">
              <div className="cx-slocaton-title">
                <h4>Send Load</h4>
                <div className="cx-btns-title">
                  <button
                    type="button"
                    onClick={(e) => CombineAddress(e)}
                    className="cx-slocation-btn"
                  >
                    <img src={next_arrow_white} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setState(!state);
                    }}
                    className="cx-sclose-btn"
                  >
                    <img src={ic_close_sload} />
                  </button>
                </div>
              </div>
              <form>
                <div
                  className="cx-form-group1"
                  style={{ marginBottom: "10px" }}
                >
                  <img
                    src={pickup_location}
                    className="cx-pickup"
                    alt="pickup-location"
                  />
                  <img src={route} className="cx-route" alt="pickup-location" />
                  {/* <input type="email" className="form-control" id="cx-pickup" placeholder="" /> */}
                  <div className="row">
                    <Autocomplete
                      className="form-control auto"
                      /* id="cx-pickup" */
                      value={fromaddress.from_address}
                      placeholder="Pickup location"
                      style={{ backgroundColor: "white", width: "80%" }}
                      onChange={(e) =>
                        setfromAddress({
                          ...fromaddress,
                          from_address: e.target.value,
                        })
                      }
                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                      onPlaceSelected={(place) => {
                        setStart({
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                        setfromAddress({
                          ...fromaddress,
                          from_address: place.formatted_address,
                          from_lat: place.geometry.location.lat(),
                          from_lon: place.geometry.location.lng(),
                        });
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                    />
                    {pinstart ? (
                      <a
                        onClick={() => pinstartChange()}
                        style={{ width: "20%" }}
                      >
                        {" "}
                        <i
                          style={{ color: "black", fontSize: "30px" }}
                          class="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>
                      </a>
                    ) : (
                      <a
                        onClick={() => pinstartChange()}
                        style={{ width: "20%" }}
                      >
                        {" "}
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          class="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>{" "}
                      </a>
                    )}
                  </div>

                  <input
                    onChange={() => pinstartChange()}
                    type="checkbox"
                    style={{ display: "none" }}
                    checked={pinstart}
                  />
                  <div className="cx-saved-loc-toggler">
                    <button type="button" onClick={() => setShow(!show)}>
                      Select from saved locations
                      <img src={ic_arrow_next} />
                    </button>
                  </div>
                </div>

                <div
                  className="cx-form-group1"
                  style={{
                    borderBottom: "1px solid rgba(255,255,255,.1)",
                    paddingBottom: "30px",
                    marginBottom: 0,
                  }}
                >
                  <img
                    src={drop_location}
                    className="cx-pickup"
                    alt="pickup-location"
                  />
                  <div className="row">
                    <Autocomplete
                      className="form-control auto"
                      /*      id="cx-pickup" */
                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                      value={toaddress.to_address}
                      style={{ backgroundColor: "white", width: "80%" }}
                      onChange={(e) =>
                        settoAddress({
                          ...toaddress,
                          to_address: e.target.value,
                        })
                      }
                      placeholder="Drop location"
                      onPlaceSelected={(place) => {
                        setEnd({
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                        });
                        settoAddress({
                          ...toaddress,
                          to_address: place.formatted_address,
                          to_lat: place.geometry.location.lat(),
                          to_lon: place.geometry.location.lng(),
                        });
                      }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                    />
                    {pinend ? (
                      <a
                        onClick={() => pinendChange()}
                        style={{ width: "20%" }}
                      >
                        {" "}
                        <i
                          style={{ color: "black", fontSize: "30px" }}
                          class="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>
                      </a>
                    ) : (
                      <a
                        onClick={() => pinendChange()}
                        style={{ width: "20%" }}
                      >
                        {" "}
                        <i
                          style={{ color: "white", fontSize: "30px" }}
                          class="fa fa-map-marker"
                          aria-hidden="true"
                        ></i>{" "}
                      </a>
                    )}
                  </div>

                  <input
                    onChange={() => {
                      pinendChange();
                    }}
                    type="checkbox"
                    style={{ display: "none" }}
                    checked={pinend}
                  />
                  <div className="cx-saved-loc-toggler">
                    <button type="button" onClick={() => setShowDestiny(!show)}>
                      Select from saved locations
                      <img src={ic_arrow_next} />
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div></div>

            {/*    <div className="cx-slocatoin-loc">
              <ul>
                <li>
                  <img src={ic_location_grey} alt="location-icon" />
                  <h5>Adelaide St</h5>
                  <p>Street name and address</p>
                </li>
                <li>
                  <img src={ic_location_grey} alt="location-icon" />
                  <h5>Adelaide St</h5>
                  <p>Street name and address</p>
                </li>
              </ul>
            </div> */}
          </div>

          <div className="cx-sendload-toggler">
            <button
              onClick={() => {
                setState(!state);
              }}
              type="button"
            >
              Send Load
              <img src={ic_btn_arrow} />
            </button>
          </div>
        </section>

        <section id="cx-saved-loc" className={show ? "cx-open" : ""}>
          <div className="cx-sloc-out">
            <div className="cx-filter"></div>
            <div className="cx-sloc-content">
              <div className="cx-sloc-header">
                <h4>Saved Source</h4>
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="cx-sloc-btn"
                >
                  <img src={ic_close_sload} alt="close-icon" />
                </button>
              </div>
              <ul className="cx-sloc-list" data-simplebar>
                {fetchedData
                  ? fetchedData.map((single) => {
                      return (
                        <li id={single.address_id}>
                          <label
                            className="form-check-label mx-3"
                            for="flexRadio3"
                          >
                            {single.address_name}{" "}
                            <div className="cx-radio">
                              <img src={ic_tick_white} />
                            </div>
                          </label>

                          <input
                            className="form-check-input"
                            onClick={() =>
                              setSourceIndex({
                                lat: parseFloat(single.address_latitude),
                                lng: parseFloat(single.address_longitude),
                                from_phone_no: single.address_phone_no,
                                from_name: single.address_name,
                                from_address: single.address_details,
                              })
                            }
                            type="radio"
                            name="flexRadio"
                            id={single.address_id + "source"}
                          />
                        </li>
                      );
                    })
                  : "No Address Found"}
              </ul>
              <div className="cx-btn-grp">
                <button
                  type="button"
                  onClick={() => {
                    setStart({ lat: SourceIndex.lat, lng: SourceIndex.lng });
                    setShow(false);
                    setfromAddress({
                      ...fromaddress,
                      from_phone_no: SourceIndex.from_phone_no,
                      from_name: SourceIndex.from_name,
                      from_address: SourceIndex.from_address,
                    });
                  }}
                  className="cx-con-btn"
                >
                  <span>Continue</span>
                  <img src={next_arrow_white} />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="cx-saved-loc" className={showdestiny ? "cx-open" : ""}>
          <div className="cx-sloc-out">
            <div className="cx-filter"></div>
            <div className="cx-sloc-content">
              <div className="cx-sloc-header">
                <h4>Saved Destiny</h4>
                <button
                  type="button"
                  onClick={() => setShowDestiny(!showdestiny)}
                  className="cx-sloc-btn"
                >
                  <img src={ic_close_sload} alt="close-icon" />
                </button>
              </div>
              <ul className="cx-sloc-list" data-simplebar>
                {fetchedData
                  ? fetchedData.map((single) => {
                      return (
                        <li id={single.address_id}>
                          <label
                            className="form-check-label mx-3"
                            for="flexRadio3"
                          >
                            {single.address_name}{" "}
                          </label>
                          <input
                            className="form-check-input"
                            type="radio"
                            onClick={() =>
                              setDestinationIndex({
                                lat: parseFloat(single.address_latitude),
                                lng: parseFloat(single.address_longitude),
                                to_phone_no: single.address_phone_no,
                                to_name: single.address_name,
                                to_address: single.address_details,
                              })
                            }
                            name="end"
                            id={single.address_id + "destination"}
                          />
                          <div className="cx-radio">
                            <img src={ic_tick_white} />
                          </div>
                        </li>
                      );
                    })
                  : "No Address Found"}
              </ul>
              <div className="cx-btn-grp">
                <button
                  type="button"
                  onClick={() => {
                    setEnd({ lat: DestinyIndex.lat, lng: DestinyIndex.lng });
                    setShowDestiny(false);
                    settoAddress({
                      ...toaddress,
                      to_phone_no: DestinyIndex.to_phone_no,
                      to_name: DestinyIndex.to_name,
                      to_address: DestinyIndex.to_address,
                    });
                  }}
                  className="cx-con-btn"
                >
                  <span>Continue</span>
                  <img src={next_arrow_white} />
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
