import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import vertical_dot from "../../../assets/images/vertical_dot.svg";
import profile_2 from "../../../assets/images/profile_2.svg";
import profile_1 from "../../../assets/images/profile_1.svg";
import { AppContext } from "../../../context/user/AppContext";
import RegisterAddDrivers from "./RegisterAddDrivers";

const RegisterDrivers = (props) => {
  const { CompanyUserData, setCompanyUserData } = useContext(AppContext);
  let drivers = CompanyUserData.drivers ? CompanyUserData.drivers : [];
  const [isAdd, setIsAdd] = useState(false);
  const [editData, setEditData] = useState({
    isEdit: false,
    data: null,
    index: -1,
  });
  // const [editIndex, setEditIndex] = useState(-1);
  // const [driverData, setDriverData] = useState(null);

  const onAddDriver = (driver) => {
    if (editData.index > -1) drivers[editData.index] = driver;
    else drivers.push(driver);
    setCompanyUserData({
      ...CompanyUserData,
      drivers: drivers,
    });
    setIsAdd(false);
  };
  const onRemove = (index) => {
    drivers.splice(index, 1);
    setCompanyUserData({
      ...CompanyUserData,
      drivers: drivers,
    });
  };
  const onEdit = (index) => {
    setEditData({ ...editData, data: drivers[index], index: index });
  };

  useEffect(() => {}, [CompanyUserData]);

  return (
    <>
      {isAdd ? (
        <RegisterAddDrivers
          isCallFrom="registration"
          isEdit={editData.isEdit}
          data={editData.data}
          onAdd={onAddDriver}
          onCancel={() => setIsAdd(false)}
        />
      ) : (
        <React.Fragment>
          <div className="right_block cx-reg-content">
            <div className="d-flex justify-content-between">
              <h2>Drivers</h2>
              <p className="step_num">3/5</p>
            </div>

            <div className="register_right mt-4 ">
              <div className="d-flex justify-content-end mb-4">
                <Link
                  to=""
                  onClick={props.nextCallback}
                  className="blue_link pt-2"
                >
                  Skip
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    setIsAdd(true);
                  }}
                  className="blue_btn ms-4"
                >
                  Add Drivers
                </Link>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {CompanyUserData.drivers &&
                  CompanyUserData.drivers.length > 0 ? (
                    CompanyUserData.drivers.map((driver, index) => {
                      return (
                        <div className="drivers_list d-flex justify-content-between">
                          <div className="itemside">
                            <div className="aside">
                              <img
                                src={driver.driver_profile_picture}
                                alt="driver_profile_picture"
                                className="header-profile-pic"
                              />
                            </div>
                            <div className="info align-self-center">
                              <p className="title">{driver.driver_name}</p>
                              <p className="smttitle">{driver.driver_email}</p>
                              <p className="smttitle">
                                {driver.driver_address}
                              </p>
                            </div>
                          </div>
                          <div className="btn-group drop_dot dropstart">
                            <a
                              href="#"
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={vertical_dot} alt="" />
                            </a>
                            <ul className="dropdown-menu">
                              {/* <li>
                                <button className="dropdown-item" type="button">
                                  Edit
                                </button>
                              </li> */}
                              <li>
                                <button
                                  onClick={() => onRemove(index)}
                                  className="dropdown-item"
                                  type="button"
                                >
                                  Remove
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="drivers_list d-flex justify-content-between">
                      <div className="itemside">
                        <p className="title">Records not found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rfbtn_block">
            <div className="d-flex justify-content-between">
              <button onClick={props.prevCallback} className="orange_btn">
                Prev
              </button>
              <button onClick={props.nextCallback} className="orange_btn">
                Next
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default RegisterDrivers;
