import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import RegistrationWizard from "./pages/company/Register/RegistrationWizard";
import "./assets/css/style-main.css";
import "./assets/css/style_new.scss";
import UploadDocuments from "./pages/company/Register/UploadDocuments";
import RegisterDrivers from "./pages/company/Register/RegisterDrivers";
import RegisterAddDrivers from "./pages/company/Register/RegisterAddDrivers";
import AddVehicles from "./pages/company/Register/AddVehicles";
import RegisterVehicles from "./pages/company/Register/RegisterVehicles";
import AddBankAccount from "./pages/company/Register/AddBankAccount";
import Dashboard from "./pages/company/Dashboard";
import { createContext, useContext, useState, useEffect } from "react";
import Loads from "./pages/customer/Loads";
import Landing from "./pages/Common_Pages/Landing";
import SendLoad from "./pages/customer/Sent-Load/SendLoad";
import LoadDetail from "./pages/customer/Sent-Load/LoadDetail";
import BookingSummary from "./pages/customer/Sent-Load/BookingSummary";
import PaymentMethod from "./pages/customer/Sent-Load/PaymentMethod";
import Login from "./pages/Common_Pages/Login";
import "../src/assets/css/style-main.css";
import LocationDetails from "./pages/customer/Sent-Load/LocationDetails";
import LiveTracking from "./pages/company/LiveTracking/LiveTracking";
import LiveTrackingDrivers from "./pages/company/LiveTracking/LiveTrackingDrivers";
import LiveTrackingPackages from "./pages/company/LiveTracking/LiveTrackingPackages";
import Drivers from "./pages/company/LiveTracking/Drivers";
import PackageDetails from "./pages/company/LiveTracking/PackageDetails";
import PackegeDetailsCompleted from "./pages/company/LiveTracking/PackegeDetailsCompleted";
import DriverListing from "./pages/company/Driver/DriverListing";
import DriverDetails from "./pages/company/Driver/DriverDetails";
import AddDriver from "./pages/company/Driver/AddDriver";
import VehicleListning from "./pages/company/Vehicles/VehicleListning";
import VehicleDetails from "./pages/company/Vehicles/VehicleDetails";
import { AddVehicle } from "./pages/company/Vehicles/AddVehicle";
import EarningStats from "./pages/company/EarningStats";
import VehicleAssignDrivers from "./pages/company/Vehicles/VehicleAssignDrivers";
import ForgotPassword from "./pages/Common_Pages/ForgotPassword";
import BankAccount from "./pages/company/BankAccount";
import CustomerProfile from "./sharedComponent/Accounts/CustomerProfile";
import AddPaymentMethod from "./sharedComponent/Accounts/AddPaymentMethod";
import PaymentHistory from "./sharedComponent/Accounts/PaymentHistory";
// import SaveLocation from "./sharedComponent/Accounts/SaveLocation";
import ContactUs from "./sharedComponent/Accounts/ContactUs";
import PrivacyPolicy from "./sharedComponent/Accounts/PrivacyPolicy";
import TelrPrivacyPolicy from "./sharedComponent/Accounts/TelrPrivacyPolicy";
import AboutUs from "./sharedComponent/Accounts/AboutUs";
import Notification from "./sharedComponent/Accounts/Notification";
import AddLocation from "./pages/customer/AddLocation";
import Register from "./pages/Common_Pages/Register";
import AddPayment from "./pages/customer/Sent-Load/AddPayment";
import TermsConditions from "./sharedComponent/Accounts/TermsConditions";
import Rating from "./sharedComponent/Accounts/Rating";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { AppContext } from "./context/user/AppContext";
import SimpleMap from "./SimpleMap";
import PackageDetailsCustomer from "./pages/customer/Tracking/PackageDetailsCustomer";
import LiveTrackingCustomer from "./pages/customer/Tracking/LiveTrackingCustomer";
import EditCustomerProfile from "./sharedComponent/Accounts/EditCustomerProfile";
import CustomerDriverDetail from "./pages/customer/Tracking/CustomerDriverDetail";
import Sidebar1 from "../src/sharedComponent/Sidebar1";
import Header from "./sharedComponent/Header";
import AssignDriver from "./pages/company/CompanyLoads/AssignDriver";
import { geolocated } from "react-geolocated";
import CheckStaus from "./pages/customer/PaymentStatus/CheckStatus";

// new updated
import Home from "./pages/Customer_New/Home"; // a
import LoadLocationDetails from "./pages/customer/Sent-Load/LoadLocationDetails";
import LocationDetailsSelect from "./pages/customer/Sent-Load/LocationDetailsSelect";
import PickupForm from "./pages/customer/PickupForm";
import DropLocationForm from "./pages/customer/DropLocationForm";
import DropLocationMap from "./pages/customer/DropLocationMap";
import LoadPackageDetails from "./pages/customer/Sent-Load/LoadPackageDetails";
import LoadDetailsForm from "./pages/customer/Sent-Load/LoadDetailsForm";
import LoadBookingSummary from "./pages/customer/Sent-Load/LoadBookingSummary";
import Offers from "./pages/customer/Sent-Load/Offers";

import OngoingPackageStatus from "./pages/customer/OngoingPackageStatus";
import OverAllStatus from "./pages/Customer_New/OverAllStatus";
import LoadDetails from "./pages/Customer_New/LoadDetails";
import UpdatePassword from "./sharedComponent/UpdatePassword";
import OTP from "./pages/Common_Pages/OTP";
import VehicleInfo from "./pages/Customer_New/SendLoad/VehicleInfo";
import AddLocations from "./pages/Customer_New/SendLoad/AddLocations";
import AddLocations1 from "./pages/Customer_New/SendLoad/AddLocations1";
import AddLocations2 from "./pages/Customer_New/SendLoad/AddLocations2";
import PickupLocationMap from "./pages/Customer_New/SendLoad/PickupLocationMap ";
import SavedLocationList from "./pages/Customer_New/SendLoad/SavedLocationList";
import SendLoadPackageDetails from "./pages/Customer_New/SendLoad/SendLoadPackageDetails";
import SendLoadAddPackages from "./pages/Customer_New/SendLoad/SendLoadAddPackages";
import SendLoadBookingSummary from "./pages/Customer_New/SendLoad/SendLoadBookingSummary";
import MapDetailsongoing from "./pages/Customer_New/LiveTracking/MapDetailsongoing";
import OngoingLiveTrackingMap from "./pages/Customer_New/LiveTracking/OngoingLiveTrackingMap";
import MapDetailsCompleted from "./pages/Customer_New/LiveTracking/MapDetailsCompleted";
import LiveTrackingLoadDetails from "./pages/Customer_New/LiveTracking/LiveTrackingLoadDetails";
import SaveLocation from "./pages/Customer_New/LiveTracking/SaveLocation";
import LoadDetailsCompleted from "./pages/Customer_New/LoadsDetailsCompleted";
import LoadServiceRequest from "./pages/Customer_New/Loads/LoadServiceRequest";
import SendLoadPaymentMethod from "./pages/Customer_New/SendLoad/SendLoadPaymentMethod";
import ServiceRequestedLoadsEditModifyLoad from "./pages/Customer_New/Loads/ServiceRequestedLoadsEditModifyLoad";
import Profile from "./pages/Customer_New/Profile";
import Tutorial from "./pages/Customer_New/Tutorial";
import RateReview from "./pages/Customer_New/Rate&Review";
import MapOverAllStatus from "./pages/Customer_New/MapOverAllStatus";
import AllongoingLoadMap from "./pages/Customer_New/LiveTracking/AllongoingLoadMap";
import EditPackage from "./pages/Customer_New/SendLoad/EditPackage";
import AddSaveLocations from "./pages/Customer_New/SendLoad/AddSaveLocations";
import AddSavedLocation from "./pages/Customer_New/SendLoad/AddSavedLocaton";
import CompanyLoadServiceRequest from "./pages/company/CompanyLoads/CompanyLoadServiceRequest";
import LoadDetailsDriver from "./pages/company/CompanyLoads/LoadDetailsDriver";
import LoadDetailsOnging from "./pages/company/CompanyLoads/LoadDetailsOngoing";
import OngoingDriver from "./pages/company/CompanyLoads/OngoingDriver";
import ForgotPassNew from "./sharedComponent/ForgetPassNew";
import UpdatePass from "./sharedComponent/UpdatePass";
import LiveTrackingDriversLoad from "./pages/company/LiveTracking/LiveTrackingDriversLoad";
import Privacy from "./pages/Common_Pages/Privacy";
import TermsPolicy from "./pages/Common_Pages/Terms";
function App() {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const {
    customerData,
    setisCustomer,
    isLoggedin,
    isCustomer,
    setisLoggedin,
    setCustomerData,
  } = useContext(AppContext);
  // console.log(isCustomer);

  useEffect(() => {
    if (
      localStorage.getItem("api_key") &&
      localStorage.getItem("customer_id") &&
      localStorage.getItem("user_id")
    ) {
    }
    if (localStorage.getItem("role") == "company") {
      setisCustomer(false);
    } else {
      setisCustomer(true);
    }

    if (isLoggedin) {
      if (isCustomer) {
        window.location.pathname === "/Login"
          ? navigate("/loads")
          : navigate(window.location.pathname);
      } else if (!isCustomer) {
        navigate("/dashboard");
      }
    } else {
      if (window.location.pathname === "/") navigate("/");
      else navigate("/Login");
    }
  }, [isLoggedin]);
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 10000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
  };

  return (
    <>
      {location != "/" &&
        location != "/" &&
        location != "/Login" &&
        location != "/OTP" &&
        location != "/PhoneVarification" &&
        location != "/ForgotPassword" &&
        location != "/SetNewPassword" &&
        location != "/UpdatePassword" &&
        location != "/Register" &&
        location != "/RegistrationWizard" &&
        location != "/Privacy" &&
        location != "/termspolicy" &&
        location != "/ProfileSetUp" && (
          <>
            <Header />
            <Sidebar1 />
          </>
        )}

      <Routes>
        {/* =============================== > NEW ROUTE < =========================*/}

        <Route path="/" element={<Landing />} />
        <Route path="Login" element={<Login />} />
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ForgotPassNew" element={<ForgotPassNew />} />
        <Route path="UpdatePassword" element={<UpdatePassword />} />
        <Route path="UpdatePass" element={<UpdatePass />} />
        {/* <Route path="Register" element={<Register />} /> */}
        <Route
          path="Register"
          element={isCustomer ? <Register /> : <RegistrationWizard />}
        />
        <Route
          path="CompanyLoadServiceRequest"
          element={<CompanyLoadServiceRequest />}
        />

        <Route path="home" element={<Home />} />
        <Route path="termspolicy" element={<TermsPolicy />} />
        <Route path="VehicleInfo" element={<VehicleInfo />} />
        <Route path="AddLocations" element={<AddLocations />} />
        {/* <Route path="AddLocations/:id" element={<AddLocations />} /> */}
        <Route path="AddLocations1" element={<AddLocations1 />} />
        <Route path="AddLocations2" element={<AddLocations2 />} />
        <Route path="AddSaveLocations/:id" element={<AddSaveLocations />} />
        <Route path="AddSavedLocation" element={<AddSavedLocation />} />
        <Route path="PickupLocationMap" element={<PickupLocationMap />} />
        <Route path="SavedLocationList" element={<SavedLocationList />} />
        <Route exact path="DriverDetails/:id" element={<DriverDetails />} />
        <Route exact path="Tutorial" element={<Tutorial />} />
        <Route exact path="RateReview" element={<RateReview />} />
        <Route exact path="SaveLocation" element={<SaveLocation />} />
        <Route exact path="EditPackage/:id" element={<EditPackage />} />

        <Route
          path="SendLoadPaymentMethod"
          element={<SendLoadPaymentMethod />}
        />
        <Route
          path="ServiceRequestedLoadsEditModifyLoad"
          element={<ServiceRequestedLoadsEditModifyLoad />}
        />
        <Route
          path="SendLoadPackageDetails"
          element={<SendLoadPackageDetails />}
        />
        <Route path="SendLoadAddPackages" element={<SendLoadAddPackages />} />
        <Route
          path="SendLoadBookingSummary"
          element={<SendLoadBookingSummary />}
        />

        <Route path="AllongoingLoadMap" element={<AllongoingLoadMap />} />
        <Route path="Privacy" element={<Privacy />} />
        <Route path="MapDetailsongoing/:id" element={<MapDetailsongoing />} />
        <Route path="OngoingDriver/:id" element={<OngoingDriver />} />
        <Route
          path="OngoingLiveTrackingMap/:id"
          element={<OngoingLiveTrackingMap />}
        />
        <Route path="MapDetailsCompleted" element={<MapDetailsCompleted />} />
        <Route path="Profile" element={<Profile />} />
        <Route
          path="LiveTrackingLoadDetails"
          element={<LiveTrackingLoadDetails />}
        />
        <Route path="LoadDetailsDriver/:id" element={<LoadDetailsDriver />} />
        <Route path="LoadDetailsOnging/:id" element={<LoadDetailsOnging />} />
        <Route
          path="LiveTrackingLoadDetails/:id"
          element={<LiveTrackingLoadDetails />}
        />
        <Route path="LoadDetailsCompleted" element={<LoadDetailsCompleted />} />

        {/* { For Loads Routing start} */}
        <Route path="/LoadServiceRequest" element={<LoadServiceRequest />} />

        {/* { For Loads Routing end} */}

        {/* =============================== > NEW ROUTE < =========================*/}

        <Route exact path="uploaddocuments" element={<UploadDocuments />} />
        <Route exact path="registerdrivers" element={<RegisterDrivers />} />
        <Route
          exact
          path="registeraddDrivers"
          element={<RegisterAddDrivers />}
        />
        <Route exact path="registervehicles" element={<RegisterVehicles />} />
        <Route path="addvehicles/:id" element={<AddVehicles />} />
        <Route exact path="addbankaccount" element={<AddBankAccount />} />
        <Route exact path="Dashboard" element={<Dashboard />} />

        <Route path="/" element={"neither"} />

        <Route path="RegistrationWizard" element={<RegistrationWizard />} />

        {/* <Route path="OTP" element={<OTP />} /> */}

        <Route exact path="UploadDocuments" element={<UploadDocuments />} />
        <Route exact path="registerdrivers" element={<RegisterDrivers />} />
        <Route
          exact
          path="registeraddDrivers"
          element={<RegisterAddDrivers />}
        />
        <Route exact path="registervehicles" element={<RegisterVehicles />} />
        <Route exact path="AddBankAccount" element={<AddBankAccount />} />
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route
          exact
          path="liveTrackingDrivers"
          element={<LiveTrackingDrivers />}
        />
        <Route
          exact
          path="liveTrackingDriversLoad"
          element={<LiveTrackingDriversLoad />}
        />
        <Route
          exact
          path="liveTrackingPackages"
          element={<LiveTrackingPackages />}
        />
        <Route
          exact
          path="liveTracking"
          caseSensitive
          element={<LiveTracking />}
        />
        <Route exact path="drivers" element={<Drivers />} />
        <Route
          exact
          path="packagedetails/:id"
          caseSensitive
          element={<PackageDetailsCustomer />}
        />
        <Route
          exact
          path="packagedetailscompleted"
          element={<PackegeDetailsCompleted />}
        />
        <Route exact path="DriverListning" element={<DriverListing />} />
        {/* <Route exact path="AddDriver" element={<AddDriver />} /> */}
        <Route exact path="AddDriver" element={<RegisterAddDrivers />} />
        <Route exact path="VehicleListning" element={<VehicleListning />} />
        <Route exact path="AddVehicle" element={<AddVehicle />} />
        <Route exact path="VehicleDetails" element={<VehicleDetails />} />
        <Route exact path="EarningStats" element={<EarningStats />} />
        <Route exact path="AssignDriver" element={<AssignDriver />} />
        <Route exact path="Offers" element={<Offers />} />

        <Route
          exact
          path="OngoingPackageStatus"
          element={<OngoingPackageStatus />}
        />
        <Route exact path="OverAllStatus" element={<OverAllStatus />} />
        <Route exact path="OverAllStatus/:id" element={<OverAllStatus />} />
        <Route
          exact
          path="MapOverAllStatus/:id"
          element={<MapOverAllStatus />}
        />

        <Route
          exact
          path="vehicleAssignDrivers"
          element={<VehicleAssignDrivers />}
        />

        {/* { For Customer Routing Start} */}

        {/* <Route path="/" element={<Landing />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/" element={<SendLoad />} /> */}

        <Route path="/CheckStatus" element={<CheckStaus />} />
        <Route path="/EditCustomerProfile" element={<EditCustomerProfile />} />

        {/* <Route path="/SendLoad" element={<SendLoad />} /> */}
        <Route path="/DropLocationMap" element={<DropLocationMap />} />
        <Route path="/LocationDetails" element={<LocationDetails />} />
        <Route
          path="/LocationDetailsSelect"
          element={<LocationDetailsSelect />}
        />
        <Route path="/LoadDetailsForm" element={<LoadDetailsForm />} />
        <Route path="/LoadPackageDetails" element={<LoadPackageDetails />} />
        <Route path="/LoadDetail" element={<LoadDetail />} />
        <Route path="/BookingSummary" element={<BookingSummary />} />
        <Route path="/LoadBookingSummary" element={<LoadBookingSummary />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route
          path="/LiveTracking/:id"
          caseSensitive
          element={<LiveTrackingCustomer />}
        />
        <Route path="/PackageDetails" element={<PackageDetails />} />
        <Route
          path="/CustomerDriverDetail/:id"
          element={<CustomerDriverDetail />}
        />

        <Route path="/AddLocation" element={<AddLocation />} />
        <Route path="/AddPayment" element={<AddPayment />} />

        {/* Accounts */}
        <Route path="/CustomerProfile" element={<CustomerProfile />} />
        <Route path="/AddPaymentMethod" element={<AddPaymentMethod />} />
        <Route path="/PaymentHistory" element={<PaymentHistory />} />
        <Route path="/SaveLocation" element={<SaveLocation />} />
        <Route path="/LoadLocationDetails" element={<LoadLocationDetails />} />
        <Route path="/PickupForm" element={<PickupForm />} />
        <Route path="/DropLocationForm" element={<DropLocationForm />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Terms" element={<TermsConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TelrPrivacyPolicy" element={<TelrPrivacyPolicy />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/Rating/:id" element={<Rating />} />
        <Route path="/Rating" element={<Rating />} />

        {/* { For Customer Routing End} */}

        {/* <Route path={"/loads"} element={<Loads />} /> */}
        <Route path="OTP" element={<OTP />} />

        <Route exact path="registervehicles" element={<RegisterVehicles />} />
        <Route exact path="AddBankAccount" element={<AddBankAccount />} />
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route
          exact
          path="liveTrackingDrivers"
          element={<LiveTrackingDrivers />}
        />
        <Route
          exact
          path="liveTrackingPackages"
          element={<LiveTrackingPackages />}
        />
        <Route exact path="liveTracking" element={<LiveTracking />} />
        <Route exact path="drivers" element={<Drivers />} />
        <Route exact path="packagedetails" element={<PackageDetails />} />
        <Route
          exact
          path="packagedetailscompleted"
          element={<PackegeDetailsCompleted />}
        />
        <Route exact path="DriverListning" element={<DriverListing />} />
        <Route exact path="AddDriver" element={<AddDriver />} />
        <Route exact path="VehicleListning" element={<VehicleListning />} />
        <Route exact path="AddVehicle" element={<AddVehicle />} />
        <Route exact path="VehicleDetails/:id" element={<VehicleDetails />} />
        <Route exact path="EarningStats" element={<EarningStats />} />
        <Route
          exact
          path="vehicleAssignDrivers"
          element={<VehicleAssignDrivers />}
        />
        <Route exact path="bankaccount" element={<BankAccount />} />
        <Route path="simplemap" element={<SimpleMap />}></Route>

        {/* company routing end */}
        {/* <Route path="/" element={<CustomerHeader />} /> */}
        <Route exact path="LoadDetails" element={<LoadDetails />} />
      </Routes>
    </>
  );
}

export default App;
