import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/user/AppContext";
import Map from "../../customer/Sent-Load/Map";
import pack1 from "../../../assets/images/package_1.svg";
import whatsapp from "../../../assets/images/whatsapp.svg";
import star from "../../../assets/images/star_highlighted.svg";
import call from "../../../assets/images/call.svg";
import next_arrow_white from "../../../assets/images/Live/next_arrow_white.svg";
import g_dot from "../../../assets/images/from_loc_red.svg";
import b_dot from "../../../assets/images/from_loc_red (1).svg";
import "../../../assets/css/cx-sLoad.css";
import "../../../assets/css/mapDetailsOngoing.scss";
import location from "../../../assets/images/location (2).svg";
import location2 from "../../../assets/images/location (2) (1).svg";

export default function MapDetailsCompleted() {
  const { sidebar } = useContext(AppContext);
  const [pinend, setpinend] = useState(false);
  const [pinstart, setpinstart] = useState(false);
  const [state, setState] = React.useState(false);

  return (
    <main
      className={sidebar ? "cx-main cx-active" : "cx-main"}
      id="cxMain"
      style={{ padding: "0", position: "relative" }}
    >
      <section id="cxs-locations">
        <div className="PickupLocationMap-main">
          <div style={{ height: "100vh" }} id="map">
            <Map pinend={pinend} pinstart={pinstart}></Map>
          </div>
          <button
            class="canvas-btn-right"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <img src={next_arrow_white} alt="" />
          </button>
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-body">
              <button
                class="canvas-btn"
                type="button"
                tabIndex="-1"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <img src={next_arrow_white} alt="" />
              </button>
              <div className="location-card position-relative">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="m-0">
                      Delivery No.
                      <span>45654656567</span>
                    </p>
                    <p className="m-0">
                      Current Location
                      <span>Queen St</span>
                    </p>
                  </div>
                  <img src={next_arrow_white} alt="" />
                </div>
                <p className="m-0">
                  Status
                  <span
                    style={{
                      color: "#17c238",
                    }}
                  >
                    completed
                  </span>
                </p>
                <div className="d-flex justify-content-between mt-3">
                  <h5>&#8377;1200</h5>
                  <p className="location-status">View Status</p>
                </div>
              </div>
              <div className="col-lg-12 col-10">
                <div className="package-details">
                  <span className="package-summary">
                    PICKUP / DELIVERING TO
                  </span>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img className="me-1" src={g_dot} alt="" />
                      <h6 className="m-0 mt-2">Michael Clarke</h6>
                    </div>
                    <img src={call} alt="" />
                  </div>

                  <p className="m-0">
                    No.125/2, Kalas Area, Visharantwadi,
                    <br />
                    Pune - 411015.
                  </p>
                  <p className="m-0" style={{ fontWeight: "bold" }}>
                    9232434123
                  </p>
                  <p className="ongoing-delivered">Delivered</p>
                  <hr
                    className="mt-0"
                    style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                  />
                  <div className="ongoing-packages">
                    <p>PACKAGES</p>
                    <div className="d-flex justify-content-between package-item">
                      <div>
                        <p className="m-0">
                          My Package{" "}
                          <span
                            style={{
                              color: "#ee651e",
                            }}
                          >
                            [Drop]
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="m-0">
                          Qty <span>3</span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between package-item mt-2">
                      <div>
                        <p className="m-0">
                          My Package 02{" "}
                          <span
                            style={{
                              color: "#ee651e",
                            }}
                          >
                            [Pickup]
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="m-0">
                          Qty <span>1</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr
                    className="mt-3 p-0"
                    style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                  />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img className="me-1" src={g_dot} alt="" />
                      <h6 className="m-0 mt-2">Michael Clarke</h6>
                    </div>
                    <img src={call} alt="" />
                  </div>

                  <p className="m-0">
                    No.125/2, Kalas Area, Visharantwadi,
                    <br />
                    Pune - 411015.
                  </p>
                  <p className="m-0" style={{ fontWeight: "bold" }}>
                    9232434123
                  </p>
                  <p className="ongoing-delivered">Delivered</p>

                  <hr
                    className="mt-0"
                    style={{ color: "#D0D0D0", height: "2px", width: "100%" }}
                  />
                  <div className="ongoing-packages">
                    <p>PACKAGES</p>
                    <div className="d-flex justify-content-between package-item">
                      <div>
                        <p className="m-0">
                          My Package{" "}
                          <span
                            style={{
                              color: "#ee651e",
                            }}
                          >
                            [Drop]
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="m-0">
                          Qty <span>3</span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between package-item mt-2">
                      <div>
                        <p className="m-0">
                          My Package 02{" "}
                          <span
                            style={{
                              color: "#ee651e",
                            }}
                          >
                            [Pickup]
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="m-0">
                          Qty <span>1</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="driver-details">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <img
                        className="img-fluid driver-img me-2"
                        src={pack1}
                        alt=""
                      />
                      <div>
                        <p className="driver-name m-0 mt-2">My Package</p>
                        <div>
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <img className="star" src={star} alt="" />
                          <span className="rating">(320)</span>
                        </div>
                      </div>
                    </div>

                    <img className="call-img mt-2" src={call} alt="" />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 mt-1 driver-view-details">View Details</p>
                    <img src={whatsapp} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="location-img">
            <img src={location} alt="" />
          </div>
          <div className="location-img2">
            <img src={location} alt="" />
          </div>
          <div className="location-img3">
            <img src={location2} alt="" />
          </div>
        </div>
      </section>
    </main>
  );
}
