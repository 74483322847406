import React from "react";
import "../../assets/css/tutorial.scss";
import ratingprofile from "../../assets/images/Send Steps/rate_driver_image.png";
import h_star from "../../assets/images/star_highlighted.svg";
import arrow_red from "../../assets/images/arrow_red.svg";

export default function Tutorial() {
  return (
    <main className="cx-main p-0 load-details-main" id="cxMain">
      <section id="cxp-details">
        <div className="container">
          <div className="col-lg-4 col-md-8 col-sm-12 Rating-card m-auto">
            <div className="text-center">
              <img src={ratingprofile} alt />
              <div>
                <h6 className="m-0 mt-3">Albie Morkel</h6>
                <p className="m-0">Brisbane, Australia</p>
                <div className="pt-3">
                  <img src={h_star} alt="" />
                  <img src={h_star} alt="" />
                  <img src={h_star} alt="" />
                  <img src={h_star} alt="" />
                  <img src={h_star} alt="" />
                </div>
                <p className="m-0 pt-3">GOOD EXPERIENCE</p>
              </div>
              <div class="form-floating mt-3">
                <textarea
                  class="form-control"
                  placeholder="Type your review"
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                />
                <label for="floatingTextarea2">Type your review</label>
              </div>
              <div>
                <button
                  type="button"
                  class=" col-12 review-btn p-2 text-start mt-3"
                >
                  Rate
                  <img
                    style={{ float: "right", marginTop: "5px" }}
                    src={arrow_red}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
