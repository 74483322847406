import React, { useContext, useEffect, useState } from 'react'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg'
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import ic_call from '../../../assets/images/Live/ic_call.svg'
import profile_2 from '../../../assets/images/profile_2.svg'
import profile_1 from '../../../assets/images/profile_1.svg'

import ic_whatsapp from '../../../assets/images/Live/ic_whatsapp.svg'
import LiveTrackingPackages from './LiveTrackingPackages'
import profile_img from '../../../assets/images/Live/profile_img.png'

import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'

import close_icon from '../../../assets/images/close_icon.svg'
import delivery_box_inactive from '../../../assets/images/delivery_box_inactive.svg'
import driver_list_active from '../../../assets/images/driver_list_active.svg'
import delivery_box_active from '../../../assets/images/delivery_box_active.svg'
import driver_list_inactive from '../../../assets/images/driver_list_inactive.svg'
import Drivers from './Drivers'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import ApiConfig, { URL } from '../../../api/ApiConfig'
import { AppContext } from '../../../context/user/AppContext'
import DriverMap from './DriverMap'
import SpinnerCmp from '../../../SpinnerCmp'

const LiveTrackingDriversLoad = () => {
  const [place, setPlace] = useState({
    center: { lat: 19.8762, lng: 75.3433 },
  })
  const { customerData, sidebar } = useContext(AppContext)
  const [active, setActive] = useState('loads')
  const [show, setShow] = useState(false)
  const [start, setStart] = useState({ lat: 40.756795, lng: -73.954298 })
  const [end, setEnd] = useState({ lat: 40.756795, lng: -73.954298 })
  const [currentlocation, setCurrentLocation] = useState(null)
  const [loadLocation, setloadLocation] = useState(null)
  const [packagestatus, setPackageStatus] = useState(false)
  const [data, setData] = useState([])
  const [loadmap, setloadmap] = useState(false)
  const [loading, setLoading] = useState(true)
  const getRequestedDataDetails = () => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'company'
        ? ApiConfig.DRIVER_LIST_API_COMPANY
        : '',
      customerData,
    )
      .then((data) => {
        console.log(data.json)
        setData(data.json.driver_list)
        setLoading(false)
      })
      .catch((error) => {
        console.log('api response', error)
        setData([])
      })
  }
  const getLoadDataDetails = (id) => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'company'
        ? ApiConfig.DRIVER_MAP_API_COMPANY
        : '',
      { ...customerData, driver_id: id },
    )
      .then((data) => {
        console.log(data.json)
        setloadLocation(data.json)
        setPackageStatus(false)
        setloadmap(false)
      })
      .catch((error) => {
        console.log('api response', error)
        setData([])
      })
  }
  const getPackageDataDetails = () => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'company'
        ? ApiConfig.ALL_LOAD_STATUS_API_COMPANY
        : '',
      { ...customerData },
    )
      .then((data) => {
        console.log(data.json)
        setPackageStatus(true)
        setloadLocation(data.json)
        setShow(false)
        setloadmap(false)
        setLoading(false)
      })
      .catch((error) => {
        console.log('api response', error)
        setPackageStatus(false)
        /*    setData([]); */
      })
  }

  const handlePackage = () => {
    getPackageDataDetails()
  }
  useEffect(() => {
    getPackageDataDetails()
    getRequestedDataDetails()
  }, [])

  const handleClick = (id) => {
    setloadmap(true)
    getLoadDataDetails(id)
  }

  return (
    <React.Fragment>
      <main
        className={sidebar ? 'cx-main cx-active p-0' : 'cx-main p-0'}
        id="cxMain"
      >
        {loading ? (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        ) : (
          <section id="cxs-live">
            <div id="cxl-map">
              {loadLocation && loadmap == false ? (
                // <DriverMap
                //   start={start}
                //   end={end}
                //   loadLocation={loadLocation.load_list}
                //   currentlocation={currentlocation}
                //   page={packagestatus ? 'package' : ''}
                // />
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw',
                  }}
                  center={place.center}
                  defaultZoom={7}
                >
                  {/* {mapDetalis &&
                    mapDetalis.length &&
                    mapDetalis.map((mapDetalisAll, index) => {
                      return ( */}
                  <div lat={19.8762} lng={75.3433}>
                    <img src={ic_whatsapp} alt="" />
                  </div>
                  {/* )
                    })} */}
                </GoogleMapReact>
              ) : (
                <div className="sweet-loading">
                  <SpinnerCmp loading={loadmap} size={40} />
                </div>
              )}
            </div>
            <div
              class={
                show
                  ? 'offcanvas  offcanvas-end ViewDrivers show'
                  : 'offcanvas  offcanvas-end ViewDrivers'
              }
              style={
                show ? { visibility: 'visible ' } : { visibility: 'hidden' }
              }
              tabindex="-1"
              id="ViewDrivers"
              data-bs-backdrop="false"
              aria-labelledby="ViewDriversLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="ViewDriversLabel">
                  Drivers
                </h5>
                <button
                  type="button"
                  onClick={() => setShow(false)}
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                {data
                  ? data.map((single) => {
                      return (
                        <div class="cxl-lpdetails" id={single.user_id}>
                          <img
                            src={
                              single.user_profile_pic
                                ? URL + single.user_profile_pic
                                : profile_img
                            }
                          />
                          <ul>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => handleClick(single.user_id)}
                              >
                                {' '}
                                <h5>{single.user_name}</h5>
                              </a>
                            </li>
                            <li class="mb-3">
                              <div className="cxl-rating">
                                {single.rating > 0 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 1 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 2 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 3 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}

                                {single.rating > 4 ? (
                                  <img src={ic_star_highlighted} />
                                ) : (
                                  <img src={ic_star_grey} />
                                )}
                                <span>({single.reviewed_count})</span>
                              </div>
                              {/* <a href="#" onClick={(e) => handleClick(single.user_id)} class="blue_link">
                                View Details
                              </a> */}
                            </li>
                            <li>
                              <button type="button" class="cx-text-btn">
                                <img src={ic_call} />
                              </button>
                              <button type="button" class="cx-text-btn">
                                <img src={ic_whatsapp} />
                              </button>
                            </li>
                          </ul>
                        </div>
                      )
                    })
                  : ''}
              </div>
            </div>
            {loadLocation && packagestatus == false && loadmap == false ? (
              <div className="cx-live-track-driver">
                <div className="card card-style">
                  <div className="card-body">
                    <div className="drivers_list d-flex justify-content-between border-0 p-0">
                      <div className="itemside">
                        <div className="aside">
                          <img
                            className="header-profile-pic"
                            src={
                              loadLocation.driver_details.user_profile_pic
                                ? URL +
                                  loadLocation.driver_details.user_profile_pic
                                : profile_1
                            }
                          />
                        </div>
                        <div className="info align-self-center">
                          <p className="title">
                            {loadLocation.driver_details.user_name}
                          </p>
                          {/*     <p className="smttitle">
                              Current Location{" "}
                              <span className="catvehcile">Queen St</span>
                            </p> */}
                          <p className="smttitle mb-3">
                            Delivering{' '}
                            <span className="catvehcile">
                              {loadLocation.load_count} loads
                            </span>
                          </p>
                          <Link
                            to={
                              '/DriverDetails/' +
                              loadLocation.driver_details.user_id
                            }
                            className="blue_link"
                          >
                            View Driver Details
                          </Link>
                        </div>
                      </div>
                      {/*   <div className="btn-group drop_dot dropstart">
                      <a href="#">
                        <img src={close_icon} alt="" />
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div
              className={
                show ? 'drivers_toggle active' : 'drivers_toggle active '
              }
            >
              <div style={{ marginBottom: '51px' }}>
                <Link
                  to="#"
                  onClick={() => {
                    handlePackage()
                    setActive('loads')
                  }}
                  className={
                    active == 'loads' ? 'driver_panel active' : 'driver_panel'
                  }
                >
                  {active == 'loads' ? (
                    <img src={delivery_box_active} alt="" />
                  ) : (
                    <img src={delivery_box_inactive} alt="" />
                  )}
                </Link>
              </div>
              <div>
                <a
                  onClick={() => {
                    setShow(!show)
                    setActive('driver')
                  }}
                  className={
                    active == 'driver' ? 'driver_panel active' : 'driver_panel'
                  }
                  role="button"
                  aria-controls="ViewDriversLabel"
                >
                  {active == 'driver' ? (
                    <img src={driver_list_active} alt="" />
                  ) : (
                    <img src={driver_list_inactive} alt="" />
                  )}
                </a>
              </div>
            </div>
          </section>
        )}
      </main>
    </React.Fragment>
  )
}

export default LiveTrackingDriversLoad
