import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import { Link, useNavigate } from "react-router-dom";
import ic_package_upload from "../../../assets/images/Send Steps/ic_package_upload.svg";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_add_blue from "../../../assets/images/Send Steps/ic_add_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import ic_edit_blue from "../../../assets/images/Send Steps/ic_edit_blue.svg";
import booking_package_image from "../../../assets/images/Send Steps/booking_package_image.png";
import ic_tick_white from "../../../assets/images/Live/ic_tick_white.svg";
import { AppContext } from "../../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";
import SpinnerCmp from "../../../SpinnerCmp";

export default function PaymentMethod() {
  const {
    loadDetails,
    setLoadDetails,
    customerData,
    setEnd,
    setStart,
    setfinalAddress,
    settoAddress,
    setfromAddress,
    sidebar,
    vehicleType,
    setvehicleType,
    vehicleCapacity,setvehicleCapacity,
    vehicleLength,setvehicleLength,
    vehiclenamestate, setvehiclenamestate,
    vehicletypestate,setvehicletypestate,
    vehiclecapacitystate,setvehiclecapacitystate,
    vehiclelengthstate,setvehiclelengthstate,
  } = useContext(AppContext);
  const [bookingDetails, setBookingDetails] = useState(
    loadDetails.bookingSummary
  );
  const [cards, setCards] = useState([]);
  let [loading, setLoading] = useState(false);
  let [selectedCardId, setselectedCardId] = useState(0);
  const navigate = useNavigate();

  const loadCards = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.CREDIT_CARD_LIST_API, customerData)
      .then((data) => {
        setLoading(false);
        if (!data.error && data.json.result) {
          console.log(" credit_card_listdata ::: ", data);
          setCards(data.json.credit_card_list);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  useEffect(() => {
  if(Object.keys(loadDetails).length === 0) navigate("/SendLoad");
    loadCards();
  }, []);

  const onBookLoad = () => {
    
    if (!selectedCardId && selectedCardId === 0) {
      swal({
        title: "Warning",
        text: "Please select card for billing",
        icon: "warning",
        button: true,
      });
      return;
    }
    setLoading(true);
    bookingDetails.credit_card_id = selectedCardId;
    PostCallWithErrorResponse(ApiConfig.BOOK_LOAD_API, {
      ...customerData,
      ...bookingDetails,
    })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.error == false) {
          swal({
            title: "Success",
            text: "Load booked successfuly!",
            icon: "success",
            button: true,
          }).then(() => {
            setLoadDetails({});
            setEnd({});
            setStart({});
            settoAddress({});
            setfromAddress({});
            setfinalAddress({
              from_address: "",
              from_lat: "",
              from_lon: "",
              from_name: "",
              from_phone_no: "",
              to_address: "",
              to_lat: "",
              to_lon: "",
              to_name: "",
              to_phone_no: "",
            });
           
            setvehicleType(null)
           setvehicleCapacity(null)
           setvehicleLength(null)
            setvehiclenamestate(null)
          setvehicletypestate(null)
           setvehiclecapacitystate(null)
           setvehiclelengthstate(null)
          window.location.href='/Loads' 
        //  navigate("/Loads");
          });
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const handleAddCreditCard=(id)=>{

  
    PostCallWithErrorResponse(ApiConfig.ADD_VERIFY_CREADIT_CARD_API, customerData)
      .then((data) => {
        console.log(" My Object :: ",data);
        if (data.error == false) {
          // setIsAddCreditCard(true);
          localStorage.setItem("referenceNo",data.json.reference_no);
          window.open(data.json.url, "_blank")
          // setpaymentGetwayObj(data.json)
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  
  
  }
  return (
    <div>
   

      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxs-payment">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="cxs-content-outer d-flex justify-content-center ">
                  {loading ? (
                    <div className="sweet-loading">
                      <SpinnerCmp loading={loading} size={40} />
                    </div>
                  ) : (
                    <div className="cxs-content-inner">
                      <div className="cxs-loc-content">
                        <div className="cxs-pcontent-title">
                          <h4>Saved Cards</h4>
                           {/* <Link to="/AddPayment" state={{ cbUri: "/PaymentMethod" }}> */}
                            {" "}
                            <button type="button" className="cx-text-btn" onClick={handleAddCreditCard}>
                              <img src={ic_add_blue} />
                              ADD NEW CREDIT CARD
                            </button>
                          {/*</Link>*/}
                        </div>
                        <ul className="cxs-card-list" data-simplebar>
                          {cards && cards.length > 0
                            ? cards.map((card, index) => {
                                return (
                                  <li>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="cardRadio"
                                        id={"cardRadio_" + index}
                                        onClick={() => {
                                          setselectedCardId(
                                            card.credit_card_id
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        for={"cardRadio_" + index}
                                      >
                                        <div className="cxs-card-details">
                                          <h4>
                                         {" "}
                                            {card.credit_card_number.replace(
                                              /\d(?=\d{4})/g,
                                              "*"
                                            )}
                                          </h4>
                                          <p>{card.credit_card_name}</p>
                                          <p>
                                            Expires{" "}
                                            {card.credit_card_expiry_date}
                                          </p>
                                        </div>
                                        <div className="cxs-radio">
                                          <img src={ic_tick_white} />
                                        </div>
                                      </label>
                                    </div>
                                  </li>
                                );
                              })
                            : ""}
                        </ul>
                      </div>
                      <div className="cxs-nav">
                        <Link to="/BookingSummary">
                          <button type="button" className="cx-text-btn">
                            <img src={ic_back_arrow_blue} />
                            <span>Back</span>
                          </button>
                        </Link>
                        {/* <Link to="/">  */}
                        <button
                          onClick={onBookLoad}
                          type="button"
                          className="cx-text-btn"
                        >
                          <span>Book Load </span>
                          <img src={ic_continue_arrow_blue} />
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
