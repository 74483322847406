import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import details_bg from "../../../assets/images/Details/details_bg.png";
import driver_profile from "../../../assets/images/Details/driver_profile.png";
import drop_off_image from "../../../assets/images/Details/drop_off_image.png";
import ic_profile_edit from "../../../assets/images/Details/ic_profile_edit.png";
import load_image from "../../../assets/images/Details/load_image.png";
import package_image_01 from "../../../assets/images/Details/package_image_01.png";
import package_image_02 from "../../../assets/images/Details/package_image_02.png";
import package_image_03 from "../../../assets/images/Details/package_image_03.png";
import pan_id from "../../../assets/images/Details/pan_id.png";
import signature from "../../../assets/images/Details/signature.png";
import "../../../assets/css/cx-package-details.css";
import { AppContext } from "../../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import SpinnerCmp from "../../../SpinnerCmp";
import ApiConfig from "../../../api/ApiConfig";
import { useParams } from "react-router-dom";
import axios from "axios";
// import   '../../../assets/css/cx-driver-details.css'
const URL = "https://logistic.redbytes.in/";
export default function PackageDetailsCustomer() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  
  const { customerData, setLoading, loading, isCustomer,sidebar } =
    useContext(AppContext);

  const getPackageDataDetails = () => {
    PostCallWithErrorResponse(
      isCustomer
        ? ApiConfig.DETAILS_LOADDATA_CUSTOMER
        : ApiConfig.DETAILS_LOADDATA_COMPANY,

      { ...customerData, load_id: id }
    )
      .then((data) => {
        console.log(data.json);
        console.log(data.json.load_details);
        setData(data.json.load_details);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData(null);
      });
  };

  function downloads(url, filename) {
    
    fetch(url, {
      headers: {
        Origin: window.location.origin
      },
      // body: JSON.stringify({...customerData,...requestBody})
      
    })
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
  }  
  const downloadImage = (url) => {
    try {

      var element = document.createElement("a");
      var file = new Blob(
        [
          url
        ],
        { type: "image/*" }
      );
      element.href =window.URL.createObjectURL(file);
      element.download = "image.jpg";
      element.click(); 
    } catch (error) {
      console.log(error);
    }
  }

  const downloadNew = e => {
    console.log(e.target.href);
    fetch(e.target.href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    
    setLoading(true);
    getPackageDataDetails();
    //const basurl="https://logistic.redbytes.in/";
    //axios.defaults.headers.get['Content-Type'] ='application/x-www-form-urlencoded';
   // axios.get(`${basurl}uploads/deliveryproof/80dropimage20220225171658.jpg`).then((response) => {
    //alert(response.data);
    //});
   // download();
    //downloads("http://localhost:3000/static/media/jofar_brand_logo.273b7f286656b3a3cf9656ba4609b268.svg", "imagexy.svg");
  }, []);


 
  const downloadFile = (file) => {

    const blob = new Blob(
      [ file ],
      { type: 'image/jpeg' }
    );
    const href =  window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = 'your file name' + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  const download = () => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
      ],
      { type: "image/*" }
    );
    element.href = window.URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  }

  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        {data ? (
          <section id="cxp-details">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="cxp-bdetails-content"
                    style={{ marginTop: 0 }}
                  >
                    <div className="cxp-details-banner"></div>
                    <div className="cxp-details">
                      <div className="cxp-load-image">
                        <img
                          style={{ borderRadius: "50%", margin: "-5px" }}
                          src={URL + data.trip_image}
                        />
                        <div className="cxp-load-graphic"></div>
                        <div className="cxp-load-name">
                          <label>Package Name</label>
                          <span>{data.trip_name}</span>
                        </div>
                      </div>
                      <ul className="cxp-details-list">
                        <li>
                          <label>Material Type</label>
                          <p>{data.trip_material_type}</p>
                        </li>
                        <li>
                          <label>Weight</label>
                          <p>{data.trip_weight} kg</p>
                        </li>
                        <li>
                          <label>Vehicle</label>
                          <p>{data.vehicle_type}, 3 Ton</p>
                        </li>
                        <li>
                          <label>Distance</label>
                          <p>{data.trip_distance}km</p>
                        </li>
                        <li>
                          <label>Delivery Cost</label>
                          <p>{data.delivery_cost}</p>
                        </li>
                      </ul>
                      <div className="cxp-dcondition">
                        <label>Delivery Conditions</label>
                        <p>{data.trip_delivery_condition}.</p>
                      </div>
                      <div className="cxp-images">
                        <label>Package Images</label>
                        <ul>
                          {data.trip_images
                            ? data.trip_images.map((single) => {
                                return (
                                  <li>
                                    <div className="uploaded_items">
                                      <div className="item_list mt-1">
                                        {" "}
                                        <img
                                          className="load_img cxp-gallery"
                                          src={URL + single.trip_image_url}
                                        ></img>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            : "Not found"}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cxp-details-content" style={{height:"95%"}}>
                    <div className="cxp-content-title">
                      <h4>From</h4>
                    </div>
                    <div className="cxp-content-details">
                      <ul>
                        <li>
                          <h4>{data.from_name}</h4>
                        </li>
                        <li>
                          <label>{data.trip_start_address}</label>
                        </li>
                        <li>
                          <label>Pune - 411015.</label>
                        </li>
                        <li>
                          <a href="#">{data.trip_start_phone_number}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" >
                  <div className="cxp-details-content" style={{height:"95%"}}>
                    <div className="cxp-content-title">
                      <h4>To</h4>
                    </div>
                    <div className="cxp-content-details" >
                      <ul>
                        <li>
                          <h4>{data.to_name}</h4>
                        </li>
                        <li>
                          <label>{data.trip_end_address}</label>
                        </li>
                        {/*           <li><label>Pune - 411015.</label></li> */}
                        <li>
                          <a href="#">{data.trip_end_phone_number}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {data.delivered_by ? (
                  <div className="col-lg-12">
                    <div className="cxp-details-content">
                      <div className="cxp-content-title">
                        <h4>Delivery Status</h4>
                      </div>
                      <div className="cxp-content-details">
                        <ul className="cxp-details-list cxpc-details-list">
                          <li>
                            <label>Delivered By</label>
                            <p>{data.delivered_by}</p>
                          </li>
                          <li>
                            <label>Delivery Cost</label>
                            <p>{data.delivery_cost}</p>
                          </li>
                          <li>
                            <label>Delivered On</label>
                            <p>{data.delivery_date_time}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {data.trip_status == "completed" ? (
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="cxp-details-content">
                        <div className="cxp-content-title cxpc-content-title">
                          
                          <h4>Drop Off Image</h4>
                          <a style={{cursor:"pointer"}}
                         
                            onClick= {() =>  downloads((data.delivery_details.drop_image
                              ? URL + data.delivery_details.drop_image
                              : drop_off_image),"image.jpg")}
                            
                            className="cx-text-btn"
                          >
                            Download 
                          </a>
                          
                        </div>
                        <img
                          className="cxpc-doc"
                          src={
                            data.delivery_details.drop_image
                              ? URL + data.delivery_details.drop_image
                              : drop_off_image
                          }
                          alt="drop-off-image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cxp-details-content">
                        <div className="cxp-content-title cxpc-content-title">
                          <h4>Recipient Signature</h4>
                          <a style={{cursor:"pointer"}}
                            download
                            
                            onClick= {() =>  downloads((data.delivery_details.signature
                              ? URL + data.delivery_details.signature
                              : signature),"image.jpg")}
                            className="cx-text-btn"
                          >
                            Download 
                          </a>
                        </div>
                        <img
                          className="cxpc-doc"
                          src={
                            data.delivery_details.signature
                              ? URL + data.delivery_details.signature
                              : signature
                          }
                          alt="drop-off-image"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cxp-details-content cxpc-details-content">
                        <div className="cxp-content-title cxpc-content-title">
                          <h4>Recipient ID Proof</h4>
                          <a style={{cursor:"pointer"}}
                            download
                            
                            onClick= {() =>  downloads((data.delivery_details.drop_image
                              ? URL + data.delivery_details.id_proof_image
                              : pan_id),"image.jpg")}
                            className="cx-text-btn"
                          >
                            Download 
                          </a>
                          {/* <button type="button" className="cx-text-btn">
                            Download PDF
                          </button> */}
                        </div>
                        {/*<h4>PAN</h4>*/}
                        <img
                          className="cxpc-doc"
                          src={
                            data.delivery_details.id_proof_image
                              ? URL + data.delivery_details.id_proof_image
                              : pan_id
                          }
                          alt="drop-off-image"
                        />
                        <div className="cxpc-doc-no">
                          <label>PAN Number</label>
                          <span>
                            {data.delivery_details.id_proof_no
                              ? data.delivery_details.id_proof_no
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        ) : (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        )}
      </main>
    </div>
  );
}
