import React, { cloneElement, useContext, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { AppContext } from "../../../context/user/AppContext";
import vehicle from '../../../assets/images/Live/map_marker.svg'
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw";
const google = window.google;

const AnyReactComponent = ({ text ,id}) => <div>{text}</div>;
const Newcomponent = ({ text ,id}) => (
  <div >
     

      <ReactTooltip id="registerTip" place="top" effect="solid">


{
  text==="assigned" ? <b>Drive is on the way to pickup the load</b> : text
}
       

      </ReactTooltip>
      <Link to={"/LiveTracking/" +id }>
      <img  data-tip data-for="registerTip"  style={{width: "88px",height: "93px", border: "0px", padding: "0px", margin: "0px", maxWidth:"none",color:"red",transform: "translate(-50%, -50%)"}} src={vehicle}></img>

      </Link>
  </div>
);

const directionsService = new google.maps.DirectionsService();
const directionsRenderer = new google.maps.DirectionsRenderer();

function DriverMap({start,end,currentlocation,loadLocation,page}) {
  console.log("currentlocation",currentlocation)
  const {
    googleApiObj,
    setIsGoogleApiLoadedObj,
    newmap,
    setNewmap,
  } = useContext(AppContext);

  const apiIsLoaded = (map, maps) => {


      setIsGoogleApiLoadedObj(map);
    



    directionsRenderer.setDirections({ routes: [] });

    directionsRenderer.setMap(map);
    const origin = start;
    const destination = end;

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          /* console.log(result.geocoded_waypoints[(0, 1)]); */
         /*  result.geocoded_waypoints = result.geocoded_waypoints[(0, 1)]; */
          console.log("result ::", result);
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

/*   useEffect(() => {
    apiIsLoaded(googleApiObj, googleApiObj);
  }, [start, end]);
 */
  const onClick = (obj) => {
    console.log(obj);

    console.log(obj.address);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw",
      }}
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={{lat:19.8761653, lng: 75.3433139}}
      defaultZoom={5}
      onClick={onClick}
   /*    onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)} */
      
    >
      {/* <AnyReactComponent
     lat={start.lat}
     lng={start.lng}
     text="My Marker"
   /> */}
   {
       loadLocation ? 
       loadLocation.map((single)=>{
           //setcenter()
           return (
    page !="package"?       single.latitude ?  <Newcomponent style={{backgroundColor:"white"}}  lat={single.latitude} id={single.load_id} lng={single.longitude} text={single.status} />
:"" : single.trip_end_latitude ?  <Newcomponent style={{backgroundColor:"white"}} lat={ single.trip_end_latitude} id={single.trip_id} lng={ single.trip_end_longitude} text={single.trip_status} />
:""
 
           )
       })
:""
   }
    </GoogleMapReact>
  );
}

export default DriverMap;
