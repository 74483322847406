import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import dots from "../../../assets/images/Loads/dots.svg";

import "../../../assets/css/cx-sload-steps.css";
import { AppContext } from "../../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";

const SavedLocationList = () => {
  const { sidebar, customerData, loadId } = useContext(AppContext);
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  console.log("addressList", addressList);
  const DeletehandleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  let [loading, setLoading] = useState(true);
  let [empty, setEmpty] = useState(false);

  const getAddressList = () => {
    PostCallWithErrorResponse(ApiConfig.GET_LOCATION_API_CUSTOMER, {
      ...customerData,
    })
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          const data1 = data.json.address_list;
          setAddressList(data1);
          setLoading(false);
        }
        if (data.json.result == false) {
          setAddressList([]);
          setEmpty(true);
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };
  const deleteAddress = (id) => {
    PostCallWithErrorResponse(ApiConfig.DELETE_ADDRESS_CUSTOMER, {
      ...customerData,
      address_id: id,
    })
      .then((data) => {
        console.log(data);
        getAddressList();
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getAddressList();
  }, []);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Saved Locations</div>
                    <h6 className="right">
                      {addressList && addressList.length} Saved Address
                    </h6>
                  </div>
                  <div
                    className="cxs-loc-content"
                    style={{
                      height: "64vh",
                      overflow: "hidden",
                    }}
                  >
                    <div className="cxs-from">
                      <form>
                        <div className="location-add-card-main">
                          {/* <div className="hr-line"></div> */}
                          <div className="saved-location">
                            <h4>Saved Locations</h4>
                            {addressList
                              ? addressList.map((address_list, index) => {
                                  return (
                                    <>
                                      {/* <Link
                                        state={address_list}
                                        // to="/create_property"
                                        to={
                                          "/AddSaveLocations/" +
                                          address_list.address_id
                                        }
                                      > */}
                                      <div key={"address_list" + index}>
                                        <div className="d-flex justify-content-between">
                                          <div className="name">
                                            {address_list.address_name}
                                          </div>
                                          <div>
                                            <div class="btn-group dropstart">
                                              <img
                                                src={dots}
                                                alt=""
                                                class=" dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{ cursor: "pointer" }}
                                              />

                                              <ul
                                                class="dropdown-menu"
                                                id="service-request-menu"
                                              >
                                                <li
                                                  className="mt-2"
                                                  onClick={() => {
                                                    deleteAddress(
                                                      address_list.address_id
                                                    );
                                                  }}
                                                >
                                                  <Link to="#">Delete</Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="address">
                                          {address_list.address_details}
                                        </div>
                                      </div>
                                      <div className="hr-line-1"></div>
                                      {/* </Link> */}
                                    </>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    {/* <Link to="/AddLocations">
                      <button type="button" className="cx-text-btn">
                        <span id="cancel-location">Cancel</span>
                      </button>
                    </Link> */}
                    <Link to="/AddSavedLocation">
                      <button type="button" className="cx-text-btn">
                        {loading ? (
                          <div
                            class="spinner-border"
                            style={{
                              color: "#EE651E",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        ) : (
                          <>
                            <span>Add Location</span>

                            <img src={ic_continue_arrow_blue} />
                          </>
                        )}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default SavedLocationList;
