import React, { useContext, useState } from "react";
// import jofar_logo from "../../assets/images/jofar_logo_copy.svg";
import jofar_logo from "../../assets/images/logo_header.svg";

import about_us_image from "../../assets/images/landing/about_us_image.png";
import app_store from "../../assets/images/landing/app_store.png";
import contact_image from "../../assets/images/landing/contact_image.png";
import google_play from "../../assets/images/landing/google_play.png";
import ic_call from "../../assets/images/landing/ic_call.svg";
import ic_mail from "../../assets/images/landing/ic_mail.svg";
import { Link } from "react-router-dom";
import "../../assets/css/cx-landing.css";
import ApiConfig, { URL } from "../../api/ApiConfig";
import swal from "sweetalert";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/user/AppContext";
import visa from "../../assets/images/Visa_Logo.png";
import mastercard from "../../assets/images/mastercard.png";
import union from "../../assets/images/UnionPay_logo.svg.png";

function Landing() {
  const [state, setState] = useState({ name: "", email: "", message: "" });
  const { isCustomer, setisCustomer } = useContext(AppContext);
  const contact = (e) => {
    e.preventDefault();
    PostCallWithErrorResponse(ApiConfig.CONTACT_LANDING, {
      user_name: state.name,
      user_email: state.email,
      text: state.message,
    })
      .then((data) => {
        console.log(data.json);

        swal({
          title: "Success",
          text: data.json.message,
          icon: "success",
          button: true,
        });
        setState({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <div>
      <header>
        {/*    style={{height:"100px"}} */}
        <nav className="navbar navbar-expand-lg cxz-navbar fixed-top">
          <div className="container">
            <div className=""></div>
            <a className="navbar-brand" href="#">
              <img className="header-img-land" src={jofar_logo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="bx bx-menu"></i>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 cxz-navbar-list">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#cxz-about">
                    About Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#cxz-contact">
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.telr.com/legal/privacy-policy"
                  >
                    Telr Policy
                  </a>
                </li>

                <li className="nav-item">
                  <div class="dropdown " style={{ marginTop: "6px" }}>
                    <a
                      class=" dropdown-toggle "
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      SignUp
                    </a>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link
                          to="/Register"
                          style={{ paddingLeft: 10 }}
                          onClick={() => setisCustomer(true)}
                          state={{ val: "customer" }}
                          className="nav-link"
                        >
                          Customer
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Register"
                          style={{ paddingLeft: 10 }}
                          onClick={() => setisCustomer(false)}
                          state={{ val: "company" }}
                          className="nav-link"
                        >
                          Company
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/Register"
                          style={{ paddingLeft: 10 }}
                          onClick={() => setisCustomer(false)}
                          state={{ val: "Individual" }}
                          className="nav-link"
                        >
                          Individual
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <div class="dropdown" style={{ marginTop: "6px" }}>
                    <a
                      class=" dropdown-toggle "
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Signin
                    </a>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link
                          to="/Login"
                          style={{ paddingLeft: 10 }}
                          state={{ val: "customer" }}
                          className="nav-link"
                        >
                          Customer
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Login"
                          style={{ paddingLeft: 10 }}
                          state={{ val: "company" }}
                          className="nav-link"
                        >
                          Company
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to="/Login"
                          style={{ paddingLeft: 10 }}
                          state={{ val: "individual" }}
                          className="nav-link"
                        >
                          individual
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main>
        <section id="cxz-hero">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="cxz-banner-content">
                  <h2>COURIER ON THE MOVE</h2>
                  <p>
                    Connecting with channel partners to create an effective
                    transportation network.
                  </p>
                  <div className="cx-btn-grp">
                    <Link to="/Login">
                      <button type="button" className="cxz-banner-btn">
                        {" "}
                        Get Started{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cxz-about">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="cxz-about-content">
                  <h2>About Us</h2>
                  <p>
                    Developing a cost-effective transportation network for
                    transporting goods. We are Jofar. We're a digital firm that
                    fills in the gaps in freight transportation. To transport
                    things at the touch of a button, we connect the physical and
                    digital worlds. We believe in a world where goods can be
                    transported with a single touch of a button. We envision a
                    world in which people can work whenever and wherever they
                    want. At Jofar, we allow you the freedom to roam and earn as
                    you like. We aren't always right, but we aren't frightened
                    of failing because it will help us grow. The idea of Jofar
                    was born in Covid Lockdown, during which we were unable to
                    move, and has been redesigned and reinvented since then. The
                    search for reinterpretations in Jofar never completes, never
                    ends.
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="cxz-about-content cxz-img">
                  <img src={about_us_image} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cxz-download">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="cxz-download-content">
                  <div className="cxz-dbtns">
                    <img src={app_store} alt="" />
                    <img src={google_play} alt="" />
                  </div>
                  <p>Download from Google Play and App Store.</p>
                </div>
                <div className="cxz-download-content mt-4">
                  <div className="cxz-dbtns">
                    <img src={visa} alt="" width="100px" />
                    <img src={mastercard} alt="" width="100px" />
                    <img src={union} alt="" width="100px" />
                  </div>
                  {/* <p>Download from Google Play and App Store.</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cxz-contact">
          <div className="container">
            <div className="cxz-contact-content">
              <h2>We love to hear from you</h2>
              <p>
                Please call or email contact form and we will be happy to assist
                you.
              </p>
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="cxz-contact-icontent  cxz-img-graphic d-flex justify-content-center">
                    <img src={contact_image} alt="" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li className="p-2">
                      <label>Name Of Company : </label> <span>JOFAR FZCO</span>
                    </li>
                    <li className="p-2">
                      <label>E-mail address : </label>{" "}
                      <span>support@jofar.com</span>
                    </li>
                    <li className="p-2">
                      <label>Phone Numbers : </label> <span>+97148053900</span>
                    </li>
                    <li className="p-2">
                      <label>P.O Box : </label> <span>182113</span>
                    </li>
                    <li className="p-2">
                      <label>
                        Complete Office Address(Office No./Warehouse/Shop,
                        etc)Area and City :{" "}
                      </label>{" "}
                      <span>
                        Dubai Silicon Oasis, DDP, Building A2, Dubai, United
                        Arab Emirates
                      </span>
                    </li>
                    <li className="p-2">
                      <label>Country</label> <span>UAE</span>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-8 m-auto">
                  <div className="cxz-contact-icontent">
                    <ul>
                      <li>
                        <img src={ic_call} alt="" />{" "}
                        <span>support@jofar.com</span>
                      </li>
                      <li>
                        <img src={ic_mail} alt="" /> <span>+97148053900</span>
                      </li>
                    </ul>
                    <form onSubmit={contact}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label for="cfname" className="form-label">
                              Full Name
                            </label>
                            <input
                              required
                              value={state.name}
                              onChange={(e) =>
                                setState({ ...state, name: e.target.value })
                              }
                              type="text"
                              className="form-control"
                              id="cfname"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="cx-form-group">
                            <label for="cemail" className="form-label">
                              Email
                            </label>
                            <input
                              required
                              value={state.email}
                              onChange={(e) =>
                                setState({ ...state, email: e.target.value })
                              }
                              type="text"
                              className="form-control"
                              id="cemail"
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="cx-form-group">
                            <label for="cmsg" className="form-label">
                              Message
                            </label>
                            <textarea
                              required
                              value={state.message}
                              onChange={(e) =>
                                setState({ ...state, message: e.target.value })
                              }
                              className="form-control"
                              type="cmsg"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="cx-btn-grp1">
                            <button type="submit" className="cx-btn">
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cxz-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cxz-footer-content">
                  <img src={jofar_logo} alt="" />
                  <p>
                    Connecting with channel partners to create an effective
                    transportation network.
                  </p>
                  <ul>
                    <li>
                      <Link to="/Privacy">PRIVACY POLICY</Link>
                    </li>
                    <li>
                      <Link to="/termspolicy">TERMS & CONDITIONS</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="cxz-footer-copyright">
            <span>© Copyright 2022. All Rights Reserved.</span>
          </div>
        </section>
      </main>
    </div>
  );
}
export default Landing;
