import React, { useContext, useEffect, useState } from "react";
import add_white from "../../assets/images/add_white.svg";
import profile_1 from "../../assets/images/profile_1.svg";
import vertical_dot from "../../assets/images/vertical_dot.svg";
import profile_2 from "../../assets/images/profile_2.svg";
import SpinnerCmp from "../../SpinnerCmp";
import { Link } from "react-router-dom";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import swal from "sweetalert";
import AddBankAccount from "./Register/AddBankAccount";

const base_url = "http://logistic.redbytes.in/";
const BankAccount = () => {
  const { customerData,sidebar } = useContext(AppContext);
  let [loading, setLoading] = useState(false);
  let [editData, setEditData] = useState({
    isShow: false,
    isEdit: false,
    data: {},
    isCallFrom: "BankAccount",
  });
  const [accountList, setAccountList] = useState([]);
  const getAccountList = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_BANK_ACCOUNTS_API, customerData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          setAccountList(data.json.account_list);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };
  const onEditBankAccount = (id) => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.COMPANY_BANK_ACCOUNTS_API, {
      ...customerData,
      driver_id: id,
    })
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false && data.json.result) {
          swal({
            title: "Success",
            text: data.json.message,
            icon: "success",
            button: true,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getAccountList();
  }, [editData]);

  return (
    <React.Fragment>
      {editData.isShow ? (
        <AddBankAccount
          isCallFrom={editData.isCallFrom}
          isEdit={editData.isEdit}
          data={editData.data}
          closeCallback={() => setEditData({ ...editData, isShow: false })}
          successCallBack={() => setEditData({ ...editData, isShow: false })}
        />
      ) : (
        <main className={sidebar ?  "cx-main cx-active p-0" :"cx-main p-0"} id="cxMain">
          {loading ? (
            <div className="sweet-loading">
              <SpinnerCmp loading={loading} size={40} />
            </div>
          ) : (
            <section id="cxl-locations">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service_loads d-flex justify-content-between">
                    <h4 className="title">Accounts [{accountList.length}]</h4>
                    <button
                      onClick={() =>
                        setEditData({
                          ...editData,
                          isEdit: false,
                          isShow: true,
                        })
                      }
                      className="blue_btn ms-4"
                    >
                      <img src={add_white} alt="" /> ADD ACCOUNT
                    </button>
                  </div>
                </div>

                {accountList && accountList.length > 0 ? (
                  accountList.map((account, index) => {
                    return (
                      <div className="col-sm-6">
                        <div className="service_load_block">
                          <div className="card card-style">
                            <div className="card-body">
                              <div className="drivers_list d-flex justify-content-between border-0 p-0">
                                <div className="itemside">
                                  <div className="info align-self-center">
                                    <p className="title">
                                      {account.bank_account_name}
                                    </p>
                                    <p className="smttitle">
                                      {account.bank_account_number}
                                    </p>
                                    <p className="smttitle">
                                      {account.bank_account_address}
                                    </p>
                                  </div>
                                </div>
                                <div className="btn-group drop_dot dropstart">
                                  <a
                                    href="#"
                                    className="btn dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <img src={vertical_dot} alt="" />
                                  </a>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() =>
                                          setEditData({
                                            ...editData,
                                            data: account,
                                            isEdit: true,
                                            isShow: true,
                                          })
                                        }
                                      >
                                        Edit
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="card card-style">
                    <div className="card-body">
                      <div className="drivers_list d-flex justify-content-between border-0 p-0">
                        <p>Records not found</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </main>
      )}
    </React.Fragment>
  );
};

export default BankAccount;
