import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import '../../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../../context/user/AppContext'
import {
  PostCallWithErrorResponse,
  simplePostCall,
} from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import swal from 'sweetalert'
import moment from 'moment'

const VehicleInfo = () => {
  const {
    sidebar,
    customerData,
    vehiclenamestate,
    setvehiclenamestate,
    vehicletypestate,
    setvehicletypestate,
    vehicleCapacity,
    setvehicleCapacity,
    vehiclecapacitystate,
    setvehiclecapacitystate,
    setvehicleLength,
    loadDetails,
    setLoadId,
    loadId,
    homeStatus,
  } = useContext(AppContext)

  console.log('loadId', loadId)

  const [loading, setLoading] = useState(false)
  const [vehicleList, setVehicleList] = useState([])
  const [vehicleType, setVehicleType] = useState([])
  const thisPageData = useLocation().loadId
  const [loadDetail, setloadDetail] = useState({
    pickup_date_time: '',
    vehicle_name_id: '',
    vehicle_type: '',
    vehicle_capacity: '',
  })
  console.log(loadDetail)
  const [errMsg, setErrMsg] = useState({
    vehicle_name_id: '',
    vehicle_type: '',
    vehicle_capacity: '',
    pickup_date_time: '',
  })
  const { locationDetail } = loadDetails

  const navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(loadDetails).length === 0) navigate('/vehicleInfo')
    fillLoockupData()
  }, [loadDetails])

  //

  const fillLoockupData = () => {
    setLoading(true)
    PostCallWithErrorResponse(ApiConfig.VEHICLE_LIST_API, customerData)
      .then((data) => {
        setLoading(false)
        // console.log(data);
        if (data.error === false) {
          setVehicleList(data.json.vehicle_name)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const getType = (vehicle_name_id, isEdit) => {
    setVehicleType([])
    setvehicletypestate('NA')
    PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_CUSTOMER_VEHICLE_TYPES, {
      ...customerData,
      vehicle_name_id: vehicle_name_id,
    })
      .then((data) => {
        setLoading(false)
        // console.log(data);
        if (data.error === false) {
          // var dd= {...lookUpData ,vehicle_types:data.json.vehicle_types,vehicle_capacities:[],vehicle_length:[]};
          // console.log("dsfsd ",dd)
          setVehicleType(data.json.vehicle_types)
          setvehicleLength([])
          setvehicleCapacity([])
        }
      })
      .catch((error) => {
        setLoading(false)
        /*  setSetLookUpData({...lookUpData,vehicle_types:[]}) */
        console.log('api response', error)
      })
  }

  const getCapacity = (vehicle_type_id, vehicle_name_id) => {
    setvehicleCapacity([])
    setvehiclecapacitystate('NA')
    PostCallWithErrorResponse(
      ApiConfig.DROPDOWN_LIST_CUSTOMER_VEHICLE_CAPACITIES,
      {
        ...customerData,
        vehicle_name_id: vehicle_name_id
          ? vehicle_name_id
          : loadDetail.vehicle_name_id,
        vehicle_type_id: vehicle_type_id,
      },
    )
      .then((data) => {
        setLoading(false)
        // console.log(data);
        if (data.error === false) {
          setvehicleCapacity(data.json.vehicle_capacities)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('api response', error)
      })
  }

  const handleInputChange = (e) => {
    const { name, value, nodeName } = e.target
    if (name === 'vehicle_name_id') {
      getType(value)
      setvehiclenamestate(value)
      // /*   setLoadDetails({...loadDetails,vehicle_name_id:value})
      setErrMsg({
        ...errMsg,
        vehicle_name_id: '',
      })
    }
    if (name == 'vehicle_type') {
      getCapacity(value, loadDetails.vehicle_name_id)
      setvehicletypestate(value)
      /*  setLoadDetails({...loadDetails,vehicle_type:value}) */
      setErrMsg({
        ...errMsg,
        vehicle_type: '',
      })
    }
    if (name == 'vehicle_capacity') {
      setvehiclecapacitystate(value)
      /*  setLoadDetails({...loadDetails,vehicle_type:value}) */
      setErrMsg({
        ...errMsg,
        vehicle_capacity: '',
      })
    }
    if (name == 'pickup_date_time') {
      setloadDetail({
        ...loadDetail,
        pickup_date_time: e.target.value,
      })
      setErrMsg({
        ...errMsg,
        pickup_date_time: '',
      })
    }

    setloadDetail({ ...loadDetail, [name]: value })
  }

  // const handleInputChange = (e) => {
  //   const{ name,value}= e.target;
  //   const loadDetailcopy={...loadDetail}
  //   loadDetailcopy[name]=value;
  //   const errMsgCopy = {...errMsg}
  //   setloadDetail(loadDetailcopy);
  //   setErrMsg(errMsgCopy);

  // }

  const onContinue = () => {
    // setLoading(true);
    if (
      loadDetail.vehicle_name_id === '' ||
      loadDetail.vehicle_type === '' ||
      loadDetail.vehicle_capacity === '' ||
      loadDetail.pickup_date_time === ''
    ) {
      if (loadDetail.vehicle_name_id.length === 0) {
        setErrMsg({ ...errMsg, vehicle_name_id: 'Please Select Vehicle ' })
        return
      }
      if (loadDetail.vehicle_type.length === 0) {
        setErrMsg({ ...errMsg, vehicle_type: 'Please Select Vehicle Type' })
        return
      }
      if (
        loadDetail.vehicle_capacity.length === 0 ||
        loadDetail.vehicle_capacity === 'NA'
      ) {
        setErrMsg({
          ...errMsg,
          vehicle_capacity: 'Please Select Vehicle Capacity',
        })
        return
      }
      if (loadDetail.pickup_date_time === '') {
        setErrMsg({ ...errMsg, pickup_date_time: 'Pickup date is requied' })
        window.scrollTo(0, 0)

        return
      }
    } else {
      simplePostCall(
        ApiConfig.ADD_LOAD_APIS,
        JSON.stringify({
          ...customerData,
          ...loadDetail,
        }),
      )
        .then((res) => {
          console.log(res)

          if (
            (homeStatus === 'load_details_added' &&
              homeStatus === 'location_added' &&
              homeStatus === 'package_added' &&
              res.message ===
                'You are not allowed to add load as there is already a pending load found') ||
            res.result === false
          ) {
            swal({
              title: 'warning',

              text: res.message,

              icon: 'warning',

              button: true,
            }).then(() => {
              navigate('/home')
            })
          } else {
            swal({
              title: 'Success',

              text: 'Vehicle Info Added successfully!',

              icon: 'success',

              button: true,
            }).then(() => {
              localStorage.setItem('trip_id', res.trip_id)
              setLoadId({ trip_id: res.trip_id })
              navigate('/AddLocations')
            })
          }
          setLoading(false)
        })

        .catch((err) => {
          console.log(err)
        })
    }
  }

  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Vehicle Info</div>
                    <div className="right">1/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from">
                      <form>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fname" className="form-label">
                                Select Vehicle
                                <span className="red-star">*</span>
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="vehicle_name_id"
                                onChange={handleInputChange}
                                value={vehiclenamestate}
                              >
                                <option selected value="">
                                  Select Vehicle
                                </option>
                                {vehicleList
                                  ? vehicleList.map((vehicle, index) => {
                                      return (
                                        <option value={vehicle.vehicle_name_id}>
                                          {vehicle.vehicle_name_value}
                                        </option>
                                      )
                                    })
                                  : null}
                              </select>
                              {errMsg.vehicle_name_id.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.vehicle_name_id}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="fpno" className="form-label">
                                Select Vehicle Type
                                <span className="red-star">*</span>
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="vehicle_type"
                                onChange={handleInputChange}
                                value={vehicletypestate}
                                disabled={vehicleType.length === 0}
                              >
                                <option value="">Select Vehicle</option>
                                {vehicleType
                                  ? vehicleType.map((list, index) => {
                                      return (
                                        <option value={list.vehicle_type_id}>
                                          {list.vehicle_type_code}
                                        </option>
                                      )
                                    })
                                  : null}
                              </select>
                              {errMsg.vehicle_type.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.vehicle_type}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="faddress" className="form-label">
                                Select Vehicle Capacity
                                <span className="red-star">*</span>
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                disabled={vehicleCapacity.length === 0}
                                value={vehiclecapacitystate}
                                name="vehicle_capacity"
                                onChange={handleInputChange}
                              >
                                <option value="">
                                  Select Vehicle Capacity
                                </option>
                                {vehicleCapacity
                                  ? vehicleCapacity.map((vehicle, index) => {
                                      // console.log(vehicle);
                                      return (
                                        <option
                                          value={vehicle.vehicle_capacity_id}
                                        >
                                          {vehicle.vehicle_capacity_value}
                                        </option>
                                      )
                                    })
                                  : null}
                              </select>
                              {errMsg.vehicle_capacity.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.vehicle_capacity}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="cx-form-group">
                              <label for="faddress" className="form-label">
                                Select Pickup Date & Time
                                <span className="red-star">*</span>
                              </label>
                              <input
                                type="datetime-local"
                                class="form-control"
                                value={loadDetail.pickup_date_time}
                                name="pickup_date_time"
                                onChange={handleInputChange}
                                min={moment().format('YYYY-MM-DD HH:MM')}
                              />
                              {errMsg.pickup_date_time.length > 0 && (
                                <div className="text-center text-danger">
                                  <span> {errMsg.pickup_date_time}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="cxs-nav">
                    <Link to="/home">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    <Link
                      // to="/AddLocations"
                      // state={loadId}
                      to="#"
                    >
                      <button
                        type="button"
                        className="cx-text-btn"
                        onClick={onContinue}
                      >
                        {loading ? (
                          <div
                            class="spinner-border"
                            style={{
                              color: '#EE651E',
                              height: '20px',
                              width: '20px',
                            }}
                          />
                        ) : (
                          <>
                            <span>Continue </span>

                            <img src={ic_continue_arrow_blue} />
                          </>
                        )}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default VehicleInfo
