import { Link } from "react-router-dom";
import logisticsLogo from "../../../assets/images/logistics_logo.png";
import vertical_dot from "../../../assets/images/vertical_dot.svg";
import vehicle_1 from "../../../assets/images/vehicle_1.svg";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/user/AppContext";
import AddVehicles from "./AddVehicles";

const RegisterVehicles = (props) => {
  const { CompanyUserData, setCompanyUserData } = useContext(AppContext);
  console.log(CompanyUserData)
  let vehicles = CompanyUserData.vehicles ? CompanyUserData.vehicles : [];
  const [isAdd, setIsAdd] = useState(false);

  const onAddVehicle = (vehicle) => {
    vehicles.push(vehicle);
    setCompanyUserData({
      ...CompanyUserData,
      vehicles: vehicles,
    });
    setIsAdd(false);
  };
  const onRemove = (index) => {
    vehicles.splice(index, 1);
    setCompanyUserData({
      ...CompanyUserData,
      vehicles: vehicles,
    });
  };

  useEffect(() => {}, [CompanyUserData]);

  return (
    <>
      {isAdd ? (
        <AddVehicles
          isCallFrom="registration"
          isEdit={false}
          data={null}
          onAdd={onAddVehicle}
          onCancel={() => setIsAdd(false)}
        />
      ) : (
        <React.Fragment>
          <div className="right_block cx-reg-content">
            <div className="d-flex justify-content-between">
              <h2>Vehicles</h2>
              <p className="step_num">4/5</p>
            </div>

            <div className="register_right mt-4 ">
              <div className="d-flex justify-content-end mb-4">
                <Link
                  to=""
                  onClick={props.nextCallback}
                  className="blue_link pt-2"
                >
                  Skip
                </Link>
                <Link
                  to=""
                  onClick={() => {
                    setIsAdd(true);
                  }}
                  className="blue_btn ms-4"
                >
                  Add Vehicles
                </Link>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {CompanyUserData.vehicles &&
                  CompanyUserData.vehicles.length > 0 ? (
                    CompanyUserData.vehicles.map((vehicle, index) => {
                      return (
                        <div className="drivers_list d-flex justify-content-between">
                          <div className="itemside">
                            <div className="aside">
                              <img
                                src={vehicle.vehicle_images[0].image_url}
                                className="header-profile-pic "
                              />
                            </div>
                            <div className="info align-self-center">
                              <p className="title">
                                {vehicle.vehicle_name_text}
                              </p>
                              <p className="smttitle">
                                Capacity{" "}
                                <span className="catvehcile">
                                  {vehicle.vehicle_capacity_text}
                                </span>
                              </p>
                              <p className="smttitle">
                                License Plate No.{" "}
                                <span className="catvehcile">
                                  {vehicle.vehicle_plate_no}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="btn-group drop_dot dropstart">
                            <a
                              href="#"
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={vertical_dot} alt="" />
                            </a>
                            <ul className="dropdown-menu">
                              {/* <li>
                                <button className="dropdown-item" type="button">
                                  Edit
                                </button>
                              </li> */}
                              <li>
                                <button
                                  onClick={() => onRemove(index)}
                                  className="dropdown-item"
                                  type="button"
                                >
                                  Remove
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="drivers_list d-flex justify-content-between">
                      <div className="itemside">
                        <p className="title">Records not found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="rfbtn_block">
            <div className="d-flex justify-content-between">
              <button onClick={props.prevCallback} className="orange_btn">
                Prev
              </button>
              <button onClick={props.nextCallback} className="orange_btn">
                Next
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default RegisterVehicles;
