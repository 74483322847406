import React, { useState, useContext, useEffect } from "react";
import CompanySideBar from "../../../sharedComponent/CompanySideBar";
import profile_1 from "../../../assets/images/profile_1.svg";
import profile_2 from "../../../assets/images/profile_2.svg";
import ic_tick_white from "../../../assets/images/Live/ic_tick_white.svg";
import CompanyHeader from "../../../sharedComponent/CompanyHeader";
import swal from "sweetalert";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { AppContext } from "../../../context/user/AppContext";
import { useLocation, useNavigate } from "react-router-dom";

const VehicleAssignDrivers = () => {
  const URL = "https://logistic.redbytes.in/";

  const { state } = useLocation();
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  const { customerData, sidebar } = useContext(AppContext);
  const [data, setData] = useState([]);
  console.log(data.user_id);
//   useEffect(() => {
//     let compid = localStorage.getItem("role2");
//     if (compid == "individual") {
//       AssignDriver(customerData.user_id, state.id);
//     }

//     // if (state) {
//     //   console.log("first");
//     // } else {
//     //   navigate("/Loads");
//     // }
//   });
  useEffect(() => {
    getDrivers();
  }, []);

  const setClick = (id) => {
    console.log(id);
    AssignDriver(id, state.id);
  };
  const getDrivers = () => {
    PostCallWithErrorResponse(
      localStorage.getItem("role") == "company"
        ? ApiConfig.DRIVER_LIST_API_COMPANY
        : navigate("/Login"),
      customerData
    )
      .then((data) => {
        console.log(data.json);
        setData(data.json.driver_list);
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData([]);
      });
  };
  const AssignDriver = (driver_id, load_id) => {
    PostCallWithErrorResponse(
      localStorage.getItem("role") == "company"
        ? ApiConfig.ASSIGN_DRIVER_TO_LOAD
        : navigate("/Login"),

      { ...customerData, load_id: load_id, driver_id: driver_id }
    )
      .then((data) => {
        if (data.json.result == false) {
          swal({
            title: "warning",
            text: data.json.message,
            icon: "warning",
            button: true,
          });
        } else {
          swal({
            title: "success",
            text: data.json.message,
            icon: "success",
            button: true,
          }).then(() => {
            navigate("/CompanyLoadServiceRequest");
          });
        }

        console.log(data.json);
        /*   setData(data.json) */
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
        setData([]);
      });
  };
  return (
    <React.Fragment>
      <main className="cx-main p-0" id="cxMain">
        <section id="cxl-locations">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="service_load_block">
                <div className="card card-style mb-4">
                  <div className="card-body">
                    <ul className="cx-sloc-list" data-simplebar>
                      {data.map((dataItem, index) => {
                        console.log(dataItem.user_id);
                        return (
                          <li
                            key={"dataItem" + index}
                            onClick={() => setClick(dataItem.user_id)}
                          >
                            <div className="form-check border-bottom">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadio"
                                id={"flexRadio" + index}
                                checked
                              />
                              <label
                                className="form-check-label"
                                for={"flexRadio" + index}
                              >
                                <div className="drivers_list border-0">
                                  <div className="itemside">
                                    <div className="aside">
                                      <img
                                        src={
                                          dataItem.user_profile_pic
                                            ? URL + dataItem.user_profile_pic
                                            : profile_1
                                        }
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                        className="img-sm"
                                      />
                                    </div>
                                    <div className="info align-self-center">
                                      <p className="title">
                                        {dataItem.user_name.replace(
                                          /\d(?=\d{4})/g,
                                          "*"
                                        )}
                                      </p>
                                      <p className="smttitle">
                                        {dataItem.user_email}
                                      </p>
                                      <p className="smttitle">
                                        {dataItem.user_address}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="cx-radio">
                                  <img src={ic_tick_white} />
                                </div>
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default VehicleAssignDrivers;
