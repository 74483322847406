import React, { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../../../context/user/AppContext";
import { PostCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import swal from "sweetalert";



export default function CheckStaus(props) {
    const [show, setShow] = React.useState({ id: "" });
    const {customerData,sidebar } = useContext(AppContext);
    const [data, setData] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    let [successdelete,setSuccessDelete]=useState(false)
    const messageDet="";
    
    useEffect(()=>{
       
        PostCallWithErrorResponse(ApiConfig.ADD_VERIFY_CREADIT_CARD_CHECK_STATUS_API, {
            ...customerData,reference_no:localStorage.getItem('referenceNo')
          })
          .then((data) => {
            console.log(" My Object :: ",data);

            let messages=data.json.message;
            if(messages.includes("Undefined array key"))
            {
             messages="Card varified Successfully";
            }
            swal({
                title: "Success",
                text: messages,
                icon: "success",
                button: true,
              }).then(()=>{
                window.location.href="/AddPaymentMethod"
              })

          
            

           
          })
          .catch((error) => {
            console.log("api response", error);
          });
        
        }, [])







return (<div>
<div id="StatusDet"></div>
</div>)
}