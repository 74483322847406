import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../../assets/images/ic_current_location.svg'
import add_red from '../../../assets/images/add_red.svg'
import edit_blue from '../../../assets/images/edit_blue.svg'
import cross from '../../../assets/images/ic_cross_red.svg'
import '../../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../../context/user/AppContext'
import package_image_01 from '../../../assets/images/Send Steps/package_image_01.png'
import package_image_02 from '../../../assets/images/Send Steps/package_image_02.png'
import package_image_03 from '../../../assets/images/Send Steps/package_image_03.png'
import add from '../../../assets/images/add_red.svg'
import ApiConfig from '../../../api/ApiConfig'
import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from '../../../api/ApiServices'
import { useEffect } from 'react'
import swal from 'sweetalert'

const URL = 'https://logistic.redbytes.in/'

const SendLoadPackageDetails = () => {
  const { sidebar, customerData, loadId } = useContext(AppContext)
  const [totalCount, setTotalCount] = useState(0)
  console.log(totalCount)
  const navigate = useNavigate()

  const [packageLists, setPackageLists] = useState([])
  let [loading, setLoading] = useState(true)
  let [empty, setEmpty] = useState(false)
  useEffect(() => {
    getpackageList()
  }, [])

  const handleCart = () => {
    if (totalCount === 0) {
      swal('Please Select Packages')
    } else {
      navigate('/SendLoadBookingSummary')
    }
  }
  const getpackageList = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.PACKAGE_LIST_API_CUSTOMERS,
      JSON.stringify({
        ...customerData,
        trip_id: loadId.trip_id,
      }),
    )
      .then((data) => {
        // console.log(data);
        if (data.error == false) {
          const data1 = data.json.package_list
          console.log(data1.length)
          setPackageLists(data1)
          setLoading(false)
          setTotalCount(data1.length ? data1.length : 0)
        }
        if (data.json.result == false) {
          setPackageLists([])
          setEmpty(true)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }
  const deletePackage = (id, e) => {
    setLoading(true)
    simplePostCall(
      ApiConfig.DELETE_PACKAGE_API,
      JSON.stringify({ ...customerData, package_id: id }),
    )
      .then((res) => {
        // console.log(res);
        if (res.result) {
          getpackageList()
          setLoading(false)

          swal({
            title: 'Success',
            text: res.message,
            icon: 'success',
            button: true,
          }).then(() => {
            // navigate("/LoadServiceRequest");
          })
        } else {
          swal({
            title: 'warning',
            text: res.message,
            icon: 'warning',
            button: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations" className="SendLoadPackageDetails">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Package Details</div>
                    <div className="right">3/4</div>
                  </div>

                  <div className="cxs-loc-content">
                    <div className="d-flex justify-content-between">
                      <Link to="/SendLoadAddPackages">
                        <div className="detail-btn">
                          <img className="me-1" src={add} alt="" />
                          <span>ADD PACKAGES</span>
                        </div>
                      </Link>
                      <div>
                        <label>
                          {packageLists && packageLists.length} added
                        </label>
                      </div>
                    </div>
                    <div className="cxs-from ">
                      <div className="row justify-content-between mt-2 gy-3">
                        {packageLists.length > 0 ? (
                          packageLists.map((list, index) => {
                            return (
                              <div
                                className="col-lg-6 package-card"
                                key={'list' + index}
                              >
                                <div className="top d-flex justify-content-between">
                                  <div className="left">
                                    {list.package_name}
                                  </div>
                                  <div className="right">
                                    {/* <Link to="/SendLoadAddPackages"> */}
                                    <img
                                      src={cross}
                                      alt=""
                                      onClick={() =>
                                        deletePackage(list.package_id)
                                      }
                                    />
                                    {/* </Link> */}
                                  </div>
                                </div>
                                <Link
                                  state={list}
                                  // to="/create_property"
                                  to={'/EditPackage/' + list.package_id}
                                >
                                  <div className="content">
                                    <div>
                                      <label htmlFor="">Material Type</label>
                                      <span>{list.trip_material_type}</span>
                                    </div>
                                    <div>
                                      <label htmlFor="">Weight</label>
                                      <span>{list.trip_weight}</span>
                                    </div>
                                    <div>
                                      <label htmlFor="">Quantity</label>
                                      <span>{list.trip_quantity}</span>
                                    </div>
                                    {list.pickup_locations.map((pickup) => {
                                      return (
                                        <div>
                                          <label htmlFor="">
                                            Pickup Locations
                                          </label>
                                          <span>{pickup.address_details}</span>
                                        </div>
                                      )
                                    })}
                                    {list.drop_locations.map((drop) => {
                                      return (
                                        <div>
                                          <label htmlFor="">
                                            Drop Locations
                                          </label>
                                          <span>{drop.address_details}</span>
                                        </div>
                                      )
                                    })}

                                    <div className="row">
                                      {list &&
                                        list.images &&
                                        list.images.map((img, index) => {
                                          return (
                                            <div
                                              className="col-3 img-package"
                                              key={'img' + index}
                                            >
                                              <img
                                                src={URL + img.trip_image_url}
                                                alt=""
                                              />
                                            </div>
                                          )
                                        })}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            )
                          })
                        ) : (
                          <h5 className="text-danger text-center">
                            Please Add Packages!
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="cxs-nav">
                    <Link to="/AddLocations">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    {/* <Link to="#"> */}
                    <button
                      onClick={handleCart}
                      type="button"
                      className="cx-text-btn"
                    >
                      <span>Continue </span>
                      <img src={ic_continue_arrow_blue} />
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default SendLoadPackageDetails
