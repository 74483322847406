import React, { useContext, useEffect, useState } from 'react'

import Sidebar1 from '../Sidebar1'
import driver_profile from '../../assets/images/Details/driver_profile.png'
import arrow_white from '../../assets/images/login/arrow_white.svg'
import '../../assets/css/cx-rating.css'
import ApiConfig from '../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import swal from 'sweetalert'
import SpinnerCmp from '../../SpinnerCmp'
import { AppContext } from '../../context/user/AppContext'
import { useNavigate, useParams } from 'react-router-dom'

export default function Rating(props) {
  const base_url = 'https://logistic.redbytes.in/'
  const { id } = useParams()
  const [rating, setRating] = React.useState(2)
  const [driver, setDriver] = React.useState(2)
  const { customerData, sidebar } = useContext(AppContext)
  let [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const experiences = ['Bad', 'Average', 'Good', 'Very Good', 'Excellent']
  const [state, setState] = useState({
    driver_id: id ? id : 0,
    review: '',
    text: '',
  })

  const loadDriverDetails = () => {
    setLoading(true)
    PostCallWithErrorResponse(ApiConfig.DRIVER_DETAILS_CUSTOMER, {
      ...customerData,
      ...{
        driver_id: 176,
      },
    })
      .then((data) => {
        setLoading(false)
        if (!data.error && data.json.result) {
          console.log('Driver data ::: ', data)
          setDriver(data.json.driver_details)
        }
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const onSubmitReview = () => {
    if (!validateFields()) return false
    setLoading(true)

    let obj = { ...state, review: rating }
    PostCallWithErrorResponse(ApiConfig.RATE_AND_REVIEW_DRIVER_API, {
      ...customerData,
      ...obj,
    })
      .then((data) => {
        setLoading(false)
        console.log(data)
        if (data.json.result) {
          swal({
            title: 'Success',
            text: 'review submitted successfuly!',
            icon: 'success',
            button: true,
          }).then(() => {
            navigate('/home')
          })
        } else {
          swal({
            title: 'warning',
            text: 'something went wrong try again!',
            icon: 'warning',
            button: true,
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('api response', error)
      })
  }
  const validateFields = () => {
    let errorfields = []
    let isValid = true
    if (!state.text) {
      errorfields.push('text')
      isValid = false
    }
    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById('error' + field).style.display = 'block'
      })
    }
    return isValid
  }
  const hideValidationError = (field) => {
    document.getElementById('error' + field).style.display = 'none'
  }
  const handleInputChange = (e) => {
    if (e.target.value.trim()) hideValidationError(e.target.name)
    setState({ ...state, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    loadDriverDetails()
  }, [])
  return (
    <div>
      <main class={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        <section id="cxr-rating">
          <div class="container">
            {loading ? (
              <div className="sweet-loading">
                <SpinnerCmp loading={loading} size={40} />
              </div>
            ) : (
              <div class="row align-items-center">
                <div class="col-lg-12 d-flex justify-content-center">
                  <div class="cxr-contact-outer">
                    <div class="cxr-profile">
                      <div class="cxr-profile-img">
                        <img
                          src={
                            driver.driver_profile_pic
                              ? base_url + driver.driver_profile_pic
                              : driver_profile
                          }
                        />
                        <div class="cxr-load-graphic"></div>
                      </div>
                      <h5>{driver.driver_name}</h5>
                      <p>{driver.address}</p>
                    </div>
                    <div class="cxr-rate">
                      <ul>
                        {experiences.map((e, index) => {
                          return (
                            <li
                              value={index}
                              className={rating >= index ? 'cx-active' : null}
                              onClick={() => {
                                setRating(index)
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="33"
                                height="32"
                                viewBox="0 0 33 32"
                              >
                                <defs>
                                  <clipPath id="clip-path">
                                    <rect
                                      id="Rectangle_3874"
                                      data-name="Rectangle 3874"
                                      width="33"
                                      height="32"
                                      transform="translate(-0.462 0.111)"
                                      fill="#f0eaea"
                                      stroke="#707070"
                                      stroke-width="1"
                                    />
                                  </clipPath>
                                </defs>
                                <g
                                  id="star_grey"
                                  transform="translate(0.462 -0.111)"
                                  clip-path="url(#clip-path)"
                                >
                                  <path
                                    id="_20627715151555590645"
                                    data-name="20627715151555590645"
                                    d="M16.111.587l4.978,10.088,11.133,1.618-8.055,7.852,1.9,11.088L16.111,26,6.154,31.232l1.9-11.088L0,12.293l11.132-1.618Z"
                                    transform="translate(0 0.201)"
                                    fill="#f0eaea"
                                  />
                                </g>
                              </svg>
                            </li>
                          )
                        })}
                      </ul>
                      <p>{experiences[rating]} experience</p>
                    </div>
                    <form>
                      <div class="cx-form-group">
                        <textarea
                          value={state.text}
                          onChange={handleInputChange}
                          placeholder="Type your review"
                          class="form-control"
                          name="text"
                        ></textarea>
                        <label id="errortext" className="for-error">
                          Enter your review
                        </label>
                      </div>
                      <div class="cx-btn-grp1">
                        <button
                          onClick={onSubmitReview}
                          type="button"
                          class="cxr-rate-btn"
                        >
                          <span>Submit</span>
                          <img src={arrow_white} />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  )
}
