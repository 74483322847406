import React, { useContext, useEffect, useState } from "react";
import Sidebar1 from "../../../sharedComponent/Sidebar1";
import driver_profile_default from "../../../assets/images/login/driver_profile_default.svg";
import ic_camera from "../../../assets/images/login/ic_camera.svg";
import ic_package_upload from "../../../assets/images/Send Steps/ic_package_upload.svg";
import ic_back_arrow_blue from "../../../assets/images/Send Steps/ic_back_arrow_blue.svg";
import ic_continue_arrow_blue from "../../../assets/images/Send Steps/ic_continue_arrow_blue.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/css/cx-sload-steps.css";
import { FileUploader } from "react-drag-drop-files";
import circle_cross from "../../../assets/images/circle_cross.svg";
import { AppContext } from "../../../context/user/AppContext";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  postMultipartWithAuthCall,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import SpinnerCmp from "../../../SpinnerCmp";
import swal from "sweetalert";

const fileTypes = ["JPG", "PNG", "GIF"];
export default function LoadDetail() {
  const { loadDetails, setLoadDetails, customerData,sidebar ,
    vehicleList,
    setVehicleList,
    vehicleType,
    setvehicleType,
    vehicleCapacity,setvehicleCapacity,
    vehicleLength,setvehicleLength,
    vehiclenamestate, setvehiclenamestate,
    vehicletypestate,setvehicletypestate,
    vehiclecapacitystate,setvehiclecapacitystate,
    vehiclelengthstate,setvehiclelengthstate,
  } = useContext(AppContext);
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  const [loadDetail, setloadDetail] = useState(
    loadDetails.loadDetail
      ? loadDetails.loadDetail
      : {
          thumbnail: "",
          images: [],
          name: "",
          weight: "",
          /* vehicle_name_id: "1",
          vehicle_capacity:"1",
          vehicle_length:"1",
        
          vehicle_type: "3", */
          material_type: "",
          delivery_conditions: "",
          distance: 0,
        }
  );
  const { locationDetail } = loadDetails;
 
/* get dropdown data start */
const getCapacity= (vehicle_type_id,vehicle_name_id) => {
  setvehicleCapacity([])
  setvehiclecapacitystate("NA")
  PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_CUSTOMER_VEHICLE_CAPACITIES, 
    {...customerData,vehicle_name_id:(vehicle_name_id ?vehicle_name_id: loadDetail.vehicle_name_id),vehicle_type_id:vehicle_type_id})
    .then((data) => {
      setLoading(false);
      console.log(data);
      if (data.error === false) {
        
        setvehicleCapacity(data.json.vehicle_capacities);
       
       
  
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log("api response", error);
    });
};
const getLength= (vehicle_capacity_id,vehicle_type_id,vehicle_name_id) => {
  
  PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_CUSTOMER_VEHICLE_LENGTH, 
    {...customerData,vehicle_name_id:vehicle_name_id ? vehicle_name_id:loadDetail.vehicle_name_id,vehicle_type_id:vehicle_type_id ? vehicle_type_id:loadDetail.vehicle_type,vehicle_capacity_id:vehicle_capacity_id})
    .then((data) => {
      setLoading(false);
      console.log(data);
      if (data.error === false) {
        
        setvehicleLength(data.json.vehicle_lengths);
       
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log("api response", error);
    });
};
const getType= (vehicle_name_id,isEdit) => {
  setvehicleType([])
  setvehicletypestate("NA")
  PostCallWithErrorResponse(ApiConfig.DROPDOWN_LIST_CUSTOMER_VEHICLE_TYPES, 
    {...customerData,vehicle_name_id:vehicle_name_id})
    .then((data) => {
      setLoading(false);
      console.log(data);
      if (data.error === false) {
        // var dd= {...lookUpData ,vehicle_types:data.json.vehicle_types,vehicle_capacities:[],vehicle_length:[]};
        // console.log("dsfsd ",dd)
        setvehicleType(data.json.vehicle_types);
        setvehicleLength([])
        setvehicleCapacity([])
       
       
      }
     

    })
    .catch((error) => {
      setLoading(false);
     /*  setSetLookUpData({...lookUpData,vehicle_types:[]}) */
      console.log("api response", error);
    });
};
  const fillLoockupData = () => {
    setLoading(true);
    PostCallWithErrorResponse(ApiConfig.VEHICLE_LIST_API, customerData)
      .then((data) => {
        setLoading(false);
        console.log(data);
        if (data.error === false) {
          setVehicleList(data.json.vehicle_name);
          
          
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

/* get dropdown data end  */

  // const addThumbnailimage = (e) => {
  //   var file = e.target.files[0];
  //   hideValidationError("thumbnail");
  //   setloadDetail({ ...loadDetail, thumbnail: file });
  // };
  const addImage = (files) => {
    hideValidationError("images");
    setloadDetail({ ...loadDetail, images: files });
  };
  const removeImage = (index) => {
    const dt = new DataTransfer();
    const input = loadDetail.images;

    for (let i = 0; i < input.length; i++) {
      const file = input[i];
      if (index !== i) dt.items.add(file);
    }
    let fileinput = dt.files;
    setloadDetail({ ...loadDetail, images: fileinput });
  };

  const handleInputChange = (e) => {
    const { name, value, nodeName } = e.target;
    if(name=="vehicle_name_id"){
      getType(value)
      setvehiclenamestate(value)
    /*   setLoadDetails({...loadDetails,vehicle_name_id:value}) */
    }
    if(name=="vehicle_type"){
      getCapacity(value,loadDetails.vehicle_name_id)
      setvehicletypestate(value)
     /*  setLoadDetails({...loadDetails,vehicle_type:value}) */
    }
    if(name=="vehicle_capacity"){
      getLength(value)
      setvehiclecapacitystate(value)
      /* setLoadDetails({...loadDetails,vehicle_capacity:value}) */
    }
    if(name=="vehicle_length"){
      setvehiclelengthstate(value)
    }

    if (name === "weight" && value !== "" && !Number(value)) return;
    if (value.trim() && nodeName !== "SELECT") hideValidationError(name);
    setloadDetail({ ...loadDetail, [name]: value });
  };

  const onBack = () => {
    setLoadDetails({
      ...loadDetails,
      loadDetail,
    });
    navigate("/LocationDetails");
  };
  const onContinue = () => {
    if (validateFields()) {
      setLoading(true);
      var newobject = {
        ...locationDetail,
        ...loadDetail,
      };
      const formData = getFormData(newobject);
      multipartPostCallWithErrorResponse(ApiConfig.ADD_LOAD_API, formData)
        .then((data) => {
          setLoading(false);
          console.log(data);
          if (data.error == false && data.json.result) {
            setLoadDetails({
              ...newobject,
              locationDetail,
              loadDetail,
              ...data.json,
            });
            navigate("/BookingSummary");
          }
          else{
            swal({
              title: "warning",
              text: "Please fill all the required inputs!",
              icon: "warning",
              button: true,
            })
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    }
  };
  useEffect(() => {
    
    if (Object.keys(loadDetails).length === 0) navigate("/SendLoad");
    fillLoockupData();
  }, [loadDetails]);

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      if (key === "images" && object[key].length > 0) {
        for (let index = 0; index < object[key].length; index++) {
          formData.append("images[]", object[key][index]);
        }
      } else formData.append(key, object[key]);
    });
    return formData;
  };

  const validateFields = () => {
    let errorfields = [];
    let isValid = true;
    // if (!loadDetail.thumbnail) {
    //   errorfields.push("thumbnail");
    //   isValid = false;
    // }
    if (!loadDetail.images || loadDetail.images.length === 0) {
      errorfields.push("images");
      isValid = false;
    }
    if (!loadDetail.name) {
      errorfields.push("name");
      isValid = false;
    }
    if (!loadDetail.weight) {
      errorfields.push("weight");
      isValid = false;
    }
    if (!loadDetail.material_type) {
      errorfields.push("material_type");
      isValid = false;
    }
    if (!loadDetail.delivery_conditions) {
      errorfields.push("delivery_conditions");
      isValid = false;
    }
    if (!isValid) {
      errorfields.forEach((field) => {
        document.getElementById("error" + field).style.display = "block";
      });
    }
    return isValid;
  };
  const hideValidationError = (field) => {
    document.getElementById("error" + field).style.display = "none";
  };
  return (
    <div>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className= {sidebar ?  "cx-main cx-active" :"cx-main"}  id="cxMain">
        <section id="cxs-dload">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="cxs-content-outer d-flex justify-content-center ">
                  {loading ? (
                    <div className="sweet-loading">
                      <SpinnerCmp loading={loading} size={40} />
                    </div>
                  ) : (
                    <div className="cxs-content-inner">
                      <div className="cxs-loc-content">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="cxs-upload">
                              <label className="required">
                                Add More Images
                              </label>
                              <div className="cxs-upload-image">
                                <form
                                  action="/upload"
                                  method="post"
                                  className="cxs-iupload-form"
                                >
                                  <FileUploader
                                    id="fileupload"
                                    handleChange={addImage}
                                    
                                    name="file"
                                    types={fileTypes}
                                    multiple={true}
                                    classes="cxs-upload-image"
                                  />
                                  {/* <input
                                  type="file"
                                  name="file"
                                  className="cxs-file"
                                  multiple
                                /> */}
                                </form>

                                <div className="uploaded_items">
                                  {loadDetail.images &&
                                  loadDetail.images.length > 0
                                    ? Array.from(loadDetail.images).map(
                                        (image, index) => {
                                          return (
                                            <div className="item_list">
                                              <img
                                                className="load_img"
                                                src={URL.createObjectURL(image)}
                                                alt=""
                                              />
                                              <a
                                                ariaColIndex={index}
                                                onClick={() =>
                                                  removeImage(index)
                                                }
                                                className="close_item"
                                              >
                                                <img
                                                  value={index}
                                                  src={circle_cross}
                                                  alt=""
                                                />
                                              </a>
                                            </div>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                              <label id="errorimages" className="for-error">
                                {" "}
                                upload at least one image{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="cxs-details">
                              <form>
                                <div className="cx-form-group">
                                  <label
                                    for="dpname"
                                    className="form-label required"
                                  >
                                    Package Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="dpname"
                                    value={loadDetail.name}
                                    name="name"
                                    onChange={handleInputChange}
                                  />
                                  <label id="errorname" className="for-error">
                                    enter package name
                                  </label>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dweight"
                                    className="form-label required"
                                  >
                                    Weight
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="dweight"
                                    value={loadDetail.weight}
                                    name="weight"
                                    onChange={handleInputChange}
                                  />
                                  <label id="errorweight" className="for-error">
                                    enter weight
                                  </label>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dvehicle"
                                    className="form-label required"
                                  >
                                    Select Vehicle
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dvehicle"
                                    aria-label=".form-select-lg example"
                                    value={vehiclenamestate}
                                    name="vehicle_name_id"
                                    onChange={handleInputChange}
                                  >
                                    <option value="NA">Select Vehicle</option>
                                    {vehicleList
                                      ? vehicleList.map((vehicle, index) => {
                                          return (
                                            <option
                                              value={vehicle.vehicle_name_id}
                                            >
                                              {vehicle.vehicle_name_value}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dvehicle"
                                    className="form-label required"
                                  >
                                    Select Type
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dvehicle"
                                    aria-label=".form-select-lg example"
                                    value={vehicletypestate}
                                    disabled={vehicleType.length===0}
                                    name="vehicle_type"
                                    onChange={handleInputChange}
                                  >
                                    <option value="NA">Select Vehicle Type</option>
                                    {vehicleType.length>0
                                      ? vehicleType.map((vehicle, index) => {
                                          return (
                                            <option
                                              value={vehicle.vehicle_type_id}
                                            >
                                              {vehicle.vehicle_type_code}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dvehicle"
                                    className="form-label required"
                                  >
                                    Select Vehicle Capacity
                                  </label>
                                  <select
                                    className="form-select"
                                    id="cvehicle"
                                    aria-label=".form-select-lg example"
                                    disabled={vehicleCapacity.length===0}
                                    value={vehiclecapacitystate}
                                    name="vehicle_capacity"
                                    onChange={handleInputChange}
                                  >
                                    <option value="NA">Select Vehicle Capacity</option>
                                    {vehicleCapacity
                                      ? vehicleCapacity.map((vehicle, index) => {
                                          return (
                                            <option
                                              value={vehicle.vehicle_capacity_id}
                                            >
                                              {vehicle.vehicle_capacity_value}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dvehicle"
                                    className="form-label required"
                                  >
                                    Select Vehicle Length
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dvehicle"
                                    aria-label=".form-select-lg example"
                                    disabled={vehicleLength.length===0}
                                    value={vehiclelengthstate}
                                    name="vehicle_length"
                                    onChange={handleInputChange}
                                  >
                                    <option value="NA">Select Vehicle Length</option>
                                    {vehicleLength.length>0
                                      ? vehicleLength.map((vehicle, index) => {
                                          return (
                                            <option
                                              value={vehicle.vehicle_length_id}
                                            >
                                              {vehicle.vehicle_length_value}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </select>
                                </div>
                                <div className="cx-form-group">
                                  <label
                                    for="dmtype"
                                    className="form-label required"
                                  >
                                    Material Type
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="dmtype"
                                    value={loadDetail.material_type}
                                    name="material_type"
                                    onChange={handleInputChange}
                                  />
                                  <label
                                    id="errormaterial_type"
                                    className="for-error"
                                  >
                                    enter material type
                                  </label>
                                </div>

                                <div className="cx-form-group">
                                  <label
                                    for="ddconditions"
                                    className="form-label required"
                                  >
                                    Delivery Conditions
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="ddconditions"
                                    placeholder=""
                                    value={loadDetail.delivery_conditions}
                                    name="delivery_conditions"
                                    onChange={handleInputChange}
                                  ></textarea>
                                  <label
                                    id="errordelivery_conditions"
                                    className="for-error"
                                  >
                                    enter delivery conditions
                                  </label>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cxs-nav cxs-wizard-nav">
                        {/* <Link to="/LocationDetails">
                        {" "} */}
                        <button
                          onClick={onBack}
                          type="button"
                          className="cx-text-btn"
                        >
                          <img src={ic_back_arrow_blue} />
                          <span>Back</span>
                        </button>
                        {/* </Link> */}
                        {/* <Link to="/BookingSummary"> */}{" "}
                        <button
                          onClick={onContinue}
                          type="button"
                          className="cx-text-btn"
                        >
                          <span>Continue </span>
                          <img src={ic_continue_arrow_blue} />
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
