import React, { useContext, useEffect, useState } from 'react'

import Sidebar1 from '../../../sharedComponent/Sidebar1'
import ic_status_close from '../../../assets/images/Live/ic_status_close.svg'
import ic_tick_blue from '../../../assets/images/Live/ic_tick_blue.svg'
import ic_route_line from '../../../assets/images/Live/ic_route_line.svg'
import ic_route_line_v from '../../../assets/images/Live/ic_route_line_v.svg'
import ic_star_grey from '../../../assets/images/Live/ic_star_grey.svg'
import ic_whatsapp from '../../../assets/images/Live/ic_whatsapp.svg'
import profile_img from '../../../assets/images/Live/profile_img.png'
import ic_star_highlighted from '../../../assets/images/Live/ic_star_highlighted.svg'
import ic_call from '../../../assets/images/Live/ic_call.svg'
import ic_package_upload from '../../../assets/images/Send Steps/ic_package_upload.svg'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_add_blue from '../../../assets/images/Send Steps/ic_add_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_edit_blue from '../../../assets/images/Send Steps/ic_edit_blue.svg'
import booking_package_image from '../../../assets/images/Send Steps/booking_package_image.png'
import '../../../assets/css/cx-live.css'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import LiveTrackingMap from './LiveTrackingMap'
import { AppContext } from '../../../context/user/AppContext'
import ApiConfig from '../../../api/ApiConfig'
import { PostCallWithErrorResponse } from '../../../api/ApiServices'
import SpinnerCmp from '../../../SpinnerCmp'
const URL = 'http://logistic.redbytes.in/'

export default function LiveTrackingCustomer() {
  debugger
  const { customerData, sidebar } = useContext(AppContext)
  const [state, setState] = React.useState(false)
  const [loadstatus, setLoadstatus] = useState([])
  const [data, setData] = useState({})
  const [show, setShow] = React.useState(true)
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [start, setStart] = useState({})
  const [end, setEnd] = useState({})
  const [currentlocation, setCurrentLocation] = useState(null)

  const getData = () => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') === 'customer'
        ? ApiConfig.MAPDATA_API_CUSTOMER
        : ApiConfig.COMPANY_MAP_STATUS,
      {
        ...customerData,
        load_id: id,
      },
    )
      .then((data) => {
        console.log(data.json.load_details)
        setData(data.json)
        setStart({
          lat: parseFloat(data.json.load_details.trip_start_latitude),
          lng: parseFloat(data.json.load_details.trip_start_longitude),
        })
        setEnd({
          lat: parseFloat(data.json.load_details.trip_end_latitude),
          lng: parseFloat(data.json.load_details.trip_end_longitude),
        })
        setCurrentLocation({
          lat: parseFloat(data.json.current_location.latitude),
          lng: parseFloat(data.json.current_location.longitude),
        })
      })
      .catch((error) => {
        console.log('api response', error)
        setData([])
      })
  }
  const getPackageStatus = () => {
    PostCallWithErrorResponse(
      localStorage.getItem('role') == 'customer'
        ? ApiConfig.PACKAGESTATUS_API_CUSTOMER
        : ApiConfig.COMPANY_PACKAGE_STATUS,
      {
        ...customerData,
        load_id: id,
      },
    )
      .then((data) => {
        console.log(data.json.load_status_list)
        setLoadstatus(data.json.load_status_list)
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
      .catch((error) => {
        console.log('api response', error)
        /* setData([]); */
      })
  }

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    getPackageStatus()
  }, [])

  return (
    <div>
      {/* !-- MAIN STARTS HERE --> */}
      <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
        {loading === false &&
        data !== {} &&
        loadstatus.length !== 0 &&
        currentlocation ? (
          <section id="cxs-live">
            <div>
              <div id="cxl-map">
                <LiveTrackingMap
                  start={start}
                  end={end}
                  currentlocation={currentlocation}
                />
              </div>
              {/* <div className="cxl-status"> */}
              <div className={`cxl-status ${show ? 'cx-active' : null} `}>
                <div className="cxl-status-title">
                  <h4>Status</h4>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(!show)
                    }}
                    className="cx-text-btn"
                  >
                    <img src={ic_status_close} />
                  </button>
                </div>
                <ul className="cxl-load-status">
                  {loadstatus
                    ? loadstatus.map((single) => {
                        return single.status !== 'Loading Load' &&
                          single.status !== 'Unloading Loads' ? (
                          <li
                            id={single.trip_id}
                            className={
                              single.trip_id
                                ? 'cxl-completed '
                                : ' cx-status-cleft'
                            }
                          >
                            <div className="cxl-status-circle">
                              {single.trip_id !== '' ? (
                                <span>
                                  {' '}
                                  <img src={ic_tick_blue} />
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="cxl-status-details">
                              <p>{single.status}</p>
                              {single.trip_id !== '' ? (
                                <span>{single.date}</span>
                              ) : (
                                ''
                              )}
                            </div>
                          </li>
                        ) : (
                          ''
                        )
                      })
                    : ''}
                </ul>
              </div>
              <div className={`cxl-details ${state ? 'cx-active' : null}`}>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="cxl-load-details">
                      <div className="cxl-ltitle">
                        <h4>Load details</h4>
                        <div className="cxl-lbtns">
                          <button
                            type="button"
                            onClick={() => {
                              setShow(!show)
                            }}
                            className="cx-text-btn cxl-status-toggler"
                          >
                            Status
                          </button>
                          {/*  <button
                            type="button"
                            onClick={() => {
                              setState(!state);
                            }}
                            className="cx-text-btn cxl-close-details"
                          >
                            Close
                          </button> */}
                          <Link to={'/packagedetails/' + id}>
                            <button type="button" className="cx-text-btn">
                              View Details
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="cxl-lpdetails">
                        <img src={URL + data.load_details.thumbnail} />
                        <ul className="cxl-load-ul">
                          <li>
                            <h5>{data.load_details.trip_name}</h5>
                          </li>
                          <li>
                            <label>Current Location</label>
                            {/*     {
                              data.current_location==null? "":<span>{data.current_location}</span>
                            } */}
                          </li>
                          <li>
                            <label>Status</label>
                            <span>{data.load_details.trip_status}</span>
                          </li>
                          <li>
                            <span className="cxl-cos">
                              {data.load_details.price}
                            </span>
                          </li>
                          <li>
                            <div className="cxl-routes">
                              <div className="cxl-from-route">
                                <label>From</label>
                                <span>
                                  {data.load_details.trip_start_address}
                                </span>
                              </div>
                              <div className="cxl-route-info">
                                {/*  <span className="cxl-km">10km away</span>
                                <span className="cxl-min">
                                  Arriving in 30 min
                                </span> */}
                                <img
                                  className="cxl-route-line"
                                  src={ic_route_line}
                                />
                                <img
                                  className="cxl-route-line-v"
                                  src={ic_route_line_v}
                                />
                              </div>
                              <div className="cxl-to-route">
                                <label>To</label>
                                <span>
                                  {data.load_details.trip_end_address}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <span className="cxl-cost">
                          {data.load_details.price}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="cxl-driver-details">
                      <div className="cxl-ltitle">
                        <h4>Driver Details</h4>
                        <div className="cxl-lbtns">
                          <Link
                            to={
                              '/CustomerDriverDetail/' +
                              data.driver_details.user_id
                            }
                          >
                            <button type="button" className="cx-text-btn">
                              View Details
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="cxl-lpdetails">
                        <img
                          src={
                            data.driver_details.user_profile_pic
                              ? URL + data.driver_details.user_profile_pic
                              : profile_img
                          }
                        />
                        <ul>
                          <li>
                            <h5>{data.driver_details.user_name}</h5>
                          </li>
                          <li>
                            <div className="cxl-rating">
                              {data.driver_details.rating > 0 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}

                              {data.driver_details.rating > 1 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}

                              {data.driver_details.rating > 2 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}

                              {data.driver_details.rating > 3 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}

                              {data.driver_details.rating > 4 ? (
                                <img src={ic_star_highlighted} />
                              ) : (
                                <img src={ic_star_grey} />
                              )}
                              <span>
                                ({data.driver_details.reviewed_count})
                              </span>
                            </div>
                          </li>
                          <li>
                            {/* <button type="button" className="cx-text-btn">
                              <img src={ic_call} />
                            </button>
                            <button type="button" className="cx-text-btn">
                              <img src={ic_whatsapp} />
                            </button> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cxl-load-details cxl-load-small">
                <div className="cxl-ltitle">
                  <h4>Load details</h4>
                  <div className="cxl-lbtns">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(!show)
                      }}
                      className="cx-text-btn cxl-status-stoggler"
                    >
                      Status
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setState(!state)
                      }}
                      className="cx-text-btn cxl-expand"
                    >
                      Expand
                    </button>
                  </div>
                </div>
                <div className="cxl-lpdetails">
                  <img
                    src={
                      data.load_details.thumbnail
                        ? URL + data.load_details.thumbnail
                        : booking_package_image
                    }
                  />
                  <ul className="cxl-load-ul">
                    <li>
                      <h5>{data.load_details.trip_name}</h5>
                    </li>

                    <li>
                      <label>Status</label>
                      <span>{data.load_details.trip_status}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div className="sweet-loading">
            <SpinnerCmp loading={loading} size={40} />
          </div>
        )}
      </main>
    </div>
  )
}
