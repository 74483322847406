import React, { useContext } from "react";
import { AppContext } from "../../../context/user/AppContext";
import "../../../assets/css/offers.scss";
import offerDiscount from "../../../assets/images/Group 30857.svg";

export default function Offers() {
  const { sidebar } = useContext(AppContext);
  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="offer-card-section col-12">
        <div class="container mt-3">
          <div class="row gy-5">
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div className="offer-card">
                <img src={offerDiscount} className="img-fluid" alt="" />
                <div className="d-flex justify-content-between">
                  <h5 className="offer-heading m-0">FIRSTORDER </h5>
                  <h6 className="offer-apply">Apply</h6>
                </div>
                <p className="save-amount m-0">Save 20 INR on this delivery!</p>
                <p
                  className="save-amount m-0 pt-3"
                  style={{ color: "#5F5F5F" }}
                >
                  Use code FIRSTORDER & get 10% off on <br /> orders above 150
                  INR. Maximum discount 30 INR
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
