import React, { useContext } from "react";
import { AppContext } from "../../context/user/AppContext";
import forward from "../../assets/images/ic_continue_arrow_blue.svg";
import back from "../../assets/images/ic_back_arrow_blue.svg";
import "../../assets/css/loadlocationdetails.scss";

import { Link } from "react-router-dom";
export default function PickupForm() {
  const { sidebar } = useContext(AppContext);

  return (
    <main className={sidebar ? "cx-main cx-active" : "cx-main"} id="cxMain">
      <div className="col-12">
        <div className="load-details-card col-lg-6 col-sm-12 mx-auto mt-3">
          <div>
            <h6>Drop LOCATION</h6>
          </div>
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <label className="address">Name</label>
              <input type="text" class="form-control form-control-sm" />
            </div>
            <div class="col">
              <label className="address">Phone Number</label>
              <input
                type="number"
                maxLength={9}
                class="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="col mt-3">
            <label className="address">Address</label>
            <textarea class="form-control form-control-sm" rows="2"></textarea>
          </div>
        </div>
        <div className="load-details-btn col-lg-6 col-sm-12 mx-auto mt-1">
          <div className="d-flex justify-content-between">
            <Link to="/LoadLocationDetails">
              <div className="detail-btn">
                <img className="me-1" src={back} alt="" />
                <span>Back</span>
              </div>
            </Link>
            <div className="detail-btn">
              <span className="me-1">continue</span>
              <img src={forward} alt="" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
