import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ic_back_arrow_blue from '../../../assets/images/Send Steps/ic_back_arrow_blue.svg'
import ic_continue_arrow_blue from '../../../assets/images/Send Steps/ic_continue_arrow_blue.svg'
import ic_current_location from '../../../assets/images/ic_current_location.svg'
import add_red from '../../../assets/images/add_red.svg'
import status_tick_right from '../../../assets/images/status_tick_right.svg'
import '../../../assets/css/cx-sload-steps.css'
import { AppContext } from '../../../context/user/AppContext'
import '../../../assets/css/overAllStatus.scss'
import orange_tick from '../../../assets/images/tick_orange.svg'
import drop_off_image from '../../../assets/images/drop_off_image.png'
import ic_cross_red from '../../../assets/images/ic_cross_red.svg'
import tickk from '../../../assets/images/tickk.svg'
import offer_graphics from '../../../assets/images/offer_graphics.svg'
import package_image_01 from '../../../assets/images/Send Steps/package_image_01.png'
import package_image_02 from '../../../assets/images/Send Steps/package_image_02.png'
import package_image_03 from '../../../assets/images/Send Steps/package_image_03.png'
import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import orange from '../../../assets/images/orange.png'
import swal from 'sweetalert'
import { TrendingUpRounded } from '@mui/icons-material'

const SendLoadBookingSummary = () => {
  const URL = 'https://logistic.redbytes.in/'

  const { sidebar, customerData, loadId } = useContext(AppContext)
  const [Expand, setExpand] = useState(false)
  const handleExpand = () => setExpand(!Expand)
  const [loading, setLoading] = useState(false)

  const [loadDetails, setLoadDetails] = useState([])
  const [OffersListsItem, setOffersListsItem] = useState([])
  const [OffersId, setOffersId] = useState('')
  const [tripListsItem, setTripListsItem] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    getlocationList()
  }, [])

  const getlocationList = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(
      ApiConfig.BOOKING_SUMMARY,
      JSON.stringify({
        ...customerData,
        trip_id: loadId.trip_id,
      }),
    )
      .then((data) => {
        // console.log(data);

        const data1 = data.json.locations
        setLoadDetails(data1)
        setOffersListsItem(data.json.offers)
        setTripListsItem(data.json.trip_details)
        setLoading(false)
      })
      .catch((error) => {
        console.log('api response', error)
      })
  }

  const apply = (OffersLists, e) => {
    setOffersId(OffersLists)
    simplePostCall(
      ApiConfig.BOOKING_SUMMARY_APPLY,
      JSON.stringify({
        ...customerData,
        trip_id: loadId.trip_id,
        offer_code: OffersLists,
      }),
    )
      .then((res) => {
        // console.log(res);

        if (res.message === 'offer applied') {
          applyRemove()
        } else {
          if (res.message === 'You already availed one offer') {
            swal({
              title: 'warning',
              text: res.message,
              icon: 'warning',
              button: true,
            })
          } else {
            swal({
              title: 'success',
              text: res.message,
              icon: 'success',
              button: true,
            })
          }
          // swal(res.message);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const bookLoad = () => {
    setLoading(TrendingUpRounded)
    simplePostCall(
      ApiConfig.BOOL_LOAD,
      JSON.stringify({
        ...customerData,
        load_id: loadId.trip_id,
      }),
    )
      .then((res) => {
        // console.log(res);
        if (res.result) {
          swal({
            title: 'Success',
            text: res.message,
            icon: 'success',
            button: true,
          }).then(() => {
            navigate('/Home')
          })
        } else {
          swal({
            title: 'warning',
            text: res.message,
            icon: 'warning',
            button: true,
          })
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const applyRemove = () => {
    simplePostCall(
      ApiConfig.BOOKING_SUMMARY_APPLY_REMOVE,
      JSON.stringify({
        ...customerData,
        trip_id: loadId.trip_id,
        offer_code: OffersId,
      }),
    )
      .then((res) => {
        // console.log(res);
        swal(res.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const OfferList = () => {
    simplePostCall(
      ApiConfig.OFFER_LIST,
      JSON.stringify({
        ...customerData,
      }),
    )
      .then((res) => {
        // console.log(res);
        // swal(res.message)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <main className={sidebar ? 'cx-main cx-active' : 'cx-main'} id="cxMain">
      <section id="cxs-locations" className="SendLoadBookingSummary">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="cxs-content-outer d-flex justify-content-center ">
                <div className="cxs-content-inner">
                  <div className="form-header">
                    <div className="left">Booking Summray</div>
                    <div className="right">4/4</div>
                  </div>
                  <div className="cxs-loc-content">
                    <div className="cxs-from ">
                      <div className="booking-status-main row">
                        <div className="col-lg-8 left-main">
                          <h4>LOADING / UNLOADING SEQUENCE</h4>
                          {loading ? (
                            <div
                              class="d-flex spinner-border justify-content-center"
                              style={{
                                color: '#EE651E',
                                height: '40px',
                                width: '40px',
                              }}
                            />
                          ) : (
                            <div id="stepper">
                              <div class="d-flex ">
                                <div class="d-flex flex-column  align-items-center">
                                  <div class="line-1 h-100"></div>
                                </div>
                              </div>
                              {loadDetails &&
                                loadDetails.map((loadDetailsItem, index) => {
                                  return (
                                    <div
                                      class="d-flex"
                                      key={'loadDetailsItem' + index}
                                    >
                                      <div class="d-flex flex-column  align-items-center">
                                        <div class="rounded-circle  px-3  ">
                                          {/* <img src={orange_tick} alt /> */}
                                          {loadDetailsItem.date_time ? (
                                            <img src={orange_tick} alt />
                                          ) : (
                                            <img
                                              style={{
                                                height: '23px',
                                                width: '23px',
                                              }}
                                              src={orange}
                                              alt
                                            />
                                          )}
                                        </div>
                                        {loadDetailsItem.date_time ? (
                                          <div class="line h-100"></div>
                                        ) : (
                                          <div class="line-1 h-100"></div>
                                        )}
                                      </div>
                                      <div className="stepper-content">
                                        <div className="load-status-main">
                                          <div className="load-status-name">
                                            {loadDetailsItem.address_name}
                                          </div>
                                          <div className="address">
                                            {loadDetailsItem.date_time}
                                          </div>
                                        </div>
                                        <div className="name-call">
                                          <div className="call">
                                            {/* <Link to="#">
                                            <img src={call} alt="" />
                                          </Link> */}
                                          </div>
                                        </div>
                                        <div className="address">
                                          {loadDetailsItem.address_details}
                                        </div>
                                        <div className="address">
                                          {loadDetailsItem.address_phone_no}
                                        </div>
                                        <div className="right">
                                          {Expand ? (
                                            <Link
                                              to="#"
                                              className="expand"
                                              onClick={handleExpand}
                                            >
                                              Collapse
                                            </Link>
                                          ) : (
                                            <Link
                                              to="#"
                                              className="expand"
                                              onClick={handleExpand}
                                            >
                                              Expand
                                            </Link>
                                          )}
                                        </div>

                                        <div className="package-expand-main">
                                          <div className="left">
                                            PACKAGES [
                                            {loadDetailsItem.package_count}]
                                          </div>
                                        </div>

                                        {Expand &&
                                          loadDetailsItem.package_list.map(
                                            (pickUp, index) => {
                                              return (
                                                <div key={'pickUp' + index}>
                                                  <div className="col-12 expanded-card expand-new-css">
                                                    <div className="top">
                                                      <div className="left">
                                                        {pickUp.package_name}
                                                      </div>
                                                      <div className="right">
                                                        {pickUp.type}
                                                      </div>
                                                    </div>
                                                    <div>
                                                      <span className="key">
                                                        Material Type
                                                      </span>
                                                      <span className="values">
                                                        {
                                                          pickUp.trip_material_type
                                                        }
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span className="key">
                                                        Weight
                                                      </span>
                                                      <span className="values">
                                                        {pickUp.trip_weight}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span className="key">
                                                        Quantity
                                                      </span>
                                                      <span className="values">
                                                        {' '}
                                                        {pickUp.trip_quantity}
                                                      </span>
                                                    </div>
                                                    <div className="d-flex me-2 expand-images-wrapper">
                                                      {pickUp.images.map(
                                                        (
                                                          pickupimage,
                                                          index,
                                                        ) => {
                                                          return (
                                                            <div
                                                              className="package-pics"
                                                              key={
                                                                'pickupimage' +
                                                                index
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  URL +
                                                                  pickupimage.trip_image_url
                                                                }
                                                                alt=""
                                                              />
                                                            </div>
                                                          )
                                                        },
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            },
                                          )}
                                      </div>
                                    </div>
                                  )
                                })}
                              <div class="d-flex ">
                                <div class="d-flex flex-column  align-items-center">
                                  <div class="rounded-circle  px-3  ">
                                    {/* {LoadAccepted.delivery_date_time ? (
                                    <img src={orange_tick} alt />
                                  ) : (
                                    <img
                                      style={{
                                        height: '23px',
                                        width: '23px',
                                      }}
                                      src={orange}
                                      alt
                                    />
                                  )} */}
                                    <img
                                      style={{
                                        height: '23px',
                                        width: '23px',
                                      }}
                                      src={orange}
                                      alt
                                    />
                                  </div>
                                </div>
                                <div className="stepper-content">
                                  <div className="load-status-main">
                                    <div className="load-status-name">
                                      Package Delivered
                                    </div>

                                    <div className="address">
                                      {/* {LoadAccepted.delivery_date_time} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-lg-4 right-main">
                          <h4>DELIVERY ESTIMATES</h4>
                          <div className="mb-2">
                            <div className="key-name">Delivery Cost</div>
                            <div className="key-value">
                              {tripListsItem && tripListsItem.cost}
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="key-name">
                              Estimated Delivery Time
                            </div>
                            <div className="key-value">
                              {tripListsItem && tripListsItem.estimated_time}
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="key-name">Coupon Applied</div>
                            <div className="key-value">
                              {tripListsItem && tripListsItem.coupon_applied}
                            </div>
                          </div>
                          <div className="mb-2">
                            <div className="key-name">To Pay</div>
                            <div className="key-value-orange">
                              {tripListsItem && tripListsItem.cost}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="offers-benifit-main">
                        <div className="top">
                          <div className="left">
                            <h4>OFFERS & BENEFITS</h4>
                          </div>
                          <div className="right">
                            <Link onClick={OfferList} to="#">
                              View All
                            </Link>
                          </div>
                        </div>
                        <div className="title">
                          2 offers available for this delivery
                        </div>
                        <div className="row offer-cards-main">
                          {OffersListsItem &&
                            OffersListsItem.map((OffersLists, index) => {
                              return (
                                <div className="col-lg-6 left offer-card">
                                  <div className="ribben-top" id="ribben-top">
                                    <img src={offer_graphics} alt="" />
                                    <span className="ribben-text">
                                      {OffersLists.amount_percentage}% Off
                                    </span>
                                  </div>
                                  <div className="top-heading">
                                    <div className="left">
                                      {OffersLists.name}
                                    </div>
                                    <div className="right">
                                      <Link
                                        onClick={() => {
                                          apply(OffersLists.offer_code)
                                        }}
                                        to="#"
                                      >
                                        Apply
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="save">
                                    {OffersLists.description}
                                  </div>
                                  <p>
                                    Use code {OffersLists.offer_code} & get{' '}
                                    {OffersLists.amount_percentage}% off
                                  </p>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cxs-nav">
                    <Link to="/SendLoadPackageDetails">
                      <button type="button" className="cx-text-btn">
                        <img src={ic_back_arrow_blue} />
                        <span>Back</span>
                      </button>
                    </Link>
                    <Link to="#">
                      <button
                        onClick={bookLoad}
                        type="button"
                        className="cx-text-btn"
                      >
                        {loading ? (
                          <div
                            class="spinner-border"
                            style={{
                              color: '#EE651E',
                              height: '20px',
                              width: '20px',
                            }}
                          />
                        ) : (
                          <>
                            <span>Book </span>

                            <img src={ic_continue_arrow_blue} />
                          </>
                        )}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default SendLoadBookingSummary
