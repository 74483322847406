import React, { useState, useContext, useEffect, useRef } from 'react'
import Map from '../../customer/Sent-Load/Map'
import { AppContext } from '../../../context/user/AppContext'
import ic_location from '../../../assets/images/ic_location.svg'
import GoogleMapReact from 'google-map-react'
import Geocode from 'react-geocode'
import { Link } from 'react-router-dom'
Geocode.setApiKey('AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw')
// set response language. Defaults to english.
Geocode.setLanguage('en')
Geocode.setLocationType('ROOFTOP')

// Enable or disable logs. Its optional.
Geocode.enableDebug()
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('es')

const PickupLocationMap = () => {
  const [pinend, setpinend] = useState(false)
  const [Addressmap, setAddressmap] = useState('')
  console.log(Addressmap)
  const {
    sidebar,
    place,
    setPlace,
    setLoactionsmap,
    setConfirmMap,
  } = useContext(AppContext)
  const [locationData, setLocationData] = useState({})
  console.log(locationData)
  let [marker, setMarker] = useState(null)

  const [locationMapName, setlocation] = useState('')

  console.log(locationMapName)
  useEffect(() => {
    setConfirmMap(Addressmap)
  }, [])
  console.log(place)
  useEffect(() => {
    getMyLocation()
  }, [])

  useEffect(() => {
    setLoactionsmap(Addressmap)
  }, [])

  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setPlace({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          })
        },
        (error) => {
          // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        },
      )
    }
  }

  Geocode.fromLatLng(place.center.lat, place.center.lng).then(
    (response) => {
      const address = response.results[0].formatted_address
      setAddressmap(address)

      console.log(address)
    },
    (error) => {
      console.error(error)
    },
  )

  console.log(place)
  useEffect(() => {
    console.log('location', locationData)
    if (locationData.maps && locationData.map) {
      const { map, maps } = locationData
      // or else call that isApiLoaded function and pass-on these arguments
      renderMarkers(map, maps)
    }
  }, [locationData, place])

  const getLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw`,
      {
        method: 'GET', // or 'PUT'
        headers: {
          // "Content-Type": "application/json",
          // 'Access-Control-Allow-Credentials': "*"
        },
        //body: JSON.stringify(data),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data.results[1].formatted_address)
        setPlace({
          ...place,
          center: { lat, lng },
          address: data.results[1].formatted_address,
        })
        setlocation({
          ...locationMapName,
          location: data.results[1].formatted_address,
          locationName: data.results[1].plus_code.compound_code,
          center: {
            lat,
            lng,
          },
        })
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const renderMarkers = (map, maps) => {
    marker && marker.setMap(null)

    marker = new maps.Marker({
      draggable: true,
      position: { ...place.center },
      map,
    })
    maps.event.addListener(marker, 'dragend', async function (event) {
      getLocation(event.latLng.lat(), event.latLng.lng())
    })
    setMarker(marker)

    return { marker }
  }

  return (
    <main
      className={sidebar ? 'cx-main cx-active' : 'cx-main'}
      id="cxMain"
      style={{ padding: '0', position: 'relative' }}
    >
      <div className="PickupLocationMap-main">
        <div style={{ height: ' calc(100vh - 60px)' }} id="map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyB1yP8YJ93rR-FUVlTEV4XbVcjJ8yA3ziw',
            }}
            center={place.center}
            defaultZoom={11}
            onGoogleApiLoaded={({ map, maps }) => {
              // renderMarkers({ map, maps });
              setLocationData({ map, maps })
            }}
          />
        </div>
        <div className="pickupLocation-head">
          <div className="left">Add Locations</div>
          <div className="right">2/4</div>
        </div>
        <div className="PickupLocationMap-bottom">
          <label className="title">SELECTED LOCATION</label>
          <div className="PickupLocationMap-bottom-inner">
            <div className="left">
              <div className="address">
                <img src={ic_location} alt="ic_location" className="me-2" />{' '}
                {Addressmap}
              </div>
              <div className="sub-add">{locationMapName.location}</div>
            </div>
            <div className="right">
              <Link to="/AddLocations2">Confirm </Link>
            </div>
          </div>
        </div>
        <div className="black-pickup-card">
          <div className="top">Package will be Picked up or Delivered here</div>
          <div className="bottom">Place the pin accurately on map</div>
        </div>
      </div>
    </main>
  )
}

export default PickupLocationMap
