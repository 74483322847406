import React, { useState, useEffect, useContext, useRef } from "react";
import ic_menu_options from "../../assets/images/Notifications/ic_menu_options.svg";
import ic_general_notification from "../../assets/images/Notifications/ic_general_notification.svg";
import ic_add_blue from "../../assets/images/Send Steps/ic_add_blue.svg";
import Sidebar1 from "../Sidebar1";
import "../../assets/css/cx-paymethod.css";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/user/AppContext";
import { BASE_URL } from "../../urls";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import SpinnerCmp from "../../SpinnerCmp";



export default function AddPaymentMethod(props) {
  const [show, setShow] = React.useState({ id: "" });
  const {customerData,sidebar } = useContext(AppContext);
  const [data, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  let [successdelete,setSuccessDelete]=useState(false)
  let [paymentGetwayObj,setpaymentGetwayObj]=useState({
    message:"",
    reference_no:"",
    result:"",
    url:""
  })
  let [empty,setEmpty]=useState(false)
  let [isAddCreditCard,setIsAddCreditCard]=useState(false)
  /* spinner  */
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  /* spinner */
  useEffect(() => {
    console.log(customerData);
    const fetchData = async () => {
      const response = await fetch(`${BASE_URL}customer/creditcard/list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          api_key: customerData.api_key,
          customer_id: customerData.customer_id,
          user_id: customerData.user_id,
        }),
      });
      const response_data = await response.json();
      if (response_data.result === true) {
        setData(response_data.credit_card_list);
        setLoading(false);
        console.log(response_data);
      }
      else{
        console.log(response_data);
        setLoading(false);
        setData([])
        setEmpty(true)

      }
    };
    fetchData();
  }, [successdelete]);
  const setClick = (id) => {
    if (show.id === id) {
      setShow({ id: "" });
    } else {
      setShow({ id: id });
    }
  };

const handleDelete=(id)=>{

       console.log("id"+id);
        PostCallWithErrorResponse(ApiConfig.DELETE_CREDIT_CARD_CUSTOMER, {
          ...customerData,credit_card_id:id
        })
          .then((data) => {
            console.log(data);  
            if (data.error == false) {

                setSuccessDelete(!successdelete)

            }
          })
          .catch((error) => {
            console.log("api response", error);
          });


}
const handleAddCreditCard=(id)=>{

  
  PostCallWithErrorResponse(ApiConfig.ADD_VERIFY_CREADIT_CARD_API, customerData)
    .then((data) => {
      console.log(" My Object :: ",data);
      if (data.error == false) {
        // setIsAddCreditCard(true);
        localStorage.setItem("referenceNo",data.json.reference_no);
        window.open(data.json.url, "_blank")
        // setpaymentGetwayObj(data.json)
      }
    })
    .catch((error) => {
      console.log("api response", error);
    });


}
  return (
    <div>
  

      <header className={sidebar ?  "cx-header cx-active" :"cx-header"}>
        <div className="cx-action-bar" >
          <div className="cx-no">
            <label>Saved Cards</label>
            <span>[{data ? data.length:0}]</span>
          </div>

          <div className="cx-button-grp">
            {/* <Link to="/AddPayment"> */}
              {" "}
              <button type="button" className="cx-text-btn" onClick={handleAddCreditCard}>
                <img src={ic_add_blue} />
                ADD NEW CREDIT CARD
              </button>
            {/* </Link> */}
          </div>
        </div>
      </header>
      {/* <!-- MAIN STARTS HERE --> */}
      <main className={sidebar ?  "cx-main cx-active" :"cx-main"}id="cxMain">
        <div className="sweet-loading">
      
          <SpinnerCmp   loading={loading}    
            size={40} />
        </div>
        {
            empty? <div id="" className="container col-md-12">
            <div className="cxlo-content">
              No Credit card found
            </div>
        </div>:""
        }
        <section id="cxpm-notifications">
          <div className="container">
            <div className="cxpm-notification-content">
              <div className="row">
                {data.map((data, index) => (
                  <div className="col-lg-6" key={index}>
                    <div className="cxpm-ncontent" key={index}>
                      <ul>
                        <li>
                          <h4>{data.credit_card_number.replace(/\d(?=\d{4})/g, "*")}</h4>
                        </li>
                        <li>
                          <span>{data.credit_card_name}</span>
                        </li>
                        <li>
                          <span>{data.credit_card_expiry_date}</span>
                        </li>
                      </ul>
                      {/* <div className="cx-btn-grp1" key={index}>
                        <button
                          type="button"
                          onClick={() => setClick(data.credit_card_id)}
                          className="cx-text-btn cxpm-options"
                        >
                          <img src={ic_menu_options} />
                          {show.id === data.credit_card_id ? (
                            <ul
                              id={index}
                              className={"cxpm-dropdown cx-active"}
                            >
                              <li>
                                <button
                                  onClick={() =>
                                    handleDelete(data.credit_card_id)
                                  }
                                  type="button"
                                className="mb-2 cx-text-btn cxsl-options"
                                >
                                  Delete
                                </button>
                              </li>
                              <li>
                                <a href="#">Edit</a>
                              </li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </button>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
