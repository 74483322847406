const BASE_URL = "https://logistic.redbytes.in/api/";
const CUSTOMER_PATH = "customer/";
const COMPANY_PATH = "driver/";
const CUSTOMERS_PATH = "customers/";

export const URL = "https://logistic.redbytes.in/";
export const C_URL = "https://www.jofar.com/";
const ApiConfig = {
  /* Customer API url Start*/
  CONTACT_US_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "contactus",
  ABOUT_US_API: BASE_URL + CUSTOMER_PATH + "aboutus",
  PRIVACY_API: BASE_URL + CUSTOMERS_PATH + "privacy",
  TERMS_AND_CONDITIONS_API: BASE_URL + CUSTOMER_PATH + "termsandconditions",
  ADD_LOCATION_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "address/add",
  ADD_LOCATION_LIST_API_CUSTOMERS: BASE_URL + CUSTOMERS_PATH + "address/add",
  ADD_LOCATION_SAVE_LOCATION: BASE_URL + "customer/address/add",
  GET_LOCATION_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "address/list",
  GET_LOCATION_API_CUSTOMERS: BASE_URL + CUSTOMERS_PATH + "address/list",
  CHANGE_LOCATION_PRIORITY_API_CUSTOMERS:
    BASE_URL + CUSTOMERS_PATH + "address/changepriority",
  ADD_PACKAGE_API_CUSTOMERS: BASE_URL + "customers/loadweb/addpackage",
  GET_PACKAGE_API: BASE_URL + "customers/load/packagedetails",
  DELETE_PACKAGE_API: BASE_URL + "customers/load/removepackage",
  OFFER_LIST: BASE_URL + "customers/offer/list",
  EDIT_PACKAGE_LOAD: BASE_URL + "customers/loadweb/editpackage",
  PACKAGE_LIST_API_CUSTOMERS: BASE_URL + CUSTOMERS_PATH + "load/packagelist",
  CONCEL_LOAD: BASE_URL + "customers/load/cancel",
  BOOKING_SUMMARY: BASE_URL + "customers/load/submit",
  PACKAGE_DETAILS: BASE_URL + "customers/load/packagedetails",
  BOOKING_SUMMARY_APPLY: BASE_URL + "customers/load/applyoffer",
  BOOKING_SUMMARY_APPLY_REMOVE: BASE_URL + "customers/load/removeoffer",
  BOOL_LOAD: BASE_URL + "customers/load/book",
  ADD_LOAD_APIS: BASE_URL + CUSTOMERS_PATH + "load/add",
  ADD_LOAD_CANCEL: BASE_URL + "customers/load/cancel",
  EDIT_LOAD_REQUEST: BASE_URL + "customers/editload",
  DELETE_CREDIT_CARD_CUSTOMER: BASE_URL + CUSTOMER_PATH + "creditcard/delete",
  DELETE_ADDRESS_CUSTOMER: BASE_URL + CUSTOMER_PATH + "address/delete",
  REQUESTED_LOADDATA_CUSTOMER: BASE_URL + CUSTOMER_PATH + "load/requested",
  ONGOING_LOADDATA_CUSTOMER: BASE_URL + CUSTOMER_PATH + "load/ongoing",
  COMPLETED_LOADDATA_CUSTOMER: BASE_URL + CUSTOMER_PATH + "load/completed",
  DETAILS_LOADDATA_CUSTOMER: BASE_URL + "drivers/loadlist/details",
  DRIVER_DETAILS_VIEWSTATUS: BASE_URL + "drivers/load/viewstatus",
  CONTACT_US_API_COMPANY: BASE_URL + CUSTOMER_PATH + "contactus",
  CONTACT_US_API: BASE_URL + CUSTOMER_PATH + "contactus",
  VEHICLE_LIST_API: BASE_URL + CUSTOMER_PATH + "vehicle/dropdownlist",
  ADD_LOAD_API: BASE_URL + CUSTOMER_PATH + "load/add",
  BOOK_LOAD_API: BASE_URL + CUSTOMER_PATH + "load/book",
  CUSTOMER_NOTIFICATION_API: BASE_URL + CUSTOMER_PATH + "notification",
  MAPDATA_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "map/loaddetails",
  PACKAGESTATUS_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "load/loadingstatus",
  CREDIT_CARD_LIST_API: BASE_URL + CUSTOMER_PATH + "creditcard/list",
  PAYMENTHISTORY_API_CUSTOMER: BASE_URL + CUSTOMER_PATH + "payment/history",
  RATE_AND_REVIEW_DRIVER_API: BASE_URL + CUSTOMER_PATH + "review",
  DASHBOARD_COMPANY_HOME: BASE_URL + CUSTOMER_PATH + "home",
  LOAD_SERVICE_REQUEST: BASE_URL + "customers/loadlist/requested",
  LOAD_SERVICE_REQUEST_MAP: BASE_URL + "customers/map",
  LOAD_SERVICE_ONGOING: BASE_URL + "customers/loadlist/ongoing",
  DRIVER_DETAILS_CUSTOMER: BASE_URL + "customer/driverdetails",
  LOAD_SERVICE_COMPLETED: BASE_URL + "customers/loadlist/completed",
  LOAD_SERVICE_LOAD_DETAILS_COMPLETED: BASE_URL + "customers/loadlist/details",
  LOAD_SERVICE_LOAD_STATUS_COMPLETED:
    BASE_URL + "customers/loadlist/viewstatus",
  DASHBOARD_DROPDOWN_LIST: BASE_URL + CUSTOMER_PATH + "vehicle/dropdownlist",
  VEHICLE_DROPDOWN_LIST: BASE_URL + CUSTOMER_PATH + "vehicle/dropdownlist",
  DROPDOWN_LIST_CUSTOMER_VEHICLE_TYPES:
    BASE_URL + CUSTOMER_PATH + "vehicle/vehicletypes",

  NOTIFICATION_DELETE_API:
    BASE_URL + CUSTOMER_PATH + "notification/bulk_delete",
  ADD_VERIFY_CREADIT_CARD_API: BASE_URL + CUSTOMER_PATH + "creditcard/verify",
  ADD_VERIFY_CREADIT_CARD_CHECK_STATUS_API:
    BASE_URL + CUSTOMER_PATH + "creditcard/checkstatus",

  /* Customer API url End*/

  /* Company API url Start*/

  // Drivers API url
  REGISTER_COMPANY_API: BASE_URL + COMPANY_PATH + "registerweb",
  DASHBOARD_COMPANY_API: BASE_URL + COMPANY_PATH + "home/company",
  DASHBOARD_DRIVER_API: BASE_URL + COMPANY_PATH + "home/driver",
  REQUESTED_LOADDATA_DRIVER: BASE_URL + "drivers/loadlist/requested",
  ONGOING_LOADDATA_DRIVER: BASE_URL + "drivers/loadlist/ongoing",
  COMPLETED_LOADDATA_DRIVER: BASE_URL + "drivers/loadlist/completed",
  DETAILS_LOADDATA_COMPANY: BASE_URL + COMPANY_PATH + "load/details",
  DETAILS_LOADDATA_DRIVER: BASE_URL + "drivers/map",
  CONTACT_US_API_CUSTOMER_COMPANY: BASE_URL + COMPANY_PATH + "contactus",
  ABOUT_US_API_COMPANY: BASE_URL + COMPANY_PATH + "aboutus",
  PRIVACY_API_COMPANY: BASE_URL + COMPANY_PATH + "privacy",
  TERMS_AND_CONDITIONS_API_COMPANY:
    BASE_URL + COMPANY_PATH + "termsandconditions",
  EARNING_API_COMPANY: BASE_URL + COMPANY_PATH + "stats",
  DRIVER_LIST_API_COMPANY: BASE_URL + COMPANY_PATH + "adddriver/list",
  DRIVER_MAP_API_COMPANY: BASE_URL + "drivers/map/list",
  DRIVER_MAP_API_COMPANY_ONGOING: BASE_URL + "drivers/map",
  PAYMENTHISTORY_API_COMPANY: BASE_URL + COMPANY_PATH + "payment/history",
  ALL_LOAD_STATUS_API_COMPANY: BASE_URL + COMPANY_PATH + "load/list",
  ALL_LOAD_STATUS_API_COMPANY_NEW: BASE_URL + "drivers/loadlist/ongoing",
  ASSIGN_DRIVER_TO_LOAD: BASE_URL + COMPANY_PATH + "load/assign",
  COMPANY_MAP_STATUS: BASE_URL + COMPANY_PATH + "map/loaddetails",
  COMPANY_PACKAGE_STATUS: BASE_URL + COMPANY_PATH + "load/loadingstatus",
  COMPANY_DRIVER_DETAIL: BASE_URL + COMPANY_PATH + "adddriver/details",
  COMPANY_NOTIFICATIONS: BASE_URL + COMPANY_PATH + "notification",
  COMPANY_DELETE_NOTIFICATION:
    BASE_URL + COMPANY_PATH + "notification/bulk_delete",

  DROPDOWN_LIST_COMPANY_API:
    BASE_URL + COMPANY_PATH + "vehicle/dropdownlistweb",
  COMPANY_ADD_DRIVER_API: BASE_URL + COMPANY_PATH + "adddriver",
  COMPANY_DELETE_DRIVER_API: BASE_URL + COMPANY_PATH + "adddriver/delete",
  COMPANY_ADD_VEHICLE_API: BASE_URL + COMPANY_PATH + "vehicleweb/add",
  COMPANY_ADD_BANK_ACCOUNTS_API: BASE_URL + COMPANY_PATH + "bankaccount/add",
  COMPANY_EDIT_BANK_ACCOUNTS_API: BASE_URL + COMPANY_PATH + "bankaccount/edit",
  COMPANY_BANK_ACCOUNTS_API: BASE_URL + COMPANY_PATH + "bankaccount/list",
  COMPANY_FORGOT_PASSWORD_API: BASE_URL + COMPANY_PATH + "forgotpassword",
  COMPANY_RESET_PASSWORD_API: BASE_URL + COMPANY_PATH + "resetpassword",
  COMPANY_VEHICLE_LIST_API: BASE_URL + COMPANY_PATH + "vehicle/list",
  COMPANY_DELETE_VEHICLE_API: BASE_URL + COMPANY_PATH + "vehicle/delete",
  COMPANY_ASSSIGN_VEHICLE_TO_DRIVER_API:
    BASE_URL + COMPANY_PATH + "vehicle/assigndriver",
  COMPANY_ASSSIGN_DRIVER_TO_VEHICLE_API:
    BASE_URL + COMPANY_PATH + "adddriver/assignvehicle",
  COMPANY_EDIT_VEHICLE_API: BASE_URL + COMPANY_PATH + "vehicleweb/edit",
  COMPANY_VEHICLE_DETAILS_API: BASE_URL + COMPANY_PATH + "vehicle/details",
  COMPANY_REJECT_LOAD: BASE_URL + COMPANY_PATH + "load/reject",
  COMPANY_REGISTER_API: BASE_URL + COMPANY_PATH + "register",
  DROPDOWN_LIST_COMPANY_VEHICLE_TYPES:
    BASE_URL + COMPANY_PATH + "vehicle/vehicletypes",
  DROPDOWN_LIST_COMPANY_VEHICLE_CAPACITIES:
    BASE_URL + COMPANY_PATH + "vehicle/vehiclecapacities",
  DROPDOWN_LIST_COMPANY_VEHICLE_LENGTH:
    BASE_URL + COMPANY_PATH + "vehicle/vehiclelengths",
  CONTACT_LANDING: BASE_URL + CUSTOMER_PATH + "contactus/web",
  DROPDOWN_LIST_CUSTOMER_VEHICLE_TYPES:
    BASE_URL + CUSTOMER_PATH + "vehicle/vehicletypes",
  DROPDOWN_LIST_CUSTOMER_VEHICLE_CAPACITIES:
    BASE_URL + CUSTOMER_PATH + "vehicle/vehiclecapacities",
  DROPDOWN_LIST_CUSTOMER_VEHICLE_LENGTH:
    BASE_URL + CUSTOMER_PATH + "vehicle/vehiclelengths",
  CREDIT_CARD_SET_DEFAULT: BASE_URL + CUSTOMER_PATH + "vehicle/vehiclelengths",
  /* Company API url End*/

  // Driver Api
};
export default ApiConfig;
