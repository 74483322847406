import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import arrow_white from '../assets/images/login/arrow_white.svg'
import eye_closed from '../assets/images/login/eye_closed.svg'
import eye_opened from '../assets/images/login/eye.svg'
import '../assets/css/cx-fpassword.css'
import { BASE_URL } from '../urls'
import LoadingBar from 'react-top-loading-bar'
import { AppContext } from '../context/user/AppContext'
import ApiConfig from '../api/ApiConfig'
import swal from 'sweetalert'

export default function UpdatePass() {
  const [eye, setEye] = useState(false)
  const [eye1, setEye1] = useState(false)
  const { setForgotData, forgotData } = useContext(AppContext)
  const [progress, setProgress] = useState(0)

  const navigate = useNavigate()
  const [passwordData, setPasswordData] = useState({
    new_password: '',
    confirm_password: '',
  })
  useEffect(() => {
    setProgress(100)
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault()
    setProgress(50)
    const { new_password, confirm_password } = e.target
 
    const response = await fetch(
      forgotData.role === 'customer'
        ? `${BASE_URL}customer/resetpassword`
        : ApiConfig.COMPANY_RESET_PASSWORD_API,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify({
          username: forgotData.username,
          token: forgotData.otp,
          new_password: new_password.value,
          confirm_password: confirm_password.value,
        }),
      },
    )
    const response_data = await response.json()

    if (response_data.result === true) {
  
      swal('Password updated successfully..')
      setForgotData({ otp: '', username: '', role: '' })
      setPasswordData({ new_password: '', confirm_password: '' })
      navigate('/Login')
    } else {
      swal(response_data.message)
    }
  }

  const handleChange = (e) => {
    console.log(e.target.value)
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <section id="cx-fpass-three">
        <LoadingBar
          color="navy"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 d-flex justify-content-center">
              <div className="cx-fpass-content">
                <h2>Forgot Password</h2>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="cx-form-group">
                    <label className="cx-fnpass">Enter New Password</label>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={passwordData.new_password}
                      type={eye ? 'text ' : 'password'}
                      name="new_password"
                      className="form-control"
                      id="cx-fnpass"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={() => setEye(!eye)}
                      className="cx-text-btn cx-eye-btn"
                    >
                      <img
                        src={eye ? eye_opened : eye_closed}
                        className="cx-eye cx-show"
                        alt="view-password"
                      />
                    </button>
                  </div>
                  <div className="cx-form-group">
                    <label className="cx-fcpass">Confirm New Password</label>
                    <input
                      onChange={(e) => handleChange(e)}
                      value={passwordData.confirm_password}
                      type={eye1 ? 'text ' : 'password'}
                      name="confirm_password"
                      className="form-control"
                      id="cx-fcpass"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={() => setEye1(!eye1)}
                      className="cx-text-btn cx-eye-btn"
                    >
                      <img
                        src={eye1 ? eye_opened : eye_closed}
                        className="cx-eye cx-show"
                        alt="view-password"
                      />
                    </button>
                  </div>
                  <div className="cx-btn-grp1">
                    <button type="submit" className="cx-fpass-btn">
                      <span>Continue</span>
                      <img src={arrow_white} />
                    </button>
                  </div>
                  <div className="cx-log">
                    <Link to="/Login">
                      {' '}
                      <button type="button" className="cx-text-btn">
                        Go to login
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
